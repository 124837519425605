import React, { useState, useEffect } from 'react';
import { Grid, Typography, Button, Box, TextField, Rating, CardMedia, Chip, ToggleButtonGroup, Link, ToggleButton, FormControl, FormHelperText } from '@mui/material';
import { Container } from '@mui/system';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useLocation,useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import DOMPurify from 'dompurify';
import helper from '../../../utils/helper';

import NinjaBundleImg from '../../../assets/course/Ninja.jpg';
import Page from '../../../components/Page';
import trialAndStripeSubscriptionService from '../../../services/trialAndStripeSubscriptionService';
import {
  openSubscriptionModal,
  setOpenSubscriptionModalDetails,
  openSnackbar,
  setSubscribedCourses,
} from '../../../store/reducer';

const StyledCard = styled(Card)({
    display: 'flex',
    padding: '16px',
    borderRadius: '16px',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
    // maxWidth: 600,
    width: '96%',
    margin: 'auto',
});
const BundleDetails = () => {
    const location = useLocation()
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [viewPrice, setViewPrice] = useState(0);
    const [loadinButton, setLoadinButton] = useState(false);

    
    const [intfrequency, setintfrequency] = React.useState(1);
    const allcourseDetails = useSelector((state) => state);
    const userRole = useSelector((state) => state.userInfo && state.userInfo.role);
    React.useMemo(() => {
        const filteredList = location?.state?.item?.prices.filter(data =>
            allcourseDetails?.currency === 'USD'
                ? data?.currencyType?.id === 2
                : data?.currencyType?.id === 1
        );


        let displayPrice;

        if (filteredList && filteredList?.length > 0 && allcourseDetails?.currency === 'USD') {
            displayPrice = `$${filteredList[0]?.actualCost}`;
        } else if (filteredList && filteredList?.length > 0 && allcourseDetails?.currency === 'INR') {
            displayPrice = `₹${filteredList[0]?.actualCost}`;
        } else if (filteredList && filteredList?.length === 0) {
            displayPrice = 'NAN'
        }
        setViewPrice(displayPrice)

    }, [allcourseDetails])

    console.log(location.state?.item, "vlocationlocation");


    const postSubscribeDetails = async (item, planId) => {
        setLoadinButton(true)
        const courseId = item.id;
        if (item?.isPaid) {
          // dispatch(setOpenSubscriptionModalDetails(item));
          // dispatch(openSubscriptionModal(planId));
          let impactClickId = '';
          try {
            impactClickId = await helper.getClickId();
          } catch (error) {
            console.error(error);
            impactClickId = '';
          }
    
          const payload = {
            courseId,
            planId,
            currencyType: allcourseDetails?.currency,
            frequency: parseInt(intfrequency, 10),
            impactClickId,
          };
          console.log(payload, 'payloadpayload');
    
          try {
            const res = await trialAndStripeSubscriptionService.createStripeSubscription(JSON.stringify(payload));
            if (res.status === 303) {
              // dispatch(closeSubscriptionModal());
              // setSubscriptionModalLoading(false);
              setLoadinButton(false)
              window.location = res.data.paymentUrl;
            } else  if (res?.data?.message) {
                setLoadinButton(false)
                dispatch(openSnackbar(res.data.message));
              } else {
                setLoadinButton(false)
                dispatch(openSnackbar('Failed to Subscribe, Please try again.'));
              }
              // setSubscriptionModalLoading(false);
            
          } catch (error) {
            setLoadinButton(false)

            console.log(error);
          }
        } else {
        //   setIsSubscribing(planId);
        setLoadinButton(true)

          try {
            const res = await trialAndStripeSubscriptionService.createStripeSubscription(JSON.stringify({ planId }));
            if (res.ok) {
                setLoadinButton(false)

              navigate('/auth/subscribe');
            //   setCardView("MyLearning")
            //   setOpenNewCourses(false)
              // setCardViewSubMenu("MyLearning")
              dispatch(openSnackbar('Course subscribed successfully'));
            } else if (res.status === 400) {
                setLoadinButton(false)

              dispatch(openSnackbar(res.data.message));
            } else {
                setLoadinButton(false)

              dispatch(openSnackbar('Failed to subscribe, please try again'));
            }
          } catch (error) {
            setLoadinButton(false)

            console.log(error);
          }
        //   setIsSubscribing(null);
        }
      };


      const handleCardClickItem = (data) => {
        if (userRole === 'USER_DTC') {
          // navigate('/auth/course-details', { state: data });
          navigate('/auth/course-details', { 
            state: { 
              ...data, 
              // subscriptionPlanId: 129,
              subscriptionPlanId: data?.planDetails?.id,
            } 
          });
        } else {
          // navigate('/app/course-details', { state: data });
          navigate('/auth/course-details', { 
            state: { 
              ...data, 
              subscriptionPlanId: data?.planDetails?.id,
            } 
          });
        }
      };

    return (
        <Page title={'Bundle-details'}>
            <Grid style={{ backgroundColor: '#F8F9FA' }} item xs={12} sm={12} mt={2}>
                <Typography variant="h4" sx={{ marginBottom: 4, textAlign: 'center' }}>{location.state?.item?.subscriptionPlanName}({viewPrice})</Typography>
                {/* <Typography variant="h5" sx={{ marginBottom: 4, textAlign: 'left', marginLeft: '40px' }}>Include Courses</Typography> */}
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h5" sx={{ marginBottom: 4, textAlign: 'left', marginLeft: '40px', flexGrow: 1 }}>
                        Include Courses
                    </Typography>
                    {/* <Button variant="contained" sx={{ marginRight: 4 }}>
                        Button Text
                    </Button> */}

                    
                    
                    {loadinButton === false ?
                    <><Button
                    variant="contained"
                    sx={{
                        marginRight: 4,
                      backgroundColor: '#002B50',
                    //   borderRadius: 0,
                      '&:hover': {
                        backgroundColor: '#002B50',
                      },

                    }}
                    onClick={() => { postSubscribeDetails(location?.state?.item, location?.state?.item?.subscriptionPlanId)}} 

                  >
                    Enroll Now
                  </Button>
                  </>
                  :
                  <>
                  <Button
                    variant="contained"
                    sx={{
                        marginRight: 4,
                      backgroundColor: '#002B50',
                    //   borderRadius: 0,
                      '&:hover': {
                        backgroundColor: '#002B50',
                      },

                    }}

                  >
                    Loading...
                  </Button>
                  </>}
                </Box>
                <Box className="pannelParent" style={{boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',borderRadius: '16px', padding: '35px 0px 15px', margin: '0px 30px'}}>
                {location && location.state.item?.courseObj && location.state?.item?.courseObj?.map((data) => (

                    <Grid style={{ height: '100%', marginBottom: '20px'}}>
                        <Box sx={{ textAlign: 'center' }}>
                            <StyledCard sx={{ height: '300px' }}>
                                <Box sx={{ position: 'relative', width: '30%', height: '100%', minWidth: '290px' }}>
                                    <CardMedia
                                        component="img"
                                        height="100%"
                                        width="100%"
                                        image={data?.thumbImage}
                                        alt="Course Image"
                                        sx={{ objectFit: 'inherit',cursor:'pointer' }}
                                      //   onClick={() => {
                                      //     if (props.data?.is_free || (props.data.subscriptionPlanId || props.data.subscriptionplanid !== null)) {
                                      //         props.handleCardClick();
                                      //     }
                                      // }}

                                      onClick={() => handleCardClickItem(data)}
                                    />
                                </Box>
                                <CardContent
                                    sx={{
                                        width: '60%',
                                        height: '95%', overflow: 'hidden',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        gap: 2,
                                    }}
                                >
                                    <Box sx={{ textAlign: 'left' }}>
                                        <Typography variant="h6" component="div" marginBottom={3}>
                                            {data?.title}
                                        </Typography>
                                        {/* <Typography variant="body2" color="textSecondary" component="p">
                                    {data?.description}</Typography> */}
                                        <Typography
                                            variant="body2" component="p"
                                            sx={{
                                                flexGrow: 1,
                                                fontSize: '0.8rem',
                                            }}
                                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data?.description) }}
                                        />
                                    </Box>
                                    {/* <Box display="flex" alignItems="center">
                                    <Rating
                                        name="simple-controlled"
                                        value={4.5}
                                        precision={0.5}
                                        readOnly
                                    />
                                    <Typography variant="body2" sx={{ ml: 0.5 }}>4.8</Typography>
                                    <Typography variant="body2" sx={{ ml: 1 }}>(12,000+)</Typography>
                                    <Chip label="Bestseller" size="small" color="warning" sx={{ ml: 1 }} />
                                </Box> */}

                                    {/* <Box display="flex" alignItems="center">
                                        <Typography sx={{ fontSize: '26px', fontWeight: 'bold', color: '#20b2aa' }}>{viewPrice}</Typography>
                                    </Box> */}
                                </CardContent>
                            </StyledCard>
                        </Box>
                    </Grid>
                
                ))}
                    </Box>
            </Grid>
        </Page>
    )
}
export default BundleDetails;