
import React, { useState, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { TextField, Button, Chip, Box,Badge,Avatar, Paper, Typography, InputAdornment, IconButton, FormHelperText, InputLabel, Select, FormControl,Grid } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { LoadingButton } from '@mui/lab';
import { Form, Formik } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import * as Yup from 'yup';
import { DropzoneArea } from 'material-ui-dropzone';
import DOMPurify from 'dompurify';
import { makeStyles } from '@mui/styles';
import DialogModal from '../../../components/modal/DialogModal';
import SnackBar from '../../../components/snackbar/snackbar';
import adminServices from '../../../services/adminServices';

const GenericAssessmentAdd = ({ open, onClose, callBack }) => {
  const scrollbarStyles = {
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#c1c1c1',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#a1a1a1',
    },
    scrollbarWidth: 'thin',
    scrollbarColor: '#c1c1c1 #f1f1f1',
  };
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [questionList, setQuestionList] = useState([]);
  const [hashtag, setHashtag] = useState([]);
  const [category, setCategory] = useState('');
  const [tags, setTags] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [isSearched, setIsSearched] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarTitle, setSnackbarTitle] = useState('');
  const [name, setName] = useState('');
  const [complexity, setComplexity] = useState('');
  const [nameError, setNameError] = useState('');

  const [ImageError, setImageError] = useState('');
  const [categoryError, setCategoryError] = useState('');
  const [ComplexityError, setComplexityError] = useState('');


  const [nodata, setNodata] = useState(true);
  const [categoryList,setCategoryList] = useState([])

  const [thumbImage, setThumbImage] = useState(null);
  const [thumbPreview, setThumbPreview] = useState(null);
  const [description, setDescription] = useState("");
  const [editorValue, setEditorValue] = useState('');
  console.log(editorValue,"editorValue");
  

  const handleChangeDescription = (value) => {
    setEditorValue(value);
  };


  

  // useEffect(async () => {
  //   await getLookUpDetails();
  // }, []);

  useEffect(() => {
    getCategory()
    setLoading(false)
  }, [open]);

  const handleAddTag = () => {
    if (searchTerm && !tags.includes(searchTerm)) {
      setTags([...tags, searchTerm]);
      getQuestionList([...tags, searchTerm]);
      setIsSearched(true);
    }
    setSearchTerm("");
  };

  const handleRemoveTag = (index) => {
    const newTags = tags.filter((_, i) => i !== index);
    setTags(newTags);
    // filterQuestions(newTags);
    // getQuestionList(newTags);
    console.log(searchTerm, "searchTermsearchTerm");
    console.log(newTags, "newTags");

    if (newTags?.length > 0) {
      getQuestionList([...newTags]);
    }
    else {
      setQuestionList([])
    }

    setIsSearched(false);
  };


  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleAddQuestion = (question) => {
    if (!selectedQuestions.some(q => q.id === question.id)) {
      setSelectedQuestions([...selectedQuestions, question]);
    }
  };

  const handleRemoveQuestion = (question) => {
    setSelectedQuestions(selectedQuestions.filter(q => q.id !== question.id));
  };


  const getQuestionList = async (data) => {
    setNodata(false)
    const result = await adminServices.getQuestionDataMCQ(data);
    if (result.ok) {
      if(result.data?.length === 0){
        setNodata(true)
      }
      else{
        setQuestionList(result.data)
        setNodata(false)
      }
    }
  };

  const handleFileChange = (file) => {
    setImageError("")
    if (file[0]?.size < 2097152) { 
      imageWidthAndHeight(file[0]).then((res) => {
        if (res.width >= 360 && res.height >= 200) { 
          const url = URL.createObjectURL(file[0]);
          setThumbPreview(url);
          setThumbImage(file[0]);
        } else {
          alert("Image dimensions must be at least 360x200px.");
        }
      });
    } 
    // else {
    //   alert("File size exceeds the 2MB limit.");
    // }
  };

  const imageWidthAndHeight = (file) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      const reader = new FileReader();

      reader.onload = function () {
        img.onload = function () {
          resolve({ width: img.width, height: img.height });
        };
        img.src = reader.result;
      };
      reader.readAsDataURL(file);
    });
  };

     const getCategory = async () => {
          const result = await adminServices.getCategoryLists();
          if (result.ok) {
              setCategoryList(result.data)
          }
      };
      

  // const getLookUpDetails = async () => {
  //   const key = ['hashtag', 'course_category'];
  //   const result = await adminServices.getLookUpData(key);
  //   if (result.ok) {
  //     setHashtag(result.data.hashtag);
  //     setCategory(result.data.course_category);
  //   }
  // };


  const Validation = () => {
    if (!name) {
      setNameError("This field is required")
      return false
    }
    if (!category) {
      setCategoryError("This field is required")
      return false
    }
    if (!complexity) {
      setComplexityError("This field is required")
      return false
    }
    
    if(!thumbImage){
      setImageError("This field is required")
      return false 
    }
    return true
  }

  const handleSubmit = async () => {
    const valid = Validation()
    if (valid) {
      setLoading(true);
      const selectedquestions = selectedQuestions.map((data) => data.id)
      const totalSeconds = (time.hours * 3600) + (time.minutes * 60) + time.seconds;
    
    
      const formData = new FormData();
      formData.append('name', name);
      formData.append('complexity_level', complexity);
      formData.append('description', editorValue);
      formData.append('time_in_mins', totalSeconds);
      formData.append('selectedquestions', selectedquestions);
      formData.append('category', category);
      formData.append('thumbImage', thumbImage);
      try {
        const response = await adminServices.genericAssessMent(formData);
        if (response.ok) {
          setSnackbarTitle('Assignment created successfully');
          setOpenSnackbar(true);
          CloseFunction()
          setName('')
          setLoading(false);
          callBack()
        }

      } catch (error) {
        console.log(error);
      }

    }
  };

  const CloseFunction = () => {
    setComplexity("")
    setCategory("")
    setName("")
    setEditorValue("")
    setQuestionList([])
    setSelectedQuestions([])
    setTags([])
    onClose()
    setIsSearched(false);
    setNameError("")
    setCategoryError("")
    setComplexityError("")
    setImageError("")
    setDescription("")
    setThumbPreview(null)
    setThumbImage(null)
  }

  const [time, setTime] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setTime((prevTime) => ({
      ...prevTime,
      [name]: value,
    }));
  };

  const hoursArray = Array.from({ length: 24 }, (_, i) => i); 
  const minutesArray = Array.from({ length: 60 }, (_, i) => i); 
  const secondsArray = Array.from({ length: 60 }, (_, i) => i);

 

  return (
    <>
      <DialogModal open={open} handleClose={CloseFunction} title="General Assessment">
        <Box sx={{ mb: 2 }}>
          <TextField
            variant="outlined"
            inputProps={{ maxLength: 50 }} 
            fullWidth
            id="addname"
            placeholder="Name"
            value={name}
            onChange={(e) => { setName(e.target.value); setNameError(''); }}
            sx={{
              bgcolor: "#f0f0f0",
              borderRadius: 1,
              height: 36,
              '& .MuiInputBase-input': {
                fontSize: 14,
                padding: "8px 12px",
              },
            }}
            error={!!nameError}
          />
          {nameError && (
            <FormHelperText error>{nameError}</FormHelperText>
          )}
        </Box>

        <Box sx={{ mb: 2 }}>
  <FormControl fullWidth variant="outlined" sx={{ bgcolor: "#f0f0f0", borderRadius: 1 }}>
    <InputLabel id="complexity-level-label">Complexity Level</InputLabel>
    <Select
      labelId="complexity-level-label"
      id="addnamecomplexity"
      value={complexity || ""}
      onChange={(e) => {setComplexity(e.target.value);setComplexityError("")}}
      label="Complexity Level"
      error={!!ComplexityError}
      sx={{
        height: 36,
        '& .MuiSelect-select': {
          fontSize: 14,
          padding: "8px 12px",
        },
      }}
    >
      <MenuItem value="">Select Complexity</MenuItem> 
      <MenuItem value="easy">Easy</MenuItem>
      <MenuItem value="medium">Medium</MenuItem>
      <MenuItem value="hard">Hard</MenuItem>
    </Select>
    {ComplexityError && <FormHelperText error>{ComplexityError}</FormHelperText>}

  </FormControl>
</Box>

        <Box sx={{ mb: 2 }}>
          <FormControl fullWidth variant="outlined" sx={{ bgcolor: "#f0f0f0", borderRadius: 1 }}>
            <InputLabel id="category-label">Category</InputLabel>
            <Select
              labelId="category-label"
              id="category-select"
              value={category}
              onChange={(e) => { setCategory(e.target.value); setCategoryError(''); }}
              label="Category"
              error={!!categoryError}
              sx={{
                height: 36,
                '& .MuiSelect-select': {
                  fontSize: 14,
                  padding: "8px 12px",
                },
              }}
            >
              {categoryList.map((cat, index) => (
                <MenuItem key={index} value={cat.id}>{cat.description}</MenuItem>
              ))}
            </Select>
            {categoryError && <FormHelperText error>{categoryError}</FormHelperText>}
          </FormControl>
        </Box>

 
        <Box sx={{ mb: 2 }}>
      {thumbPreview === null ? (
        <FormControl
          required
          component="fieldset"
          color="primary"
          variant="outlined"
          fullWidth
          name="thumbImage"
        >
          <Typography variant="subtitle1">Thumb Image* (360X200)</Typography>
          <DropzoneArea
            acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
            showPreviews={false}
            dropzoneText="Drag and Drop Image or Browse File"
            showPreviewsInDropzone={false}
            maxFileSize={300000000}
            filesLimit={1}
            showAlerts={false}
            onChange={handleFileChange}
            useChipsForPreview
            previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
            showFileNamesInPreview
          />
       {ImageError && <FormHelperText error>{ImageError}</FormHelperText>}

        </FormControl>
      ) : (
        <div className={classes.imgPreviewRoot}>
          <Typography variant="subtitle1">Thumb Image</Typography>
          <Badge
            badgeContent={
              <CloseIcon
                className={classes.badgeAlign}
                onClick={() => {
                  setThumbPreview(null);
                  setThumbImage(null);
                }}
              />
            }
          >
            <Avatar
              variant="rounded"
              src={thumbPreview}
              className={thumbPreview !== null && classes.fileImgSIze}
            />
          </Badge>
        </div>
      )}
      </Box>

      <Box sx={{ mb: 4}}>
      <Typography variant="subtitle1">Description</Typography>

      <ReactQuill
        value={editorValue}
        onChange={handleChangeDescription}
        style={{ height: '150px',marginBottom:'80px' }}
      />
      </Box>

      <Box sx={{ mb: 4}}>
        {selectedQuestions.length > 0 && (
          <>
            <Typography
              variant="body2"
              sx={{ fontSize: '0.875rem', textDecoration: 'underline', mb: 1,mt:1 }}
            >
              Selected Questions
            </Typography>
            <Paper
              sx={{
                mb: 1,
                mt:1,
                // p: 1,
                bgcolor: "#e3faf4",
                width: '100%',
                maxHeight: selectedQuestions.length > 4 ? '150px' : 'auto',
                overflowY: 'auto',
                ...scrollbarStyles,
              }}
            >
              {selectedQuestions.map((question) => (
                <Box key={question.id} sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                  {/* <Typography variant="body2" sx={{ flexGrow: 1, fontSize: '0.75rem' }}>
                    {question.question_text}
                  </Typography> */}
                  <Typography
                      id='questionviewadd1'
                      variant="body2"
                      sx={{
                        flexGrow: 1,
                        fontSize: '0.75rem',
                      }}
                      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(question.question_text) }}
                    />
                  <Button
                    variant="outlined"
                    color="error"
                    id="removeaddquestiontag"
                    onClick={() => handleRemoveQuestion(question)}
                    sx={{
                      fontSize: '0.75rem',
                      minWidth: '24px',
                      minHeight: '24px',
                      padding: '2px',
                      borderRadius: '12px',
                      '& .MuiButton-label': { fontSize: '1rem' },
                    }}
                  >
                    x
                  </Button>
                </Box>
              ))}
            </Paper>
          </>
        )}
       

        <Paper sx={{ mb: 2, p: 1, bgcolor: "#f7f7f7", width: '100%' }}>
          <Box sx={{ display: "flex", mb: 1,mt:1 }}>
            <TextField
              variant="outlined"
              fullWidth
              id="searchaddquestion"
              placeholder="Search Questions..."
              value={searchTerm}
              onChange={handleSearchChange}
              sx={{
                bgcolor: "#f0f0f0",
                borderRadius: 1,
                height: 36,
                '& .MuiInputBase-input': {
                  fontSize: 14,
                  padding: "8px 12px",
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton id="searchiconadd" onClick={handleAddTag} sx={{ p: 0, color: "#1976d2" }}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, mb: 1 }}>
            {tags.map((tag, index) => (
              <Chip
                key={index}
                label={tag}
                onDelete={() => handleRemoveTag(index)}
                color="primary"
                size="small"
                sx={{ fontSize: 10 }}
              />
            ))}
          </Box>

          {isSearched && questionList?.length === 0 ? (
            <Typography variant="body2" sx={{ textAlign: 'center', color: 'text.secondary' }}>
             {nodata === false ?"loading...":"No records found"}
            </Typography>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                maxHeight: questionList.length > 4 ? '140px' : 'auto',
                overflowY: 'auto',
                ...scrollbarStyles,
              }}
            >
              {questionList?.map((question) => {
                const isSelected = selectedQuestions.some((selected) => selected.question_text === question.question_text);

                return (
                  <Box key={question.id} sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    {/* <Typography
                id='questionview1'
                variant="body2"
                sx={{
                  flexGrow: 1,
                  fontSize: '0.75rem',
                  bgcolor: isSelected ? "#cfe0ff" : "transparent",
                  padding: '4px',
                  borderRadius: '4px',
                }}
              >
                {question.question_text}
              </Typography> */}
                    <Typography
                      id='questionviewadd'
                      variant="body2"
                      sx={{
                        flexGrow: 1,
                        fontSize: '0.75rem',
                        bgcolor: isSelected ? "#cfe0ff" : "transparent",
                        padding: '4px',
                        borderRadius: '4px',
                      }}
                      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(question.question_text) }}
                    />
                    <Button
                      id='questionadd1'
                      variant="outlined"
                      color="primary"
                      onClick={() => handleAddQuestion(question)}
                      sx={{
                        fontSize: '0.75rem',
                        minWidth: '24px',
                        minHeight: '24px',
                        padding: '2px',
                        borderRadius: '12px',
                        '& .MuiButton-label': { fontSize: '1rem' },
                      }}
                    >
                      +
                    </Button>
                  </Box>
                );
              })}
            </Box>
          )}
        </Paper>
        </Box>

        <Grid style={{ marginBottom: 1 }} container spacing={2}>
          {/* Hours */}
          <Grid item xs={2.5}>
            <FormControl fullWidth>
              <InputLabel>Hours</InputLabel>
              <Select
                name="hours"
                value={time.hours}
                onChange={handleChange}
                label="Hours"
              >
                {hoursArray.map((hour) => (
                  <MenuItem key={hour} value={hour}>
                    {hour}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Minutes */}
          <Grid item xs={2.5}>
            <FormControl fullWidth>
              <InputLabel>Minutes</InputLabel>
              <Select
                name="minutes"
                value={time.minutes}
                onChange={handleChange}
                label="Minutes"
              >
                {minutesArray.map((minute) => (
                  <MenuItem key={minute} value={minute}>
                    {minute}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Seconds */}
          <Grid item xs={2.5}>
            <FormControl fullWidth>
              <InputLabel>Seconds</InputLabel>
              <Select
                name="seconds"
                value={time.seconds}
                onChange={handleChange}
                label="Seconds"
              >
                {secondsArray.map((second) => (
                  <MenuItem key={second} value={second}>
                    {second}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Box sx={{ display: "flex", justifyContent: "flex-start", mt: 2, ml: 1.4 }}>
          {selectedQuestions.length > 0 && (
            <LoadingButton
              type="submit"
              id='addassessmentgeneral'
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              fullWidth
              loading={loading}
            >
              Submit
            </LoadingButton>
          )}
        </Box>
      </DialogModal>

      <SnackBar open={openSnackbar} snackbarTitle={snackbarTitle} close={() => setOpenSnackbar(false)} />

    </>

  );
}

const useStyles = makeStyles((theme) => ({
  imgPreviewRoot: {
    borderRadius: '10px',
    padding: '0.8rem;',
  },
  fileImgSIze: {
    width: '100%',
    height: '120px',
    objectFit: 'cover',
    objectPosition: 'center',
    border: '1px solid #fff',
    borderRadius: '5px',
    boxShadow: '0 3px 10px rgb(0 0 0 / 20%)',
  },
  badgeAlign: {
    boxShadow: '0 2px 8px -5px #ff0000',
    color: '#FF0000',
    fontSize: '1.2rem',
    backgroundColor: '#fff',
    padding: '2px',
    borderRadius: '10px',
    cursor: 'pointer',
  },
  deleteLabel: {
    width: 'max-content',
    cursor: 'pointer',
  },
}));
export default GenericAssessmentAdd;