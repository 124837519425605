import React, { useEffect, useState, useRef } from 'react';
import katex from "katex";
import "katex/dist/katex.min.css";

import {
  Avatar,
  Badge,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  RadioGroup,
  Radio,
  Link,
  TextField,
  Typography,
  Tooltip,
  Tabs,
  Tab,
} from '@mui/material';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { createTheme, styled } from '@mui/material/styles';
import { DropzoneArea } from 'material-ui-dropzone';

import { Form, Formik, Field, FieldArray, ErrorMessage } from 'formik';
import { LoadingButton } from '@mui/lab';
import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import { Editor } from '@tinymce/tinymce-react';
import moment from 'moment';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/mode-mysql';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/theme-textmate';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/snippets/python';

import AlignVerticalBottomIcon from '@mui/icons-material/AlignVerticalBottom';
import AlignVerticalCenterIcon from '@mui/icons-material/AlignVerticalCenter';
import AlignVerticalTopIcon from '@mui/icons-material/AlignVerticalTop';
// import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import AppleIcon from '@mui/icons-material/Apple';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useSelector } from 'react-redux';
import './styles.css';
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
// import { styled } from "@mui/material/styles";

import preSignedServices from '../../../services/preSignedServices';
import AddHotKeysModel from './AddHotKeysModel';
import HighlightCellsModel from './HighlightCellsModel';
import BasicModals from '../../../components/modal/BasicModel';
import GridRenderer from '../../course/GridRenderer'
import adminServices from '../../../services/adminServices';
import ErrorFocus from '../../../components/ErrorFocus/ErrorFocus';
import Modal from '../../../components/modal/Index';
import WindowsIcon from '../../../assets/logo/windows.svg';

window.katex = katex;

const modules = {
  toolbar: [
    ["bold", "italic", "underline"], // toggled buttons
    // ["blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ header: [1, 2, 3, false] }],
    ["image", { formula: { customClass: 'qlformula' } }],
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ align: [] }],
    ["clean"], // remove formatting button
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};


const descriptionModules = {
  toolbar: [
    ["bold", "italic", "underline"], // toggled buttons
    // ["blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ header: [1, 2, 3, false] }],
    [{ color: [] }], // dropdown with defaults from theme
    [{ align: [] }],
    ["clean"], // remove formatting button
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};

const descriptionFormats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "list",
  "bullet",
  "indent",
  "link",
  "color",
  "align",
  "code-block",
  "script",
  "clean",
];
const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
  "formula",
  "color",
  "background",
  "align",
  "code-block",
  "script",
  "clean",
];
// const formats = [
//   'header', 'font', 'list', 'bold', 'italic', 'underline', 'color', 'background', 'align', 'math'
// ];
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const editorPlugin = [
  'a11ychecker',
  'advlist',
  'advcode',
  'advtable',
  'autolink',
  'checklist',
  'export',
  'lists',
  'link',
  'image',
  'charmap',
  'preview',
  'anchor',
  'searchreplace',
  'visualblocks',
  'powerpaste',
  'fullscreen',
  'formatpainter',
  'insertdatetime',
  'media',
  'table',
  'help',
  'wordcount',
];

const toolbarStyle =
  'undo redo | casechange blocks | bold italic backcolor | ' +
  'alignleft aligncenter alignright alignjustify | ' +
  'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help';

const AddScreenModel = ({
  typeOfScreen,
  modalOpen,
  handleModalClose,
  handleSubmitForm,
  data,
  authorList,
  formType,
  loading,
  topicId,
  screenId,
  type,
  formMode,
  callBackForKeyCreate,
  codeScreenType,
  subCategory,
  handleDeleteAudioFile,
  handleGetTemplate,
  templates,
  ...otherProps
}) => {
  const editorRef = useRef(null);
  const quillRef = useRef(null);
  const classes = useStyles();
  const location = useLocation();
  const [keyType, setKeyType] = useState('');
  const [isUploadKeyObj, setIsUploadKeyObj] = useState(false);
  const [keyObjects, setKeyObjects] = useState([]);
  const [isMCQDisabled, setIsMCQDisabled] = useState(true);
  // const [clickedImageUrl, setClickedImageUrl] = useState(null);
  const [rangeObjects, setRangeObjects] = useState([]);

  const [openModal, setOpenModal] = React.useState(false);
  const [addKeys, setAddKeys] = React.useState(false);
  const [screenID, setScreenID] = useState(null);
  const [tabIndex, setTabIndex] = React.useState(0);
  const user = useSelector((state) => state.userInfo);

  // const [choice, setChoice] = useState(null);
  // const handleRadioChange = (event) => {
  //   setChoice(event.target.value);
  // };

  const [selectedValue, setSelectedValue] = useState('No'); // Initially set to "No"
  const [editDataFor, setEditData] = React.useState('');
  const [indexkey, setIndexkey] = React.useState('');
  // const [introTemplateSelection, setIntroTemplateSelection] = useState('');
  const [dropDownImage, setDropdownImage] = useState('')


  const handleradioChange = (event) => {
    setSelectedValue(event.target.value);
  };


  const handleEditHotKeys = (data, index) => {
    setEditData(data);
    setIndexkey(index)
    setOpenModal(true);

  }
  const handleChangeTab = (event, newValue) => {
    if (tabIndex > 0) setTabIndex(newValue);
  };

  // console.log(rangeObjects, "rangeObjects");



  // const [question, setQuestion] = useState('');
  const [options, setOptions] = useState([], []);
  const [correctAnswer, setCorrectAnswer] = useState(null);

  const addOption = () => {
    setOptions([...options, '']);

  };

  const handleOptionChange = (index, text) => {
    const updatedOptions = [...options];
    updatedOptions[index] = text;
    setOptions(updatedOptions);
    console.log('message for option', options)
    // setInitialValues('option',text)

  };

  const handleCorrectAnswerChange = (index) => {
    setCorrectAnswer(index);
    console.log('message for correctanswer', setCorrectAnswer)
  };


  const time = new Date().toLocaleTimeString();
  const now = moment(new Date()).format('YYYY-MM-DD H:mm:ss')
  const currentDate = now.substring(0, 10)

  const dateString = ['SELECT CURDATE()'];
  const timeString = ['SELECT CURTIME()'];
  const nowString = ['SELECT NOW()']
  const formatdateString = [`DATE_FORMAT(NOW(),'%d-%m-%Y')`]

  let object;
  // const [horizon,setHorizon] = useState([]);
  // const [meridian,setMeridian] = useState([]);


  function getOutputText(codeValue, result) {

    const isDateFunction = codeValue.includes(dateString)
    const isTimeFunction = codeValue.includes(timeString)
    const isNowFunction = codeValue.includes(nowString)
    const isFormatFunction = codeValue.includes(formatdateString)

    let output

    if (isDateFunction) {
      output = currentDate
    }
    else if (isTimeFunction) {
      output = time.substring(0, 8)
    }
    else if (isNowFunction) {
      output = now
    }
    else if (isFormatFunction) {
      output = moment(new Date()).format('DD-MM-YYYY')
    }
    else {
      output = result
    }
    return output
  }

  const [initialValues, setInitialValues] = React.useState({
    screenType: typeOfScreen,
    screenTitle: '',
    backgroundImg: null,
    backGroundPreview: null,
    description: '',
    buttonLabel: '',
    horizontalAlign: 'MIDDLE',
    verticalAlign: 'CENTER',
    keyboardType: '',
    audioFile: null,
    audioPreView: null,
    mcqOptions: [],

    correctAnswer: '',

    playSound: false,
    isAddQuestion: false,
    iqQuestion: '',
    iqAssessment: false,
    isCourseAssessment: false,
    mcqQuestion: '',
    isMutipleChoice: false,
    subCategory: codeScreenType?.code,
    isSubModuleAssessment: false,
    module: [],
    subModule: [],
    isCaseStudiesAssessment: false,
    caseStudiesQuestion: '',
    types: type,
    codeResultType: '',
    codeOutput: '',
    codeResultImage: null,
    codeResultPreview: null,
    prependScript: '',
    appendScript: '',
    inputCode: '',
    introTemplateSelection: '',
    dropDownImage: ''
  });
  const [formInitialValues, setFormInitialValues] = useState(initialValues);

  useEffect(() => {
    setKeyObjects([]);
    setRangeObjects([]);

    // action screen tab index set 0 t
    setTabIndex(0);
  }, [modalOpen]);

  const [coordinateRatios, setCoordinateRatios] = useState([]);
  const [gridURL, setGridURL] = useState("");
  useEffect(() => {
    setScreenID(screenId);
    if (type === 'CASE_STUDIES') {
      if (data !== null && formType === 'EDIT') {
        setKeyObjects(data.keyObj ? data.keyObj : []);
        setRangeObjects(data.rangeObj ? data.rangeObj : []);
        setKeyType(data.actionType.code);
        setInitialValues({
          screenType: data.type,
          screenTitle: data.title,
          backgroundImg: null,
          backGroundPreview: data.backgroundImg,
          description: data.description,
          buttonLabel: data.buttonLabel,
          horizontalAlign: data?.horizontalAlign?.code,
          verticalAlign: data?.verticalAlign?.code,
          playSound: data.soundRequired,
          keyboardType: data.actionType && data.actionType?.code,
          // isAddQuestion: false,
          caseStudiesQuestion: data.caseStudiesQuestion,
          subCategory: codeScreenType?.code,
          isCourseAssessment: data.isCourseAssessment,
          isSubModuleAssessment: data.isSubModuleAssessment,
          isMutipleChoice: data.isMutipleChoice,
          types: type,
          codeResultType: data.resultType?.code,
          codeOutput: data.textResult,
          codeOutputValue: data.textResult,
          mcqOptions: data.mcqOptions,
          correctAnswer: data.correctAnswer,
          iqQuestion: data.iqQuestion,
          iqQuestionValue: data.iqQuestion,
          mcqQuestion: data.mcqQuestion,
          mcqQuestionValue: data.mcqQuestion,
          codeResultImage: null,
          codeResultPreview: data.resultImage,
          prependScript: data.prependCode,
          appendScript: data.appendCode,
          inputCode: data.inputCode,
        });

        setAddKeys(true);
      } else {
        const values = {
          screenType: typeOfScreen,
          screenTitle: '',
          backgroundImg: null,
          backGroundPreview: null,
          description: '',
          buttonLabel: '',
          horizontalAlign: 'MIDDLE',
          verticalAlign: 'CENTER',
          keyboardType: '',



          audioFile: null,
          audioPreView: null,
          subCategory: codeScreenType?.code,
          playSound: false,
          isAddQuestion: false,
          iqQuestion: '',
          iqQuestionValue: '',

          mcqOptions: [],
          correctAnswer: '',

          mcqQuestion: '',
          mcqQuestionValue: '',
          isCourseAssessment: false,
          isSubModuleAssessment: false,
          isMutipleChoice: false,
          module: [],
          subModule: [],
          isCaseStudiesAssessment: true,
          caseStudiesQuestion: '',

          types: type,
          codeResultType: '',
          codeOutput: '',
          codeOutputValue: '',
          codeResultImage: null,
          codeResultPreview: null,
          prependScript: '',
          appendScript: '',
          inputCode: '',
          introTemplateSelection: '',
        };
        setInitialValues(values);
      }
    } else if (data !== null && formType === 'EDIT') {
      setKeyObjects(data.keyObj ? data.keyObj : []);

      // console.log("in edit data key objects is ",data.keyObj);
      // console.log("in edit data key objects is ",data.rangeObj);

      setRangeObjects(data.rangeObj ? data.rangeObj : []);
      setCanClickHglgtBtn(true);
      setGridURL("");

      if (data.rangeObj && data.rangeObj[0].range && data.rangeObj[0].range.range) {
        const retrievedCoords = data.rangeObj[0].range.range.selectedCells;

        setCoordinateRatios(retrievedCoords);
        setShowRanges(true);
      }

      setKeyType(data.actionType && data.actionType.code);
      setInitialValues({
        screenType: data.type,
        screenTitle: data.title,
        backgroundImg: null,
        backGroundPreview: data.backgroundImg,
        description: data.description,
        buttonLabel: data.buttonLabel,
        horizontalAlign: data?.horizontalAlign?.code || 'MIDDLE',
        verticalAlign: data?.verticalAlign?.code || 'CENTER',
        audioFile: data.audio,
        audioPreView: data.audio,
        playSound: data.soundRequired,
        keyboardType: data.actionType && data.actionType?.code,
        iqQuestion: data.iqQuestion,
        subCategory: codeScreenType?.code,
        iqQuestionValue: data.iqQuestion,

        mcqOptions: data.mcqOptions,
        correctAnswer: data.correctAnswer,

        mcqQuestion: data.mcqQuestion,
        mcqQuestionValue: data.mcqQuestion,
        isCourseAssessment: data.isCourseAssessment,
        isSubModuleAssessment: data.isSubModuleAssessment,
        isMutipleChoice: data.isMutipleChoice,
        isCaseStudiesAssessment: data.isCaseStudiesAssessment,
        caseStudiesQuestion: data.caseStudiesQuestion,
        types: type,
        codeResultType: data.resultType?.code,
        codeOutput: data.textResult,
        codeOutputValue: data.textResult,

        codeResultImage: null,
        codeResultPreview: data.resultImage,
        prependScript: data.prependCode,
        appendScript: data.appendCode,
        inputCode: data.inputCode,
        introTemplateSelection: data?.templates ? "yes" : "no",
        dropDownImage: data?.templates ? data?.templates : null,
      });
      setAddKeys(true);
      if (data.isCourseAssessment || data.isSubModuleAssessment)
        setIsMCQDisabled(false)
      else
        setIsMCQDisabled(true)
    } else {
      setShowRanges(false);
      setGridURL("");
      setInitialValues({
        screenType: typeOfScreen,
        screenTitle: '',
        backgroundImg: null,
        backGroundPreview: null,
        description: '',
        buttonLabel: '',
        horizontalAlign: 'MIDDLE',
        verticalAlign: 'CENTER',
        audioFile: null,
        audioPreView: null,
        playSound: false,
        keyboardType: '',
        iqQuestion: '',
        iqQuestionValue: '',

        mcqOptions: [],
        correctAnswer: '',

        mcqQuestion: '',
        mcqQuestionValue: '',
        isCourseAssessment: false,
        isSubModuleAssessment: false,
        isMutipleChoice: false,
        isCaseStudiesAssessment: false,
        caseStudiesQuestion: '',
        subCategory: codeScreenType?.code,
        types: type,
        codeResultType: '',
        codeOutput: '',
        codeOutputValue: '',

        codeResultImage: null,
        codeResultPreview: null,
        prependScript: '',
        appendScript: '',
        inputCode: '',
        introTemplateSelection: '',
        dropDownImage: ''
      });
    }
  }, [codeScreenType, screenId, typeOfScreen, data, modalOpen, formType, type]);

  const [openClickModel, setOpenClickModel] = useState(false);
  const handleClickModel = () => {
    setOpenClickModel(true);
    setAttemptedCreate(false);
  };

  const handleModal = () => {
    setOpenModal(true);
  };



  function checkBackgroundImg(obj) {
    let setvalue = ''
    const substring = editDataFor.mac.backgroundImg
    const substring1 = editDataFor.windows.backgroundImg
    const new1 = substring1.includes(obj.widowImage.name)
    const new2 = substring.includes(obj.macImage.name)
    if (new1 === true && new2 === true) {
      setvalue = false
    }
    else if (new1 === true && new2 === false) {
      setvalue = true
    }
    else if (new1 === false && new2 === true) {
      setvalue = true
    }
    else if (new1 === false && new2 === false) {
      setvalue = true
    }
    return setvalue
  }

  // const handleSubmitKeyForm = (values) => {
  //   if (editDataFor) {
  //     const parameter = checkBackgroundImg(values)
  //     const array = keyObjects;
  //     const object = {
  //       id:editDataFor.id,
  //       asimageChange:true,
  //       isChange:true,
  //       windows: {
  //         keyCode: values.combinedWindowKeys,
  //         backgroundImg: URL.createObjectURL(values.widowImage),
  //         keyName: values.combinedWindowKeyName,
  //         windowsKeyName: 'windowsPreSignedUrl',
  //         fileName: values.widowImage?.name,
  //         contentType: values.widowImage?.type,
  //         file: values.widowImage,
  //       },
  //       mac: {
  //         keyCode: values.combinedMacKeys,
  //         backgroundImg: URL.createObjectURL(values.macImage),
  //         keyName: values.combinedMacKeyName,
  //         macKeyName: 'macPreSignedUrl',
  //         fileName: values.macImage?.name,
  //         contentType: values.macImage?.type,
  //         file: values.macImage,
  //       },
  //       positionIndex: indexkey,
  //     };
  //     // array.push(object);
  //     keyObjects[indexkey] = object
  //     setKeyObjects(keyObjects)
  //     setOpenModal(false);
  //   } else {
  //     const array = keyObjects;
  //     const object = {
  //       id:"new",
  //       isChange:false,
  //       asimageChange:false,
  //       windows: {
  //         keyCode: values.combinedWindowKeys,
  //         backgroundImg: URL.createObjectURL(values.widowImage),
  //         keyName: values.combinedWindowKeyName,
  //         windowsKeyName: 'windowsPreSignedUrl',
  //         fileName: values.widowImage?.name,
  //         contentType: values.widowImage?.type,
  //         file: values.widowImage,
  //       },
  //       mac: {
  //         keyCode: values.combinedMacKeys,
  //         backgroundImg: URL.createObjectURL(values.macImage),
  //         keyName: values.combinedMacKeyName,
  //         macKeyName: 'macPreSignedUrl',
  //         fileName: values.macImage?.name,
  //         contentType: values.macImage?.type,
  //         file: values.macImage,
  //       },
  //       positionIndex: array.length,
  //     };
  //     array.push(object);
  //     setKeyObjects(array);
  //     setOpenModal(false);
  //   }
  // };

  // const handleSubmitKeyForm = (values) => {
  //   const array = keyObjects;
  //   const object = {
  //     windows: {
  //       keyCode: values.combinedWindowKeys,
  //       backgroundImg: URL.createObjectURL(values.widowImage),
  //       keyName: values.combinedWindowKeyName,
  //       windowsKeyName: 'windowsPreSignedUrl',
  //       fileName: values.widowImage?.name,
  //       contentType: values.widowImage?.type,
  //       file: values.widowImage,
  //     },
  //     mac: {
  //       keyCode: values.combinedMacKeys,
  //       backgroundImg: URL.createObjectURL(values.macImage),
  //       keyName: values.combinedMacKeyName,
  //       macKeyName: 'macPreSignedUrl',
  //       fileName: values.macImage?.name,
  //       contentType: values.macImage?.type,
  //       file: values.macImage,
  //     },
  //     positionIndex: array.length,
  //   };
  //   array.push(object);
  //   setKeyObjects(array);
  //   setOpenModal(false);

  //   if (formType === 'EDIT') {
  //     handleCreateActionKeys(object);
  //   }
  //   setIsUploadKeyObj(false);
  // };
  const handleSubmitKeyForm = (values) => {

    if (editDataFor) {
      const parameter = checkBackgroundImg(values)
      const array = keyObjects;
      const object = {
        id: editDataFor.id,
        asimageChange: true,
        isChange: true,
        windows: {
          keyCode: values.combinedWindowKeys,
          backgroundImg: URL.createObjectURL(values.widowImage),
          keyName: values.combinedWindowKeyName,
          windowsKeyName: 'windowsPreSignedUrl',
          fileName: values.widowImage?.name,
          contentType: values.widowImage?.type,
          file: values.widowImage,
        },
        mac: {
          keyCode: values.combinedMacKeys,
          backgroundImg: URL.createObjectURL(values.macImage),
          keyName: values.combinedMacKeyName,
          macKeyName: 'macPreSignedUrl',
          fileName: values.macImage?.name,
          contentType: values.macImage?.type,
          file: values.macImage,
        },
        positionIndex: indexkey,
      };
      // array.push(object);
      keyObjects[indexkey] = object
      setKeyObjects(keyObjects)
      setOpenModal(false);
      setEditData('');
      setIndexkey('')
    } else {
      const array = keyObjects;
      object = {
        id: "new",
        isChange: false,
        asimageChange: false,
        windows: {
          keyCode: values.combinedWindowKeys,
          backgroundImg: URL.createObjectURL(values.widowImage),
          keyName: values.combinedWindowKeyName,
          windowsKeyName: 'windowsPreSignedUrl',
          fileName: values.widowImage?.name,
          contentType: values.widowImage?.type,
          file: values.widowImage,
        },
        mac: {
          keyCode: values.combinedMacKeys,
          backgroundImg: URL.createObjectURL(values.macImage),
          keyName: values.combinedMacKeyName,
          macKeyName: 'macPreSignedUrl',
          fileName: values.macImage?.name,
          contentType: values.macImage?.type,
          file: values.macImage,
        },
        positionIndex: array.length,
      };
      array.push(object);
      setKeyObjects(array);
      setOpenModal(false);
      setEditData('');
      setIndexkey('')
      if (formType === 'EDIT') {
        console.log('object withinEDIT', object)
      }
    }
  };
  // everything you need to render the grid on the popup
  // const [gridURL,setGridURL] = useState("");
  const [canClickHglgtBtn, setCanClickHglgtBtn] = useState(false);
  const [renderHighlightGrid, setRenderHighlightGrid] = useState(false);

  const uploadForHighlight = (values) => {
    const array = [];
    const object = {
      range: {
        range: {},
        backgroundImg: URL.createObjectURL(values.widowImage),
        keyName: values.combinedWindowKeyName,
        windowsKeyName: 'windowsPreSignedUrl',
        fileName: values.widowImage?.name,
        contentType: values.widowImage?.type,
        file: values.widowImage,
      },
      positionIndex: array.length,
    };
    array.push(object);

    setRangeObjects(array);
    setGridURL(URL.createObjectURL(values.widowImage));

    setOpenClickModel(false);
    setCanClickHglgtBtn(true);
    setPixels({});
    setCoordinateRatios([]);
  };
  const handleCreateActionKeys = async (object) => {
    console.log('object', object);
    const keysData = new FormData();
    keysData.append('windows', JSON.stringify(object.windows));
    keysData.append('mac', JSON.stringify(object.mac));
    keysData.append('positionIndex', object.positionIndex);



    if (type === 'CASE_STUDIES') {
      const createRes = await adminServices.createActionScreenKeysForCaseStudy(screenID, keysData);
      console.log('message of  ', createRes);
      if (createRes.ok) {
        await preSignedServices.preSignedUrlUpload(createRes.data[0]?.windowsImagePreSignedUrl, object.windows?.file);
        await preSignedServices.preSignedUrlUpload(createRes.data[1]?.macImagePreSignedUrl, object.mac?.file);
        callBackForKeyCreate();
      }
    } else {
      const createRes = await adminServices.createActionScreenKeys(topicId, screenId, keysData);
      if (createRes.ok) {
        console.log(createRes);
        await preSignedServices.preSignedUrlUpload(createRes.data[0]?.windowsImagePreSignedUrl, object.windows?.file);
        await preSignedServices.preSignedUrlUpload(createRes.data[1]?.macImagePreSignedUrl, object.mac?.file);
      }
    }
  };

  const handleDeleteHotKeys = (idx, id) => {
    if (formType === 'CREATE') {
      const filterData = keyObjects.filter((res, i) => i !== idx);
      setKeyObjects(filterData);
    } else {
      deleteActionScreenKeys(idx, id);
    }
  };

  const handleDeleteWurl = () => {
    setCanClickHglgtBtn(false);
    // setGridURL('');
    setRangeObjects([]);

    setPixels({});

    // when you delete the image (and therefore any added highlights)
    // forget all the previous times you clicked "Create"
    setAttemptedCreate(false);
  };

  function debugBase64(base64URL) {
    // setClickedImageUrl(base64URL);
    const image = new window.Image();
    image.src = base64URL;
    const w = window.open('');
    if (w) {
      w.document.write('<html><body>');
      w.document.write(image.outerHTML);
      w.document.write('</body></html>');
      w.document.close();
    } else {
      console.error('Failed to open a new window.');
    }
  }

  const deleteActionScreenKeys = async (idx, id) => {
    const deleteRes = await adminServices.deleteActionScreenKeys(id);
    if (deleteRes.ok) {
      const filterData = keyObjects.filter((res, i) => i !== idx);
      console.log(filterData);
      setKeyObjects(filterData);
    }
  };

  const imageWidthAndHeight = (provideFile) => {
    // take the given file (which should be an image) and return the width and height\
    if (provideFile) {
      const imgDimensions = { width: null, height: null };
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(provideFile);
        reader.onload = function () {
          const img = new Image();
          img.src = reader.result;
          img.onload = function () {
            imgDimensions.width = img.width;
            imgDimensions.height = img.height;
            resolve(imgDimensions);
          };
        };
      });
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const onLoadForEditorHeight = (editor) => {
    // Your editor options comes here
    editor.on('change', (arg, activeEditor) => {
      const aceEditor = activeEditor;
      const newHeight =
        aceEditor.getSession().getScreenLength() *
        (aceEditor.renderer.lineHeight + aceEditor.renderer.scrollBar.getWidth());
      aceEditor.container.style.height = newHeight < 120 ? `120px` : `${newHeight}px`;
      aceEditor.resize();
    });
  };

  // array will store object, with one key for a horizontal range and another for the vertical,
  // with keys for point 1 and point 2
  const [pixels, setPixels] = useState({});
  // const [hX1,sethX1] = useState(0);
  // const [hX2,sethX2] = useState(0);
  // const [vY1,setvY1] = useState(0);
  // const [vY2,setvY2] = useState(0);
  const [showRanges, setShowRanges] = useState(false);
  const [attemptedCreate, setAttemptedCreate] = useState(false);
  // const [coordinateRatios,setCoordinateRatios] = useState([]);
// console.log(pixels,"pixels");

function updateRangeObject(rangeObject, pixels) {
  const updatedRangeObject = [...rangeObject];
  
  updatedRangeObject[0].range.range.backgroundWidth = pixels.backgroundWidth;
  updatedRangeObject[0].range.range.backgroundHeight = pixels.backgroundHeight;
  updatedRangeObject[0].range.range.selectedGridSize = pixels.selectedGridSize;
  updatedRangeObject[0].range.range.selectedCells = pixels.selectedCells;
  updatedRangeObject[0].range.range.highlightedCellRatios = pixels.highlightedCellRatios;

  return updatedRangeObject;
}

  useEffect(() => {
    if (Object.keys(pixels).length > 0) {

      setShowRanges(true);
      const updatedRangeObjects = updateRangeObject(rangeObjects, pixels);
      setRangeObjects(updatedRangeObjects)
      setCoordinateRatios(pixels.selectedCells);
    } else {
      setShowRanges(false);
    }
  }, [pixels]);

  const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    minWidth: 54,
  }));

  const Image = styled("img")({
    width: 100,
    height: 70,
    marginRight: 8,
  });
  useEffect(() => {
    if (topicId) {
      handleGetTemplate(topicId);
    }
  }, [topicId]);


  return (
    <>
      <Modal
        open={modalOpen}
        toggle={handleModalClose}
        children={
          <>
            <Typography variant="subtitle2" className={classes.subtitle}>
              {typeOfScreen}
            </Typography>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={Yup.object().shape({
                screenType: Yup.string().required('Required').nullable(),
                types: Yup.string(),
                caseStudiesQuestion: Yup.string().when(['types', 'isCaseStudiesAssessment', 'screenType'], {
                  is: (types, isCaseStudiesAssessment, screenType) =>
                    (types === 'CASE_STUDIES' || isCaseStudiesAssessment) && screenType === 'ACTION',

                  then: Yup.string().trim().required('Required'),

                  otherwise: Yup.string().nullable(),
                }),
                screenTitle: Yup.string().trim().required('Required').nullable(),
                // introTemplateSelection: Yup.string().required('This field is required'),
                backgroundImg: Yup.mixed().when(['backGroundPreview', 'screenType', "introTemplateSelection"], {
                  is: (backGroundPreview, screenType, introTemplateSelection) => introTemplateSelection === 'no' && backGroundPreview === null && screenType !== 'ACTION',
                  then: Yup.string()
                    .required('Background image is required')
                    .nullable()
                    .test(
                      'fileSize',
                      'Maximum file size is 3MB',
                      (value) => value && typeof value === 'object' && value.size < 3000000
                    ),
                  otherwise: Yup.string().nullable(),
                }),
                keyboardType: Yup.string().when('screenType', {
                  is: 'ACTION',
                  then: Yup.string().required('Required'),
                  otherwise: Yup.string().nullable(),
                }),
                buttonLabel: Yup.string().when('screenType', {
                  is: (screenType) => screenType !== 'ACTION',
                  then: Yup.string().trim().required('Required'),
                  otherwise: Yup.string().nullable(),
                }),
                iqQuestion: Yup.string().when(
                  ['isCourseAssessment', 'isSubModuleAssessment', 'keyboardType', 'screenType'],
                  {
                    is: (isCourseAssessment, isSubModuleAssessment, keyboardType, screenType) =>
                      (isCourseAssessment === true || isSubModuleAssessment === true) && screenType === 'ACTION',

                    // (keyboardType === 'INDIVIDUAL_HOT_KEYS' || keyboardType === 'COMBINED_HOT_KEYS'),
                    then: Yup.string().trim().required('Required'),
                    otherwise: Yup.string().nullable(),
                  }
                ),



                mcqQuestion: Yup.string().when(
                  ['isMutipleChoice', 'keyboardType', 'screenType'],
                  {
                    is: (isMutipleChoice, keyboardType, screenType) =>
                      (isMutipleChoice === true) && screenType === 'ACTION',

                    // (keyboardType === 'INDIVIDUAL_HOT_KEYS' || keyboardType === 'COMBINED_HOT_KEYS'),
                    then: Yup.string().trim().required('Required'),
                    otherwise: Yup.string().nullable(),
                  }
                ),



                correctAnswer: Yup.string().when(
                  ['isMutipleChoice', 'screenType', 'mcqOptions'],
                  (isMutipleChoice, screenType, mcqOptions) => {
                    if (
                      isMutipleChoice &&
                      screenType === 'ACTION' &&
                      mcqOptions
                    ) {
                      const options = mcqOptions.map(option => option.option);
                      return Yup.string().oneOf(
                        options,
                        'Correct Answer must match one of the options'
                      ).required('Correct Answer is required');
                    }
                    return Yup.string().nullable();
                  }
                ),



                codeResultType: Yup.string().when(['screenType', 'keyboardType'], {
                  is: (screenType, keyboardType) => screenType === 'ACTION' && keyboardType === 'CODE',
                  then: Yup.string().trim().required('Required'),
                  otherwise: Yup.string().nullable(),
                }),

                inputCode: Yup.string().when(['screenType', 'keyboardType'], {
                  is: (screenType, keyboardType) => screenType === 'ACTION' && keyboardType === 'CODE',
                  then: Yup.string().trim().required('Output code required'),
                  otherwise: Yup.string().nullable(),
                }),


                codeOutputValue: Yup.string().when(['screenType', 'keyboardType', 'codeResultType'], {
                  is: (screenType, keyboardType, codeResultType) =>
                    screenType === 'ACTION' && keyboardType === 'CODE' && codeResultType === 'TEXT',
                  then: Yup.string()
                    .nullable()
                    .required('Required')
                    .test('is-not-empty',
                      'Output is required',
                      (value) => value !== '<p><br></p>' && value !== ''
                    ),

                  otherwise: Yup.string().nullable(),
                }),

                codeResultImage: Yup.mixed().when(['codeResultPreview', 'codeResultType'], {
                  is: (codeResultPreview, codeResultType) => codeResultPreview === null && codeResultType === 'IMAGE',
                  then: Yup.mixed()
                    .nullable()
                    .required('Required')
                    .test(
                      'fileSize',
                      'Maximum file size is 4MB',
                      (value) => value && typeof value === 'object' && value.size < 4194304
                    ),
                  otherwise: Yup.mixed().nullable(),
                }),

                audioFile: Yup.mixed().when(['audioPreView'], {
                  is: (audioPreView) => audioPreView && audioPreView.substr(0, 4) === 'blob',
                  then: Yup.mixed()
                    .nullable()

                    .test(
                      'fileSize',
                      'Maximum file size is 5MB',
                      (value) => (value && typeof value === 'object' && value.size <= 5000000) || true
                    ),
                  otherwise: Yup.mixed().nullable(),
                }),
                // description: Yup.mixed().when({
                //   is: ()=>initialValues.introTemplateSelection==='yes',
                //   then: Yup.string().required('Description is required when using the intro template'),
                //   otherwise: Yup.string().nullable(),
                // }),
                description: Yup.mixed().when(['introTemplateSelection'], {
                  is: (introTemplateSelection) => introTemplateSelection === 'yes',
                  then: Yup.string()
                    .required('Description is required when using the intro template')
                    .nullable()
                  ,
                  otherwise: Yup.string().nullable(),
                }),
                dropDownImage: Yup.mixed().when(['introTemplateSelection'], {
                  is: (introTemplateSelection) => introTemplateSelection === 'yes',
                  then: Yup.string()
                    .required('Template is required when using the intro template')
                    .nullable()
                  ,
                  otherwise: Yup.string().nullable(),
                }),
                introTemplateSelection: Yup.mixed().when(['screenType'], {
                  is: (screenType) => screenType !== 'ACTION',
                  then: Yup.string()
                    .required('Required')
                    .nullable()
                  ,
                  otherwise: Yup.string().nullable(),
                }),
              })}
              onSubmit={(values, editData) => {


                if (values.screenType === 'ACTION' && keyObjects.length === 0 && values.keyboardType !== 'CODE') {
                  setIsUploadKeyObj(true);
                  // console.log("11111111");

                  if (showRanges) {
                    // console.log(rangeObjects, "22222222");

                    const array2 = rangeObjects;
                    // for (let i = 0; i < array2.length; i+=1) {
                    //   array2[i].range.range = pixels;
                    // }
                    // setRangeObjects(array2);

                    const shouldExecute = array2.every(item => !item.range.range || Object.keys(item.range.range).length === 0);

                    if (shouldExecute) {
                      alert("1111111111")
                      for (let i = 0; i < array2.length; i += 1) {
                        array2[i].range.range = pixels; 
                      }
                      setRangeObjects(array2); 
                      console.log('range.range updated for all objects.');
                    } 
                    // else {
                    //   alert("2222222222")

                    //   for (let i = 0; i < array2.length; i += 1) {
                    //     array2[i].range.range = pixels; 
                    //   }
                    //   setRangeObjects(array2);                            
                    // }
                    console.log('rangeObjectsqrangeObjectsq', rangeObjects)
                    // alert("5")


                    handleSubmitForm(values, rangeObjects);
                  }

                } else {
                  // console.log('333333')
                  // for code based action
                  // handleSubmitForm(values, keyObjects,dropDownImage);
                  handleSubmitForm(values, keyObjects, editDataFor);
                  // setIntroTemplateSelection('')
                  setDropdownImage('')
                }
                setAttemptedCreate(true);
              }}
            >
              {({ values, errors, touched, handleBlur, handleChange, setFieldValue }) => (
                <Form>
                  <Box sx={{ mt: 2, padding: '0 8px !important' }}>
                    <Grid container spacing={2}>
                      {values.screenType !== 'ACTION' && (
                        <>
                          <Grid item xs="12">
                            <Typography className={classes.background} variant="subtitle1">
                              Screen Title (For Internal Use)*
                            </Typography>
                            <TextField
                              className={classes.dropzone}
                              fullWidth
                              id="outlined-basic"
                              variant="outlined"
                              name="screenTitle"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="text"
                              value={values.screenTitle}
                              error={Boolean(touched.screenTitle && errors.screenTitle)}
                              helperText={touched.screenTitle && errors.screenTitle}
                            />
                            <ErrorFocus />
                          </Grid>
                          {
                            (typeOfScreen === "INTRO" || typeOfScreen === "SUCCESS") &&
                            (
                              <Grid item xs='12'>
                                <FormControl>
                                  <FormLabel id="demo-row-radio-buttons-group-label">Do you want to use the intro template</FormLabel>
                                  <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="introTemplateSelection"
                                    value={values.introTemplateSelection}
                                    onChange={(e) => {
                                      // const value = e.target.value;
                                      // setIntroTemplateSelection(e.target.value)
                                      setFieldValue("introTemplateSelection", e.target.value)
                                    }}
                                  >
                                    <FormControlLabel value="yes"
                                      onChange={() => {
                                        setFieldValue("backgroundImg", null);
                                        setFieldValue("backGroundPreview", null)
                                      }}
                                      control={<Radio />} label="Yes"
                                    />
                                    <FormControlLabel value="no" onChange={() => setFieldValue("dropDownImage", '')} control={<Radio />} label="No" />
                                  </RadioGroup>
                                  {touched.introTemplateSelection && errors.introTemplateSelection ? (
                                    <FormHelperText style={{ color: '#F44336' }}>
                                      {errors.introTemplateSelection}
                                    </FormHelperText>
                                  ) : null}
                                </FormControl>
                              </Grid>
                            )
                          }
                          {
                            values.introTemplateSelection === "yes" && (
                              <FormControl sx={{ m: 1, minWidth: 400 }} size="small">
                                <InputLabel id="demo-select-small-label">Select Image</InputLabel>
                                <Select
                                  labelId="demo-select-small-label"
                                  name='dropDownImage'
                                  id="demo-select-small"
                                  value={values.dropDownImage}
                                  label="Select Image"
                                  onChange={(event) => {
                                    // setDropdownImage(event.target.value);
                                    setFieldValue('dropDownImage', event.target.value)
                                  }}
                                >
                                  {templates?.length > 0 &&
                                    templates.map((template, index) => (
                                      <MenuItem key={index} value={template.id}>
                                        <Image src={template.url} alt={template.name} width={50} height={50} />
                                        <span>{template.name}</span>
                                      </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText style={{ color: '#F44336' }}>
                                  {touched.dropDownImage && errors.dropDownImage}
                                </FormHelperText>
                              </FormControl>
                            )
                          }
                          {values.introTemplateSelection === "no" && (
                            <Grid item xs="12">
                              <Typography className={classes.background}>Background Image*</Typography>
                              {values.backGroundPreview === null ? (
                                <FormControl
                                  required
                                  component="fieldset"
                                  color="primary"
                                  variant="outlined"
                                  fullWidth
                                  name="backgroundImg"
                                  value={values.backgroundImg}
                                >
                                  <DropzoneArea
                                    acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                                    showPreviews
                                    dropzoneText="Drag and Drop Image or Browse File"
                                    showPreviewsInDropzone={false}
                                    maxFileSize={104857600}
                                    filesLimit={1}
                                    showAlerts={false}
                                    value={values.backgroundImg}
                                    onChange={(file) => {
                                      setFieldValue('backgroundImg', file);
                                      if (file[0]?.size < 4194304) {
                                        const url = URL.createObjectURL(file[0]);
                                        setFieldValue('backGroundPreview', url);
                                      }
                                    }}
                                    useChipsForPreview
                                    previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                    showFileNamesInPreview
                                  />

                                  <FormHelperText style={{ color: '#F44336' }}>
                                    {touched.backGroundPreview && errors.backgroundImg}
                                  </FormHelperText>
                                </FormControl>
                              ) : (
                                <div className={classes.imgPreviewRoot}>
                                  <Badge
                                    badgeContent={
                                      <CloseIcon
                                        onClick={() => {
                                          setFieldValue('backGroundPreview', null);
                                          console.log(values);
                                        }}
                                        className={classes.badgeAlign}
                                      />
                                    }
                                  >
                                    <Avatar
                                      variant="rounded"
                                      src={values.backGroundPreview}
                                      sx={{ width: '120px', height: '80px' }}
                                      className={values.thumbPreview !== null && classes.fileImgSIze}
                                    />
                                  </Badge>
                                  {errors.backgroundImg && touched.backgroundImg && errors.backgroundImg}
                                </div>
                              )}
                            </Grid>
                          )}
                        </>
                      )}

                      {values.screenType === 'ACTION' && (
                        <>
                          <Box sx={{ padding: '0 0px 0 16px !important', width: '100%', minHeight: '80vh' }}>
                            <Box sx={{ margin: '16px 0' }}>
                              <Tabs value={tabIndex} onChange={handleChangeTab} aria-label="basic tabs">
                                <Tab label="SCREEN INFO" {...a11yProps(0)} />
                                <Tab label="ASSESSMENT" {...a11yProps(1)} />
                                <Tab label="ACTION KEY" {...a11yProps(2)} disabled={tabIndex === 0} />
                              </Tabs>
                            </Box>

                            <TabPanel value={tabIndex} index={0}>
                              <Grid item xs="12">
                                <Typography className={classes.background} variant="subtitle1" gutterBottom>
                                  Screen Title (For Internal Use)*
                                </Typography>
                                <TextField
                                  className={classes.dropzone}
                                  fullWidth
                                  id="outlined-basic"
                                  variant="outlined"
                                  name="screenTitle"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  type="text"
                                  value={values.screenTitle}
                                  error={Boolean(touched.screenTitle && errors.screenTitle)}
                                  helperText={touched.screenTitle && errors.screenTitle}
                                />
                                <ErrorFocus />
                              </Grid>

                              {formMode !== 'caseStudy' && (
                                <Grid item xs="12" mt={1} mb={1}>
                                  <Typography className={classes.background} gutterBottom variant="subtitle1">
                                    Description
                                  </Typography>
                                  <div className={classes.dropzone}>
                                    <>
                                      <ReactQuill theme="snow"
                                        value={values.description}
                                        onChange={(content, editor) => {
                                          setFieldValue('description', content);
                                        }}
                                        style={{ minHeight: '50px' }}
                                      />
                                    </>
                                  </div>
                                </Grid>
                              )}

                              {formMode !== 'caseStudy'
                                ? values.screenType === 'ACTION' && (
                                  <Grid container spacing={2} sx={{ marginTop: '8px' }}>
                                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={values.isCaseStudiesAssessment}
                                            value={values.isCaseStudiesAssessment}
                                            onChange={(e, value) => {
                                              setFieldValue('isCaseStudiesAssessment', value);
                                            }}
                                            error={Boolean(
                                              touched.isCaseStudiesAssessment && errors.isCaseStudiesAssessment
                                            )}
                                            helperText={
                                              touched.isCaseStudiesAssessment && errors.isCaseStudiesAssessment
                                            }
                                            id="isCaseStudiesAssessment"
                                            name="isCaseStudiesAssessment"
                                            color="primary"
                                          />
                                        }
                                        label="Do you wish to include this screen in Case Study ?"
                                      />
                                    </Grid>

                                    {values.isCaseStudiesAssessment === true && (
                                      <Grid item xs="12">
                                        <CKEditor
                                          editor={ClassicEditor}
                                          data={values.caseStudiesQuestion}
                                          onChange={(event, editor) => {
                                            const data = editor.getData();
                                            setFieldValue('caseStudiesQuestion', data);
                                          }}
                                        />

                                        <FormHelperText style={{ color: '#F44336' }}>
                                          {errors.caseStudiesQuestion}
                                        </FormHelperText>
                                      </Grid>
                                    )}

                                  </Grid>
                                )
                                : ''}

                              {formMode !== 'caseStudy' && (
                                <Grid item xs="12" mt={1}>
                                  <Typography gutterBottom className={classes.background} variant="subtitle1">
                                    Audio File
                                  </Typography>

                                  {values.audioPreView ? (
                                    <Box sx={{ display: 'flex' }}>
                                      <audio
                                        style={{ height: '36px' }}
                                        controls
                                        src={values.audioPreView}
                                        controlsList="nodownload"
                                      >
                                        <track kind="captions" src="sampleCaptions.vtt" />
                                      </audio>
                                      <Tooltip title="Remove a file">
                                        <IconButton
                                          size="small"
                                          color="primary"
                                          onClick={() => {
                                            setFieldValue('audioPreView', null);
                                            setFieldValue('audioFile', null);
                                          }}
                                        >
                                          <CloseIcon fontSize="small" />
                                        </IconButton>
                                      </Tooltip>

                                        <>

                                          {/*  <Tooltip title ={`Download file ${values.audioFile.name}`}>
                                          <a href={values.audioPreView} download>
                                            <IconButton size="small" color="primary">
                                              <CloudDownloadIcon fontSize="small" />
                                            </IconButton>
                                          </a>
                                        </Tooltip> */}
                                          <Tooltip title='Delete file'>
                                            <IconButton
                                              size="small"
                                              color="primary"
                                              onClick={() => {
                                                setFieldValue('audioPreView', null);
                                                setFieldValue('audioFile', null);
                                                handleDeleteAudioFile();
                                              }}
                                            >
                                              <DeleteIcon fontSize="small" />
                                            </IconButton>
                                          </Tooltip>
                                        </>
                                      

                                      <h6>{touched.audioFile && errors.audioFile}</h6>
                                    </Box>
                                  ) : (
                                    <TextField
                                      className={classes.dropzone}
                                      inputprops={{ accept: ['audio/mpeg', 'audio/wav', 'audio/ogg', 'audio/mp3'] }}
                                      fullWidth
                                      id="outlined-basic"
                                      variant="outlined"
                                      name="audioFile"
                                      onBlur={handleBlur}
                                      onChange={(event) => {
                                        const file = event.currentTarget?.files[0];

                                        if (
                                          ['audio/mpeg', 'audio/wav', 'audio/ogg', 'audio/mp3'].includes(file?.type)
                                        ) {
                                          setFieldValue('audioFile', file);
                                          if (file?.size < 50000000) {
                                            setFieldValue('audioPreView', URL.createObjectURL(file));
                                          }
                                        }
                                      }}
                                      type="file"
                                      error={Boolean(touched.audioFile && errors.audioFile)}
                                      helperText={touched.audioFile && errors.audioFile}
                                    />
                                  )}

                                  <ErrorFocus />
                                </Grid>
                              )}

                              {values.screenType === 'ACTION' && formMode === 'caseStudy' && (
                                <Grid item xs="12" sx={{ marginTop: '16px' }}>
                                  <Typography gutterBottom>Case Study Question</Typography>
                                  <CKEditor
                                    editor={ClassicEditor}
                                    data={values.caseStudiesQuestion}
                                    onChange={(event, editor) => {
                                      const data = editor.getData();
                                      setFieldValue('caseStudiesQuestion', data);
                                    }}
                                  />
                                  <FormHelperText style={{ color: '#F44336' }}>
                                    {errors.caseStudiesQuestion}
                                  </FormHelperText>
                                </Grid>
                              )}
                              <Box textAlign="right">
                                <Button
                                  color="primary"
                                  variant="contained"
                                  sx={{ marginTop: '1rem' }}
                                  type="button"
                                  onClick={() => {
                                    if (values.screenTitle !== '') {
                                      setTabIndex(1);
                                    }
                                  }}
                                >
                                  Next
                                </Button>
                              </Box>
                            </TabPanel>


                            <TabPanel value={tabIndex} index={1}>
                              <Box sx={{ minHeight: '70vh' }}>
                                <Grid item xs="12" sm={12}>

                                  <Grid container spacing={2}>
                                    <Grid item xs={6} sm={6} sx={{ paddingTop: '4px !important' }}>
                                      <FormControl component="fieldset" color="secondary">
                                        <FormGroup value={values.isCourseAssessment}>
                                          <FormControlLabel
                                            value={values.isCourseAssessment}
                                            checked={values.isCourseAssessment}
                                            color="secondary"
                                            control={
                                              <Checkbox
                                                color="secondary"
                                                onChange={(e, value) => {
                                                  setFieldValue('isCourseAssessment', value);
                                                  if (values.isSubModuleAssessment || value) {
                                                    setIsMCQDisabled(false);
                                                  }
                                                  else
                                                    setIsMCQDisabled(true);
                                                }
                                                }
                                                name="isCourseAssessment"
                                              />
                                            }
                                            label={
                                              <Typography color={'secondary'} variant="body1">
                                                Course Assessment
                                              </Typography>
                                            }
                                          />
                                        </FormGroup>
                                      </FormControl>
                                    </Grid>

                                    <Grid item xs={6} sx={{ paddingTop: '4px !important' }}>
                                      <FormControl component="fieldset" color="secondary">
                                        <FormGroup value={values.isSubModuleAssessment}>
                                          <FormControlLabel
                                            value={values.isSubModuleAssessment}
                                            checked={values.isSubModuleAssessment}
                                            color="secondary"
                                            control={
                                              <Checkbox
                                                color="secondary"
                                                onChange={(e, value) => {
                                                  setFieldValue('isSubModuleAssessment', value);
                                                  if (values.isCourseAssessment || value) {
                                                    setIsMCQDisabled(false);
                                                  }
                                                  else
                                                    setIsMCQDisabled(true);
                                                }
                                                }
                                                name="isSubModuleAssessment"
                                              />
                                            }
                                            label={
                                              <Typography color={'secondary'} variant="body1">
                                                Sub-Module Assessment
                                              </Typography>
                                            }
                                          />
                                        </FormGroup>
                                      </FormControl>
                                    </Grid>
                                  </Grid>


                                  {(values.isCourseAssessment === true || values.isSubModuleAssessment === true) && (
                                    <Grid item xs="12" mt={1}>
                                      <Typography className={classes.background} gutterBottom variant="subtitle1">
                                        IQ Question*
                                      </Typography>
                                      <TextField
                                        fullWidth
                                        size="small"
                                        name="iqQuestion"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="text"
                                        value={values.iqQuestion}
                                        variant="outlined"
                                        error={Boolean(touched.iqQuestion && errors.iqQuestion)}
                                        helperText={touched.iqQuestion && errors.iqQuestion}
                                      />
                                      {/* )} */}
                                    </Grid>
                                  )}


                                  <div style={{ display: 'flex', marginTop: '20px' }}>
                                    <div>
                                      <p style={{ justifyContent: 'space-between', marginRight: '20px' }}>Include screen with assessment</p>
                                    </div>
                                    <div>
                                      <FormControl component="fieldset">
                                        <RadioGroup name="yesNo" value={selectedValue} onChange={handleradioChange}>
                                          <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                          <FormControlLabel value="No" control={<Radio />} label="No" />
                                        </RadioGroup>
                                      </FormControl>
                                    </div>
                                  </div>

                                  <Grid item xs={6} sx={{ paddingTop: '4px !important' }} style={{ marginTop: '30px' }}>
                                    <FormControl component="fieldset" color="secondary">
                                      <FormGroup value={values.isMutipleChoice}>
                                        <FormControlLabel
                                          value={values.isMutipleChoice}
                                          checked={values.isMutipleChoice}
                                          color="secondary"
                                          control={
                                            <Checkbox
                                              color="secondary"
                                              onChange={(e, value) => {

                                                console.log("Calling MCQ checkbox", value, values.mcqQuestion, values.correctAnswer, values.mcqOptions);
                                                setFieldValue('isMutipleChoice', value);

                                              }}
                                              checked={values.isMutipleChoice}
                                              name="isMutipleChoice"
                                              disabled={isMCQDisabled}
                                            />
                                          }
                                          label={
                                            <Typography color={'secondary'} variant="body1">
                                              Multiple Choice
                                            </Typography>
                                          }
                                        />
                                      </FormGroup>
                                    </FormControl>
                                  </Grid>

                                  {/* Conditionally render the question and mcqOptions if the "Multiple Choice" checkbox is checked */}
                                  {values.isMutipleChoice && !isMCQDisabled && (
                                    <>
                                      <Grid item xs="12" mt={1}>
                                        <Typography className={classes.background} gutterBottom variant="subtitle1" >
                                          MCQ Question*
                                        </Typography>
                                        <ReactQuill
                                          fullWidth
                                          name="mcqQuestion"
                                          id="mcqQuestion"
                                          // onChange={handleChange}
                                          onChange={(content, delta, source, editor) => {
                                            const isEmpty = !content.trim().replace(/<[^>]*>/g, '').length;
                                            setFieldValue('mcqQuestion', isEmpty ? '' : content);
                                          }}
                                          value={values.mcqQuestion}
                                          error={Boolean(touched.mcqQuestion && errors.mcqQuestion)}
                                          helperText={touched.mcqQuestion && errors.mcqQuestion}
                                          modules={modules}
                                          formats={formats}
                                          bounds={".app"}
                                        />
                                        <FormHelperText style={{ color: '#F44336' }}>{errors.mcqQuestion}</FormHelperText>
                                        {/* )} */}
                                      </Grid>
                                      <FieldArray name="mcqOptions">
                                        {({ insert, remove, push }) => (
                                          <div id="mcqOptions">
                                            {values.mcqOptions != null && values.mcqOptions.length > 0 &&
                                              values.mcqOptions.map((opt, index) => (
                                                <div style={{ position: 'relative', marginTop: '10px' }} key={index}>
                                                  <ReactQuill
                                                    id={`mcqOptions-${index}option`}
                                                    halfwidth
                                                    style={{ marginTop: '25px', marginLeft: '25px' }}
                                                    name={`mcqOptions.${index}.option`}
                                                    onChange={(content, delta, source, editor) => {
                                                      const isEmpty = !content.trim().replace(/<[^>]*>/g, '').length;
                                                      setFieldValue(`mcqOptions.${index}.option`, isEmpty ? '' : content);
                                                    }}
                                                    value={opt.option}
                                                    placeholder="Option"
                                                    modules={modules}
                                                    formats={formats}
                                                    bounds={".app"}
                                                  />
                                                  <IconButton
                                                    id={`deleteBtn-${index}`}
                                                    aria-label="delete"
                                                    color="error"
                                                    style={{ position: 'absolute', top: '-30px', right: '-10px' }}
                                                    onClick={() => remove(index)}
                                                  >
                                                    <ClearIcon fontSize="small" />
                                                  </IconButton>
                                                </div>
                                              ))
                                            }

                                            <button
                                              id="addOptionBtn"
                                              type="button"
                                              style={{
                                                backgroundColor: 'rgb(63, 186, 150)',
                                                color: 'white',
                                                border: 'none',
                                                borderRadius: '6px',
                                                padding: '10px 20px',
                                                margin: '10px',
                                                cursor: 'pointer',
                                              }}
                                              onClick={async () => {
                                                if (values.mcqOptions.every(option => option.option.trim())) {
                                                  await new Promise((r) =>
                                                    setTimeout(() => {
                                                      push({ option: "", id: Date.now() });
                                                      r();
                                                    }, 500)
                                                  );
                                                } else {
                                                  alert("Please enter options before adding a new one.");
                                                }
                                              }}
                                            >
                                              Add Option
                                            </button>

                                            <Grid item xs="12" mt={1}>
                                              <Typography className={classes.background} gutterBottom variant="subtitle1">
                                                Correct Answer
                                              </Typography>
                                              <ReactQuill
                                                id="correct-answer"
                                                fullWidth
                                                name="correctAnswer"
                                                onChange={(content, delta, source, editor) => {
                                                  setFieldValue('correctAnswer', content);
                                                }}
                                                value={values.correctAnswer}
                                                error={Boolean(touched.correctAnswer && errors.correctAnswer)}
                                                helperText={touched.correctAnswer && errors.correctAnswer}
                                                modules={modules}
                                                formats={formats}
                                                bounds={".app"}
                                              />
                                              <FormHelperText style={{ color: '#F44336' }}>{errors.correctAnswer}</FormHelperText>
                                            </Grid>
                                          </div>
                                        )}
                                      </FieldArray>
                                    </>
                                  )}
                                  <Box textAlign="right">

                                    <Button
                                      color="primary"
                                      variant="contained"
                                      type="button"
                                      sx={{ marginTop: '1rem' }}
                                      onClick={async (e) => {
                                        if (!isMCQDisabled && values.isMutipleChoice) { // Check if Multiple Choice is selected
                                          if (values.mcqOptions.some(option => option.option.trim() === '')) {
                                            // Check if all options are filled for MCQ
                                            alert("Please fill out all options before proceeding.");
                                            e.preventDefault();
                                            return;
                                          }
                                          if (values.mcqOptions.length < 3 && values.screenTitle !== '') {
                                            // Check if there are at least three options for MCQ
                                            alert("Please select at least three options before proceeding.");
                                            e.preventDefault();
                                            return;
                                          }
                                        }
                                        console.log('Proceed to the next step');
                                        setTabIndex(2);
                                      }}
                                    >
                                      Next
                                    </Button>

                                  </Box>



                                </Grid>

                              </Box>
                            </TabPanel>




                            <TabPanel value={tabIndex} index={2}>
                              <Box sx={{ minHeight: '70vh' }}>
                                <Grid item xs="12" sm={12}>
                                  <ToggleButtonGroup
                                    color="secondary"
                                    value={values.keyboardType}
                                    exclusive
                                    fullWidth
                                    disabled={keyObjects.length > 0 || formType === 'EDIT'}
                                    onChange={(event, selected) => {
                                      if (formType === 'CREATE') {
                                        if (selected) setFieldValue('keyboardType', selected);
                                        setAddKeys(true);
                                        setKeyType(selected);
                                      } else if (type === 'CASE_STUDIES') {
                                        if (selected) setFieldValue('keyboardType', selected);
                                        setAddKeys(true);
                                        setKeyType(selected);
                                      }
                                      setAttemptedCreate(false);
                                    }}
                                  >
                                    <ToggleButton sx={{ fontSize: '0.7rem' }} value="INDIVIDUAL_HOT_KEYS">
                                      Individual Hot Keys
                                    </ToggleButton>
                                    <ToggleButton sx={{ fontSize: '0.7rem' }} value="COMBINED_HOT_KEYS">
                                      Combined hot keys
                                    </ToggleButton>
                                    <ToggleButton sx={{ fontSize: '0.7rem' }} value="CODE">
                                      Code
                                    </ToggleButton>
                                    <ToggleButton sx={{ fontSize: '0.7rem' }} value="CLICK">
                                      Click
                                    </ToggleButton>
                                    {/* <ToggleButton sx={{ fontSize: '0.7rem' }} value="PATTERN" disabled>
                                      Pattern
                                    </ToggleButton> */}
                                  </ToggleButtonGroup>
                                  <FormHelperText style={{ color: '#F44336' }}>
                                    {errors.keyboardType && touched.keyboardType && errors.keyboardType}
                                  </FormHelperText>
                                </Grid>

                                {addKeys &&
                                  values.keyboardType !== '' &&
                                  (values.keyboardType === 'INDIVIDUAL_HOT_KEYS' ||
                                    values.keyboardType === 'COMBINED_HOT_KEYS') && (
                                    <Grid item xs="12">
                                      <div className={classes.combined}>
                                        <Typography>Hot Key and Images</Typography>

                                        {((keyObjects.length < 1 && values.keyboardType === 'COMBINED_HOT_KEYS') ||
                                          values.keyboardType === 'INDIVIDUAL_HOT_KEYS') && (
                                            <Button onClick={handleModal} className={classes.individual}>
                                              Add Keys
                                            </Button>
                                          )}
                                      </div>
                                      <div style={{ marginTop: '1rem' }}>
                                        <Divider />
                                      </div>
                                      {isUploadKeyObj && (
                                        <FormHelperText style={{ color: '#F44336' }}>
                                          Keys and background image required
                                        </FormHelperText>
                                      )}
                                    </Grid>
                                  )}

                                <Grid item xs={12} sx={12}>
                                  {keyObjects.length > 0 && (
                                    <Box display="flex" marginBottom={1.4}>
                                      <Box className={classes.keyBoardBox}>
                                        <img src={WindowsIcon} alt="windows" width={28} />
                                        <Typography sx={{ marginLeft: '8px !important' }}> Windows</Typography>
                                      </Box>
                                      <Box className={classes.keyBoardBox}>
                                        <AppleIcon sx={{ color: '#A0A8AE', fontSize: '2rem !important' }} />
                                        <Typography sx={{ marginLeft: '6px !important' }}>Mac</Typography>
                                      </Box>
                                    </Box>
                                  )}
                                  {keyObjects &&
                                    keyObjects.map((res, index) => (
                                      <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        marginBottom={1.5}
                                        key={`keyBox${index}`}
                                      >
                                        <Box display="flex" alignItems="center">
                                          <Box display="flex" alignItems="center">
                                            {res.windows.keyName &&

                                              res.windows.keyName.map((res, idx) => (
                                                <div style={{ display: 'flex' }} key={`keyObj${index}`}>
                                                  <Typography className={classes.boxItem}>{res}</Typography>
                                                  <span> {res.windows && res.windows.keyName.length}</span>
                                                </div>
                                              ))}
                                          </Box>

                                          <Link target="new" onClick={() => debugBase64(res.windows.backgroundImg)}>
                                            Background img
                                          </Link>
                                          <div className={classes.container}>
                                            {res.windows.backgroundImg && (
                                              <img src={res.windows.backgroundImg} alt="Background img" style={{ height: '70px', width: '100px' }} />
                                            )}
                                          </div>

                                        </Box>

                                        <Box display="flex" alignItems="center">
                                          <Box display="flex" alignItems="center">
                                            {res.mac.keyName &&
                                              res.mac.keyName.map((res) => (
                                                <Typography className={classes.boxItem} key={`macKey${index}`}>{res}</Typography>
                                              ))}
                                          </Box>
                                          <Link target="new" onClick={() => debugBase64(res.mac.backgroundImg)}>
                                            Background img
                                          </Link>

                                          {res.mac.backgroundImg && (
                                            <img src={res.mac.backgroundImg} alt="Background img" style={{ height: '70px', width: '100px' }} />
                                          )}

                                        </Box>
                                        <IconButton size="small" style={{ marginLeft: '5px' }} onClick={() => handleEditHotKeys(res, index)} >
                                          <EditIcon fontSize="small" />
                                        </IconButton>
                                        <IconButton size="small" onClick={() => handleDeleteHotKeys(index, res.id)}>
                                          <DeleteIcon fontSize="small" />
                                        </IconButton>
                                      </Box>
                                    ))}
                                </Grid>

                                {(values.keyboardType === 'INDIVIDUAL_HOT_KEYS' ||
                                  values.keyboardType === 'COMBINED_HOT_KEYS') && (
                                    <>
                                      {formMode !== 'caseStudy' && (
                                        <Grid item xs="12" mt={1}>
                                          <Typography className={classes.background} gutterBottom>
                                            Box Position
                                          </Typography>
                                          <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div className={classes.dropzone}>
                                              <Typography gutterBottom variant="subtitle2">
                                                Horizontally Align
                                              </Typography>
                                              <ToggleButtonGroup
                                                value={values.horizontalAlign}
                                                exclusive
                                                onChange={(event, selected) => {
                                                  if (selected) setFieldValue('horizontalAlign', selected);
                                                }}
                                                aria-label="text alignment"
                                              >
                                                <ToggleButton value="LEFT" aria-label="left aligned">
                                                  <FormatAlignLeftIcon />
                                                </ToggleButton>
                                                <ToggleButton value="MIDDLE" aria-label="centered">
                                                  <FormatAlignCenterIcon />
                                                </ToggleButton>
                                                <ToggleButton value="RIGHT" aria-label="right aligned">
                                                  <FormatAlignRightIcon />
                                                </ToggleButton>
                                              </ToggleButtonGroup>
                                              <FormHelperText style={{ color: '#F44336' }}>
                                                {errors.horizontalAlign &&
                                                  touched.horizontalAlign &&
                                                  errors.horizontalAlign}
                                              </FormHelperText>
                                            </div>
                                            <div style={{ marginLeft: '1rem' }}>
                                              <Typography gutterBottom variant="subtitle2">
                                                Vertically Align
                                              </Typography>
                                              <ToggleButtonGroup
                                                value={values.verticalAlign}
                                                exclusive
                                                onChange={(event, selected) => {
                                                  if (selected) setFieldValue('verticalAlign', selected);
                                                }}
                                                aria-label="text alignment"
                                              >
                                                <ToggleButton value="TOP" aria-label="left aligned">
                                                  <AlignVerticalTopIcon />
                                                </ToggleButton>
                                                <ToggleButton value="CENTER" aria-label="centered">
                                                  <AlignVerticalCenterIcon />
                                                </ToggleButton>
                                                <ToggleButton value="BOTTOM" aria-label="right aligned">
                                                  <AlignVerticalBottomIcon />
                                                </ToggleButton>
                                              </ToggleButtonGroup>
                                            </div>
                                          </div>
                                        </Grid>
                                      )}
                                    </>
                                  )}

                                {/* START OF CLICK */}
                                {values.keyboardType !== '' &&
                                  (values.keyboardType === 'CLICK') && (
                                    <Grid item xs="12">
                                      <div className={classes.combined}>
                                        <Typography>Image and Highlight</Typography>

                                        {((values.keyboardType === 'CLICK')) && (
                                          <Button onClick={handleClickModel} className={classes.individual}>
                                            Upload Image
                                          </Button>
                                        )}
                                      </div>
                                      <div style={{ marginTop: '1rem' }}>
                                        <Divider />
                                      </div>
                                      {rangeObjects && rangeObjects.length === 0 && !showRanges && attemptedCreate &&
                                        <FormHelperText style={{ color: '#F44336' }}>
                                          Background image required
                                        </FormHelperText>
                                      }
                                      {rangeObjects && rangeObjects.length > 0 && !showRanges && attemptedCreate &&
                                        (<FormHelperText style={{ color: '#F44336' }}>
                                          Highlight required
                                        </FormHelperText>)
                                      }
                                    </Grid>
                                  )}

                                {values.keyboardType !== '' &&
                                  (values.keyboardType === 'CLICK') && (
                                    <Grid item xs={12} sx={12}>
                                      {rangeObjects &&
                                        rangeObjects.map((res, index) => (
                                          <Box
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="space-between"
                                            marginBottom={1.5}
                                          >
                                            <Box display="flex" alignItems="center">
                                              <Link target="new" onClick={() => debugBase64(res.range.backgroundImg)}>
                                                Background img
                                              </Link>
                                            </Box>
                                            <Button
                                              size="small"
                                              onClick={() => {
                                                setRenderHighlightGrid(!renderHighlightGrid);
                                                setAttemptedCreate(false);
                                              }}
                                              disabled={!canClickHglgtBtn}
                                              sx={{ marginTop: '3px' }}
                                            >
                                              Highlight
                                            </Button>
                                            <IconButton size="small" onClick={() => handleDeleteWurl()}>
                                              <DeleteIcon fontSize="small" />
                                            </IconButton>
                                          </Box>))}
                                    </Grid>
                                  )}

                                {/* {(values.keyboardType === 'CLICK') && (coordinateRatios.length > 0) && (showRanges ? (
                                  <>
                                    <Typography>
                                      {`Percentages width/height of image: ${JSON.stringify(coordinateRatios)}`}
                                    </Typography>
                                    <br />
                                  </>
                                ) : ( <></> ))} */}

                                {(values.keyboardType === 'CLICK') && typeof coordinateRatios === 'object' && showRanges &&
                                  (<>
                                    <Typography>
                                      {/* {console.log('showRanges, rangeObj---',showRanges,rangeObjects)} */}
                                      {`Selected cells: [${coordinateRatios}]`}
                                    </Typography>
                                    <br />
                                  </>
                                  )}

                                {(values.keyboardType === 'CLICK') && (
                                  <>
                                    {formMode !== 'caseStudy' && (
                                      <Grid item xs="12" mt={1}>
                                        <Typography className={classes.background} gutterBottom>
                                          Box Position
                                        </Typography>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                          <div className={classes.dropzone}>
                                            <Typography gutterBottom variant="subtitle2">
                                              Horizontally Align
                                            </Typography>
                                            <ToggleButtonGroup
                                              value={values.horizontalAlign}
                                              exclusive
                                              onChange={(event, selected) => {
                                                if (selected) setFieldValue('horizontalAlign', selected);
                                              }}
                                              aria-label="text alignment"
                                            >
                                              <ToggleButton value="LEFT" aria-label="left aligned">
                                                <FormatAlignLeftIcon />
                                              </ToggleButton>
                                              <ToggleButton value="MIDDLE" aria-label="centered">
                                                <FormatAlignCenterIcon />
                                              </ToggleButton>
                                              <ToggleButton value="RIGHT" aria-label="right aligned">
                                                <FormatAlignRightIcon />
                                              </ToggleButton>
                                            </ToggleButtonGroup>
                                            <FormHelperText style={{ color: '#F44336' }}>
                                              {errors.horizontalAlign &&
                                                touched.horizontalAlign &&
                                                errors.horizontalAlign}
                                            </FormHelperText>
                                          </div>
                                          <div style={{ marginLeft: '1rem' }}>
                                            <Typography gutterBottom variant="subtitle2">
                                              Vertically Align
                                            </Typography>
                                            <ToggleButtonGroup
                                              value={values.verticalAlign}
                                              exclusive
                                              onChange={(event, selected) => {
                                                if (selected) setFieldValue('verticalAlign', selected);
                                              }}
                                              aria-label="text alignment"
                                            >
                                              <ToggleButton value="TOP" aria-label="left aligned">
                                                <AlignVerticalTopIcon />
                                              </ToggleButton>
                                              <ToggleButton value="CENTER" aria-label="centered">
                                                <AlignVerticalCenterIcon />
                                              </ToggleButton>
                                              <ToggleButton value="BOTTOM" aria-label="right aligned">
                                                <AlignVerticalBottomIcon />
                                              </ToggleButton>
                                            </ToggleButtonGroup>
                                          </div>
                                        </div>
                                      </Grid>
                                    )}
                                  </>
                                )}
                                {/* END OF CLICK */}

                                {/* Radio button select for result type */}

                                {values.keyboardType === 'CODE' && values.screenType === 'ACTION' && (
                                  <>
                                    <Grid item xs="12" mt={1}>
                                      <Box
                                        sx={{
                                          height: '400px',
                                          overflow: 'auto',
                                        }}
                                      >
                                        <Typography variant="body1" gutterBottom>
                                          Prepend code
                                        </Typography>
                                        <AceEditor
                                          mode={codeScreenType?.code === 'SEQUEL' ? 'mysql' : 'python'}
                                          theme={codeScreenType?.code === 'SEQUEL' ? 'textmate' : 'monokai'}
                                          fontSize={14}
                                          // highlightActiveLine={false}
                                          value={values.prependScript}
                                          onLoad={onLoadForEditorHeight}
                                          height={70}
                                          placeholder={'Enter prepend script'}
                                          // showGutter={false}
                                          style={{
                                            width: '100%',
                                            marginBottom: '0.1rem',
                                            borderRadius: '6px',
                                          }}
                                          onChange={(code) => {
                                            setFieldValue('prependScript', code);
                                          }}
                                          name="PREPEND"
                                          editorProps={{ $blockScrolling: true }}
                                          setOptions={{
                                            enableBasicAutocompletion: true,
                                            enableLiveAutocompletion: true,
                                            enableSnippets: true,
                                            // firstLineNumber: 6,
                                          }}
                                        />
                                        <Typography gutterBottom variant="body1" mt={1}>
                                          Input code
                                        </Typography>
                                        <AceEditor
                                          mode={codeScreenType?.code === 'SEQUEL' ? 'mysql' : 'python'}
                                          theme={codeScreenType?.code === 'SEQUEL' ? 'textmate' : 'monokai'}
                                          fontSize={14}
                                          value={values.inputCode}
                                          onLoad={onLoadForEditorHeight}
                                          height={120}
                                          placeholder={'Enter input code here'}
                                          // showGutter={false}
                                          style={{
                                            width: '100%',
                                            marginBottom: '0.1rem',
                                            borderRadius: '6px',
                                          }}
                                          onChange={(code) => {
                                            setFieldValue('inputCode', code);
                                          }}
                                          name="INPUT_CODE"
                                          editorProps={{ $blockScrolling: true }}
                                          setOptions={{
                                            enableBasicAutocompletion: true,
                                            enableLiveAutocompletion: true,
                                            enableSnippets: true,
                                            firstLineNumber: values.prependScript?.split(/\r\n|\r|\n/).length + 1,
                                          }}
                                        />
                                        <FormHelperText style={{ color: '#F44336' }}>
                                          {errors.inputCode && touched.inputCode && errors.inputCode}
                                        </FormHelperText>
                                        <Typography variant="body1" gutterBottom mt={1}>
                                          Append code
                                        </Typography>
                                        <AceEditor
                                          mode={codeScreenType?.code === 'SEQUEL' ? 'mysql' : 'python'}
                                          theme={codeScreenType?.code === 'SEQUEL' ? 'textmate' : 'monokai'}
                                          fontSize={15}
                                          value={values.appendScript}
                                          onLoad={onLoadForEditorHeight}
                                          height={70}
                                          placeholder={'Enter append script'}
                                          // showGutter={false}
                                          style={{
                                            width: '100%',
                                            borderRadius: '6px',
                                          }}
                                          onChange={(code) => {
                                            setFieldValue('appendScript', code);
                                          }}
                                          name="APPEND"
                                          editorProps={{ $blockScrolling: true }}
                                          setOptions={{
                                            enableBasicAutocompletion: true,
                                            enableLiveAutocompletion: true,
                                            enableSnippets: true,
                                            firstLineNumber:
                                              values.inputCode?.split(/\r\n|\r|\n/).length +
                                              values.prependScript?.split(/\r\n|\r|\n/).length +
                                              1,
                                          }}
                                        />
                                      </Box>
                                    </Grid>

                                    <Grid item xs={12}>
                                      <Typography variant="subtitle1">Result</Typography>
                                      <FormControl component="fieldset" color="primary">
                                        <RadioGroup
                                          name="codeResultType"
                                          color="primary"
                                          value={values.codeResultType}
                                          error={Boolean(touched.codeResultType && errors.codeResultType)}
                                          helperText={touched.codeResultType && errors.codeResultType}
                                          onChange={handleChange}
                                          inputprops={{ autoFocus: true }}
                                        >
                                          <div>
                                            <FormControlLabel
                                              value={'TEXT'}
                                              color="primary"
                                              control={<Radio color="primary" />}
                                              label={'Text'}
                                            />
                                            <FormControlLabel
                                              value={'IMAGE'}
                                              color="primary"
                                              control={<Radio color="primary" />}
                                              label={'Image'}
                                            />
                                          </div>
                                        </RadioGroup>
                                        <FormHelperText style={{ color: '#F44336' }}>
                                          {errors.codeResultType && touched.codeResultType && errors.codeResultType}
                                        </FormHelperText>
                                      </FormControl>
                                      <ErrorFocus />
                                    </Grid>
                                    {values.codeResultType === 'TEXT' && (
                                      <Grid item xs="12">
                                        <Typography variant="subtitle1" gutterBottom>
                                          Output
                                        </Typography>

                                        {/* <Editor
                                          initialValue={getOutputText(values.inputCode,values.codeOutput)}                                          
                                          onEditorChange={(content) => {
                                            setFieldValue('codeOutputValue', content);
                                          }}
                                          onChange={(content, editor) => {
                                            setFieldValue('codeOutput', content);
                                          }}
                                          apiKey="5zvkxxagwye04bcqch8xphx1c1p5b85mjbxijiuwjvd8es4b"
                                          onInit={(evt, editor) => {
                                            editorRef.current = editor;
                                          }}
                                          init={{
                                            selector: 'textarea#basic-example',
                                            height: 200,
                                            plugins: editorPlugin,
                                            toolbar: toolbarStyle,
                                            content_style:
                                              'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                          }}
                                        /> */}


                                        <ReactQuill
                                          theme="snow"
                                          value={values.codeOutputValue}
                                          onChange={(content) => {
                                            console.log('Content changed:', content);
                                            setFieldValue('codeOutputValue', content);
                                          }}
                                          style={{ minHeight: '150px' }}
                                        />
                                        {errors.codeOutputValue && touched.codeOutputValue && (
                                          <FormHelperText style={{ color: '#F44336' }}>{errors.codeOutputValue}</FormHelperText>
                                        )}
                                      </Grid>
                                    )}

                                    {values.codeResultType === 'IMAGE' && (
                                      <Grid item xs={12} sm={12} md={12}>
                                        {values.codeResultPreview === null ? (
                                          <FormControl
                                            required
                                            component="fieldset"
                                            color="primary"
                                            variant="outlined"
                                            fullWidth
                                            name="codeResultImage"
                                            value={values.codeResultImage}
                                          >
                                            <Typography variant="subtitle1">Upload result picture * </Typography>

                                            <DropzoneArea
                                              acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                                              showPreviews
                                              dropzoneText="Drag and Drop Image or Browse File"
                                              showPreviewsInDropzone={false}
                                              maxFileSize={30000000}
                                              filesLimit={1}
                                              showAlerts={false}
                                              value={values.codeResultImage}
                                              onChange={(file) => {
                                                // setFieldValue('codeResultImage', await convertBase64(file[0]));
                                                setFieldValue('codeResultImage', file[0]);
                                                // console.log(URL.createObjectURL(file[0]));
                                                // setFieldValue('codeResultPreview', URL.createObjectURL(file[0]));

                                                if (file[0]?.size < 4194304) {
                                                  imageWidthAndHeight(file[0]).then((res) => {
                                                    // if (res.width >= 360 && res.height >= 200) {
                                                    const url = URL.createObjectURL(file[0]);
                                                    setFieldValue('codeResultPreview', url);
                                                    // }
                                                  });
                                                }
                                              }}
                                              useChipsForPreview
                                              previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                              showFileNamesInPreview
                                            />
                                            <FormHelperText style={{ color: '#F44336' }}>
                                              {touched.codeResultPreview && errors.codeResultImage}
                                            </FormHelperText>
                                          </FormControl>
                                        ) : (
                                          <div className={classes.imgPreviewRoot}>
                                            <Typography variant="subtitle1">Result Preview</Typography>
                                            <Badge
                                              badgeContent={
                                                <CloseIcon
                                                  onClick={() => {
                                                    setFieldValue('codeResultPreview', null);
                                                    setFieldValue('codeResultImage', null);
                                                  }}
                                                  className={classes.badgeAlign}
                                                />
                                              }
                                            >
                                              <Avatar
                                                variant="rounded"
                                                src={values.codeResultPreview}
                                                className={values.codeResultPreview !== null && classes.fileImgSIze}
                                              />
                                            </Badge>
                                            <Typography sx={{ fontSize: '0.75rem !important' }} color="red">
                                              {touched.codeResultPreview && errors.codeResultImage}
                                            </Typography>
                                          </div>
                                        )}
                                      </Grid>
                                    )}
                                  </>
                                )}
                              </Box>

                              {// eslint-disable-next-line prefer-template
                                (user.role === 'SUPER_ADMIN' || (authorList).includes(user.firstName + ' ' + user.lastName)) && <Grid item xs="12" sx={{ marginTop: '1rem' }}>

                                  <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    loading={loading}
                                  >
                                    {formType === 'EDIT' ? 'Update' : 'Create'}
                                  </LoadingButton>
                                </Grid>}
                            </TabPanel>
                          </Box>
                        </>
                      )}

                      {values.screenType !== 'ACTION' && (
                        <>
                          <Grid item xs="12">
                            {
                              values.introTemplateSelection === "yes" ?
                                <Typography className={classes.background} gutterBottom variant="subtitle1">
                                  Description*
                                </Typography>
                                :
                                <Typography className={classes.background} gutterBottom variant="subtitle1">
                                  Description
                                </Typography>
                            }

                            {/* <Editor
                                value={values.description}
                                onEditorChange={(content, editor) => {
                                  setFieldValue('description', content);
                                }}
                                apiKey="5zvkxxagwye04bcqch8xphx1c1p5b85mjbxijiuwjvd8es4b"
                                init={{
                                  selector: 'textarea#basic-example',
                                  height: 200,
                                  plugins: editorPlugin,
                                  toolbar: toolbarStyle,
                                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                }}
                              /> */}
                            <ReactQuill theme="snow"
                              value={values.description}
                              modules={descriptionModules}
                              formats={descriptionFormats}
                              onChange={(content, editor) => {
                                const isEmpty = !content.trim().replace(/<[^>]*>/g, '').length;
                                // console.log("isEmpty...",isEmpty)
                                setFieldValue('description', isEmpty ? '' : content);
                                // setFieldValue('description', content);
                              }}
                              style={{ minHeight: '50px' }}

                            />
                            <FormHelperText style={{ color: '#F44336' }}>
                              {touched.description && errors.description}
                            </FormHelperText>
                          </Grid>

                          <Grid item xs="12">
                            <Typography className={classes.background}>Button Label*</Typography>
                            <TextField
                              className={classes.dropzone}
                              fullWidth
                              name="buttonLabel"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="text"
                              value={values.buttonLabel}
                              variant="outlined"
                              error={Boolean(touched.buttonLabel && errors.buttonLabel)}
                              helperText={touched.buttonLabel && errors.buttonLabel}
                            />
                          </Grid>

                          {formMode !== 'caseStudy' && (
                            <Grid item xs="12" mt={1}>
                              <Typography className={classes.background} variant="subtitle1">
                                Audio File
                              </Typography>

                              {values.audioPreView ? (
                                <Box sx={{ display: 'flex' }}>
                                  <audio
                                    style={{ height: '36px' }}
                                    controls
                                    src={values.audioPreView}
                                    controlsList="nodownload"
                                  >
                                    <track kind="captions" src="sampleCaptions.vtt" />
                                  </audio>
                                  {/*  <a href={values.audioPreView} download>
                                    <Tooltip title="Download file">
                                      <IconButton size="small" color="primary">
                                        <CloudDownloadIcon fontSize="small" />
                                      </IconButton>
                                    </Tooltip>
                                  </a> */}
                                  <Tooltip title="Remove file">
                                    <IconButton
                                      size="small"
                                      color="primary"
                                      onClick={() => {
                                        setFieldValue('audioPreView', null);
                                        setFieldValue('audioFile', null);
                                      }}
                                    >
                                      <CloseIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>

                                  {formType === 'EDIT' && 
                                  // !values.audioFile && 
                                  (
                                    <Tooltip title="Delete file">
                                      <IconButton
                                        size="small"
                                        color="primary"
                                        onClick={() => {
                                          setFieldValue('audioPreView', null);
                                          setFieldValue('audioFile', null);
                                          handleDeleteAudioFile();
                                        }}
                                      >
                                        <DeleteIcon fontSize="small" />
                                      </IconButton>
                                    </Tooltip>
                                  )}

                                  <h6>{touched.audioFile && errors.audioFile}</h6>
                                </Box>
                              ) : (
                                <TextField
                                  className={classes.dropzone}
                                  inputprops={{ accept: ['audio/mpeg', 'audio/wav', 'audio/ogg', 'audio/mp3'] }}
                                  fullWidth
                                  id="outlined-basic"
                                  variant="outlined"
                                  name="audioFile"
                                  onBlur={handleBlur}
                                  onChange={(event) => {
                                    const file = event.currentTarget?.files[0];
                                    if (['audio/mpeg', 'audio/wav', 'audio/ogg', 'audio/mp3'].includes(file?.type)) {
                                      setFieldValue('audioFile', file);
                                      if (file?.size < 50000000) {
                                        setFieldValue('audioPreView', URL.createObjectURL(file));
                                      }
                                    }
                                  }}
                                  type="file"
                                  error={Boolean(touched.audioFile && errors.audioFile)}
                                  helperText={touched.audioFile && errors.audioFile}
                                />
                              )}

                              <ErrorFocus />
                            </Grid>
                          )}
                        </>
                      )}

                      {values.screenType === 'SUCCESS' && (
                        <Grid item xs="12">
                          <FormControl component="fieldset" color="secondary">
                            <FormGroup value={values.playSound}>
                              <FormControlLabel
                                value={values.playSound}
                                checked={values.playSound}
                                color="secondary"
                                control={
                                  <Checkbox
                                    color="secondary"
                                    onChange={(e, value) => {
                                      setFieldValue('playSound', value);
                                    }}
                                    name="playSound"
                                  />
                                }
                                label={
                                  <Typography color={'secondary'} variant="body1">
                                    Play Sound
                                  </Typography>
                                }
                              />
                            </FormGroup>
                          </FormControl>
                        </Grid>
                      )}

                      {// eslint-disable-next-line prefer-template
                        (typeOfScreen === 'INTRO' || typeOfScreen === 'SUCCESS') && (user.role === 'SUPER_ADMIN' || (authorList).includes(user.firstName + ' ' + user.lastName)) && (
                          <Grid item xs="12">
                            <LoadingButton type="submit" variant="contained" color="primary" fullWidth loading={loading}>
                              {formType === 'EDIT' ? 'Update' : 'Create'}
                            </LoadingButton>
                          </Grid>
                        )}
                    </Grid>
                  </Box>
                </Form>
              )}
            </Formik>
          </>
        }
        {...otherProps}
      />

      <AddHotKeysModel
        open={openModal}
        handleClose={() => { setOpenModal(false); setEditData(''); setIndexkey('') }}
        // handleClose={() => setOpenModal(false)}
        handleSubmit={handleSubmitKeyForm}
        keyType={keyType}
        editData={editDataFor}
      />
      <HighlightCellsModel
        open={openClickModel}
        handleClose={() => setOpenClickModel(false)}
        handleSubmit={uploadForHighlight}
      />
      <BasicModals
        openModel={renderHighlightGrid}
        closeModel={() => setRenderHighlightGrid(false)}
        title='Select Click Target'
        children={
          <GridRenderer
            toggleClose={setRenderHighlightGrid}
            prevState={coordinateRatios}
            setFunction={setPixels}
            gridURL={rangeObjects?.[0]?.range.backgroundImg}
          />
        }
        maxWidth='50%' // Adjust this value to control the width
        maxHeight='50vh' // Adjust this value to control the height
        style={{
          width: '50%',
          height: '50vh',
          maxWidth: '50%',
          maxHeight: '50vh'
        }}
      />

    </>
  );
};

const useStyles = makeStyles(() => ({
  imgPreviewRoot: {
    borderRadius: '10px',
    padding: '0.8rem;',
    marginBottom: 16,
  },


  combined: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  badgeAlign: {
    boxShadow: '0 2px 8px -5px #ff0000',
    color: '#FF0000',
    fontSize: '1.2rem',
    backgroundColor: '#fff',
    padding: '2px',
    borderRadius: '10px',
    cursor: 'pointer',
  },

  individual: {
    border: '1px dotted #BCBCBC',
    padding: '4px 12px',
    color: '#00B673',
  },



  boxItem: {
    width: ' max-content',
    border: '1px solid gray',
    padding: '0.2rem 0.4rem',
    borderRadius: '4px',
    marginRight: '8px',
    fontSize: '12px',
  },
  keyBoardBox: {
    display: 'flex',
    alignItems: 'center',
    width: '46%',
  },
  editorToolbar: {
    display: 'none',
  },
  fileImgSIze: {
    width: '100%',
    height: '120px',
    objectFit: 'cover',
    objectPosition: 'center',
    border: '1px solid #fff',
    borderRadius: '5px',
    boxShadow: '0 3px 10px rgb(0 0 0 / 20%)',
  },
  subtitle: {
    borderBottom: '1px solid #E4E4E4',
    margin: '-16px',
    padding: ' 8px 30px',
  },
  //   qlformula:{
  //    marginLeft:'90px'
  // }
}));

const customeTheme = createTheme({
  components: {
    MuiToggleButtonGroup: {
      styleOverrides: {
        grouped: {
          backgroundColor: '#000E3B',
          color: 'white',
          fontWeight: 'bold',
        },
      },
    },
    MuiTouchRipple: {
      styleOverrides: {
        root: {
          borderRight: '1px solid #707070',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#FE7000',
            color: '#ffffff',
            '&:hover': {
              backgroundColor: '#FE7000',
            },
          },
          '&:hover': {
            backgroundColor: '#000E3B',
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#000E3B',
          borderRadius: '5px',
          color: 'white',
          fontWeight: 'bold',
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          borderRight: '1px solid #FFFFFF',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#000E3B',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
          '&.Mui-selected': {
            color: '#00FFA1 !important',
          },
        },
      },
    },
  },
});
export default AddScreenModel;
