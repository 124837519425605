import { createSlice } from '@reduxjs/toolkit';
import * as Actions from '../Redux/Constant';

export const initialState = {
  isLogin: false,
  userInfo: null,
  openSubscriptionModal: false,
  openSubscriptionModalDetails: null,
  planId: null,
  userSubscribedCourses: [],
  snackbarOpen: false,
  snackbarTitle: '',
  openWebInfoModal: false,
  noOfTimeOpenWebInfo: 2,
  acceptCookies: true,
  isGuiding: false,
  keyIntelligence: false,
  isBannerVisible: true,
  ExcelDetails: [],
  WordDetails: [],
  AccountDetails: [],
  CodeDetails: [],
  ErgDetails: [],
  RecommendedDetails: [],
  CertificateDetails: [],
  FreeCourseDetails:[],
  AllCourseDetails:[],
  CourseProgress:{},
  WordCourse: [],

  CourseModuleDetails:[],
  SearchCourse:[],
  CategoryDetails:[],
  PowerPointDetails:[],
  BundleDetails:[],
  currency: 'USD',
  GetOfferDetails:[],
  GetLearnerDetails:[],
  GetAssessmentDetails:[],
  comingfrom:"Courses"

};

export const counterSlice = createSlice({
  name: 'root',
  initialState,

  reducers: {
    loginSuccess: (state, action) => {
      state.isLogin = true;
      state.userInfo = action.payload;
    },

    logoutSuccess: (state) => {
      state.isLogin = false;
      state.userInfo = null;
      state.userSubscribedCourses = [];
      state.planId = null;
      state.noOfTimeOpenWebInfo = 2;
      state.isBannerVisible = true;
    },

    openSubscriptionModal: (state, action) => {
      state.openSubscriptionModal = true;
      state.planId = action.payload;
    },

    closeSubscriptionModal: (state) => {
      state.openSubscriptionModal = false;
      state.planId = null;
      state.openSubscriptionModalDetails = null;
    },

    setSubscribedCourses: (state, action) => {
      state.userSubscribedCourses = action.payload;
    },

    setOpenSubscriptionModalDetails: (state, action) => {
      state.openSubscriptionModalDetails = action.payload;
    },

    openSnackbar: (state, action) => {
      state.snackbarTitle = action.payload;
      state.snackbarOpen = true;
    },

    handleOpenWebInfoModal: (state, action) => {
      state.openWebInfoModal = action.payload;
    },

    handleTimerWebInfoModal: (state, action) => {
      state.noOfTimeOpenWebInfo = action.payload;
    },

    closeSnackbar: (state) => {
      state.snackbarTitle = '';
      state.snackbarOpen = false;
    },

    closeCookiesAlert: (state) => {
      state.acceptCookies = false;
    },

    guideHandler: (state, action) => {
      state.isGuiding = action.payload;
    },

    setKeyIntelligence: (state, action) => {
      state.keyIntelligence = action.payload;
    },

    setBanner: (state, action) => {
      state.isBannerVisible = action.payload;
    },
    setCurrencyValue: (state, action) => {
      state.currency = action.payload;
    },
    ComingFrom: (state, action) => {
      state.comingfrom = action.payload;
    },
  },

  // Move extraReducers here (outside reducers)
  extraReducers: (builder) => {
    builder
      .addCase(Actions.GetExcelDetailPending, (state) => { state.ExcelDetails = []})
      .addCase(Actions.GetExcelDetailSuccess, (state, action) => { state.ExcelDetails = action.payload})
      .addCase(Actions.GetExcelDetailFailure, (state) => { state.ExcelDetails = []})

      .addCase(Actions.GetWordDetailsPending, (state) => { state.WordDetails = []})
      .addCase(Actions.GetWordDetailsSuccess, (state, action) => { state.WordDetails = action.payload})
      .addCase(Actions.GetWordDetailsFailure, (state) => { state.WordDetails = []})

      .addCase(Actions.GetAccDetailsPending, (state) => { state.AccountDetails = []})
      .addCase(Actions.GetAccDetailsSuccess, (state, action) => {state.AccountDetails = action.payload;})
      .addCase(Actions.GetAccDetailsFailure, (state) => { state.AccountDetails = []})

      .addCase(Actions.GetCodeDetailsPending, (state) => { state.CodeDetails = []})
      .addCase(Actions.GetCodeDetailsSuccess, (state, action) => {state.CodeDetails = action.payload;})
      .addCase(Actions.GetCodeDetailsFailure, (state) => { state.CodeDetails = []})

      .addCase(Actions.GetRecomendedSuccess, (state, action) => {state.RecommendedDetails = action.payload;})
      
      
      .addCase(Actions.GetCertificateSuccess, (state, action) => {state.CertificateDetails = action.payload;})
  
      .addCase(Actions.GetFreeCoursePending, (state) => {})
      .addCase(Actions.GetFreeCourseSuccess, (state, action) => {state.FreeCourseDetails = action.payload;})
      .addCase(Actions.GetFreeCourseFailure, (state) => { state.FreeCourseDetails = []})

      .addCase(Actions.GetAllCourseSuccess, (state, action) => {state.AllCourseDetails = action.payload;})

      .addCase(Actions.GetProgressSuccess, (state, action) => {state.CourseProgress = action.payload;})

      .addCase(Actions.GetCourseSuccess, (state, action) => {state.CourseModuleDetails = action.payload;})


      .addCase(Actions.GetSearchCourseSuccess, (state, action) => {state.SearchCourse = action.payload;})

      .addCase(Actions.GetCategorySuccess, (state, action) => {state.CategoryDetails = action.payload;})
      .addCase(Actions.GetPowerPointSuccess, (state, action) => {state.PowerPointDetails = action.payload;})

      
      .addCase(Actions.GetERGSuccess, (state, action) => {state.ErgDetails = action.payload;})
      .addCase(Actions.GetWordSucces, (state, action) => {state.WordCourse = action.payload;})

      .addCase(Actions.GetBundleSucces, (state, action) => {state.BundleDetails = action.payload;})
      
      .addCase(Actions.GetOfferSucces, (state, action) => {state.GetOfferDetails = action.payload;})

      .addCase(Actions.GetLearnerSuccess, (state, action) => {state.GetLearnerDetails = action.payload;})

      .addCase(Actions.GetAssessmentSuccess, (state, action) => {state.GetAssessmentDetails = action.payload;})

      
    },
});

export const {
  loginSuccess,
  logoutSuccess,
  openSubscriptionModal,
  closeSubscriptionModal,
  setSubscribedCourses,
  setOpenSubscriptionModalDetails,
  closeSnackbar,
  openSnackbar,
  handleOpenWebInfoModal,
  handleTimerWebInfoModal,
  closeCookiesAlert,
  guideHandler,
  setKeyIntelligence,
  setBanner,
  setCurrencyValue,
  ComingFrom
} = counterSlice.actions;

export default counterSlice.reducer;