import React, { useState, useEffect, useLayoutEffect, useMemo } from 'react';
import moment from 'moment';
import { Box, Grid, Typography, Radio, RadioGroup, FormControlLabel, FormControl, Button, LinearProgress, Paper, Breadcrumbs, Link, Tooltip } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { BarChart } from '@mui/x-charts/BarChart';
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useDispatch, useSelector } from 'react-redux';
import DOMPurify from "dompurify";
import Page from '../../components/Page';
import QuestionList from './QuestionComponent';
import ProgressBar from './GeneralAssessmentProgresBar';
import Rocket from '../../assets/iqImages/rocket.gif';
import IqStart from '../../assets/iqImages/iqStart.png';
import ImageView from '../../assets/Images/ProgressFrame.png'
import adminServices from '../../services/adminServices';
import LottieLoading from '../../components/LottieLoading';
import Snackbar from '../../components/snackbar/snackbar';
import './generalAssessmentStyle.css';

const GeneralAssessment = () => {
  const userid = useSelector((state) => state?.userInfo && state?.userInfo?.id);
  const navigate = useNavigate();
  const location = useLocation();
  
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const name = queryParams.get('name');
  const [questions, setQuestions] = useState('');
  const [allDetails, setAllDetails] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [textview, setText] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [correctAnswerIndex, setCorrectAnswerIndex] = useState(null);
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(null);
  const [buttonClick, setButtonClick] = useState(false);
  const [spaceview, setSpaceView] = useState(true);
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [CloseTime, setCloseTime] = useState('');

  const [allQuestions, setAllQuestions] = useState([]);
  const [myallQuestion, setMyallQuestion] = useState([]);
  const [progress, setProgress] = useState(0);
  const [Clicked, setClicked] = useState(false);
  
  const [isLoading, setIsLoading] = useState(false);
  const [attemptAssessment, setAttemptAssessment] = useState('');
  const [attempt, setAttempt] = useState(false);
  


  const [correctResponse, setCorrectResponse] = useState(0);
  const [inCorrectResponse, setInCorrectResponse] = useState(0);

  const [isTimeout, setIsTimeout] = useState(false);  
  const [isopenSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarTitle, setSnackbarTitle] = useState('');
  const [startingtime, setStartingTime] = useState('');
  const [answerCount, setAnswerCount] = useState(0);
  const [isSummery, SetIsSummery] = useState(false);
  const [summaryData, setSummaryData] = useState([]);
  const [time, setTime] = useState(0);
  const [timeWith, setTimeWith] = useState(false);
  
  const [withoutimer, setwithoutimer] = useState(false);
  const [currentPage, setCurrentPage] = useState(0); 
  const itemsPerPage = 5;




  const [isActive, setIsActive] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  localStorage.setItem('from', 'General Assessment');  

// console.log(currentIndex,"currentIndex");
// console.log(correctResponse,"correctResponse");
// console.log(isActive,"isActive");
// console.log(time,"time");

  const sanitizedContent = DOMPurify.sanitize(questions?.length > 0 && questions[currentIndex]?.question_text);

  const handleRetry = async(attemptAssessment) => {
    const result = await adminServices.deleteAssessmentById(userid,attemptAssessment);    
     setMyallQuestion([]);
    if(result.ok){
      setCorrectResponse(0)
      setCurrentIndex(0);
      setSpaceView(false)
      setAllQuestions([])
      setCorrectAnswerIndex(null)
      setIsAnswerCorrect(null)
      setProgress(0)
      setText(false)
      setTime(allDetails?.time_in_mins);
      getAssessmentDetails();
      // getAssessmentDetailsBestScore();
  
      setIsActive(true);
      setIsPaused(false);
      setStartTime(getCurrentTime())
    }
  }

  const getSummaryById = async () => {
    setIsLoading(true);
    const result = await adminServices.getSummaryById(userid, id);
    if (result.ok) {
      setSummaryData(result.data);
    }
    setIsLoading(false)

  }


  const handleSummary = () => {
    getSummaryById()
    SetIsSummery(true)
  }

  const handleBack = () => {
    setwithoutimer(false)

    localStorage.setItem('from', 'General Assessment');
    navigate('/auth/subscribe')
  }

  const handleBackLeave = () => {
    if(attempt === false){
      const currentime = getCurrentTime()
      handleNextTimeout(currentime);
    }
    setwithoutimer(false)

    localStorage.setItem('from', 'General Assessment');
    navigate('/auth/subscribe')
  }


 
  const handleKeyDown = (event) => {
    event.preventDefault();
    if (event.key === " ") {
      PostSpace();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);


  const getCurrentTime = () => {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');

    return `${hours}:${minutes}:${seconds}`;
  };

  useEffect(() => {
    setStartingTime(Date.now())
    getAssessmentDetails()
  }, [])


  useEffect(() => {
    let timer;
    let lastTimestamp = Date.now();

    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        lastTimestamp = Date.now();
      } else if (document.visibilityState === "visible") {
        const now = Date.now();
        const elapsed = Math.floor((now - lastTimestamp) / 1000);
        setTime((prevTime) => Math.max(0, prevTime - elapsed));
      }
    };

    if (!spaceview) {
      if (isActive && time > 0) {
        timer = setInterval(() => {
          setTime((prevTime) => prevTime - 1);
        }, 1000);
      }

      if (time === 0 && timeWith && !Clicked) {
        setIsTimeout(true);
        localStorage.removeItem("assessmentData");
        setAllQuestions([]);
        handleNextNew();
        clearInterval(timer);
      } else if (!timeWith) {
        timer = setTimeout(() => {
          localStorage.removeItem("assessmentData");
          WithoutTimerFunction();
        }, 900000);
      }

      document.addEventListener("visibilitychange", handleVisibilityChange);
    }

    return () => {
      clearInterval(timer);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [spaceview, isActive, time, timeWith, Clicked]);

  // useEffect(() => {
  //   let timer;
  
  //   if (spaceview === false) {
  //     if (isActive && time > 0) {
  //       const startTime = Date.now();
  
  //       timer = setInterval(() => {
  //         const elapsedTime = Math.floor((Date.now() - startTime) / 1000);
  //         setTime((prevTime) => Math.max(prevTime - elapsedTime, 0)); 
  //       }, 1000);
  //     }
  
  //     if (time === 0 && timeWith === true && Clicked === false) {
  //       setIsTimeout(true);
  //       localStorage.removeItem('assessmentData');
  //       setAllQuestions([]);
  //       handleNextNew();
  //       clearInterval(timer);
  //     } else if (timeWith === false) {
  //       timer = setTimeout(() => {
  //         localStorage.removeItem('assessmentData');
  //         WithoutTimerFunction();
  //       }, 900000);
  //     }
  
  //     return () => clearInterval(timer);
  //   }
  // }, [spaceview, isActive, time, timeWith, Clicked]);
  



  const WithoutTimerFunction = () => {
    setwithoutimer(true)
    setIsTimeout(true);
  }
  // useEffect(() => {
  //   let timer;
  //   if (spaceview === false) {

  // timer = setTimeout(() => {
  //   setOpenSnackbar(true);
  //   setSnackbarTitle('15 minutes response');
  //   setIsTimeout(true);
  //   localStorage.removeItem('assessmentData');
  //   handleNext();
  // }, 900000);

  //     if (isActive && !isPaused && time > 0) {
  //       timer = setInterval(() => {
  //         setTime((prevTime) => prevTime - 1);  
  //       }, 1000);
  //     }
  //        if (time === 0 || !isActive || isPaused) {
  //       clearInterval(timer);
  //     }

  //     return () => clearInterval(timer);
  //   }
  //   // return () => {
  //   //   if (timer) {
  //   //     clearTimeout(timer);
  //   //   }
  //   // };
  // }, [spaceview,isActive, isPaused, time]);


  const startTimer = () => {
    setIsActive(true);
    setIsPaused(false);
  };

  const pauseTimer = () => {
    setIsPaused(true);
  };

  const resumeTimer = () => {
    setIsPaused(false);
  };

  const resetTimer = () => {
    setIsActive(false);
    setIsPaused(false);
    setTime(allDetails?.time_in_mins);
  };

  const StayFunction = () =>{
    if(attempt === false){
      const currentime = getCurrentTime()
      handleNextTimeout(currentime);
      setAttempt(true)
    }
    getAssessmentDetails()
   
  }



  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins} ${mins === 1 ? "Min" : "Mins"} ${secs} ${secs === 1 ? "Second" : "Seconds"}`;
  };

  const formatTimeTimer = (seconds) => {

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  // console.log(formatTime(Math.floor(summaryData.total_time_taken)),"RTime");

  function CloseTimeCal(time) {
    if (!time) return 0;
    const totalSeconds = (time.hours || 0) * 3600 + (time.minutes || 0) * 60 + (time.seconds || 0);
    return totalSeconds;
  }




  const getAssessmentDetails = async () => {
    setIsLoading(true)
    const result = await adminServices.getAssessmentDetails(userid, id);
    if (result.ok) {
      setAllDetails(result?.data)
      setTime(result?.data?.time_in_mins)
      setTimeWith(typeof result?.data?.time_in_mins === 'number' && result?.data?.time_in_mins > 0);

      setQuestions(
        result?.data?.questionsDetails
          .sort(() => Math.random() - 0.5)
          .slice(0, 25)
      );
    }
    setIsLoading(false)

  }


  const getAssessmentDetailsBestScore = async () => {
    // setIsLoading(true)
    const result = await adminServices.getAssessmentDetails(userid, id);
    if (result.ok) {
      setAllDetails(result?.data)
    }
    // setIsLoading(false)

  }
  const PostSpace = async () => {

    const starttime = getCurrentTime()
    const data = {
      assessmentId: id,
      starttime: Date.now()
    };
    localStorage.setItem('assessmentData', JSON.stringify(data));
    setStartTime(starttime)
    setSpaceView(false)
    setIsActive(true);
    setIsPaused(false);
  };

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const calculateCorrectPercentage = (array, questions) => {
    const totalQuestions = questions?.length;
    if (totalQuestions === 0) return 0;
    const correctResponses = array.filter(item => item.response_recorded === "correct").length;
    setCorrectResponse(correctResponses)
    const incorrectResponses = array.filter(item => item.response_recorded !== "correct").length;
    setInCorrectResponse(incorrectResponses)
    const percentage = (correctResponses / totalQuestions) * 100;
    return percentage;
  };


  function normalizeOptions(options) {
    return options?.map(option => {
      if (typeof option === "string" && option.startsWith("<") && option.endsWith(">")) {
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = option;
        return tempDiv.textContent || tempDiv.innerText || "";
      }
      return option;
    });
  }

  const handleSubmit = async () => {
    if (selectedOption) {
      const currentQuestion = questions[currentIndex];
      const correctAnswers = currentQuestion.options.correctAnswer;
      const cleartext = normalizeOptions(currentQuestion.options.mcqOptions);
      const selectedIndex = cleartext.indexOf(selectedOption);
      const isCorrect = correctAnswers[selectedIndex];

      if (questions[currentIndex]) {
        questions[currentIndex] = {
          ...questions[currentIndex],
          answerCorrect: isCorrect
        };
      }
      setQuestions(questions)
      setIsAnswerCorrect(isCorrect);
      setCorrectAnswerIndex(correctAnswers.indexOf(true));
      setButtonClick(true)

      const objData = {
        qn_seq_no: allQuestions?.length + 1,
        response_recorded: isCorrect ? "correct" : "incorrect",
        related_question_id: currentQuestion.id,
      };
      const correctPercentage = Math.ceil(calculateCorrectPercentage([...allQuestions, objData], questions));
      setProgress(correctPercentage);
      setAllQuestions((prevQuestions) => [...prevQuestions, objData]);
      setMyallQuestion((prevQuestions) => [...prevQuestions, objData]);
    } else {
      alert("Please select any option");
    }
  };

  const calculateTimeDifference = (dateStarted, dateCompleted) => {
    const startTime = new Date(`1970-01-01T${dateStarted}Z`);
    const endTime = new Date(`1970-01-01T${dateCompleted}Z`);
    const differenceInMilliseconds = endTime - startTime;
    const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);
    const differenceInMinutes = Math.floor(differenceInSeconds / 60);
    const remainingSeconds = differenceInSeconds % 60;
    return { minutes: differenceInMinutes, seconds: remainingSeconds };
  };

  const handleNext = async () => {    
    setButtonClick(false);
    resetState();
    if (questions?.length > currentIndex + 1 ) {
      
         setCurrentIndex((prev) => prev + 1);
    } else {
      
      setIsLoading(true)
      const endtime = getCurrentTime()
      setEndTime(endtime)
      const timediff = calculateTimeDifference(startTime, getCurrentTime())
      setCloseTime(timediff)
      const body = {
        assessment_id: location?.state?.id,
        user_id: userid,
        user_result: allQuestions,
        date_started: startTime,
        date_completed: getCurrentTime(),
        difference_date: timediff,
        current_score: progress
      };
      console.log(body,"bodybody1111");
      
      const result = await adminServices.postAssessmentSubmit(body);
      console.log(result,"result1111");

      if (result.ok) {
        if (result?.data && result.data.length > 0) {
          const assessmentId = result.data[0]?.id;
          if (assessmentId) {
            setAttemptAssessment(assessmentId);
          }
        }
        setIsLoading(false)
        setText(true)
        getAssessmentDetailsBestScore()
        setAttempt(true)
        // setProgress(0)
    //       setCorrectAnswerIndex(null)
    // setIsAnswerCorrect(null)
        // setCurrentIndex(0);
        setClicked(true)
        // getAssessmentDetails()
      }


    }
  };

  const handleNextNew = async () => {    
    // if(allQuestions?.length > 0){
    setButtonClick(false);
    resetState();
      setIsLoading(true)
      const endtime = getCurrentTime()
      setEndTime(endtime)
      const timediff = calculateTimeDifference(startTime, getCurrentTime())
      setCloseTime(timediff)
      const body = {
        assessment_id: location?.state?.id,
        user_id: userid,
        user_result: allQuestions,
        date_started: startTime,
        date_completed: getCurrentTime(),
        difference_date: timediff,
        current_score: progress
      };
      console.log(body,"bodybody1111");
      
      const result = await adminServices.postAssessmentSubmit(body);
      console.log(result,"result1111");

      if (result.ok) {
        if (result?.data && result.data.length > 0) {
          const assessmentId = result.data[0]?.id;
          if (assessmentId) {
            setAttemptAssessment(assessmentId);
          }
        }
        setIsLoading(false)
        setText(true)
        getAssessmentDetailsBestScore()
        setAttempt(true)
        // setProgress(0)
    //       setCorrectAnswerIndex(null)
    // setIsAnswerCorrect(null)
        // setCurrentIndex(0);
        setClicked(false)
        // getAssessmentDetails()
      


    // }
  }
  };


  const handleNextTimeout = async (endtime) => {
    setButtonClick(false);
    resetState();
    // if (questions?.length > currentIndex + 1) {
    //   setCurrentIndex((prev) => prev + 1);
    // } else {
    setIsLoading(true)
    // const endtime = getCurrentTime()
    // setEndTime(endtime)
    const timediff = calculateTimeDifference(startTime, endtime)
    setCloseTime(timediff)
    const body = {
      assessment_id: location?.state?.id,
      user_id: userid,
      user_result: allQuestions,
      date_started: startTime,
      date_completed: endtime,
      difference_date: timediff,
      current_score: progress
    };
    console.log(body, "body");

    const result = await adminServices.postAssessmentSubmit(body);
    console.log(result, "result");

    if (result.ok) {
      getAssessmentDetailsBestScore()
      setCorrectResponse(0)
    setSpaceView(false)
    setAllQuestions([])
    setCorrectAnswerIndex(null)
    setIsAnswerCorrect(null)
    // setProgress(0)
    setText(false)
    getAssessmentDetails();
    setIsActive(true);
    setIsPaused(false);
    setCurrentIndex(0);
    setStartTime(getCurrentTime())
      setIsLoading(false)
      // setText(true)
      // getAssessmentDetails()
    }


    // }
  };

  const resetState = () => {
    setSelectedOption("");
    setIsAnswerCorrect(null);
    setCorrectAnswerIndex(null);
  };
  const handleNavigation = () => {
    navigate('/auth/subscribe')
  };



  // const sortedData = summaryData?.scores?.sort((a, b) => new Date(a.date) - new Date(b.date));
  const sortedData = summaryData?.scores?.sort((a, b) => new Date(b.date) - new Date(a.date));

  const startIndex = currentPage * itemsPerPage;
  const paginatedData = sortedData?.slice(startIndex, startIndex + itemsPerPage);
  const lastScores = paginatedData?.map((item) => item.score) || [];


  const paddedScores = [
    ...lastScores,
    ...Array(itemsPerPage - lastScores.length).fill(null),
  ];

  // const xAxisLabels = [
  //   ...paddedScores.map((_, index) =>
  //     lastScores[index] !== undefined ? `Reattempt${startIndex + index + 1}` : ""
  //   ),
  // ];
  const totalLength = sortedData?.length || 0;

  const xAxisLabels = [
    ...paddedScores.map((_, index) =>
      lastScores[index] !== undefined
        ? `GA${totalLength - (startIndex + index)}`
        : ""
    ),
  ];
  

  const totalPages = Math.ceil((sortedData?.length || 0) / itemsPerPage);


const correctPercentage = `${myallQuestion?.filter(q => q.response_recorded === "correct")?.length}`;
const incorrectPercentage = `${myallQuestion?.filter(q => q.response_recorded === "incorrect")?.length}`;

const notAnsweredPercentage = questions?.length - (parseInt(correctPercentage, 10) + parseInt(incorrectPercentage, 10));
// const notAnsweredWidth = `${allQuestions - (correctPercentage + incorrectPercentage)}`;


  return (
    <Page title={'Begin Course'} style={{ padding: '0px', paddingTop: '70px !important', marginTop: 1 }}>

      <Box
        sx={{
          marginTop: "20px",
          width: '100%',
          paddingLeft: '50px',
          backgroundColor: 'white',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
          marginBottom: '16px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Breadcrumbs
          aria-label="breadcrumb"
          sx={{
            padding: '15px',
            paddingLeft: '100px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Link
            underline="hover"
            color="inherit"
            onClick={() => handleNavigation('/auth/subscribe')}
            style={{ cursor: 'pointer' }}
          >
            General Assessment
          </Link>
          <Typography color="text.primary">
            {allDetails?.title ? allDetails?.title : 'Condition Functions'}
          </Typography>
        </Breadcrumbs>

        {spaceview === false && textview === false && timeWith === true && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              paddingRight: '100px',
              marginTop: '-8px',
            }}
          >
            {/* <Typography className='TimeLeft' style={{ marginRight: "50px", marginTop: '-30px', marginBottom: '5px', color: time <= 59 ? 'red' : 'black' }} variant="body2" >
              Remaining Time: {formatTimeTimer(time)}
            </Typography> */}
          </Box>
        )}
      </Box>


      {isSummery ?
        <Grid container spacing={2} style={{ padding: "20px", width: '92%', margin: 'auto' }}>
          <Grid item xs={12} md={6}>
            <Grid container >
              <Grid item xs={12}>
                <Paper style={{ padding: "10px" }}>
                  <Typography variant="h6">Your Test Score</Typography>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Tooltip title={`Correct: ${myallQuestion?.filter(q => q.response_recorded === "correct")?.length} answers`}>
                      <div
                        style={{
                          background: "#00C365",
                          width: `${(correctPercentage / questions?.length) * 100}%`,
                          height: "10px",
                          marginRight: "5px",
                        }}
                      />
                    </Tooltip>

                    <Tooltip title={`Incorrect: ${myallQuestion?.filter(q => q.response_recorded === "incorrect")?.length} answers`}>
                      <div
                        style={{
                          background: "#E70000",
                          width: `${(incorrectPercentage / questions?.length) * 100}%`,
                          height: "10px",
                          marginRight: "5px",
                        }}
                      />
                    </Tooltip>
  
                    {/* <Tooltip title={`Not Answered: ${questions?.length - allQuestions?.filter(q => q.response_recorded === "incorrect")?.length + allQuestions?.filter(q => q.response_recorded === "correct")?.length} answers`}> */}
                    <Tooltip title={`Not Answered: ${notAnsweredPercentage}`}>
                    
                      <div
                        style={{
                          background: "#437BFC",
                          width: `${(notAnsweredPercentage / questions?.length) * 100}%`,
                          height: "10px",
                        }}
                      />
                    </Tooltip>
                  </div>

                  <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                      <div
                        style={{
                          width: "12px",
                          height: "12px",
                          backgroundColor: "#00C365",
                          borderRadius: "50%",
                          display: "inline-block",
                        }}
                      />
                      <Typography variant="body1">Correct Answer</Typography>
                    </div>

                    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                      <div
                        style={{
                          width: "12px",
                          height: "12px",
                          backgroundColor: "#E70000",
                          borderRadius: "50%",
                          display: "inline-block",
                        }}
                      />
                      <Typography variant="body1">Incorrect Answer</Typography>
                    </div>

                    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                      <div
                        style={{
                          width: "12px",
                          height: "12px",
                          backgroundColor: "#437BFC",
                          borderRadius: "50%",
                          display: "inline-block",
                        }}
                      />
                      <Typography variant="body1">Not Answered</Typography>
                    </div>
                  </div>
                </Paper>

              </Grid>
              <Grid item spacing={2} style={{ marginTop: "20px", width: "100%" }}>
                <Paper style={{ padding: "20px", height: "100%" }}>
                  <Typography variant="h5">Time Summary</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={6} style={{ padding: "10px", marginTop: '20px' }}>
                      <Paper style={{ padding: "10px", textAlign: "center", backgroundColor: '#FE780F' }}>
                        <Typography variant="subtitle1" color={'white'}> Overall Test Time</Typography>
                        <Typography variant="h6" color="white">
                          {formatTime(Math.floor(summaryData.total_time_taken))}
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item xs={6} style={{ padding: "10px", marginTop: '20px' }}>

                      <Paper style={{ padding: "10px", textAlign: "center", backgroundColor: '#FE780F' }}>
                        <Typography variant="subtitle1" color={'white'}>Total Test Time</Typography>
                        {/* <Typography variant="h6" color={'white'}>{formatTime(Math.floor(summaryData.avg_time))}</Typography> */}
                        <Typography variant="h6" color={'white'}>{CloseTime.minutes} Minutes {CloseTime.seconds} Seconds</Typography>



                      </Paper>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper style={{ padding: "20px", height: "100%" }}>
              <Typography variant="h6">Your Overall Score</Typography>
              {!isLoading &&
                <>
                  <div>
                    <BarChart
                      series={[
                        {
                          data: paddedScores,
                        },
                      ]}
                      xAxis={[{scaleType: "band", data: xAxisLabels }]}

                      barWidth={Math.floor(100 / itemsPerPage)}
                      height={290}
                      margin={{ top: 10, bottom: 30, left: 40, right: 10 }}

                    />

                    <div style={{ marginTop: "20px", display: "flex", justifyContent: "center" }}>
                      <button
                        onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 0))}
                        disabled={currentPage === 0}
                        style={{
                          marginRight: "10px",
                          padding: "5px 10px",
                          fontSize: "20px",
                          cursor: "pointer",
                          backgroundColor: "transparent",
                          border: "none",
                          color: currentPage === 0 ? "gray" : "black",
                        }}
                      >
                        &#x25C0;
                      </button>
                      <span style={{ marginTop: '8px' }}>
                        {/* Range {currentPage + 1} of {totalPages} */}
                        Range {totalPages - currentPage} of {totalPages}
                      </span>
                      <button
                        onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages - 1))}
                        disabled={currentPage === totalPages - 1}
                        style={{
                          marginLeft: "10px",
                          padding: "5px 10px",
                          fontSize: "20px",
                          cursor: "pointer",
                          backgroundColor: "transparent",
                          border: "none",
                          color: currentPage === totalPages - 1 ? "gray" : "black",
                        }}
                      >
                        &#x25B6;
                      </button>
                    </div>
                  </div>
                </>
              }

            </Paper>
          </Grid>
        </Grid>

        : <>{isLoading ?
          <>
            <div
              style={{
                position: 'absolute',
                width: '100%',
                height: `calc(100vh - 70px)`,
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 999,
              }}
            >
              <LottieLoading loading={isLoading} />
            </div>
          </>
          :
          <>
            <Grid
              container
              spacing={2}
              marginTop="10px"
              width="93%"
              placeItems='flex-start'
              className='generalSection'
              sx={{
                backgroundColor: 'white',
                placeItems: 'flex-start',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                borderRadius: '8px',
                padding: '16px',
                margin: 'auto',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'start',
              }}
            >
              <Grid item className="GridParent" style={{ padding: '0', borderRadius: '10px', }} xs={2} spacing={2}>
                <div className="sectionParent" style={{ padding: '15px', backgroundColor: "#0b1124", width: "210px", borderRadius: '8px' }}>
                  <div className="sectionOne">
                    <div>
                      <CircularProgressbar
                        value={progress}
                        text={`${progress}%`}
                      />
                    </div>
                    <Box textAlign="center" margin={0}>
                      <Typography style={{ fontSize: '12px', color: '#e9e9e9', paddingTop: '15px', margin: '0px' }}>Correct Answers in Percentage</Typography>
                      {spaceview === false && textview === true && <Box >
                        <Typography gutterBottom style={{ fontSize: '12px', color: '#e9e9e9', paddingTop: '15px' }}>
                          {correctResponse} Correct Answers
                        </Typography>
                        <Typography style={{ fontSize: '12px', color: '#e9e9e9' }} >
                          {inCorrectResponse} Incorrect Answers
                        </Typography>
                      </Box>}
                    </Box>
                  </div>
                  <div  className="sectionTwo">
                    <img src={ImageView} alt="Brain Illustration" />
                  </div>

                </div>
              </Grid>

              <Grid item className='pannelcontent' xs={7} style={{ padding: '0', paddingLeft: '12px' }}>
                <Box style={{ flexDirection: 'column', }}>

                  {/* {location?.state?.assessmentResult === null || "" && */}

                  {spaceview &&
                    <Box
                      sx={{
                        //  width: '90%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '8px',
                        padding: '16px',

                      }}
                    >

                      <Box>
                        <Grid xs={12}>
                          <Box >
                            <Typography variant="h6" className='generalHeading' gutterBottom > General Assessment
                            </Typography>
                            <Box className='properAlign'
                              style={{
                                marginTop: "20px",
                                //  width: '500px',
                                width: '80%',
                                margin: 'auto',
                                minHeight: '200px',
                                border: '2px solid orange',
                                padding: '20px',
                                boxSizing: 'border-box'
                              }}
                            >
                              <img src={Rocket} alt="loading" width={60} style={{ margin: 'auto', display: 'block' }} />
                              <Typography variant="h6" gutterBottom textAlign={'center'}> {allDetails?.title ? allDetails?.title : 'Condition Functions'}
                              </Typography>


                              <Typography variant="body1" textAlign={'center'}>
                                Know how much you've learned with skillset IQ. Take your own time. You can always retake it to improve your score.
                              </Typography>
                            </Box>


                          </Box>
                          <Box mt={4}>
                            <Typography variant="h5" align="center" color={'primary'} gutterBottom>
                              Let's begin!
                            </Typography>
                            <Typography style={{
                              background: '#FEFEFF 0% 0% no-repeat padding-box',
                              textAlign: 'center',
                              borderRadius: '6px',
                              boxShadow: '1px 1px 3px #a4a4a4',
                              width: 'max-content',
                              margin: '1rem auto',
                              padding: '8px 16px',
                              fontWeight: '600',
                            }}>Space</Typography>
                            <Typography align="center">Click on the Spacebar on your keyboard to Start</Typography>
                          </Box>
                        </Grid>
                      </Box>

                    </Box>}


                  {spaceview === false && textview === false &&
                    <>
                      <Typography variant="h6" className='generalHeading' gutterBottom > General Assessment
                      </Typography>
                      {spaceview === false && textview === false && timeWith === true && (
 
 <Typography className='TimeLeft' style={{ float: 'right', marginRight: "0px", marginTop: '-30px', marginBottom: '5px', color: time <= 59 ? 'red' : 'black' }} variant="body2" >
Remaining Time: {formatTimeTimer(time)}
</Typography> 

)}
                      <Box className="Assessmentpannel" style={{
                        marginTop: "20px",
                        marginBottom: "20px",
                        minHeight: '350px',
                        border: '1px solid orange',
                        padding: '20px',
                        boxSizing: 'border-box'
                      }}>
                        <Typography
                          variant="h6"
                          dangerouslySetInnerHTML={{ __html: sanitizedContent }}
                        />
                        <FormControl>
                          <RadioGroup value={selectedOption} onChange={buttonClick === false ? handleChange : ""}>
                            {questions &&
                              questions[currentIndex]?.options &&
                              questions[currentIndex]?.options?.mcqOptions?.length > 0 &&
                              questions[currentIndex]?.options?.mcqOptions.map((option, i) => {
                                const sanitizedOption = DOMPurify.sanitize(option);
                                const optionView = new DOMParser()
                                  .parseFromString(sanitizedOption, "text/html")
                                  .body.textContent || "";
                                let optionColor = "";
                                if (isAnswerCorrect !== null) {
                                  if (i === correctAnswerIndex) {
                                    optionColor = "#00B818";
                                  } else if (optionView === selectedOption) {
                                    optionColor = "red";
                                  }
                                }
                                return (
                                  <FormControlLabel
                                    key={i}
                                    value={optionView}
                                    control={<Radio />}
                                    label={optionView}
                                    style={{ color: optionColor, marginLeft: "20px" }}
                                  />
                                );
                              })}
                          </RadioGroup>
                        </FormControl>

                        <LinearProgress variant="determinate" value={((currentIndex + 1 )/ allDetails?.questions_list?.length * 100)} sx={{ marginY: 2 }} />

                        {buttonClick === false &&
                          <Button variant="contained" onClick={() => handleSubmit()}>
                            Submit
                          </Button>}
                        {buttonClick === true && (
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '100%',
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: '600',
                                color: isAnswerCorrect ? 'green' : 'red',
                                textAlign: 'center',
                              }}
                            >
                              {isAnswerCorrect ? "Correct Answer!" : "Wrong Answer!"}
                            </Typography>
                          </Box>
                        )}

                        {buttonClick === true &&
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-between',

                            }}
                          >
                            <Box sx={{ mt: 'auto', textAlign: 'right' }}>
                              <Button variant="contained" onClick={() => handleNext()}>
                                Next
                              </Button>
                            </Box>
                          </Box>


                        }
                      </Box></>}
                  {spaceview === false && textview === true &&
                    <>
                      <Typography variant="h6" className='generalHeading' gutterBottom > General Assessment
                      </Typography>
                      <Box
                          className="boardClass"
                        sx={{
                          margin: 'auto',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginTop: '55px',
                        }}>

                        <Box className="contentrgb rgb-animate">
                          <Typography variant="h4" sx={{ color: 'black', textAlign: 'center', marginTop: '8px' }}> Congratulations ! </Typography>
                          <Typography sx={{
                            fontWeight: 'normal', color: 'black', textAlign: 'center', padding: "10px", lineHeight: 1.1,
                          }}>
                            Your General Assessment Challenge is Completed
                          </Typography>
                        </Box>
                        <Typography
                          variant="h6"
                          sx={{
                            marginTop: "20px"
                          }}
                        >
                          Your Score is {correctResponse}/{questions && questions?.length}
                        </Typography>

                        <Button sx={{
                          marginTop: '10px',
                          backgroundColor: "#fff",
                          color: "black",
                          border: "1px solid black",
                          borderRadius: "5px",
                          cursor: "pointer",
                          fontSize: "14px",
                          padding: '5px 10px',
                        }}
                          onClick={() => handleRetry(attemptAssessment)}>
                          Retry
                        </Button>
                        <Button sx={{
                          marginTop: '50px',
                          backgroundColor: "#fff",
                          color: "black",
                          border: "1px solid black",
                          borderRadius: "5px",
                          cursor: "pointer",
                          fontSize: "14px",
                          padding: '5px 10px',
                        }}
                          onClick={handleSummary}>
                          View Summary
                        </Button>
                      </Box>
                      {/* <LinearProgress variant="determinate" value={parseInt(Math.ceil(allQuestions?.length / questions[currentIndex]), 10)} sx={{ marginY: 2 }} /> */}
                    </>
                  }



                </Box>
              </Grid>

              <Grid item xs={3} className='ScoreBoard' style={{ display: 'flex', justifyContent: 'center' }}>
                <QuestionList question={questions} bestScore={allDetails.best_score} firstScore={allDetails.first_score} handleBack={handleBack} currentIndex={currentIndex} spaceview={spaceview} textview={textview}
                  />
              </Grid>
            </Grid>
          </>}
        </>}
      <Snackbar open={isopenSnackbar} snackbarTitle={snackbarTitle} close={() => setOpenSnackbar(false)} />

       {withoutimer === true && attempt === false &&<div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "700px",
          backgroundColor: "white",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          borderRadius: "8px",
          zIndex: "1000",
          padding: "20px",
          textAlign: "center",
        }}
      >
        <h3 style={{ fontWeight: "bold" }}>The test is not governed by time, but your session has expired!</h3>
        <p>The current test session is expired. Please close this tab and restart your test.</p>
          <div style={{marginTop:"10px"}}>
          <Button
            variant="outlined"
            onClick={handleBackLeave}
            style={{
              marginRight: "10px",
              // backgroundColor: "#4CAF50",
              color: "black",
              borderRadius: "0px",
            }}
          >
            Leave
          </Button>
          <Button
            variant="outlined"
            onClick={StayFunction}
            style={{
              // backgroundColor: "#f44336",
              color: "black",
              // border: "none",
              borderRadius: "0px",
            }}
          >
            Stay
          </Button>
        </div>
      </div>}
    </Page>
  );
};

export default GeneralAssessment;
