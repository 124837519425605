import client from './apiClient';

const getSubscriptionInfo = () => client.get('/subscription/info');

const postSubscriptionInfo = (data) => client.post('/subscription/info', JSON.stringify(data));

const putSubscriptionInfo = (id, data) => client.put(`/subscription/info?subscriptionId=${id}`, JSON.stringify(data));

const deleteSubscriptionInfo = (id) => client.delete(`/subscription/info?subscriptionId=${id}`);

const getSubscriptionPlans = () => client.get('/subscription/plan');

const postSubscriptionPlan = (subscriptionId, data,isAssesment) =>
  client.post(`/subscription/plan?subscriptionId=${subscriptionId}&isAssesment=${isAssesment}`, JSON.stringify(data));

const putSubscriptionPlan = (subscriptionId, data) =>
  client.put(`/subscription/plan?subscriptionPlanId=${subscriptionId}`, JSON.stringify(data));

const deleteSubscriptionPlans = (id) => client.delete(`/subscription/plan?subscriptionPlanId=${id}`);

const getSubscriptionDetailsByPlanId = (planId) =>
  client.get(`/individual/user/subscription/course?subscriptionPlanId=${planId}`);

export default {
  getSubscriptionInfo,
  deleteSubscriptionInfo,
  putSubscriptionInfo,
  getSubscriptionPlans,
  postSubscriptionInfo,
  postSubscriptionPlan,
  deleteSubscriptionPlans,
  putSubscriptionPlan,
  getSubscriptionDetailsByPlanId,
};
