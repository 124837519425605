/* eslint-disable */
import React, { useEffect, useState, useRef, useLayoutEffect, useMemo } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Backdrop, Box, Button, Card, CardActions, CardContent, Divider, Avatar, Collapse, CircularProgress, Stack, Fade, Grid, IconButton, Link, Modal, Paper, Tooltip, Typography, Dialog, DialogTitle, DialogContent, DialogActions, } from '@mui/material';
import 'react-circular-progressbar/dist/styles.css';
import 'react-html5video/dist/styles.css';
import { DefaultPlayer as Video } from 'react-html5video';
import { Icon } from '@iconify/react';
import Check from '@mui/icons-material/Check';
import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import CheckIcon from '@mui/icons-material/Check';
import LockIcon from '@mui/icons-material/Lock';
import { useLocation, NavLink, useNavigate } from 'react-router-dom';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import GaugeChart from 'react-gauge-chart';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import Skeleton from '@mui/material/Skeleton';
import swal from 'sweetalert';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import { IKContext, IKImage, IKVideo } from 'imagekitio-react';

import SlideshowIcon from '@mui/icons-material/Slideshow';

import './index.css';
import helper from '../../../utils/helper';

import background from '../../../assets/logo/cardBackground.png';
import CardSkeleton from '../../../components/Skeleton/cardSkeleton'

import BasicModal from '../../../components/modal/BasicModel';
import ButtonAnimation from '../../../components/ButtonAnimation';
import caseStudyService from '../../../services/caseStudy/caseStudyService';
import courseApi from '../../../services/users/courseApi';
import Iconify from '../../../components/Iconify';
import iqImage from '../../../assets/logo/iqImage.png';
import iqServices from '../../../services/iqTest/index';
import Page from '../../../components/Page';
import palette from '../../../theme/palette';
import ImageKit from '../../../settings';
import trialAndStripeSubscriptionService from '../../../services/trialAndStripeSubscriptionService';
import { openSubscriptionModal, setOpenSubscriptionModalDetails,openSnackbar } from '../../../store/reducer';
import SearchBar from './SearchBar';
import ListPage from './ListPage';
import { blue } from '@mui/material/colors';
import adminServices from 'src/services/adminServices';
import ReferenceScreen from '../../course/ReferenceScreen';
import loginServices from 'src/services/loginServices';
import AchivementModel from './Achivement'
import IqModel from './Iqmodel'
import { getCourseProgress, getCourseDetails1 } from '../../../Redux/Action'
import Dottedlogo from '../../../assets/Images/description_logo.png'

const LinkBtn = React.forwardRef((props, ref) => {
  return <NavLink to={props.to} {...props} innerRef={ref} />;
});



const CustomStepIcon = ({ isCompleted, children }) => {
  return (
    <div style={{ color: isCompleted ? "green" : "gray" }}>
      {children}
    </div>
  );
};

const CustomConnector = ({ isCompleted }) => {
  return (
    <div
      style={{
        height: "2px",
        backgroundColor: isCompleted ? "green" : "gray", // Change this color as needed
        width: "100%",
      }}
    />
  );
};

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
    // ...theme.applyStyles('dark', {
    //   borderColor: theme.palette.grey[800],
    // }),
  },
}));

const QontoStepIconRoot = styled('div')(({ theme }) => ({
  color: '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  '& .QontoStepIcon-completedIcon': {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  // ...theme.applyStyles('dark', {
  //   color: theme.palette.grey[700],
  // }),
  variants: [
    {
      props: ({ ownerState }) => ownerState.active,
      style: {
        color: '#784af4',
      },
    },
  ],
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
    // ...theme.applyStyles('dark', {
    //   backgroundColor: theme.palette.grey[800],
    // }),
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme }) => ({
  backgroundColor: '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  // ...theme.applyStyles('dark', {
  //   backgroundColor: theme.palette.grey[700],
  // }),
  variants: [
    {
      props: ({ ownerState }) => ownerState.active,
      style: {
        backgroundImage:
          'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
      },
    },
    {
      props: ({ ownerState }) => ownerState.completed,
      style: {
        backgroundImage:
          'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
      },
    },
  ],
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <SettingsIcon />,
    2: <GroupAddIcon />,
    3: <VideoLabelIcon />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

export default function UserCourseDetails(props) {
  const videoRef = useRef(null);
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userRole = useSelector((state) => state.userInfo && state.userInfo.role);
  
  const testrole = useSelector((state) => state);
  const userId = useSelector((state) => state.userInfo && state.userInfo.id);
  const { userSubscribedCourses } = useSelector((state) => state);
  const { openSubscriptionModalDetails, userInfo } = useSelector((state) => state);
  const courseDetails = location.state;  
  const [caseStudy, setCaseStudy] = useState([]);
  const [courseModules, setCourseModules] = useState([]);
  const [subscription, setSubscription] = useState({});
  const [achivementModel, setAchivementModel] = useState(false);
  const allcourseDetails = useSelector((state) => state);
  const [currentIndex,setCurrentIndex]=useState(location?.state?.index&&location?.state?.index)
  const [isTrialing, setIsTrialing] = useState(null);
  const prevProgressRef = useRef();
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 600);
  const hashTagDetails = location.state?.hashTag ? location.state.hashTag : location.state?.hashtag;
  const [courseProgress, setCourseProgress] = useState(null);
  const [csTestResult, setCsTestResult] = useState(null);
  const [csTestLoading, setCsTestLoading] = useState(false);
  const storedExpandedItem = localStorage.getItem('expandedItem');
  const initialExpanded = storedExpandedItem !== null ? Number(storedExpandedItem) : null;
  const storedSubExpandedItem = localStorage.getItem('subModuleExpandedItem');
  const initialSubmoduleExpanded = storedSubExpandedItem !== null ? Number(storedSubExpandedItem) : null;
  const [expanded, setExpanded] = React.useState(initialExpanded);
  const [isWantMoreText, setIsWantMoreText] = useState(true);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [openContent, setOpenContent] = useState(false);
  const [openResult, setOpenResult] = useState(false);
  const [resumeData, setResumeData] = useState(null);
  const [subModuleExpanded, setSubModuleExpanded] = React.useState(initialSubmoduleExpanded);
  const [csConfig, setCsConfig] = useState(null);
  const [efficiencyVal, setEfficiencyVal] = useState(10);
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [openMobieViewError, setOpenMobileViewError] = useState(false);
  const [videoTitle, setVideoTitle] = useState({
    title: '',
    video: null,
  });

  const [searchResults, setSearchResults] = useState([]);
  const [searchComplete, setSearchComplete] = useState(false);
  const [courseComplet, setCourseComplet] = useState(false);
  const [modeldisable, setModeldisable] = useState(false);

  const [appliedFilter, setAppliedFilter] = useState('All');
  const [allBlocks, setAllBlocks] = useState([]);
  const [simBlocks, setSimBlocks] = useState([]);
  const [vidBlocks, setVidBlocks] = useState([]);
  const [slice, setSlice] = useState([]);
  const [bannerDetails, setBannerDetails] = useState('');
  const [activeStep, setactiveStep] = useState(location.state?.index ?location.state?.index :null);
  const [selectedModuleIndex, setSelectedModuleIndex] = useState(location.state?.index ?location.state?.index :0);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [iqModel, setIqModel] = useState(false);
  const [viewComplete, setViewComplete] = useState('');
  const [IsLoading, setIsLoading] = useState(false);


  const [isSubscribed, setIsSubscribed] = useState(true);
  const [intfrequency, setintfrequency] = React.useState(1);
  const [currency, setCurrency] = React.useState(userInfo?.currencyType ? userInfo?.currencyType.code : 'USD');


   useEffect(() => {
    
          if (allcourseDetails.AllCourseDetails.length > 0) {              
            const obj = allcourseDetails.AllCourseDetails.find((item) => item?.subscriptionPlanId === courseDetails.subscriptionPlanId || courseDetails.subscriptionplanid );           
            if (obj !== undefined) {
                  if ( obj.isSubscribed === false) {
                      setIsSubscribed(false);
                  } 
              }
          }
      }, [courseDetails]);
  
  useEffect(() => {
    const playVideo = async () => {
      if (videoRef.current) {
        try {
          await videoRef.current.play();
        } catch (error) {
          console.error("Autoplay failed or blocked: ", error);
        }
      }
    };

    playVideo();

    return () => {
      if (videoRef.current) {
        videoRef.current.pause();
      }
    };
  }, [videoTitle]);

  const CloseModel = () => {
    setIqModel(false);

  }

  const [milestones, setMilestones] = useState({
    crossed25: false,
    crossed50: false,
    crossed75: false,
    crossed100: false,
  });


  const CloseModelAchivement = (progress) => {
    setMilestones({
      crossed25: false,
      crossed50: false,
      crossed75: false,
      crossed100: false,
    });
    setAchivementModel(false);
    setModeldisable(true);
 
    const existingProgressData = JSON.parse(localStorage.getItem("progressmodelold"));
    const safeProgressData = Array.isArray(existingProgressData) ? existingProgressData : [];
 
    const newProgress = {
      progress: progress,
      id: courseDetails?.id,
      // progress25: progress >= 25 && progress < 50,
      // progress50: progress >= 50 && progress < 75,
      // progress75: progress >= 75 && progress < 100,
      progress100: progress === 100,
      userid: userId
    };
 
    const updatedProgressData = safeProgressData?.map((entry) => {
      if (entry.userid === userId && entry.id === courseDetails?.id) {
        return { ...entry, progress100: newProgress.progress100,
          // progress75: newProgress.progress75,
          // progress50: newProgress.progress50,
          // progress25: newProgress.progress25,
         };
      }
      return entry;
    });
 
    const entryExists = updatedProgressData?.some(
      (entry) => entry.userid === userId && entry.id === courseDetails?.id
    );
    const finalProgressData = entryExists ? updatedProgressData : [...updatedProgressData, newProgress];
    localStorage.setItem("progressmodelold", JSON.stringify(finalProgressData));
  }


  const oldProgress = JSON.parse(localStorage.getItem("progressmodelold"));

  React.useMemo(() => {
    const courseModulesProgress = courseModules?.map((module) => {
      const allCompleted = module.subModule?.every((subModule) => subModule.completed === 100);
      return {
        ...module,
        modelcomplete: allCompleted
      };
    });


    const modelCompleteCount = courseModulesProgress?.filter(item => item.modelcomplete === true)?.length;
    const completedModules = "Completed Modules - " + modelCompleteCount + " / " + courseModules?.length;
    // const completionPercentage = courseModules?.length ? ((modelCompleteCount / courseModules?.length) * 100).toFixed(1) : 0;
    const completionPercentage = courseModules?.length
      ? Math.round((modelCompleteCount / courseModules?.length) * 100)
      : 0;




    setViewComplete(`${completedModules} (${completionPercentage}%)`);
  }, [courseModules])

  useEffect(() => {
    // CloseModelAchivement();
    setIqModel(false);
    getDetails()
  }, [])


  useLayoutEffect(() => {
    if (allcourseDetails?.CourseProgress) {
      setCourseProgress(allcourseDetails?.CourseProgress)
      const iqTestCount = parseInt(allcourseDetails?.CourseProgress?.iqtestcount, 10);
      const overallProgressmodel = parseInt(allcourseDetails?.CourseProgress?.overallProgress, 10);
      if (overallProgressmodel === 0 || overallProgressmodel === 100) {
        if (iqTestCount === 0) {
          setIqModel(true);
        }
      } else if (iqTestCount >= 1) {
        setIqModel(false);
      }
    }
  }, [allcourseDetails])



  React.useMemo(() => {
    if (allcourseDetails?.CourseProgress) {

      if (parseFloat(allcourseDetails.efficiencyScore) >= 10 && parseFloat(allcourseDetails.efficiencyScore) < 100) {
        setEfficiencyVal(100);
      } else if (
        parseFloat(allcourseDetails.efficiencyScore) >= 100 &&
        parseFloat(allcourseDetails.efficiencyScore) < 1000
      ) {
        setEfficiencyVal(1000);
      } else if (
        parseFloat(allcourseDetails.efficiencyScore) >= 1000 &&
        parseFloat(allcourseDetails.efficiencyScore) < 10000
      ) {
        setEfficiencyVal(10000);
      }
    }
  }, [allcourseDetails])


  const getDetails = async () => {
    const res = await adminServices.getBasicDetails();
    if (res.ok) {
      res.data?.settings?.map(item => {
        switch (item.config_name) {

          case "static_banner_message":
            setBannerDetails(item.data && item.data[0])
            break;

        }
      })

    }
  }

  useLayoutEffect(() => {
    if (courseDetails && courseDetails?.comingFrom === 'simulationPage' && courseDetails?.model === 'model') {
      const progress = courseProgress && parseInt(courseProgress.overallProgress, 10)? courseProgress && parseInt(courseProgress.overallProgress, 10):0;
      const prevProgress = prevProgressRef.current;
      if (prevProgress !== undefined) {
        setMilestones((prevMilestones) => {
          const updatedMilestones = { ...prevMilestones };
 
          if (progress >= 25 && progress<=30
            // oldProgress?.length > 0 &&
            // oldProgress.some(data => data.id === courseDetails?.id && data.userid === userId && data.progress25 === false)
          ) {
            updatedMilestones.crossed25 = true;
            setTimeout(() => {
              setAchivementModel(true);
            }, 1000);
          } else if (progress >= 50
            // && 
            // oldProgress?.length > 0 &&
            // oldProgress.some(data => data.id === courseDetails?.id && data.userid === userId && data.progress50 === false)
          ) {
            updatedMilestones.crossed50 = true;
            setTimeout(() => {
              setAchivementModel(true);
            }, 1000);          } else if (progress >= 75
            // && oldProgress?.length > 0 &&
            // oldProgress.some(data => data.id === courseDetails?.id && data.userid === userId && data.progress75 === false)
 
          ) {
            updatedMilestones.crossed75 = true;
            setTimeout(() => {
              setAchivementModel(true);
            }, 1000);           
          } else if (
            progress === 100 &&
            oldProgress?.length > 0 &&
            oldProgress.some(
              data => data.id === courseDetails?.id && data.userid === userId && data.progress100 === false
            )
          ) {
            updatedMilestones.crossed100 = true;
            setTimeout(() => {
              setAchivementModel(true);
            }, 10000);          }
 
 
          // if (
          //   progress === 100 &&
          //   oldProgress?.length > 0 &&
          //   oldProgress.some(data => data.id === courseDetails?.id && data.userid === userId && data.progress100 === false)
          // ){
          //     updatedMilestones.crossed100 = true;
          //     setAchivementModel(true);
          //   }
          else {
            setAchivementModel(false);
          }
 
          return updatedMilestones;
        });
      }
 
      prevProgressRef.current = progress;
    }
  }, [courseProgress]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#00B673' : '#00B673',
    },
  }));

  const AchievementsSection = ({ progressPercentage, achivementprogress }) => {
    const resultfinal = (
      achivementprogress?.iqtestcount > 0 &&
      progressPercentage >= 25 &&
      progressPercentage >= 50 &&
      progressPercentage >= 75 &&
      progressPercentage === 100 &&
      achivementprogress?.efficiencyScore >= 3 &&
      achivementprogress?.efficiencyScore >= 4
  ) ? true : false;
    const achievements = [
      { id: 1, unlocked: achivementprogress?.iqtestcount > 0, icon: achivementprogress?.iqtestcount > 0 ? require('../../../assets/Images/Medal1_green.png') : require('../../../assets/Images/lock_page.png'), message: "You have completed 0 %" },

      { id: 2, unlocked: progressPercentage >= 25, icon: progressPercentage >= 25 ? require('../../../assets/Images/Medal2_green.png') : require('../../../assets/Images/lock_page.png'), message: "You have completed 25% of couse" },
      { id: 3, unlocked: progressPercentage >= 50, icon: progressPercentage >= 50 ? require('../../../assets/Images/Medal3_green.png') : require('../../../assets/Images/lock_page.png'), message: "You have completed 50% of couse" },
      { id: 4, unlocked: progressPercentage >= 75, icon: progressPercentage >= 75 ? require('../../../assets/Images/Medal4_green.png') : require('../../../assets/Images/lock_page.png'), message: "You have completed 75% of couse" },
      { id: 5, unlocked: progressPercentage == 100, icon: progressPercentage == 100 ? require('../../../assets/Images/Medal5_green.png') : require('../../../assets/Images/lock_page.png'), message: "You have completed 100% of couse" },
      { id: 6, unlocked: achivementprogress?.efficiencyScore >=3, icon:achivementprogress?.efficiencyScore >=3 ? require('../../../assets/Images/Medal6_green.png') : require('../../../assets/Images/lock_page.png') },
      { id: 7, unlocked: achivementprogress?.efficiencyScore >=4, icon:achivementprogress?.efficiencyScore >=4 ? require('../../../assets/Images/Medal7_green.png') : require('../../../assets/Images/lock_page.png') },
      { id: 8, unlocked: resultfinal, icon: resultfinal ? require('../../../assets/Images/Medal9_green.png'): require('../../../assets/Images/lock_page.png')  },
      // { id: 9, unlocked: progressPercentage === 100, icon: progressPercentage === 100 ? require('../../../assets/Images/Medal5_green.png') : require('../../../assets/Images/Medal5_green.png') },

      { id: 9, unlocked: false, icon: require('../../../assets/Images/lock_page.png') },

    ];

    achivementprogress?.iqtestcount > 0,
    progressPercentage >= 25,
    progressPercentage >= 50,
    progressPercentage >= 75,
    progressPercentage == 100,
    achivementprogress?.efficiencyScore >=3,
    achivementprogress?.efficiencyScore >=4

    return (
      <>
      <Box
  sx={{
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    marginTop: '20px',
    // maxWidth: '320px',
    position: 'relative',
    maxWidth: '100%',
    padding: '16px',
    maxHeight: '168px'
  }}
>
  <Box
    key={achievements[8].id}
    sx={{
      position: 'absolute',
      top: '-30px',
      left: '50%',
      transform: 'translateX(-50%)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}
  >
    <Box
      sx={{
        width: '80px',
        height: '80px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Avatar
        src={achievements[8].icon}
        sx={{
          width: '76px',
          height: '76px',
          opacity: achievements[8].unlocked ? 1 : 0.4,
          transition: 'opacity 0.3s ease',
          backgroundColor: 'white',
        }}
      />
    </Box>
    {achievements[8].unlocked && (
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          right: 1,
          backgroundColor: 'green',
          borderRadius: '50%',
          width: '12px',
          height: '12px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: '2px solid green',
        }}
      >
        <CheckIcon sx={{ fontSize: '10px', color: 'white' }} />
      </Box>
    )}
  </Box>

  {achievements?.slice(0, 7).map((achievement, index) => {
    const rowPosition = index % 4;
    const yOffset = (rowPosition === 1 || rowPosition === 2) ? -20 : 0;
   // const topMargin = index <= 3 ? '55px' :  '0px';
   const topMargin = index === 0 || index === 3 ? '45px': index === 1 || index === 2 ? '64px': index === 4 ? '-40px' : '0px' ;

    return (
      <Box
        key={achievement.id}
        sx={{
          position: 'relative',
          transform: `translateY(${yOffset}px)`,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: topMargin,
          
        }}
      >
        <Box
          sx={{
            width: '60px',
            height: '60px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Tooltip title={achievement.message} arrow>
            <Avatar
              src={achievement.icon}
              sx={{
                width: '56px',
                height: '56px',
                opacity: achievement.unlocked ? 1 : 0.4,
                transition: 'opacity 0.3s ease',
                cursor: 'pointer',
              }}
            />
          </Tooltip>
        </Box>
        {achievement.unlocked && (
          <Box
            sx={{
              position: 'absolute',
              top: 2,
              right: 2,
              backgroundColor: 'green',
              borderRadius: '50%',
              width: '12px',
              height: '12px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px solid white',
            }}
          >
            <CheckIcon sx={{ fontSize: '10px', color: 'white' }} />
          </Box>
        )}
      </Box>
    );
  })}
</Box>

<Divider />
<Box className="efficiencyScore" >
  <GaugeChart
    id="gauge-chart2"
    nrOfLevels={4}
    arcWidth={0.2}
    percent={courseProgress?.efficiencyScore / efficiencyVal || 0.2}
    animate={false}
    textColor="#00b673"
    formatTextValue={() =>
      courseProgress ? `${parseInt(courseProgress.efficiencyScore, 10)}x` : '0x'
    }
    cornerRadius={0}
    arcPadding={0}
    colors={['#e5eaec', '#92d4b5', '#59bd91', '#1da86b']}
    hideText
    needleColor="#d8dcdd"
    needleBaseColor="#d8dcdd"
  />
  <Typography
    variant="body1"
    sx={{
      fontWeight: '600',
      fontSize: { xs: '0.8rem', sm: '1rem', md: '1.2rem' },
      lineHeight: '1.2',
      textAlign: 'center',
      color: '#FE7000',
    }}
  >
    {courseProgress ? `${parseInt(courseProgress.efficiencyScore, 10)}x` : '0x'}
  </Typography>
  <Typography
    variant="subtitle1"
    sx={{
      fontWeight: 'bold',
      textAlign: 'center',
      marginTop: '1px',
      fontSize: { xs: '0.9rem', sm: '1rem' },
    }}
  >
    Efficiency Score
  </Typography>
</Box>

            </>
    );
  };



  useEffect(() => {
    if (!courseDetails) {
      navigate('/app/course');
    }
  }, []);

  const SubcriptionEnable = (data) => {
    return (
      <Paper elevation={3} style={{ width: '97%', margin: '0 auto', marginLeft: '30px' }}>
        <div style={{ display: 'flex', marginTop: -30, marginBottom: 30, justifyContent: 'space-between', padding: '1rem' }}>
          <div className={classes.renewContent}>
            <Typography style={{ fontWeight: 'bold', fontSize: '18px' }} component="h2">
              {data.title}
            </Typography>
            <Typography> {data.description}</Typography>
          </div>
          <div className={classes.renewContent}>
            {userRole === 'USER_DTC' && (
              <>
                {subscription?.planStatus?.toLowerCase() === 'expired' ? (
                  <Button variant="contained" onClick={() => postSubscribeDetails(subscription?.subscriptionPlanId)}>
                    {subscription?.isTrial && 'Subscribe'}
                    {subscription?.isTrial === false && 'Renew'}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    className={classes.subscribe}
                    onClick={() => postSubscribeDetails(subscription?.subscriptionPlanId)}
                  >
                    Subscribe
                  </Button>
                )}
              </>
            )}
            {userRole === 'AUTH_USER' &&
              subscription?.isSubscribed &&
              subscription?.planStatus?.toLowerCase() === 'active' && (
                <Button
                  variant="contained"
                  className={classes.subscribe}
                  onClick={() => postSubscribeDetails(subscription?.subscriptionPlanId)}
                >
                  {subscription?.planStatus?.toLowerCase()}
                </Button>
              )}
          </div>
        </div>
      </Paper>


      // <Paper elevation={3}>
      //   <div style={{ display: 'flex',marginTop:-30,marginBottom:30, justifyContent: 'space-between', padding: '1rem' }}>
      //     <div className={classes.renewContent}>
      //       <Typography style={{ fontWeight: 'bold', fontSize: '18px' }} component="h2">
      //         {data.title}
      //       </Typography>
      //       <Typography> {data.description}</Typography>
      //     </div>
      //     <div className={classes.renewContent}>
      //       {userRole === 'USER_DTC' && (
      //         <>
      //           {subscription?.planStatus?.toLowerCase() === 'expired' ? (
      //             <Button variant="contained" onClick={() => postSubscribeDetails(subscription?.subscriptionPlanId)}>
      //               {subscription?.isTrial && 'Subscribe'}
      //               {subscription?.isTrial === false && 'Renew'}
      //             </Button>
      //           ) : (
      //             <Button
      //               variant="contained"
      //               className={classes.subscribe}
      //               onClick={() => postSubscribeDetails(subscription?.subscriptionPlanId)}
      //             >
      //               Subscribe
      //             </Button>
      //           )}
      //         </>
      //       )}
      //       {userRole === 'AUTH_USER' &&
      //         subscription?.isSubscribed &&
      //         subscription?.planStatus?.toLowerCase() === 'active' && (
      //           <Button
      //             variant="contained"
      //             className={classes.subscribe}
      //             onClick={() => postSubscribeDetails(subscription?.subscriptionPlanId)}
      //           >
      //             {subscription?.planStatus?.toLowerCase()}
      //           </Button>
      //         )}
      //     </div>
      //   </div>
      // </Paper>
    );
  };


  const [isQuery, setIsQuery] = useState('');
  const getFilterData = (masterData) => {
    const aBlocks = [];
    const sBlocks = [];
    const vBlocks = [];
    masterData.forEach(res => {
      if (typeof (res.subModule) !== undefined && res.subModule != null) {
        // console.log('subModule.....',res.subModule);
        res.subModule.forEach(sub => {
          aBlocks.push({
            type: 'v',
            sub: sub.courseSubmoduleName,
            mod: res.courseModuleName,
            video: sub.video,
            id: sub.id
          });
          vBlocks.push({
            type: 'v',
            sub: sub.courseSubmoduleName,
            mod: res.courseModuleName,
            video: sub.video,
            id: sub.id
          });
          if (typeof (sub.subModuleTopics) !== undefined && sub.subModuleTopics != null) {
            sub.subModuleTopics.forEach(topic => {
              aBlocks.push({
                type: 's',
                topic: topic.courseSubmoduleTopics,
                sub: sub.courseSubmoduleName,
                mod: res.courseModuleName,
                tid: topic.id,
                sid: sub.id
              });
              sBlocks.push({
                type: 's',
                topic: topic.courseSubmoduleTopics,
                sub: sub.courseSubmoduleName,
                mod: res.courseModuleName,
                tid: topic.id,
                sid: sub.id
              });
            });
          }
        });
      }
    });

    setAllBlocks(aBlocks);
    setSimBlocks(sBlocks);
    setVidBlocks(vBlocks);
    setSlice(aBlocks);
    // setSlice({"All":aBlocks,"Sim":sBlocks,"Vid":vBlocks,"Rtc":[]})
    setSearchResults(slice);
  };

  ;

  //  const handleReferenceClick = (id,name) => {
  //   console.log("inside handleReferenceClick......",id,name);
  //   if (userRole === 'USER_DTC') {
  //     console.log("userRole === 'USER_DTC...");
  //     navigate(`/auth/reference-screen?id=${id}&courseSubmoduleName=${name}`, {
  //       replace: true,
  //       state: { id,name},
  //     });
  //   } else {
  //     console.log("userRole...2");
  //     navigate(`/app/reference-screen?id=${id}&courseSubmoduleName=${name}`, {
  //       replace: true,
  //       state: { id,name },
  //     });
  //   }

  //  };

  const updateCourseCopletStatus = async () => {
    try {
      const updateResult = await courseApi.updateCourseCompletionStatus(courseDetails?.id, userId)
      if (updateResult.ok) {
      }
    }
    catch {
      console.log(error);
    }
  }
  const getCourseCompletionDetails = async () => {
    try {
      const result = await courseApi.getCourseCompletionStatus(
        courseDetails?.id, userId);
      if (result.ok) {
        setCourseComplet(result.data.course_completed);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const postSubscribeDetailsButton = async (item) => {
    console.log(item,"item");
    
    const planId = item.subscriptionPlanId || item.subscriptionplanid
    const courseId = item.id;
    setIsLoading(true);

    if (item.isPaid) {
      let impactClickId = '';
      try {
        impactClickId = await helper.getClickId();
      } catch (error) {
        console.error(error);
        impactClickId = '';
      }

      const payload = {
        userId,
        courseId,
        planId,
        currencyType: currency,
        frequency: parseInt(intfrequency),
        impactClickId,
      };
      console.log(payload, 'payloadpayload');

      try {
        const res = await trialAndStripeSubscriptionService.createStripeSubscription(JSON.stringify(payload));
        if (res.status === 303) {
          // dispatch(closeSubscriptionModal());
          // setSubscriptionModalLoading(false);
          window.location = res.data.paymentUrl;
          setIsLoading(false);
          // getCourseDetails()
        } else {
          if (res?.data?.message) {
            setIsLoading(false);

            dispatch(openSnackbar(res.data.message));
          } else {
            setIsLoading(false);

            dispatch(openSnackbar('Failed to Subscribe, Please try again.'));
          }
          // setSubscriptionModalLoading(false);
        }
      } catch (error) {
        setIsLoading(false);

        console.log(error);
      }
    } else {
      setIsLoading(true);
      try {
        const res = await trialAndStripeSubscriptionService.createStripeSubscription(JSON.stringify({ planId }));
        if (res.ok) {
          getCourseDetails()

          dispatch(openSnackbar('Course subscribed successfully'));
        } else if (res.status === 400) {
          dispatch(openSnackbar(res.data.message));
        } else {
          dispatch(openSnackbar('Failed to subscribe, please try again'));
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);

      }
      setIsLoading(false);
    }
  };


  const getCourseDetails = async () => {
    setLoading(true);
    try {
      const response = await courseApi.getCourseModulesAndSubmodules(
        courseDetails?.id,
        // courseDetails?.subscriptionPlanId
        courseDetails.subscriptionPlanId ? courseDetails.subscriptionPlanId : courseDetails.subscriptionplanid !== null ? courseDetails.subscriptionplanid : ''

      );
      if (response.ok) {
        setCourseModules(response?.data?.course);
        setCaseStudy(response?.data?.files);
        setSubscription(response?.data?.subscription);
        if (!response?.data?.subscription?.isTrial && response?.data?.subscription?.isSubscribed) {
          if (
            response?.data?.subscription?.planStatus?.toLowerCase() === 'expired' ||
            response?.data?.subscription?.planStatus?.toLowerCase() === 'cancel'
          ) {
            if (userRole === 'USER_DTC') {
              postSubscribeDetails(response?.data?.subscription?.subscriptionPlanId);
            }
          }
        }
        getFilterData(response?.data?.course);
      }
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (courseModules && courseModules.length > 0) {
      getFilterData(courseModules);
    }
  }, [courseModules]);

  const getLastCompletedDetails = async () => {
    const res = await courseApi.getUserLastCompletedDetails(courseDetails?.id);
    if (res.ok) {
      setResumeData(res.data);
    }
  };

  // const getCourseProgress = async () => {
  //   const iqResponse = await iqServices.getIqAndCourseProgress(courseDetails?.id);
  //   if (iqResponse.ok) {
  //     setCourseProgress(iqResponse.data);

  //     if (parseFloat(iqResponse.data.efficiencyScore) >= 10 && parseFloat(iqResponse.data.efficiencyScore) < 100) {
  //       setEfficiencyVal(100);
  //     } else if (
  //       parseFloat(iqResponse.data.efficiencyScore) >= 100 &&
  //       parseFloat(iqResponse.data.efficiencyScore) < 1000
  //     ) {
  //       setEfficiencyVal(1000);
  //     } else if (
  //       parseFloat(iqResponse.data.efficiencyScore) >= 1000 &&
  //       parseFloat(iqResponse.data.efficiencyScore) < 10000
  //     ) {
  //       setEfficiencyVal(10000);
  //     }
  //   }
  // };

  const postSubscribeDetails = async (planId) => {
    dispatch(setOpenSubscriptionModalDetails(courseDetails));
    dispatch(openSubscriptionModal(planId));
  };

  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: expanded === expand ? 'rotate(180deg)' : 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  const ExpandMoreSub = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: subModuleExpanded === expand ? 'rotate(180deg)' : 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  useEffect(() => {
    getCourseDetails();
    dispatch(getCourseProgress(courseDetails.id))
    getLastCompletedDetails();
    // getCourseProgress();
    getCaseStudyResult();
    getCaseStudyConfig();
    getCourseCompletionDetails();
  }, [courseDetails]);

  const getCaseStudyResult = () => {
    try {
      caseStudyService.getCaseStudyResult(courseDetails.id).then((res) => {
        if (res.ok) {
          if (Object.keys(res.data).length > 0) {
            setCsTestResult(res.data);
          }
          setCsTestLoading(true);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getCaseStudyConfig = () => {
    caseStudyService.getCaseStudyConfig(courseDetails?.id).then((response) => {
      if (response.ok && response.status === 200) {
        if (Object.keys(response.data).length > 0) {
          setCsConfig(response.data);
        }
      }
    });
  };

  const moduleRefs = useRef([]);
  useEffect(() => {
    if (activeStep !==null && moduleRefs.current[activeStep]) {
      console.log('inside here ');
      
      moduleRefs.current[activeStep].scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

  }, [activeStep,loading]);


  const handleExpandClick = (i) => {
    if (expanded === i) {
      setExpanded(-1);
      setSubModuleExpanded(null);
      localStorage.setItem('expandedItem', -1);
    } else {
      setSubModuleExpanded(null);
      localStorage.setItem('subModuleExpandedItem', -1);
      setTimeout(() => {
        setExpanded(i);
        localStorage.setItem('expandedItem', i);
      }, 300);
    }
  };

  const handleSubModuleExpandClick = (i) => {
    const isExpandingSameSubModule = subModuleExpanded === i;

    setSubModuleExpanded(isExpandingSameSubModule ? -1 : i);

    setTimeout(() => {
      if (!isExpandingSameSubModule) {
        setSubModuleExpanded(i);
      }
    }, 300);

    localStorage.setItem('subModuleExpandedItem', isExpandingSameSubModule ? -1 : i);
  };
  const handleClickTrialButton = async (planId, enrollmentType, courseDetails) => {
    try {
      const res = await trialAndStripeSubscriptionService.postTrialCourseEnrollmentDetails(
        JSON.stringify({ planId: planId, enrollmentType: enrollmentType, courseId: courseDetails?.id })
      );
      if (res.ok) {
        
      } else {
        if (res?.data?.message) {
          dispatch(openSnackbar(res.data.message));
        } else {
          dispatch(openSnackbar('Failed to Subscribe Trial Course, Please try again.'));
        }
      }
    } catch (error) {
      console.log(error);
    }
    setIsTrialing(null);
  };

  const handleStartSimulation = (subModuleId, module,selectindex, resumeClic = false) => {
    
  if(isSmallScreen){
    setOpenMobileViewError(true)
  } else{
    if(resumeData===null&&courseDetails.is_free) 
      {    
        handleClickTrialButton(courseDetails.subscriptionplanid,courseDetails?.is_free,courseDetails)
      } 
    if (resumeData && resumeClic) {
      if (userRole === 'USER_DTC') {
        navigate(
          `/auth/course-simulation?module=${resumeData.Modulename}&id=${subModuleId}&topic=${resumeData.courseSubModuleTopicId}&screen=${resumeData.courseSubModuleTopicScreenId}`,
          { replace: true, state:{ ...courseDetails,selectindex:selectindex} }


 
        );
      } else {
        navigate(
          `/app/course-simulation?module=${resumeData.Modulename}&id=${subModuleId}&topic=${resumeData.courseSubModuleTopicId}&screen=${resumeData.courseSubModuleTopicScreenId}`,
          { replace: true, state: { ...courseDetails,selectindex:selectindex}  }
        );
      }
    } else {
      if (userRole === 'USER_DTC') {
        navigate(`/auth/course-simulation?id=${subModuleId}&module=${module}`, { replace: true, state: { ...courseDetails,selectindex:selectindex}  });
      } else {
        navigate(`/app/course-simulation?id=${subModuleId}&module=${module}`, { replace: true, state: { ...courseDetails,selectindex:selectindex}  });
      }
    }
  } 

  };

  const handleIQstart = (type) => {
    if (courseDetails?.is_free) {
      alert("Sorry! The SkillsetIQ feature is not available for Free Course.")
return
    }
    if (subscription?.isSubscribed && ['active', 'expired', 'cancel'].includes(subscription?.planStatus?.toLowerCase())) {
      const keyType = courseModules[0]?.subModule[0]?.subModuleTopics[0]?.actionType
      if (type === 'COURSE') {
        if (userRole === 'USER_DTC') {
          navigate(`/auth/skilliq-test?courseId=${courseDetails?.id}`, {
            replace: true,
            // state: { ...courseDetails, ...keyType,subModule },
            state: { ...courseDetails, keyType },

          });
        } else {
          navigate(`/app/skilliq-test?courseId=${courseDetails?.id}`, {
            replace: true,
            state: { ...courseDetails, keyType },

            // state: { ...courseDetails, ...keyType ,subModule},
          });
        }
      }
    }
    else {
      alert("Please subscribe to unlock the IQ Test")
    }
  };

  const handleIQstartVideo = (type) => {
    if (courseDetails?.is_free) {
      alert("Sorry! The SkillsetIQ feature is not available for Free Course.")
return
    }
    if (subscription?.isSubscribed && ['active', 'expired', 'cancel'].includes(subscription?.planStatus?.toLowerCase())) {
      const keyType = 'VIDEO';
      if (type === 'COURSE') {
        if (userRole === 'USER_DTC') {
          navigate(`/auth/skilliq-test?courseId=${courseDetails.id}`, {
            replace: true,
            state: { ...courseDetails, keyType },
          });
        } else {
          navigate(`/app/skilliq-test?courseId=${courseDetails.id}`, {
            replace: true,
            state: { ...courseDetails, keyType },
          });
        }
      }
    }
    else {
      alert("Please subscribe to unlock the IQ Test")
    }
  };

  async function downloadURI(url, filename) {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
      })
      .catch(console.error);
  }

  const fileIcon = [
    { type: 'image/jpeg', icon: 'ant-design:file-image-outlined' },
    { type: 'image/png', icon: 'ant-design:file-image-outlined' },
    { type: 'image/jpg', icon: 'ant-design:file-image-outlined' },
    { type: 'image/svg+xml', icon: 'ant-design:file-image-outlined' },
    { type: 'application/pdf', icon: 'fa6-regular:file-pdf' },
    { type: 'video/mp4', icon: 'la:file-video-solid' },
    { type: 'text/csv', icon: 'teenyicons:csv-outline' },
    { type: 'application/octet-stream', icon: 'teenyicons:xls-outline' },

    { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', icon: 'teenyicons:xls-outline' },
  ];

  useLayoutEffect(() => {
    if (courseProgress && parseInt(courseProgress.overallProgress) === 100 && courseComplet === false) {
      const timer = setTimeout(() => {
        updateCourseCopletStatus()
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [courseProgress]);

  const takeCaseStudyTestHandle = () => {
    if (courseProgress && parseInt(courseProgress.overallProgress, 10) < 100) {
      swal({
        // title: 'Good job!',
        text: 'You must complete 100% to be eligible to take the test',
        // icon: 'success',
        buttons: {
          confirm: {
            text: 'Okay',
            value: true,
            visible: true,
            className: '',
            closeModal: true,
          },
          // cancel: {
          //   text: 'Cancel',
          //   value: null,
          //   visible: true,
          //   className: '',
          //   closeModal: true,
          // },
        },
        closeOnClickOutside: false,
      });
    } else {
      swal({
        // title: 'Good job!',
        text: ' You have only one chance to pass the test. Please make sure that you have prepared well',
        // icon: 'success',
        buttons: {
          confirm: {
            text: 'Okay',
            value: true,
            visible: true,
            className: '',
            closeModal: true,
          },
          cancel: {
            text: 'Cancel',
            value: null,
            visible: true,
            className: '',
            closeModal: true,
          },
        },
        closeOnClickOutside: false,
      }).then((res) => {
        if (res) {
          const keyType = { actionType: courseModules[0]?.subModule[0]?.subModuleTopics[0]?.actionType };
          navigate(
            userRole === 'USER_DTC'
              ? `/auth/case-studyiq-test?courseId=${courseDetails?.id}`
              : `/app/case-studyiq-test?courseId=${courseDetails?.id}`,
            {
              replace: true,
              state: { ...courseDetails, ...keyType },
              // state: { ...courseDetails, ...keyType,subModule},
            }
          );
        }
      });
    }
  };

  const CaseStudyHtml = () => {
    return (
      <>
        {csConfig?.status === 'ACTIVE' && (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={9}>
              <Paper elevation={2} sx={{ padding: '1.4rem', marginTop: '1rem' }}>
                <Box className={classes.courserContent}>
                  <Typography variant="h6" className="casestudy">
                    Case Study
                  </Typography>
                </Box>

                {caseStudy.length === 0 && (
                  <Typography align="center" variant="subtitle1">
                    Files are not available
                  </Typography>
                )}

                {caseStudy.map((res, index) => (
                  <Box className={classes.fileList}>
                    <Link underline="none" variant="subtitle1" sx={{ display: 'flex' }} href={res.url} target="_blank">
                      {fileIcon.map((item) => {
                        return <>{res.type === item.type ? <Iconify icon={item.icon} width={20} height={20} /> : ''}</>;
                      })}
                      &nbsp; {res.name}
                    </Link>

                    {/* <IconButton size="small" onClick={() => downloadURI(res.url, res.type)}>
                  <CloudDownloadIcon color="primary" />
                </IconButton> */}

                    {res?.type === 'image/jpeg' ||
                      res?.type === 'image/png' ||
                      res?.type === 'image/jpg' ||
                      res?.type === 'image/svg+xml' ||
                      res?.type === 'application/pdf' ||
                      res?.type === 'video/mp4' ? (
                      <IconButton size="small" onClick={() => downloadURI(res.url, res.type)}>
                        <CloudDownloadIcon color="primary" />
                      </IconButton>
                    ) : (
                      <Link
                        underline="none"
                        variant="subtitle1"
                        sx={{ display: 'flex' }}
                        href={res.url}
                        target="_blank"
                      >
                        <IconButton size="small">
                          <CloudDownloadIcon color="primary" />
                        </IconButton>
                      </Link>
                    )}
                  </Box>
                ))}
                {/* 
            <IconButton size="small" onClick={() => downloadURI(res.url, res.type)}>
              <CloudDownloadIcon color="primary" />
            </IconButton>
          </Box>
        ))} */}

                {csTestLoading && (
                  <Box mt={3}>
                    {csTestResult ? (
                      <Button variant="contained" color="primary" onClick={() => setOpenResult(true)}>
                        View result
                      </Button>
                    ) : (
                      <>
                        {csConfig?.status === 'ACTIVE' && (
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={() => {
                              takeCaseStudyTestHandle();
                            }}
                          >
                            Take Test
                          </Button>
                        )}
                      </>
                    )}
                  </Box>
                )}

                {/* <Button
          color="primary"
          variant="contained"
          onClick={() => {
            swal({
              // title: 'Good job!',
              text: ' You have only one chance to pass the test. Please make sure that you have prepared well',
              // icon: 'success',
              buttons: {
                confirm: {
                  text: 'Okay',
                  value: true,
                  visible: true,
                  className: '',
                  closeModal: true,
                },
                cancel: {
                  text: 'Cancel',
                  value: null,
                  visible: true,
                  className: '',
                  closeModal: true,
                },
              },
              closeOnClickOutside: false,
            }).then((res) => {
              if (res) {
                navigate(userRole==="USER_DTC"?`/auth/case-studyiq-test?courseId=${courseDetails.id}`:`/app/case-studyiq-test?courseId=${courseDetails.id}`, {
                  replace: true,
                  state: courseDetails,
                });
              }
            });
          }}
        >
          Take Test
        </Button> */}
              </Paper>
            </Grid>
          </Grid>
        )}
      </>
    );
  };

  const ProgressBar = ({ progressPercentage, achivementprogress }) => {

    const steps = [
      { unlocked: achivementprogress > 0 ? true : false, bordercolor: achivementprogress > 0 ? true : false, icon: achivementprogress > 0 ? require('../../../assets/Images/Medal1_green.png') : require('../../../assets/Images/Medal1_grey.png') },
      { unlocked: progressPercentage >= 25 ? true : false, bordercolor: progressPercentage >= 25 ? true : false, icon: progressPercentage >= 25 ? require('../../../assets/Images/Medal2_green.png') : require('../../../assets/Images/Medal2_grey.png') },

      { unlocked: progressPercentage >= 50 ? true : false, bordercolor: progressPercentage >= 50 ? true : false, icon: progressPercentage >= 50 ? require('../../../assets/Images/Medal3_green.png') : require('../../../assets/Images/Medal3_grey.png') },
      { unlocked: progressPercentage >= 75 ? true : false, bordercolor: progressPercentage >= 75 ? true : false, icon: progressPercentage >= 75 ? require('../../../assets/Images/Medal4_green.png') : require('../../../assets/Images/Medal4_grey.png') },
      { unlocked: progressPercentage === 100 ? true : false, bordercolor: progressPercentage === 100 ? true : false, icon: progressPercentage === 100 ? require('../../../assets/Images/Medal5_green.png') : require('../../../assets/Images/Medal5_grey.png') },
    ];

    const getIconStyle = (index) => ({
      width: '60px',
      height: '60px',
    });

    const getSegmentWidth = (index) => {
      const lowerBound = index * 25;
      const upperBound = (index + 1) * 25;

      if (progressPercentage >= upperBound) {
        return '100%';
      } else if (progressPercentage > lowerBound) {
        const percentageInSegment = ((progressPercentage - lowerBound) / 25) * 100;
        return `${percentageInSegment}%`;
      }
      return '0%';
    };

    const getSegmentColor = (index) => {
      const upperBound = (index + 1) * 25;
      return progressPercentage >= upperBound ? '#4caf50' : '#e0e0e0';
    };

    const getIconBorderColor = (index) => {
      const upperBound = (index + 1) * 25;
      return progressPercentage >= upperBound ? '#4caf50' : '#000';
    };

    
   
    return (
      <Box className="barGraph" sx={{ width: '98%', backgroundColor: '#f9f9f9' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            {steps.map((step, index) => (
              <React.Fragment key={index}>
                <Box sx={{ textAlign: 'center', position: 'relative', flex: 1 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '80px',
                      height: '80px',
                      // border: step.unlocked ? '2px solid green' : '2px solid grey',  
                      // borderRadius: '50%',  
                      // boxSizing: 'border-box', 
                      overflow: 'hidden',
                      position: 'relative',
                    }}
                  >
                    <Avatar
                      src={step.icon}
                      sx={{
                        width: '80px',
                        height: '80px',
                        opacity: step.unlocked ? 1 : 0.4,
                        transition: 'opacity 0.3s ease',
                      }}
                    />
                  </Box>

                  {step.unlocked && (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 1.5,
                        right: 1,
                        backgroundColor: 'green',
                        color: '#fff',
                        borderRadius: '50%',
                        padding: '4px',
                        border: '2px solid white',
                        width: '22px',
                        height: '22px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        boxShadow: '0 0 5px rgba(0, 128, 0, 0.4)',
                      }}
                    >
                      <CheckIcon sx={{ fontSize: '16px', color: 'white' }} />
                    </Box>
                  )}
                </Box>


                {index < steps.length - 1 && (
                  <Box
                    sx={{
                      height: 2,
                      width: '130%',
                      backgroundColor: '#e0e0e0',
                      position: 'relative',
                    }}
                  >
                    <Box
                      sx={{
                        height: 2,
                        width: getSegmentWidth(index),
                        backgroundColor: getSegmentColor(index),
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        borderStyle: progressPercentage >= (index + 1) * 25
                          ? '1px solid green'
                          : (
                            progressPercentage > index * 25 && progressPercentage < (index + 1) * 25
                          )
                            ? 'dashed'
                            : "",

                        transition: 'width 0.3s ease-in-out',
                      }}
                    />
                  </Box>
                )}
              </React.Fragment>
            ))}
          </Box>
        </Box>
        <Typography className="contentPara" style={{ marginLeft: 90, marginTop: -60, marginBottom: 10, fontSize: '11px', color: 'black' }}>{viewComplete}</Typography>
        <Box sx={{ marginTop: '40px', marginLeft: -5, display: 'table-cell', alignItems: 'center', width: 'inherit', position: 'relative', top: '45px'}}>
          {(userRole === 'AUTH_USER' || userRole === 'USER_DTC')
            ? (

              <>
                {courseDetails?.type !== 'video ' ?
                  <>
                    {achivementprogress === 0 &&
                      <Box 
                        display="flex !important"
                        justifyContent={
                          progressPercentage < 25 ? 'flex-start' :
                            progressPercentage >= 25 && progressPercentage < 50 ? 'space-between' :
                              progressPercentage >= 50 && progressPercentage < 100 ? 'center' :
                                'flex-end'
                        }
                        width="96vw"  maxWidth="1480px"
                      >
                        <Button
                          variant="contained"
                          onClick={() => handleIQstart('COURSE')}
                          style={{
                            backgroundColor: '#002B50',
                            marginTop: '5px',
                            marginLeft:
                              progressPercentage >= 75 && progressPercentage < 100 ? '45.5%' :
                                progressPercentage >= 25 && progressPercentage < 50 ? '20%' :
                                  '0',
                          }}
                          disabled={loading}
                        >
                          {loading ? (
                            'Loading...'
                          ) : (
                            'Take IQ Assessment'
                          )}
                        </Button>
                      </Box>
                    }
                    {achivementprogress >= 1 &&
                      <Box 
                        display="flex !important"
                        justifyContent={
                          progressPercentage < 25 ? 'flex-start' :
                            progressPercentage >= 25 && progressPercentage < 50 ? 'space-between' :
                              progressPercentage >= 50 && progressPercentage < 100 ? 'center' :
                                'flex-end'
                        }
                        width="96vw"  maxWidth="1480px"
                      >
                        <Button
                          onClick={() => handleIQstart('COURSE')}
                          variant="contained"
                          style={{
                            backgroundColor: '#002B50',
                            marginTop: '5px',
                            marginLeft:
                              progressPercentage >= 75 && progressPercentage < 100 ? '45.5%' :
                                progressPercentage >= 25 && progressPercentage < 50 ? '20%' :
                                  '0',
                          }}
                          disabled={loading}
                        >
                          {loading ? (
                            'Loading...'
                          ) : (
                            'ReTake IQ Assessment'
                          )}
                        </Button>
                      </Box>}

                  </> :
                  (
                    <>

                      {achivementprogress === 0 &&
                        <Box 
                          display="flex !important"
                          justifyContent={
                            progressPercentage < 25 ? 'flex-start' :
                              progressPercentage >= 25 && progressPercentage < 50 ? 'space-between' :
                                progressPercentage >= 50 && progressPercentage < 100 ? 'center' :
                                  'flex-end'
                          }
                          width="96vw"  maxWidth="1480px"
                        >
                          <Button
                            variant="contained"
                            onClick={() => handleIQstartVideo('COURSE')}
                            style={{
                              backgroundColor: '#002B50',
                              marginTop: '5px',
                              marginLeft:
                                progressPercentage >= 75 && progressPercentage < 100 ? '45.5%' :
                                  progressPercentage >= 25 && progressPercentage < 50 ? '20%' :
                                    '0',
                            }}
                            disabled={loading}
                          >
                            {loading ? (
                              'Loading...'
                            ) : (
                              'Take IQ Assessment'
                            )}
                          </Button>
                        </Box>
                      }
                      {achivementprogress >= 1 &&
                        <Box 
                          display="flex !important"
                          justifyContent={
                            progressPercentage < 25 ? 'flex-start' :
                              progressPercentage >= 25 && progressPercentage < 50 ? 'space-between' :
                                progressPercentage >= 50 && progressPercentage < 100 ? 'center' :
                                  'flex-end'
                          }
                          width="96vw" maxWidth="1480px"
                        >
                          <Button
                            variant="contained"
                            onClick={() => handleIQstartVideo('COURSE')}
                            style={{
                              backgroundColor: '#002B50',
                              marginTop: '5px',
                              marginLeft:
                                progressPercentage >= 75 && progressPercentage < 100 ? '45.5%' :
                                  progressPercentage >= 25 && progressPercentage < 50 ? '0' :
                                    '0',
                            }}
                            disabled={loading}
                          >
                            {loading ? (
                              'Loading...'
                            ) : (
                              'ReTake IQ Assessment'
                            )}
                          </Button>
                        </Box>}
                    </>)}
              </>
            ) : (
              '111'
            )}
        </Box>
      </Box>
    );
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 600);
    };
 
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
 
  const typographyStyle = {
    fontFamily: 'Poppins',
    color: '#000',
    fontWeight: '600',
    fontSize: isSmallScreen ? '20px' : '32px',
  };

  return (
    <Page title={'Course-details'}>
 <Card
  sx={{
    top: '50%',
    backgroundColor: 'black', 
    height: '30px',
    margin: {
      // xs: '0 10px', 
      // sm: '0 20px', 
      // md: '0 15px',
      xs: '0px', 

    },
    padding: 0,
    zIndex: 999,
    borderRadius: '0',
    width: {
      // xs: '94%', 
      // sm: '96%',
      // md: '97.8%',
      xs: '100%'
    },
    position: 'relative',
    marginRight: {
      // xs: '8px', 
      // sm: '12px',
      // md: '15px', 
    },
    
  }}
>
  <CardContent 
    sx={{ 
      position: 'relative',
      height: '100%',
      padding: {
        xs: '4px 8px', 
        sm: '4px 12px', 
        md: '4px 16px', 
      },
      '&:last-child': {
        paddingBottom: '4px'
      },
      width: '100%',
    }}
  >
    <Typography
      gutterBottom
      className="fontdetails"
      sx={{
        color: 'white',
        fontSize: {
          xs: 9, 
          sm: 11, 
          md: 12, 
        },
        fontWeight: '600',
        position: 'absolute',
        textAlign: 'center',
        padding: '0px 0',
        whiteSpace: 'nowrap',
        display: 'inline-block',
        animation: 'slide 15s linear infinite',
        overflow: 'hidden',
        width: '100%',
        '@media (max-width: 320px)': { 
          fontSize: 9,
        },
      }}
    >
      {bannerDetails?.value || "Data analytics plays a crucial role in various domains and industries, helping organizations make informed decisions, improve processes and gain valuable"}
    </Typography>
  </CardContent>
</Card>
    

      <Box style={{ display: 'flex', justifyContent: 'space-between'}} className={classes.coursecard}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={3} lg={4} xl={4} className={classes.courseImg}>
            <img src={courseDetails?.coverageImage ? courseDetails?.coverageImage : courseDetails?.cover_image_url}
              className={classes.image} alt="Course Cover"/>
              {courseDetails.introVideo ? (
          <>
            <Box className={classes.playbutton} style={{position: 'absolute', top: '42%', left: '40%'}}>
              <Button
                onClick={handleOpen}
                className={classes.glowing}
                sx={{
                  background: 'unset',
                  '&:hover': {
                    background: 'unset',
                  },
                  '&:after': {
                    background: 'unset !important',
                  },
                }}
              >
                <ButtonAnimation />
              </Button>
            </Box>

          </>
        ) : (
          <div className={classes.playbutton} />
        )}
          </Grid>

          <Grid item xs={12} sm={12} md={8.4} lg={7} xl={7} style={{ marginLeft: '20px' }}>
            <Box>
              {courseDetails.title && courseDetails.title.length > 54 ? (
                <>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', pr: 4 }}>
  <Tooltip title={courseDetails?.title} arrow>
    <Typography
      style={{
        fontFamily: 'Poppins',
        color: '#000000',
        fontWeight: '600',
        fontSize: '32px',
        '@media (max-width: 600px)':{
          fontSize: '16px',
        },
      }}
      className={classes.coursetitle}
      gutterBottom
      variant="h3"
    >
      {courseDetails.title}
    </Typography>
  </Tooltip>

  <img
    className={classes.logo}
    src={
      courseDetails.category?.categoryImgUrl
        ? courseDetails.category.categoryImgUrl
        : courseDetails?.category_image
    }
    alt="Category Logo"
    style={{
      marginRight: '9px',
      width: '100%',
      maxWidth: '35px', 
    }}
  />
</Box>

                </>
              ) : (
                <>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    {/* <Tooltip title={courseDetails.title} arrow> */}
                    <Typography
                     style={typographyStyle}
                      className={classes.coursetitle}
                      gutterBottom
                      variant="h3"
                    >
                      {courseDetails.title}
                    </Typography>
                    {/* </Tooltip> */}

                    <img
    className={classes.logo}
    src={
      courseDetails.category?.categoryImgUrl
        ? courseDetails.category.categoryImgUrl
        : courseDetails?.category_image
    }
    alt="Category Logo"
    style={{
      marginRight: '9px',
      width: '100%',
      maxWidth: '35px', 
    }}
  />
                  </Box>
                </>
              )}

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <Box mt={1}>
                    {courseDetails?.short_desc !== null && courseDetails?.short_desc.slice(0, 3)?.map((item, index) => (

                      <Typography
                        key={index}
                        variant="body1"
                        sx={{
                          color: 'black',
                          marginBottom: 1,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <img
                          src={Dottedlogo}
                          // alt="Category Logo"
                          width="14px"
                          style={{ marginRight: '8px', marginTop: '2px' }}
                        />
                        {item}
                      </Typography>

                    ))}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Box mt={1}>
                    {courseDetails?.short_desc !== null && courseDetails?.short_desc.slice(3, 6)?.map((item, index) => (
                      <Typography
                        key={index}
                        variant="body1"
                        sx={{
                          color: 'black',
                          marginBottom: 1,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <img
                          src={Dottedlogo}
                          width="14px"
                          style={{ marginRight: '8px', marginTop: '2px' }}
                        />
                        {item}
                      </Typography>

                    ))}
                  </Box>
                </Grid>
              </Grid>




              <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '50px' }}>
                <Box className="groupBtn" display="flex" flexDirection="row" gap={1}>
                  <Button
                    variant="contained"
                    sx={{
                      minWidth: '50px',
                      backgroundColor: '#002B50',
                      borderRadius: 0,
                      '&:hover': {
                        backgroundColor: '#002B50',
                      },
                    }}
                  >
                    {courseProgress && courseProgress.overallProgress === '100' ? 'Completed' : 'Inprogress'}
                  </Button>

                  <Button
                    variant="contained"
                    sx={{
                      minWidth: '50px',
                      backgroundColor: '#002B50',
                      borderRadius: 0,
                      '&:hover': {
                        backgroundColor: '#002B50',
                      },
                    }}
                  >
                    {courseDetails && courseDetails?.category?.description ? courseDetails?.category?.description : courseDetails?.category_description}
                  </Button>

                  <Button
                    variant="contained"
                    sx={{
                      minWidth: '50px',
                      borderRadius: 0,
                      backgroundColor: '#002B50',
                      '&:hover': {
                        backgroundColor: '#002B50',
                      },
                    }}
                  >
                    {hashTagDetails && hashTagDetails?.length > 0
                      ? hashTagDetails[0]?.description
                      : 'No Hashtags Available'}
                  </Button>

                  <Button
                    variant="contained"
                    sx={{
                      minWidth: '50px',
                      borderRadius: 0,
                      backgroundColor: '#002B50',
                      '&:hover': {
                        backgroundColor: '#002B50',
                      },
                    }}
                  >
                    {courseDetails && courseDetails?.type}
                  </Button>

                  {!isSubscribed && userRole !== 'AUTH_USER' && !courseDetails?.is_free  &&
                    <Button
                      variant="contained"
                      onClick={() => postSubscribeDetailsButton(courseDetails)}
                      sx={{
                        marginTop:'40px',
                        minWidth: '50px',
                        borderRadius: 0,
                        position: 'absolute',
                        right: '30px',
                        backgroundColor: '#FE780F',
                        '&:hover': {
                          backgroundColor: 'green',
                        },
                        borderRadius: '8px',
                      }}
                      disabled={IsLoading}
                    >
                      {IsLoading ? "Please wait..." : "Subscribe Now"}
                    </Button>
                  }
                </Box>

              </div>
            </Box>
          </Grid>
        </Grid>


      </Box>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        sx={{
          '.css-moysu4': {
            border: 'none ! important',
          },
        }}
      >
        <Fade in={open}>
          {courseDetails.introVideo && (
            <Box className={classes.vediobox}>
              <Video controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}>
                <source src={courseDetails.introVideo} type="video/webm" />
                <track label="English" kind="subtitles" srcLang="en" src={courseDetails.introVideo} default />
              </Video>
            </Box>
          )}
        </Fade>
      </Modal>


      <Stack className={classes.progressSection} sx={{ marginTop: '40px', height: '120px', marginBottom: 10, marginLeft: 4, marginBottom: '50px' }}  spacing={4}>
        <ProgressBar progressPercentage={courseProgress && parseInt(courseProgress.overallProgress, 10)} achivementprogress={courseProgress && parseInt(courseProgress?.iqtestcount, 10)} />
      </Stack>

      <AchivementModel achivementModel={achivementModel} progress={courseProgress && parseInt(courseProgress.overallProgress, 10)} handlCloseBack={CloseModelAchivement} />

      <IqModel openmodel={iqModel} userRole={userRole} subscription={subscription} courseDetails={courseDetails} courseModules={courseModules} progress={courseProgress} handlCloseBack={CloseModel} />

      <BasicModal openModel={openContent} closeModel={() => setOpenContent(!openContent)} title={courseDetails.title}>
        <Typography id="transition-modal-title" variant="subtitle1" component="h2">
          Description
        </Typography>
        <Typography id="transition-modal-description" sx={{ mt: 2 }}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla in tortor a ex dictum commodo. Fusce pretium,
          quam a egestas dictum, purus erat sodales nibh, eget sollicitudin urna felis non felis.
        </Typography>
      </BasicModal>

      <Grid container className={classes.CourseContentSec} mt={2} sx={{marginLeft: '15px'}}>
        {userRole === 'USER_DTC' && (
          <>
            {subscription?.isSubscribed &&
              (subscription?.planStatus?.toLowerCase() === 'cancel' ||
                subscription?.planStatus?.toLowerCase() === 'expired') && (
                <Grid item xs={12}>
                  <SubcriptionEnable
                    title={
                      subscription?.planStatus?.toLowerCase() === 'expired'
                        ? 'Your trial period is expired '
                        : 'Your trial period is cancel'
                    }
                    description={
                      subscription?.planStatus?.toLowerCase() === 'expired'
                        ? 'To continue learning, kindly renew your subscription.'
                        : 'To continue learning, kindly subscription.'
                    }
                  />
                </Grid>
              )}
          </>
        )}


        {userRole === 'AUTH_USER' &&
          subscription?.isSubscribed &&
          subscription?.planStatus?.toLowerCase() === 'cancel' && (
            <Grid item xs={12}>
              <SubcriptionEnable
                title="Your subscription has been cancelled."
                description="Kindly contact admin support."
              />
            </Grid>
          )}
        {userRole === 'AUTH_USER' &&
          subscription?.isSubscribed &&
          subscription?.planStatus?.toLowerCase() === 'expired' && (
            <Grid item xs={12}>
              <SubcriptionEnable
                title="Your subscription has expired."
                description="To renew your subscription, please contact admin support."
              />
            </Grid>
          )}



 {/* main grid */}
        <Grid container style={{marginLeft: '0px', justifyContent: 'space-between'}} spacing={2}>
          <Grid item className={classes.gridA}
            style={{
              // marginLeft: 60,
              backgroundColor: '#FFFFFF',
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
              borderRadius: '8px',
             
            }}           
            // xs={12} sm={12} md={8} lg={8}
            >
            <Box >
              <Box className={classes.courserContent}>
                <Typography className='fontdetails' variant="h6">Course Content</Typography>
              </Box>
              {loading ? (
                <div>
                  <CardSkeleton />
                </div>
              ) : (
                <Box display="flex" flexDirection="row" height="440px">
                  <Box className="logDetails"
                    flex="0 0 32%"
                    sx={{
                      paddingRight: '16px',
                      // minHeight: '462px',
                      // maxHeight: '462px',
                      
                      height: '470px',
                      overflowY: 'auto',
                      scrollbarWidth: 'none',
                      '-ms-overflow-style': 'none',
                      '&::-webkit-scrollbar': { display: 'none' },
                    
                    }}
                  >
                    {courseModules &&
                      courseModules.map((res, index) => (
                        <Card
                          key={index}
                          ref={(el) => (moduleRefs.current[index] = el)} 
                          sx={{
                            cursor: 'pointer',
                            maxHeight: courseModules && courseModules?.length === 2 ? '220px' : '95px',
                            minHeight: courseModules && courseModules?.length === 2 ? '220px' : '95px',
                            margin: '6px 0',
                            borderRadius: 0,
                            backgroundColor: selectedModuleIndex === index ? '#F5FFFB' : '',
                            boxShadow: selectedModuleIndex === index ? '0px 0px 4px #8DE6BB' : '',
                            width: selectedModuleIndex === index ? '100%' : '96%',
                            marginLeft: '2px',
                            transition: 'box-shadow 0.3s, border-color 0.3s',
                            '&:hover': {
                              borderColor: selectedModuleIndex === index ? '#BBF7FD' : '#ccc',
                            },
                          }}
                          onClick={() => setSelectedModuleIndex(index)}
                        >
                          <CardContent style={{ marginTop: '-6px' }} sx={{ padding: '5px', lineHeight: '1.2' }}sm={{padding: '24px' }}>
                            <Typography className='fontdetails' sx={{ fontSize: '12px', color: '#6E6E6E' }}sm={{ fontSize: '14px'}}>Module {index + 1}</Typography>
                            <Typography className='fontdetails'
                              style={{
                                color: selectedModuleIndex === index ? 'green' : '', fontSize: selectedModuleIndex === index ? '18px' : '16px', fontWeight: '900', lineHeight: '1.2'}}
     
                            >
                              {res.courseModuleName}
                            </Typography>
                          </CardContent>
                        </Card>
                      ))}
                  </Box>
{/* right box */}
                  <Box className="RightCore"
                    flex="0 0 66%"
                    sx={{
                      // minHeight: '462px',
                      // maxHeight: '462px',
                      height:'470px',
                      // height: '100%',
                      overflowY: 'scroll',
                      // marginLeft: '5px',
                      marginTop: '5px',
                      padding: '10px',
                      // border: '2px solid #BBF7FD',
                      // borderRadius: '8px',
                      boxShadow: loading === false ? '0px 0px 4px #8DE6BB' : '',

                      backgroundColor: loading === false ? '#F5FFFB' : "",
                      scrollbarWidth: 'none',
                      '-ms-overflow-style': 'none',
                      '&::-webkit-scrollbar': { display: 'none' },
                      '@media (max-width: 768px)':{

                      }
                    }}
                  >
                    {selectedModuleIndex !== null &&
                      courseModules?.length > 0 &&
                      courseModules[selectedModuleIndex]?.subModule?.length > 0 &&
                      courseModules[selectedModuleIndex]?.subModule.map((subModule, indx) => (
                        <React.Fragment key={indx}>
                          <Card className={classes.subModuleSec}
                            sx={{
                              cursor: 'pointer',
                              width: 'auto',
                              margin: '3px 5px',
                              borderRadius: '4px',
                              boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                              transition: 'box-shadow 0.3s, border-color 0.3s',
                              minHeight: subModule.courseSubmoduleName.length > 40 ? 'auto' : '45px',
                              '&:hover': {
                                boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                                borderColor: '#002B50',
                              },
                            }}
                          >
                            <CardContent className={classes.paddingZero}>
                              <Typography  style={{ fontSize: '12px', color: '#6E6E6E' }}>
                                Sub Module {indx + 1}
                              </Typography>
                              <Box className={classes.fontdetailstxt}
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <Box
                                  sx={{
                                    flexGrow: 1,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    marginRight: '0px',
                                    textAlign: 'start',
                                    display: 'block',
                                    width: '100%',
                                    WebkitLineClamp: 2,
                                    WebkitBoxOrient: 'vertical',
                                  }}
                                  md={{
                                    display: '-webkit-box',
                                    WebkitLineClamp: 2,
                                    WebkitBoxOrient: 'vertical',
                                  }}
                                >
                                  <Typography
                                    className='fontdetails'
                                    variant="h6"
                                    sx={{
                                      fontSize: '12px',
                                      display: 'flex',
                                      alignItems: 'start',
                                      wordBreak: 'break-word',
                                      fontWeight: 'normal',
                                      // flexDirection: 'column'
                                      '@media (max-width: 600px)':{
                                        flexDirection: 'column',
                                           alignItems: 'start'
                                },
                                    }}
                                  >
                                    <span className='subModuleName' style={{lineHeight: '1.2'}}>{subModule.courseSubmoduleName} </span>
                                    <Typography
                                      variant="subtitle2"
                                      color="secondary"
                                      component="span"
                                      sx={{
                                        marginLeft: '10px',
                                        fontSize: '11px',
                                        whiteSpace: 'nowrap',
                                        margin: '1px 8px',
                                        lineHeight: '1',
                                        alignSelf: 'center',
                                        marginBottom: '0px',
                                        justifySelf: 'center'
                                      }} >
                                        {`${subModule.completed}% completed`}
                                    </Typography>
                                  </Typography>
                                </Box>


                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                    flexShrink: 0,
                                  }}
                                >
                                  <LoadingButton
                                    variant="outlined"
                                    className={classes.CourseButton}
                                    onClick={() =>
                                      handleStartSimulation(subModule?.id, courseModules[selectedModuleIndex]?.courseModuleName,selectedModuleIndex)
                                    }
                                  >
                                    Start
                                  </LoadingButton>
                                </Box>
                              </Box>
                            </CardContent>
                          </Card>





                        </React.Fragment>
                      ))}
                  </Box>


                </Box>
              )}
            </Box>
          </Grid>

        <Grid item className={classes.gridB} style={{width: '350px'}}>
          <Grid container spacing={2} className='numGridB' style={{width: '350px'}}>
            <Grid item xs={12} className={classes.gridBsub}>
              <Paper elevation={1} className={classes.paper1}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <SearchBar
                      slice={slice}
                      setSearchResults={setSearchResults}
                      filter={appliedFilter}
                      setSearchComplete={setSearchComplete}
                      setIsQuery={setIsQuery}
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.searchtabsgrid}>
                    <ListPage
                      searchResults={searchResults}
                      searchComplete={searchComplete}
                      handleStartSimulation={handleStartSimulation}
                      setOpenVideoModal={setOpenVideoModal}
                      setVideoTitle={setVideoTitle}
                      isQuery={isQuery}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} className={classes.gridBsub}>
              <Paper elevation={1}
                sx={{padding: '8px',textAlign: 'center',border: '2px solid #ccc',borderRadius: '8px',display: 'flex',flexDirection: 'column',alignItems: 'center',justifyContent: 'space-between',position: 'relative', height: '450px',}}
                className={classes.paper}>
                <Typography className='achivementText' variant="h6" sx={{fontWeight: 'bold',textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',color: '#fff',fontSize: { xs: '1.2rem', sm: '1.5rem', md: '1.5rem' },marginTop: '0px', marginBottom: '10px'}}>ACHIEVEMENTS</Typography>
                <AchievementsSection sx={{maxHeight: '150px'}}
                  progressPercentage={courseProgress?.overallProgress || 0}
                  achivementprogress={courseProgress}
                />
            
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        </Grid>
      </Grid>
    
      {userRole !== 'AUTH_USER' &&
        !subscription?.isTrial &&
        (subscription?.isSubscribed || subscription?.planStatus?.toLowerCase() === 'active') && <CaseStudyHtml />}

      {userRole === 'AUTH_USER' &&
        subscription?.isSubscribed &&
        subscription?.planStatus?.toLowerCase() === 'active' && <CaseStudyHtml />}

      <BasicModal openModel={openResult} closeModel={() => setOpenResult(!openResult)} title="Case study result">
        <Typography variant="h5" gutterBottom sx={{ display: 'flex' }}>
          Test Result :{' '}
          {csTestResult && csTestResult.result ? (
            <Typography variant="h5" color="secondary">
              &nbsp;Pass
            </Typography>
          ) : (
            <Typography variant="h5" color="primary">
              &nbsp; Fail
            </Typography>
          )}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Percentage : {csTestResult && csTestResult.resultInPercentage} %
        </Typography>
        {/* <Typography variant="body1" gutterBottom>
          Time Taken : {csTestResult && csTestResult.timeTaken}
        </Typography> */}
        <Typography variant="body1" gutterBottom>
          Correct Answer: {csTestResult && csTestResult.rightAnswer}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Wrong Answer: {csTestResult && csTestResult.wrongAnswer}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Total number of question: {csTestResult && csTestResult.totalScreen}
        </Typography>
      </BasicModal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openVideoModal}
        onClose={handleClose}
        closeAfterTransition
        sx={{
          backgroundColor: '#0a00008c !important',
          '.css-moysu4': {
            border: 'none !important',
          },
        }}
      >
        <Fade in={openVideoModal}>
          <Box className={classes.vediobox}>
            <Typography variant="h6" color="#fff" gutterBottom>
              {videoTitle.title}
            </Typography>
            <IKContext urlEndpoint={['dev', 'test'].includes($(process.env.REACT_APP_ENV)) ? process.env.REACT_APP_AWS_S3_URL : ImageKit.ImageKitUrl} isPrivateFile>
              <IKVideo
                ref={videoRef}
                className={classes.videoContainer}
                src={videoTitle.video}
                controls={true}
                controlsList="nodownload"
              />

            </IKContext>
            <IconButton
              sx={{
                position: 'absolute',
                top: '24px',
                background: '#f6f6f6',
                // '.css-3u56ba-MuiButtonBase-root-MuiIconButton-root:hover': {
                //   backgroundColor: 'rgb(255 255 255 / 67%) !important',
                // },
              }}
              className={classes.closeBtn}
              size="small"
              onClick={() => setOpenVideoModal(false)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        </Fade>
      </Modal>


      <Dialog fullWidth open={openMobieViewError}>
      <DialogTitle style={{color: "#FE7000", textAlign: 'center', paddingBottom: '0px',fontSize: '18px', fontWeight: '900', paddingTop: '22px'}}>Desktop Only!</DialogTitle> 
      <CloseIcon onClick={() => setOpenMobileViewError(false)} sx={{ position: 'absolute', right: '0',padding: '5px',fontSize: '36px',marginRight: '5px',marginTop: '5px',fontWeight: '800',color: '#000'}} fontSize="small" />
      <DialogContent style={{ fontSize: '13px', textAlign: 'center', fontWeight: '750', lineHeight: '1.18', color: '#000', padding: '15px 15px 0px'}}>Our Guided-Simulation Learning Platform is best viewed on Desktop only. If you are using a Mobile device, we suggest you shift to a laptop or PC to upskill well!</DialogContent>      
      <DialogActions sx={{justifyContent: 'center',justifyContent: 'center', paddingBottom: '15px'}}>
        <Button sx={{letterSpacing: '1.3px'}} onClick={() => setOpenMobileViewError(false)}>START LEARNING BETTER!</Button>
      </DialogActions>
    </Dialog>
    </Page>
  );
}


const useStyles = makeStyles((theme) => ({
  subscribe: {
    color: '#FFFFFF',
    backgroundColor: '#00B673',
    padding: '9px 18px',
    borderRadius: '20px',
    fontWeight: '400',
    '&:hover': {
      backgroundColor: '#00B673',
    },
  },
    closeBtn: {
    '&:hover': {
      backgroundColor: 'rgb(255 255 255 / 67%)',
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    minHeight: {
      xs: '300px',  // Mobile
      sm: '380px',  // Tablet
      md: '455px'   // Desktop
    },
    maxHeight: {
      xs: '300px',
      sm: '380px',
      md: '455px'
    },
    border: '2px solid #ccc',
    borderRadius: '8px',
    backgroundImage: `url(${require('../../../assets/Images/shapeachivement.png')})`, 
    backgroundPositionY: '-19px',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    width: '100%',

  },
  banner: {
    dispaly: 'flex',
    justifyContent: 'space-between',
  },
  renewContent: {
    flexDirection: 'column',
    justifyContent: 'center',
    display: 'flex',
    height: '103px',
  },
  paper1: {
    padding: '16px',
    marginTop: -18,
    textAlign: 'center',
    marginBottom: '1rem',
    [theme.breakpoints.down('md')]: {
      marginBottom: '1rem',
    },
  },
  cardItem: {
    marginBottom: '24px',
    border: '1px solid #e1e1e1ab',
  },
  resumeBox: {
    width: '140px',
    padding: '8px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  courserContent: {
    margin: '4px 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  infoIcon: {
    color: 'gray',
    marginLeft: '8px',
  },
  progress: {
    width: '42%',
    marginBottom: '0.9rem',
  },
  courseBtn: {
    width: 'max-content',
    background: ' #fff',
    padding: '4px 8px',
    borderRadius: '6px',
  },
  logo: {
    boxShadow: '0px 3px 22px #00000029',
    padding: '1px',
    width: '35px',
    borderRadius: '6px',
    '@media (min-width: 1200px)': {
      marginRight: '-90px!important',
    },
  },


  category: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  categoryPosition: {
    position: 'relative',
    top: '13rem',
  },
  coursecard: {
    position: 'relative',
    minHeight: '20rem',
    marginLeft: 30,
    marginRight: 30,
    marginTop: 20,
    // backgroundColor: '#102D73',
    backgroundColor: '#fff !important',

    padding: '2rem',
    borderRadius: '6px',
    [theme.breakpoints.down('md')]: {
      minHeight: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '1rem',
    },
    '@media screen (max-width: 500px)':{
      marginRight: '15px',
      marginLeft: '15px'
    }
  },
  videoContainer: {
    width: '100%',
  },
  coursetitle: {
    overflow: 'hidden',
    color: 'black',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: {
      xs: 3,          // Mobile shows 3 lines
      sm: 2,          // Tablet shows 2 lines
      md: 2           // Desktop shows 2 lines
    },
    WebkitBoxOrient: 'vertical',
    fontSize: {
      xs: '1rem',     // Mobile
      sm: '1.1rem',   // Tablet
      md: '1.2rem'    // Desktop
    },
  },
  efficiencyScore: {
    textAlign: 'center',
    marginTop: '-26px',
    '@media (max-width: 768px)': {
      marginTop: '-20px',
    },
    '@media (max-width: 576px)': {
      marginTop: '-15px',
    },
  },
  efficiencyText: {
    fontSize: {
      xs: '0.9rem',
      sm: '1rem',
      md: '1.1rem'
    },
    fontWeight: '600',
    lineHeight: 1.5,
  },
  efficiencyLabel: {
    fontSize: '0.9rem',
    '@media (max-width: 768px)': {
      fontSize: '0.8rem',
    },
    '@media (max-width: 576px)': {
      fontSize: '0.7rem',
    },
  },

  // coursetitle: {
  //   overflow: 'hidden',
  //   textOverflow: 'ellipsis',
  //   display: '-webkit-box',
  //   WebkitLineClamp: '1',
  //   WebkitBoxOrient: 'vertical',
  //   [theme.breakpoints.down('md')]: {
  //     overflow: 'auto',
  //     WebkitBoxOrient: 'initial',
  //   },
  // },
  content: {
    minHeight: '12rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '10',
    WebkitBoxOrient: 'vertical',
    color: '#fff',
    fontWeight: '200',
    margin: '1rem 0rem 1.5rem 0rem',
    [theme.breakpoints.down('lg')]: {
      minHeight: '13rem',
    },
    '@media (max-width: 1040px)': {
      minHeight: '10rem',
    },
  },
  resume: {
    color: '#000000',
    fontWeight: '450',
    backgroundColor: '#fff',
    fontSize: '14px',
    borderRadius: '6px',
    padding: '2.5px 8px',
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  powerpoint: {
    backgroundColor: '#fff',
    fontSize: '14px',
    color: '#02723B',
    borderRadius: '6px',
    padding: '7px 8px',
    width: 'fit-content',
    margin: '0px 12px',
    marginTop: '10px',
  },
  // image: {
  //   borderRadius: '6px',
  //   [theme.breakpoints.up('lg')]: {
  //     width: '75%',
  //     maxHeight: '400px',
  //     marginLeft: 'auto',
  //   },
  //   [theme.breakpoints.down('md')]: {
  //     display: 'none',
  //   },
  // },
  // image: {
  //   width: '100%',
  //   minHeight: '270px',
  //   maxHeight: '270px',
  //   objectFit: 'contain',
  //   margin: '0',
  //   padding: '0',
  //   border: '2px solid #ccc',
  //   borderRadius: '8px',
  // },

  image: {
    width: '100%',
    height: {
      xs: '200px',    // Mobile
      sm: '230px',    // Tablet
      md: '270px'     // Desktop
    },
    // objectFit: 'cover',
    border: '2px solid #ccc',
    borderRadius: '8px',
  },

  courseImg: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%', // Ensure the container takes the full width
    hight:'100%',
    position:'relative',
    margin: '0',
    padding: '0',
  },

  imagebox: {
    padding: '6px',
    marginTop: '1rem',
    backgroundImage: `url(${background})`,
    borderRadius: '8px',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  halfRow: {
    width: '50%',
  },
  center: {
    alignItems: 'center',
    width: '100%',
  },
  // courseImg: {
  //   display: 'flex',
  //   flexDirection: 'column',
  //   // alignItems: 'center',
  //   // justifyContent: 'center',
  //   alignItems: 'flex-start',
  //   justifyContent: 'flex-start',
  //   width: '100%', // Ensure the container takes the full width
  //   margin: '0', // Reset any margins that may cause centering
  //   padding: '0', // Reset any padding 
  // },
  play: {
    padding: '0px ! important',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  vedio: {
    height: '100%',
    width: '100%',
  },
  vediobox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    height: 'auto',
    bgcolor: 'background.paper',
    padding: '20px',
    boxShadow: 24,
    p: 4,
  },
  iqbox: {
    display: 'flex',
    padding: {
      xs: '10px',     
      sm: '10px 20px',
      md: '10px 50px 10px 10px'
    },
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: {
      xs: '10px',
      sm: '15px',
      md: '20px'
    }
  },
  assesment: {
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      margin: '12px 0px',
    },
  },
  iqbutton: {
    padding: '8px',
    fontSize: '0.95rem',
    fontWeight: ' bold',
    [theme.breakpoints.down('sm')]: {
      margin: 'auto',
    },
  },
  videoIcon: {
    padding: '0px',
  },
  fileList: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0.5rem 1rem',
    background: '#F5F5F5',
    borderRadius: '10px',
    marginBottom: '0.8rem',
  },
  showMoreText: {
    minHeight: '14rem',
    display: '-webkit-box',
    WebkitLineClamp: 11,
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    color: '#fff ! important',
  },
  textcolor: {
    minHeight: '14rem',
    maxHeight: '14rem',
    color: '#fff ! important',
    overflow: 'scroll',
    WebkitLineClamp: 11,
    WebkitBoxOrient: 'vertical',
    // display: '-webkit-box',
  },
  readMore: {
    width: 'max-content',
    cursor: 'pointer',
  },
  CourseButton: {
    width: 70,
    color: '#6a6e6b', // initial text color
    border: '1px solid #6a6e6b', // initial border color
    borderRadius: '6px',
    fontSize: '12px',
    backgroundColor: 'white',
    '&:hover': {
      color: '#00B673', // text color on hover
      border: '1px solid #00B673', // border color on hover
      backgroundColor: 'white',
    },
  },
  searchtabsgrid: {
    display: 'flex',
    justifyContent: 'center',
  },
  playbtn: {
    width: '60px',
    height: '60px',
    background: 'radial-gradient( gree 60%, rgba(255, 255, 255, 1) 62%)',
    borderRadius: '50%',
    position: 'relative',
    display: 'block',
    // margin: "100px auto",
    boxShadow: '0px 0px 25px 3px rgba(255, 0, 128, 0.8)',
    ':after': {
      content: '',
      position: 'absolute',
      left: '50%',
      top: '50%',
      webkitTransform: 'translateX(-40%) translateY(-50%)',
      transform: 'translateX(-40%) translateY(-50%)',
      transformOrigin: 'center center',
      width: '0',
      height: '0',
      borderTop: '10px solid transparent',
      borderBottom: '10px solid transparent',
      borderLeft: '15px solid #fff',
      zIndex: '100',
      webkitTransition: 'all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19)',
      transition: 'all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19)',
    },
    ':before': {
      content: '',
      position: 'absolute',
      width: '140%',
      height: '140%',
      webkitAnimationDelay: '0s',
      animationDelay: '0s',
      webkitAnimation: 'pulsate1 2s',
      animation: 'pulsate1 2s',
      webkitAnimationDirection: 'forwards',
      animationDirection: 'forwards',
      webkitAnimationIterationCount: 'infinite',
      animationIterationCount: 'infinite',
      webkitAnimationTimingFunction: 'steps',
      animationTimingFunction: 'steps',
      opacity: '1',
      borderRadius: '50%',
      border: '5px solid rgba(255, 255, 255, .75)',
      top: '-30%',
      left: '-30%',
      background: 'rgba(198, 16, 0, 0)',
    },
  },

  renewContent: {
    flexDirection: 'column',
    justifyContent: 'center',
    display: 'flex',
    height: '90px',
  },
  iconifystyle: {
    // color: '#63B182',
    minWidth: '51px',
    height: '65px',
  },
  playbutton: {
    backgroundColor: 'unset !important',
    position: 'absolute',
    top:'40%',
    left:'42%',
    display: {
      xs: 'none',     // Hidden on mobile
      sm: 'block',    // Show on tablet and up
    },
    top: {
      sm: '44%',      // Tablet
      md: '50%'       // Desktop
    },
    left: {
      sm: '10%',      // Tablet
      md: '15%'       // Desktop
    },
    '&:hover': {
      backgroundColor: 'unset !important',
    },
  },
  progressbarcontainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },

  stepcontainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '10px',
  },

  step: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: '1',
    position: 'relative',
  },

  steplabel: {
    marginTop: '5px',
    fontSize: '12px',
  },

  completed: {
    color: 'green',
  },

  progressline: {
    position: 'relative',
    width: '100%',
    height: '5px',
    backgroundColor: '#009A31',
    borderRadius: '5px',
  },

  progressfill: {
    height: '100%',
    borderRadius: '5px',
  },


  glowing: {
    padding: '0px',
    borderRadius: '50%',
    animation: '$pulse 1.5s infinite',
  },

  progressSection:{
'@media screen (max-width: 500px)':{
  marginBottom: '55px'
}
  },


  subModuleSec:{
    '@media (max-width: 500px)':{
      margin: '5px',
    },
 
  },
  paddingZero:{
    '@media (max-width: 500px)':{
      padding: '5px',
      paddingBottom: '8px'
    },
 
  },
  fontdetailstxt:{
    '@media (max-width: 500px)':{
      display: 'flex',
      flexDirection: 'column'
    },
  },

  gridA:{
    width: 'calc(100% - 360px)',
  } ,
  CourseContentSec: {
    marginLeft: '30px',
    marginRight: '30px',
    width: 'initial',
    '@media (max-width: 500px)':{
      marginLeft: '15px'
    }
  },

  '@media (max-width: 992px)': {
    gridA:{
      width: '100% !important',
      overflowY: 'auto'
    },
    gridB: {
      width: '350px',
      marginTop: '30px'
    },
    gridBsub: {
      paddingLeft: '0px !important'
    }

  },




  '@global': {
    '@keyframes pulse': {
      '0%': {
        transform: 'scale(0.95)',
        boxShadow: '0 0 0 0 rgba(0, 0, 0, 0.7)',
      },
      '70%': {
        transform: 'scale(1)',
        boxShadow: '0 0 0 10px rgba(0, 0, 0, 0)',
      },
      '100%': {
        transform: 'scale(0.95)',
        boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)',
      },
    },
    '@keyframes slide': {
      '0%': {
        transform: 'translateX(100%)'
      },
      '100%': {
        transform: 'translateX(-100%)'
      }

    },
  },
}));