import React, { useState, useEffect,useLayoutEffect } from 'react';
import { Container, Grid, Typography, Box, TextField, Button, CircularProgress,Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom'; // Import hooks from react-router-dom
import { LoadingButton } from '@mui/lab';
// import UserLayout from 'src/layouts/userLayout';
import Page from '../../components/Page';
import SnackBar from '../../components/snackbar/snackbar';
import loginServices from '../../services/loginServices';
import LottieLoading from '../../components/LottieLoading';
import SuccessPage from '../../assets/Images/successPage.png';
import failPage from '../../assets/Images/fail.png';

const EmailVerification = () => {
    const location = useLocation();  
    const navigate = useNavigate();  

    const [emailID, setEmail] = useState('');
    const [token, setToken] = useState('');
    const [verificationStatus, setVerificationStatus] = useState(null);
    const [loading, setLoading] = useState(true);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarTitle, setSnackbarTitle] = useState('');
    const [statues, setStatus] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [response, setResponse] = useState('');


     useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const emailFromParams = queryParams.get('email');
        const tokenFromParams = queryParams.get('token');

        if (emailFromParams) setEmail(emailFromParams);
        if (tokenFromParams) setToken(tokenFromParams);
    }, [location.search]);

     const handleVerifyClick = async () => {
        setLoading(true);
        try {
             const res = await loginServices.verifyEmail(emailID, token);
             setResponse(res.data==='This mail is alredy verified'?res.data:'')
            if (res.status === 200) {
                
                // setOpenSnackbar(true);
                // setSnackbarTitle(res.data);                
                setStatus(true)
            } else {
                setStatus(false)
            }
        } catch (error) {
            setVerificationStatus('Error: Something went wrong.');
            setSnackbarTitle('Error: Something went wrong.');
        }
        setLoading(false);
       

    };
    useLayoutEffect(()=>{
        if(emailID){
            handleVerifyClick();
        }
    },[emailID])

    const SendEmailVerification = async () =>{
        const data = {
            email:emailID
        }
        try {
          const res = await loginServices.sendEmail(data);
          if (res.ok) {
        //  setOpenSnackbar(true)
        //  setSnackbarTitle(res.data.message)
          } 
        } catch (error) {
          console.log(error);
        }
      }
    const handleGoHome = (() => {
        setIsModalOpen(true)
        SendEmailVerification()
    })
    const BackToLogIn = (() => {
        navigate('/login')
    })

    const closeModal = () => {
        setIsModalOpen(false);
        navigate('/login')
      };
    return (
        <div>
            <Page title={'Email Verification'} style={{ padding: '0px !important' }}>
                <Box sx={{ padding: 3 , marginTop:'40px'}}>
                    <Container>
                        {!loading ? <Box sx={{ backgroundColor: '#f7f7f7', paddingTop: '50px', paddingBottom: '50px' }}>
                            {statues&&!response ?
                                <Grid container spacing={3} justifyContent="center">
                                     <Grid item xs={12} sm={6} md={4} sx={{
                                        height:'80vh',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        marginTop:'80px',
                                        backgroundColor: 'white',
                                        padding: '20px',
                                        borderRadius: '10px',
                                        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                                        transition: 'box-shadow 0.3s ease',
                                        ':hover': {
                                            boxShadow: '0 6px 20px rgba(0, 0, 0, 0.15)',
                                        },
                                        position: 'relative'
                                    }}>
                                        <Box sx={{ position: 'relative', width: '100%', height: 'auto' }}>
                                            <img
                                                src={SuccessPage}
                                                alt="logo"
                                                style={{
                                                    maxWidth: '268px',
                                                    margin: 'auto',
                                                    scale: 1.2
                                                }}
                                            />

                                            <Typography
                                                variant="h6"
                                                component="p"
                                                sx={{
                                                    textAlign: 'center',
                                                    fontWeight: 'bold',
                                                    fontSize: { xs: '18px', sm: '20px', md: '24px' },
                                                    marginTop: '20px'
                                                }}
                                            >
                                                Email Verified
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                component="p"
                                                sx={{
                                                    textAlign: 'center',
                                                    fontSize: { xs: '16px', sm: '18px', md: '20px' },
                                                    marginTop: '8px'
                                                }}
                                            >
                                              Your email address was successfully verified.
                                            </Typography>
                                            <LoadingButton
                                                size="medium"
                                                type="submit"
                                                variant="contained"
                                                loading={loading}
                                                sx={{
                                                    backgroundColor: '#D52F37',
                                                    borderRadius: '5px',
                                                    position: 'absolute',
                                                    left:"170px",
                                                    transform: 'translateX(-50%)',
                                                }}
                                                onClick={BackToLogIn}
                                            >
                                                Login to KeySkillset
                                            </LoadingButton>
                                        </Box>

                                    </Grid>
                                </Grid>
                                :
                                <Grid container spacing={3} justifyContent="center">
                                    <Grid item xs={12} sm={6} md={4} sx={{
                                        height:'75vh',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        marginTop:'20px',
                                        backgroundColor: 'white',
                                        padding: '20px',
                                        borderRadius: '10px',
                                        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                                        transition: 'box-shadow 0.3s ease',
                                        ':hover': {
                                            boxShadow: '0 6px 20px rgba(0, 0, 0, 0.15)',
                                        },
                                        position: 'relative'
                                    }}>
                                        <Box sx={{ position: 'relative', width: '100%', height: 'auto' }}>
                                            <img
                                                src={failPage}
                                                alt="logo"
                                                style={{
                                                    maxWidth: '100',
                                                    margin: 'auto',
                                                    height:'200px',
                                                    scale: 1.2
                                                }}
                                            />
                                            <Typography
                                                variant="h6"
                                                component="p"
                                                sx={{
                                                    textAlign: 'center',
                                                    fontWeight: 'bold',
                                                    fontSize: { xs: '18px', sm: '20px', md: '24px' },
                                                    color: '#D52F37', 
                                                }}
                                            >
                                                Email Verification Failed
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                component="p"
                                                sx={{
                                                    textAlign: 'center',
                                                    fontSize: { xs: '16px', sm: '18px', md: '20px' },
                                                    marginTop: '5px',
                                                }}
                                            >
                                              {response ||'It looks like you have verified your email already or the verification link has expired!'} 
                                            </Typography>
                                            <LoadingButton
                                                size="medium"
                                                type="submit"
                                                variant="contained"
                                                loading={loading}
                                                sx={{
                                                    marginTop:'25px',
                                                    backgroundColor: '#D52F37', 
                                                    borderRadius: '5px',
                                                    position: 'absolute',
                                                    left: '50%',
                                                    transform: 'translateX(-50%)',
                                                }}
                                                onClick={handleGoHome}
                                            >
                                                Try Again
                                            </LoadingButton>
                                        </Box>
                                    </Grid>
                                </Grid>}
                        </Box> :
                            <div
                                style={{
                                    position: 'absolute',
                                    width: '80%',
                                    height: `calc(100vh - 70px)`,
                                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                    display: 'flex',
                                    flexFlow: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',

                                }}
                            >

                                <LottieLoading loading={loading} />
                            </div>}


                    </Container>
                </Box>
                <Dialog open={isModalOpen} onClose={closeModal}>
          <DialogTitle>Verify your email.</DialogTitle>
          <DialogContent>
          <p>We have sent an email to {''}<strong>{emailID}</strong>{''}.</p>
          <p>Please verify your email and login!</p>         
           </DialogContent>
          <DialogActions>
            <LoadingButton
              size="medium"
              type="submit"
              variant="contained"
              sx={{ backgroundColor: '#D52F37', borderRadius: '5px' }}
              onClick={closeModal}
            >
              Go back To Login
            </LoadingButton>

          </DialogActions>
        </Dialog>
            </Page>

            <SnackBar
                open={openSnackbar}
                snackbarTitle={snackbarTitle}
                close={() => setOpenSnackbar(false)}
            />
        </div>
    );
};

export default EmailVerification;
