import React, { createContext, useContext, useState } from 'react';

const SearchContext = createContext();

export const useSearch = () => {
    return useContext(SearchContext);
};

export const SearchProvider = ({ children }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [status, setStatus] = useState(true);


    const clearSearch = () => {
        setSearchQuery('');
    };

    const  handleSearchStatus = (data) =>{        
        setStatus(data)
    }
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            console.log("Searching for:", searchQuery);
        }
    };

    return (
        <SearchContext.Provider value={{ searchQuery,status, setSearchQuery, clearSearch, handleKeyPress,handleSearchStatus }}>
            {children}
        </SearchContext.Provider>
    );
};
