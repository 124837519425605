/* eslint-disable */
import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
// import User from './pages/User';
import Login from './container/login/Index';
import NotFound from './pages/Page404';
import DashboardApp from './pages/DashboardApp';
import UserLayout from './layouts/userLayout/UserLayout';
import Dashboard from './container/dashboard/Index';
import BeginCourse from './container/course/Index';

import CreateCourseTypes from './container/admin/courseDetails/CreateCourseTypes';
import CourseContent from './container/admin/courseDetails/CourseContent';
import { GetStarted } from './website/pages/GetStarted';

import CourseList from './container/admin/courseDetails/CourseList';
import ClientDetalis from './container/admin/clientDetails/Index';
import SubModuleDetails from './container/admin/courseDetails/SubModuleDetails';
import Account from './container/profile/Index';

import ClientUsers from './container/admin/users/ClientList';
import IndividualUsers from './container/admin/users/IndividualList';
import UsersTrialTables from './container/admin/reports/userTrialTables/Index';

import UsersSubscribed from './container/admin/reports/userTrialTables/userSubscribedTables/index';
import SubscribedUsersNew from './container/admin/reports/subscribedUsersTable/Index';
import UserDetails from './container/admin/reports/subscribedUsersTable/userDetails/Index';
import ContentWriter from './container/admin/users/ContentCreaterList'
import Feedback from './container/admin/feedback/Index';
import ResetPassword from './container/login/ResetPassword';
import ClientCourse from './container/clientAdmin/courses/Index';
import UserTable from './container/clientAdmin/users/UserTable';
import ClientAccount from './container/clientAdmin/AccountProfile/Index';
import ClientAdminCourse from './container/clientAdmin/courseDetails/Index';
import UserCourseProgress from './container/admin/courseProgress/Index';
import McqQuestion from './container/admin/courseDetails/McqQuestion';
import QuestionBank from './container/admin/courseDetails/QuestionBank'
import GenericAssessment from './container/admin/courseDetails/GenericAssessment';
import Settings from './container/admin/courseDetails/Settings';
import SettingViewPage from './container/admin/courseDetails/ConfigView'
import AddSetting from './container/admin/courseDetails/SettingCreatePage'
// users components
  
import UserCourseDetails from './container/users/course/UserCourseDetails';
import UserCourseList from './container/users/course/CourseList';
import SkillIQ from './container/course/SkillIQ';
import GeneralAssessment  from './container/course/generalAssessmentView';
import GeneralAssessmentMain  from './container/generalAssessment/GeneralAssessmentMain';


import { CourseAnalyticDetails } from './container/clientAdmin/Analytics/CourseAnalytics';
import CourseAnalytics from './container/clientAdmin/Analytics/Index';
import { CoursesAnalyticsTab } from './container/clientAdmin/Analytics/CoursesAnalyticsTab';
import { CaseStudiesTab } from './container/clientAdmin/Analytics/CaseStudiesTab';
import Subscriptions from './container/admin/subscriptions/Index';
import WebsiteLayout from './layouts/website/websiteLayout';
import WebsiteLayoutUser from "./layouts/website/UserLayout";
import EmailVerification from "./website/pages/EmailVerification"
import BundleDetails from './container/users/course/BundleDetails';

// import Register from './website/pages/Signup'
// import { SelfStudy } from './website/pages/SelfStudy';
// import { Business } from './website/pages/Business';
// import { HigherEducation } from './website/pages/HigherEducation';

// import { Category } from './website/pages/Category';
// import { BlogPost } from './website/pages/BlogPost';
// import { GetStarted } from './website/pages/GetStarted';
// import { PasswordProtected } from './website/pages/PasswordProtected';
import CaseStudyIQTest from './container/users/CaseStudyIQTest';
// import { Course } from './website/components/Course';
import { SuccessPage } from './website/pages/SuccessPage';

import ProtectedRoute from './components/ProtectedRoutes';
import KeySkillsetCourses from './container/users/course/KeySkillsetCourses';

// d2c user

import Index from './container/authUser/Index';
import MyCourseDetails from './container/authUser/myCourseDetails';
import UserSubscriptions from './container/authUser/UserSubscriptions';
import SimulationPreview from './container/course/SimulationPreview';
import { element } from 'prop-types';

// ----------------------------------------------------------------------

export default function Router() {
  const userRole = useSelector((state) => state.userInfo && state.userInfo.role);
  let routingDetails = null;

  const CheckRoute = () => {

    const validRoutes = [...directUser,...admin,...user,...client]; 
    const currentPath = window.location.pathname;

    if(currentPath.includes("/auth/")){
      const navissue = validRoutes.filter((data) => data.path == currentPath.replace("/auth/", ""))
      if (navissue && navissue?.length > 0) {
        return <Navigate to="/login" />;
      } else {
        return <Navigate to="/404" />;
      }
    }
    else{
      const navissue = validRoutes.filter((data) => data.path == currentPath.replace("/app/", ""))
      if (navissue && navissue?.length > 0) {
        return <Navigate to="/login" />;
      } else {
        return <Navigate to="/404" />;
      } 
    }
 
  };



  const CheckRouteContentCreater = () => {
    const validRoutes = contentWriter; 
    const currentPath = window.location.pathname;
    const navissuecontent = validRoutes.filter((data) => data.path == currentPath.replace("/app/", ""))
  
    if (navissuecontent && navissuecontent?.length > 0) {
      return <Navigate to="/login" />;
    } else {
      return <Navigate to="/404" />;
    }
  };


  



  const admin = [
    { path: 'dashboard', element: <DashboardApp /> },
    { path: 'course', element: <CourseList /> },
    { path: 'course-type', element: <CreateCourseTypes /> },
    { path: 'course-content', element: <CourseContent /> },
    { path: 'submodules', element: <SubModuleDetails /> },
    // { path: 'user', element: <User /> },

    // { path: 'course', element: <CourseList /> },
    { path: 'client-users', element: <ClientUsers /> },
    { path: 'individual-users', element: <IndividualUsers /> },
    { path: 'trial-users', element: <UsersTrialTables /> },
    // { path: 'subscribed-users', element: <UsersSubscribed /> },
    { path: 'subscribed-users-new', element: <SubscribedUsersNew /> },
    { path: 'user-details', element: <UserDetails /> },
    { path: 'simulation-preview', element: <SimulationPreview /> },
    { path: 'skilliq-test', element: <SkillIQ /> },
    { path: 'content-writer-details', element:<ContentWriter/>},
    { path: 'client-details', element: <ClientDetalis /> },
    { path: 'subscription', element: <Subscriptions /> },

    { path: 'account', element: <Account /> },

    { path: 'feedback', element: <Feedback /> },
    { path: 'client-course', element: <ClientCourse /> },
    { path: 'client-Admin-course', element: <ClientAdminCourse /> },
    { path: 'course-progress', element: <UserCourseProgress /> },
    { path: 'question-details', element: <McqQuestion/> },
    { path: 'question-book', element: <QuestionBank/> },
    { path: 'genericassessment', element: <GenericAssessment/> },
    { path: 'settings', element: <Settings/> },
    { path: 'view-Settings', element: <SettingViewPage/> },
    { path: 'add-Settings', element: <AddSetting/> },

  ];

  const client = [
    { path: 'dashboard', element: <DashboardApp /> },
    { path: 'users', element: <UserTable /> },
    { path: 'user-table', element: <UserTable /> },
    { path: 'course', element: <ClientCourse /> },
    { path: 'client-course-content', element: <ClientAdminCourse /> },
    { path: 'account', element: <ClientAccount /> },
    { path: 'analytics', element: <CourseAnalytics /> },
    { path: 'analytics-details', element: <CourseAnalyticDetails /> },
    { path: 'courses-analyticstab', element: <CoursesAnalyticsTab /> },
    { path: 'case-studiestab', element: <CaseStudiesTab /> },

    // { path: 'analyticscard', element: <AnalyticsTable /> },
  ];

  const user = [
    { path: 'dashboard', element: <DashboardApp /> },
    { path: 'course', element: <UserCourseList /> },
    { path: 'course-details', element: <UserCourseDetails /> },
    { path: 'course-simulation', element: <BeginCourse /> },
    { path: 'skilliq-test', element: <SkillIQ /> },
    { path: 'case-studyiq-test', element: <CaseStudyIQTest /> },
    
    { path: 'generalassessment', element: <GeneralAssessmentMain/> },

    
    { path: 'account', element: <Account /> },
  ];

  const directUser = [
    { path: 'my-courses', element: <UserCourseList /> },
    // { path: 'subscribe', element: <UserCourseList /> },
    { path: 'course-details', element: <UserCourseDetails /> },
    { path: 'skilliq-test', element: <SkillIQ /> },
    { path: 'course-simulation', element: <BeginCourse /> },
    // { path: 'general-test', element: <GeneralAssessment/> },
    { path: 'generalassessment', element: <GeneralAssessmentMain/> },


    { path: 'account', element: <Account /> },
    { path: 'subscribe', element: <KeySkillsetCourses /> },
    { path: 'success-page', element: <SuccessPage /> },
    { path: 'failure-page', element: <SuccessPage /> },
    { path: 'case-studyiq-test', element: <CaseStudyIQTest /> },
    { path: 'subscriptions', element: <UserSubscriptions /> },
    { path: 'bundledetails', element: <BundleDetails /> },

  ];

  const contentWriter = [
    { path: 'dashboard', element: <DashboardApp /> },
    { path: 'general-test', element: <GeneralAssessment/> },
    { path: 'generalassessment', element: <GeneralAssessmentMain/> },

    { path: 'course', element: <CourseList /> },
    { path: 'course-type', element: <CreateCourseTypes /> },
    { path: 'course-content', element: <CourseContent /> },
    { path: 'submodules', element: <SubModuleDetails /> },
    { path: 'simulation-preview', element: <SimulationPreview /> },
    { path: 'account', element: <Account /> },
    // { path: 'self-study', element: <SelfStudy /> },
    // { path: 'business', element: <Business /> },
    // { path: 'higher-education', element: <HigherEducation /> },
    // { path: 'question-book', element: <McqQuestion/> },

    { path: 'skilliq-test', element: <SkillIQ /> },
    { path: 'question-details', element: <McqQuestion/> },
    { path: 'question-book', element: <QuestionBank/> },
    { path: 'genericassessment', element: <GenericAssessment/> },
    { path: 'settings', element: <Settings/> },
    { path: 'view-Settings', element: <SettingViewPage/> },

    // { path: 'category', element: <Category /> },
    // { path: 'blog-post', element: <BlogPost /> },
    // { path: 'getstarted', element: <GetStarted /> },
    // { path: 'password-protected', element: <PasswordProtected /> },
  ];

  // direct user

  const directUserPath = [
    {
      path: '/auth',
      element: <WebsiteLayoutUser />,
      children: userRole && userRole === 'USER_DTC' ? directUser : [{ path: '*', element: <NotFound /> }],
    },

    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/login" /> },
        { path: 'login', element: <Login /> },
        { path: 'forgot-password', element: <ResetPassword /> },
        { path: 'mailverification', element: <EmailVerification /> },
        { path: '404', element: <NotFound /> },
        { path: '*',element:<CheckRoute/>},
      ],
    },

    {
      path: '/',
      element: <WebsiteLayout />,
      children: [{ path: 'sign-up', element: <GetStarted /> }],
    },
    
   
    
    // {
    //   path: '/register',
    //   element: <Register />,
    //   children: [{ path: 'register', element: <Register /> }],
    // },
    { path: '*', element:<CheckRoute /> },
  ];

  // admin and client admin routing paths
  const adminPath = [
    {
      path: '/app',
      element: (
        <ProtectedRoute>
          <DashboardLayout />
        </ProtectedRoute>
      ),

      children: userRole && userRole === 'SUPER_ADMIN' ? admin : client,
    },

    {
      path: '/',
      element: <WebsiteLayout />,
      children: [
        { path: '/', element: <Navigate to="/login" /> },
        { path: 'login', element: <Login /> },
        { path: 'forgot-password', element: <ResetPassword /> },
        { path: 'mailverification', element: <EmailVerification /> },
        { path: '404', element: <NotFound /> },
        // { path: '*', element:<CheckRouteAdmin /> },
        { path: '*', element:<CheckRoute /> },

        
      ],
    },
    {
      path: '/',
      element: <WebsiteLayout />,
      children: [{ path: 'sign-up', element: <GetStarted /> }],
    },

    // {
    //   path: '/register',
    //   element: <Register />,
    //   children: [{ path: 'register', element: <Register /> }],
    // },
    // { path: '*', element:<CheckRouteAdmin /> },
    { path: '*', element:<CheckRoute /> },

  ];

  // content writer and user module routing paths
  const userPath = [
    {
      path: '/app',
      element: (
        <ProtectedRoute>
          {userRole === 'CONTENT_WRITER' ? <DashboardLayout/> : <UserLayout />}
          </ProtectedRoute>
      ),
      children: userRole && userRole === 'CONTENT_WRITER' ? contentWriter : user,
    },

    {
      path: '/',
      element: <WebsiteLayout />,
      children: [
        // { path: '/', element: <Navigate to="/home" /> },
        { path: 'login', element: <Login /> },
        { path: 'forgot-password', element: <ResetPassword /> },
        { path: 'mailverification', element: <EmailVerification /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element:<CheckRoute />},
      ],
    },
    // {
    //   path: '/register',
    //   element: <Register />,
    //   children: [{ path: 'register', element: <Register /> }],
    // },
    {
      path: '/',
      element: <WebsiteLayout />,
      children: [{ path: 'sign-up', element: <GetStarted /> }],
    },
    // {
    //   path: '/register',
    //   element: <Register />,
    //   children: [{ path: 'register', element: <Register /> }],
    // },


    { path: '*',element:<CheckRoute /> },
  ];

  if (userRole === 'USER_DTC') {
    routingDetails = useRoutes(directUserPath);
  } else {
    routingDetails = useRoutes(userRole === 'AUTH_USER' || userRole === 'CONTENT_WRITER' ? userPath : adminPath);
  }
  return routingDetails;
}