import client from './apiClient';

const postUserLogin = (data) => client.post('/individual/user', JSON.stringify(data));

const userLogin = (data) => client.post('/authenticate', JSON.stringify(data));

const generateOTP = (data) => client.post('/authenticate/generateOTP', JSON.stringify(data));

const createPassword = (data) => client.post('/authenticate/changePassword', JSON.stringify(data));

const getUserInfo = () => client.get('/authenticate/loginInfo');

const resetPassword = (data) => client.post('/authenticate/resetPassword', JSON.stringify(data));

const logout = () => client.put('/authenticate/logout');
const forgetPassword = (data) => client.post('/authenticate/forgotPassword',JSON.stringify(data));
const verifyEmail = (email,verificationToken) => client.get(`/individual/user/verifyEmail?token=${verificationToken}&email=${email}`);
const PasswordChange = (data) => client.put('/authenticate/passwordChange',JSON.stringify(data));
const sendEmail = (data) => client.post('/authenticate/emailVerification',JSON.stringify(data));



export default {
  logout,
  userLogin,
  generateOTP,
  createPassword,
  getUserInfo,
  resetPassword,
  postUserLogin,
  forgetPassword,
  verifyEmail,
  PasswordChange,
  sendEmail
};
