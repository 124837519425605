/* eslint-disable */

import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Typography, Button, Box, TextField, Rating, CardHeader, CardMedia, ListItem, Chip, ToggleButtonGroup, Link, ToggleButton, FormControl, FormHelperText } from '@mui/material';
import { Container } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import DOMPurify from 'dompurify';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import CastForEducationIcon from '@mui/icons-material/CastForEducation';
import WebPage from '../../../website/components/WebPage';
import courseApi from '../../../services/users/courseApi';
import CardSkeleton from '../../../components/Skeleton/cardSkeleton';
import IndividualCard from '../../../components/cards/IndividualCard';
import SearchCard from '../../../components/cards/Searchcard'
import BundleCard from '../../../components/cards/BundleCard';
import preSignedServices from '../../../services/preSignedServices';
import CssBaseline from '@mui/material/CssBaseline';

import {
  openSubscriptionModal,
  setOpenSubscriptionModalDetails,
  openSnackbar,
  setSubscribedCourses,ComingFrom
} from '../../../store/reducer';
import trialAndStripeSubscriptionService from '../../../services/trialAndStripeSubscriptionService';
import helper from 'src/utils/helper';
import Modal from 'react-modal';
import Account from '../../profile/Index'
import BasicPaper from 'src/components/cards/BasicPaper';
import PhoneNumber from 'src/components/PhoneNumber/Index'
import ResetPasswordModal from 'src/container/profile/ResetPasswordModal'
import SnackBar from 'src/components/snackbar/snackbar'
import PageHeader from 'src/components/PageHeader'
import adminServices from 'src/services/adminServices';
import { loginSuccess } from 'src/store/reducer';
import loginServices from 'src/services/loginServices'
Modal.setAppElement('#root');
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import StarIcon from '@mui/icons-material/Star';
import { LoadingButton } from '@mui/lab';
import { DropzoneArea } from 'material-ui-dropzone';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import SwiperCore, { Navigation, Pagination, Mousewheel, Keyboard, Autoplay } from 'swiper';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Mycourse from '../../users/course/Mycourse'
import NotFound from './NotFountCourses'


// import Sidebar from './Sidebar'
import FreeCourses from './Freecourses'
import RecomendedCourses from './Recomendedcourse'
import { styled } from '@mui/material/styles';
SwiperCore.use([Navigation, Pagination, Mousewheel, Keyboard]);
import WebsiteLayout from '../../../layouts/website/websiteLayout';
import UserCard from '../../../components/cards/Coursecard';
import { useSearch } from '../../../layouts/website/SearchContext';
import CategaryLogo from '../../../assets/Images/category.png'
import {
  getExcelDetails, getOfficeDetails, getAccFinDetails, getCodingDetails, getPowerPointDetails, getRecomdedCourseList, getCertificateList, getAllFreeCourses,
  getAllcourseDetails, getSearchedCourse, getCategoryDetails, getErgDetails, getWordCourse, getBundleCourses1, getOfferbaner, getLernerView, getAssessmentList
} from '../../../Redux/Action'
import Excelimage from '../../../assets/Images/Python Libraries and Data Structures.jpg'
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import './dashboard.css'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const drawerWidth = 240;
const StyledCard = styled(Card)({
  display: 'flex',
  padding: '16px',
  borderRadius: '16px',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  // maxWidth: 600,
  width: '95%',
  margin: 'auto',
});

const ImageOverlay = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  backgroundColor: 'rgba(0, 255, 0, 0.3)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  padding: '16px',
  fontWeight: 'bold',
});

const StyledPrice = styled(Typography)({
  fontSize: '24px',
  fontWeight: 'bold',
  color: '#20b2aa',
});

const OriginalPrice = styled(Typography)({
  fontSize: '18px',
  color: '#ff4d4f',
  textDecoration: 'line-through',
  marginLeft: '8px',
});

const KeySkillsetCourses = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();
  const swiperRef = useRef(null);
  const storedFrom = localStorage.getItem('from');
  const { searchQuery, setSearchQuery, clearSearch,handleSearchStatus } = useSearch();
  const queryParams = new URLSearchParams(location.search);
  const from = queryParams.get('from');
  const userRole = useSelector((state) => state.userInfo && state.userInfo.role);
  const userid = useSelector((state) => state.userInfo && state.userInfo.id);
  const allcourseDetails = useSelector((state) => state);
  const [individualCourses, setIndividualCourses] = useState([]);
  const [bundleCourses, setBundleCourses] = useState([]);
  const [individualCoursesLoading, setIndividualCoursesLoading] = useState(false);
  const [bundleCoursesLoading, setBundleCoursesLoading] = useState(false);
  const [excelCoursesLoading, setExcelCoursesLoading] = useState(false);
  const [officeCoursesLoading, setOfficeCoursesLoading] = useState(false);
  const [accCoursesLoading, setAccCoursesLoading] = useState(false);
  const [codingCoursesLoading, setCodingCoursesLoading] = useState(false);
  const [isTrialing, setIsTrialing] = useState(null);
  const [isSubscribing, setIsSubscribing] = useState(null);
  const [searchLoading, setSearchLoading] = useState(false);
  const { openSubscriptionModalDetails, userInfo } = useSelector((state) => state);
  const [assessmentDetails, setAssessmentDetails] = useState([]);



  const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];
  const [snackbarTitle, setSnackbarTitle] = useState('');
  const [urlImage, setUrlImage] = useState(null);
  const [showimageo, setShowimageo] = useState(true);
  const [editField, setEditField] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openResetModel, setOpenResetModel] = useState(false);
  const [isClickedButton, setIsClickedButton] = useState(false);
  const closeResetModel = () => setOpenResetModel(false);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [isFirstTimeLogin, setIsFirstTimeLogin] = useState(false);
  const [lastLoginDatetime, setLastLoginDatetime] = useState(null);
  const [isopenSnackbar, setOpenSnackbar] = useState(false);
  const [actualCosts, setActualCosts] = useState([]);
  const [bundleCosts, setBundleCosts] = useState([]);

  const [excelDetails, setExcelDetails] = useState([]);
  const [officeDetails, setOfficeDetails] = useState([]);
  const [wordCourse, setWordCourse] = useState([]);

  const [financeDetails, setFinanceDetails] = useState([]);
  const [codingDetails, setCodingCourse] = useState([]);
  const [powerpointDetails, setPowerPointCourse] = useState([]);
  const [openNewCourses, setOpenNewCourses] = React.useState(true);
  const [cardview, setCardView] = useState("Courses");
  const [cardViewSubMenu, setCardViewSubMenu] = useState("AllCourses");
  const [viewPrice, setViewPrice] = useState(0);
  const [currency, setCurrency] = React.useState(userInfo?.currencyType ? userInfo?.currencyType.code : 'USD');
  const [frequency, setFrequency] = React.useState('yearly');
  const [intfrequency, setintfrequency] = React.useState(1);
  const [reference, setReference] = React.useState(false);
  const [coursegroup, setCourseGroup] = useState([]);
  const [freeCourses, setFreeCourses] = useState([]);
  const [courseRecomended, setCourseRecomended] = useState([]);
  const [courseErg, setCourseErg] = useState([]);
  const [certifiedCourse, setCertifiedCourse] = useState([]);
  const [openmodel, setopenmodel] = React.useState(true);
  const [searchedCourse, setSearchdCourse] = useState(null);
  const [offerDetails, setofferDetails] = useState('');
  const [lernersViewing, setlearnerdViewing] = useState('');
  const [lernerViewingsection, setLernerViewingsection] = useState([]);
  const [timezone, setTimezone] = useState("Asia/Kolkata");
  const [day, setDay] = useState("");
  const [visibleCount, setVisibleCount] = useState(16);
  const [widthSection, setWidthSection] = useState('');
  const [subscribedAssessment, setSubscribedAssessment] = useState([]);
  const [isSmallScreen, setisSmallScreen] = useState(window.innerWidth < 600);



  useEffect(async () => {
    if (searchQuery) {
      dispatch(getSearchedCourse(searchQuery));
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
    else {
      setSearchdCourse(null)
      clearSearch();
      dispatch(getExcelDetails(cardViewSubMenu))
      dispatch(getOfficeDetails(cardViewSubMenu))
      dispatch(getAccFinDetails(cardViewSubMenu))
      dispatch(getCodingDetails(cardViewSubMenu))
      dispatch(getPowerPointDetails(cardViewSubMenu))
      dispatch(getWordCourse(cardViewSubMenu))
      dispatch(getRecomdedCourseList(userid))
      dispatch(getCertificateList())
      dispatch(getAllFreeCourses())
      dispatch(getAllcourseDetails())
      dispatch(getErgDetails())
      dispatch(getOfferbaner())
      dispatch(getLernerView())
      dispatch(getAssessmentList(userid))
      dispatch(getBundleCourses1(1))
    }

  }, [searchQuery]);



  useEffect(() => {
    const viewportWidth = window.innerWidth;
    let resultWidth
    if (viewportWidth >= 600) {
      ;
      resultWidth = viewportWidth - 305;
    } else if (viewportWidth <= 600) {
      resultWidth = '100%';
    }
    // const resultWidth = viewportWidth - 305;
    setWidthSection(resultWidth);
  }, []);


  const getDetails = async () => {
    const res = await adminServices.getAssessmentDetailsById(userid);
    if (res.ok) {
      setSubscribedAssessment(res.data)
    }
  }

  useEffect(() => {
    getDetails()
  }, []);
  
  // useEffect(() => {    
  //   // const storedFrom = localStorage.getItem('from');    
  //   // setCardView(storedFrom ? storedFrom : "Courses");
  //   // setOpenNewCourses(storedFrom === "Courses" ? true : false)
  //   setCardView(allcourseDetails?.comingfrom)
  //   setOpenNewCourses(allcourseDetails?.comingfrom === "Courses" ? true : false)
  // }, []);

 
  
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [cardview]);


  const handleCoursesClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setSearchQuery(null);
    clearSearch();
    setSearchdCourse(null);
    setCardViewSubMenu('AllCourses');
    handleSearchStatus(false);
    setOpenNewCourses(!openNewCourses);
    // setCardView('Courses');
    dispatch(ComingFrom("Courses"))

  }

  function handleResize() {
    const viewportWidth = window.innerWidth;

    let resultWidth
    if (viewportWidth >= 600) {
      ;
      resultWidth = viewportWidth - 305;
    } else if (viewportWidth <= 600) {
      resultWidth = '100%';
    }

    //  const resultWidth = viewportWidth - 305;
    setWidthSection(resultWidth);
  }

  window.addEventListener('resize', handleResize);

  const handleMyCourseClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setSearchQuery(null);
    clearSearch();
    setSearchdCourse(null);
    handleSearchStatus(false);
    // setCardView('MyLearning');
    dispatch(ComingFrom("MyLearning"))
    setOpenNewCourses(false);
  };
  const handleGenrelAssessmentClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setSearchQuery(null);
    clearSearch();
    setSearchdCourse(null);
    handleSearchStatus(true)
    // setCardView('General Assessment');
    dispatch(ComingFrom("General Assessment"))
    setOpenNewCourses(false);
  };

  const handleSubMenuClick = (subMenu) => {
    setCardViewSubMenu(subMenu);
  };

  const Sidebar = () => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const sidebarContent = (
      <div>
        <Toolbar sx={{ minHeight: isMobile ? '48px' : '64px' }} />
        <Box style={{ marginTop: isMobile ? 10 : 30 }} />
        <List sx={{ p: isMobile ? 0.5 : 2 }}>
          <ListItemButton className='sectionHeading'
            onClick={handleCoursesClick}
            selected={cardview === 'Courses'}
            sx={{
              minHeight: isMobile ? '40px' : '48px',
              px: isMobile ? 1 : 2,
              backgroundColor: cardview === 'Courses' ? '#f0f0f0' : 'inherit',
              '&:hover': { backgroundColor: '#e0e0e0' },
            }}
          >
            <ListItemIcon sx={{ minWidth: isMobile ? 36 : 26 }}>
              <ContactEmergencyIcon fontSize={isMobile ? 'small' : 'small'} />
            </ListItemIcon>
            {!isMobile && (
              <ListItemText
                primary="Courses"
                sx={{
                  color: cardview === 'Courses' ? '#e32f29' : 'inherit',
                  '& .MuiTypography-root': {
                    whiteSpace: 'nowrap'
                  }
                }}
              />
            )}
            {openNewCourses ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          {openNewCourses && (
            <List component="div" disablePadding>
              {[
                { label: 'All Courses', value: 'AllCourses', icon: <MenuBookIcon /> },
                { label: 'Free Courses', value: 'FreeCourses', icon: <LocalOfferIcon /> },
                { label: 'Certification Courses', value: 'CertificationCourses', icon: <WorkspacePremiumIcon /> }
              ].map((item) => (
                <ListItemButton
                  key={item.value}
                  onClick={() => handleSubMenuClick(item.value)}
                  selected={cardViewSubMenu === item.value}
                  sx={{
                    pl: isMobile ? 2 : 4,
                    minHeight: isMobile ? '36px' : '30px',
                    backgroundColor: cardViewSubMenu === item.value ? '#e0e0e0' : 'inherit',
                    '&:hover': { backgroundColor: '#d0d0d0' },
                  }}
                >
                  {isMobile &&
                    <ListItemIcon sx={{ minWidth: isMobile ? 36 : 56 }}>
                      {React.cloneElement(item.icon, { fontSize: isMobile ? 'small' : 'medium' })}
                    </ListItemIcon>}
                  {!isMobile && (
                    <ListItemText
                      primary={item.label}
                      sx={{
                        color: cardViewSubMenu === item.value ? '#e32f29' : 'inherit',
                        '& .MuiTypography-root': {
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }
                      }}
                    />
                  )}
                </ListItemButton>
              ))}
            </List>
          )}
          <ListItemButton className='sectionHeading'
            onClick={handleMyCourseClick}
            selected={cardview === 'MyLearning'}
            sx={{
              minHeight: isMobile ? '40px' : '30px',
              px: isMobile ? 1 : 2,
              backgroundColor: cardview === 'MyLearning' ? '#f0f0f0' : 'inherit',
              '&:hover': { backgroundColor: '#e0e0e0' },
            }}
          >
            <ListItemIcon sx={{ minWidth: isMobile ? 36 : 26 }}>
              <CastForEducationIcon fontSize={isMobile ? 'small' : 'small'} />
            </ListItemIcon>
            {!isMobile && (
              <ListItemText
                primary="My Learning"
                sx={{
                  color: cardview === 'MyLearning' ? '#e32f29' : 'inherit',
                  '& .MuiTypography-root': {
                    whiteSpace: 'nowrap'
                  }
                }}
              />
            )}
          </ListItemButton>
          {/* <ListItemButton className='sectionHeading'
            onClick={handleGenrelAssessmentClick}
            selected={cardview === 'General Assessment'}
            sx={{
              minHeight: isMobile ? '40px' : '30px',
              px: isMobile ? 1 : 2,
              backgroundColor: cardview === 'General Assessment' ? '#f0f0f0' : 'inherit',
              '&:hover': { backgroundColor: '#e0e0e0' },
            }}
          >
            <ListItemIcon sx={{ minWidth: isMobile ? 36 : 26 }}>
              <AssignmentTurnedInIcon fontSize={isMobile ? 'small' : 'small'} />
            </ListItemIcon>
            {!isMobile && (
              <ListItemText
                primary="General Assessment"
                sx={{
                  color: cardview === 'General Assessment' ? '#e32f29' : 'inherit',
                  '& .MuiTypography-root': {
                    whiteSpace: 'nowrap'
                  }
                }}
              />
            )}
          </ListItemButton> */}
        </List>
      </div>
    );
    return (
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Box
          component="aside"
          sx={{
            width: { xs: '60px', sm: drawerWidth },
            flexShrink: { sm: 0 },
            display: { xs: 'block', sm: 'block' },
            bgcolor: 'background.paper',
            padding: { xs: 0.5, sm: 2 },
            border: '1px solid #ddd',
            borderColor: 'grey.300',
            borderRadius: 2,
            borderRight: isSmallScreen ? '5px solid #f5f5f5' : '11px solid #f5f5f5',
            '& .MuiListItemText-root': {
              width: '100%'
            }
          }}
        >
          {sidebarContent}
        </Box>
      </Box>
    );
  }

  const handlePrice = (items, type) => {
    let actualCosts = []
    items && items?.length > 0 && items.map(item => {
      let element = {
        id: 0,
        USD: 0,
        INR: 0
      }

      let prices = item.prices
      if (prices.length > 0) {
        prices.forEach(p => {
          element.id = item.subscriptionPlanId
          if (p.currencyType?.code === 'USD') {
            element.USD = p.actualCost
          } else
            element.INR = p.actualCost
        })
        actualCosts.push(element)
      }
      if (type === 'individual')
        setActualCosts(actualCosts)
      else
        setBundleCosts(actualCosts)
    })
  }

  useEffect(() => {
    const fetchTimezone = async (lat, lon) => {
      try {
        const response = await fetch(`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${lat}&longitude=${lon}&localityLanguage=en`);
        const data = await response.json();
        const timezoneInfo = data.localityInfo.informative.find(info => info.name.startsWith("Asia/"));
        if (timezoneInfo) {
          setTimezone(timezoneInfo.name);
        } else {
          console.error('Timezone not found in response:', data);
        }
      } catch (error) {
        console.error("Error fetching timezone:", error);
      }
    };




    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            fetchTimezone(latitude, longitude);
          },
          (error) => {
            console.error("Error getting location:", error);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    };
    getLocation();
  }, [])


  useEffect(() => {
    const updateTimeOfDay = () => {
      const data = getTimeOfDayByTimeZone(timezone);
      setDay(data)
    };
    updateTimeOfDay();
    const intervalId = setInterval(updateTimeOfDay, 60 * 60 * 1000);
    return () => clearInterval(intervalId);
  }, [timezone]);

  function getTimeOfDayByTimeZone(timeZone) {
    const options = { hour: 'numeric', hour12: false, timeZone: timeZone };
    const hour = new Intl.DateTimeFormat('en-US', options).format(new Date());

    if (hour >= 5 && hour < 12) {
      return "Morning";
    } else if (hour >= 12 && hour < 17) {
      return "Afternoon";
    } else if (hour >= 17 && hour < 21) {
      return "Evening";
    } else {
      return "Evening";
    }
  }

  const handleCloseModel = () => {
    setopenmodel(false)
  }
  const handleOpenModel = () => {
    setopenmodel(true)
  }


  const handleNavigateAssessment = (data) => {
    navigate(`/auth/generalassessment?id=${data.id}&name=${data.title}`, { state: data })
  }

  const myFunctionChk = () => {
    setisSmallScreen(window.innerWidth < 600);
  };

  useEffect(()=>{
    window.addEventListener('resize', myFunctionChk);
    return () => {
      window.removeEventListener('resize', myFunctionChk);
    };
  },[])


  React.useMemo(() => {
    setCardView(allcourseDetails?.comingfrom)
    setOpenNewCourses(allcourseDetails?.comingfrom === "Courses" ? true : false)
    
    if(allcourseDetails?.comingfrom==='General Assessment'){
      handleSearchStatus(true)
    }
    else{
      handleSearchStatus(false)
    }

  


    const unwantedSubscriptions = [
      "Number Ninja Yearly",
      "Workplace Essentials Yearly",
      "Quickbooks Yearly Combo"
    ];

    const filteredExcelDetail = allcourseDetails?.ExcelDetails && allcourseDetails?.ExcelDetails?.length > 0 && allcourseDetails?.ExcelDetails?.filter(item =>
      !unwantedSubscriptions.includes(item.subscription_name)
    );
    let sortedData =filteredExcelDetail&& filteredExcelDetail?.sort((a, b) => a.is_free - b.is_free);
    setExcelDetails(sortedData);

    const filteredwordDetail = allcourseDetails?.WordDetails && allcourseDetails?.WordDetails?.length > 0 && allcourseDetails?.WordDetails?.filter(item =>
      !unwantedSubscriptions.includes(item.subscription_name)
    );
    let sortedWordData =filteredwordDetail&& filteredwordDetail?.sort((a, b) => a.is_free - b.is_free);
    setOfficeDetails(sortedWordData);


    const filteredaccountDetail = allcourseDetails?.AccountDetails && allcourseDetails?.AccountDetails?.length > 0 && allcourseDetails?.AccountDetails?.filter(item =>
      !unwantedSubscriptions.includes(item.subscription_name)
    );
    let sortedAccount =filteredaccountDetail&& filteredaccountDetail?.sort((a, b) => a.is_free - b.is_free);
    setFinanceDetails(sortedAccount);

    const filteredCodeDetail = allcourseDetails?.CodeDetails && allcourseDetails?.CodeDetails?.length > 0 && allcourseDetails?.CodeDetails?.filter(item =>
      !unwantedSubscriptions.includes(item.subscription_name)
    );
    let sortedCodeData =filteredCodeDetail&& filteredCodeDetail?.sort((a, b) => a.is_free - b.is_free);
    setCodingCourse(sortedCodeData);


    const filteredPowerDetail = allcourseDetails?.PowerPointDetails && allcourseDetails?.PowerPointDetails?.length > 0 && allcourseDetails?.PowerPointDetails?.filter(item =>
      !unwantedSubscriptions.includes(item.subscription_name)
    );
    let sortedPowerDetail =filteredPowerDetail&& filteredPowerDetail?.sort((a, b) => a.is_free - b.is_free);
    setPowerPointCourse(sortedPowerDetail);



    const filteredallDetail = allcourseDetails?.AllCourseDetails && allcourseDetails?.AllCourseDetails?.length > 0 && allcourseDetails?.AllCourseDetails?.filter(item =>
      !unwantedSubscriptions.includes(item.subscription_name)
    );    
    let sorteddallDetail =filteredallDetail&& filteredallDetail?.sort((a, b) => a.isSubscribed - b.isSubscribed);
    setCourseGroup(sorteddallDetail);

    const filteredfreeDetail = allcourseDetails?.FreeCourseDetails && allcourseDetails?.FreeCourseDetails?.length > 0 && allcourseDetails?.FreeCourseDetails?.filter(item =>
      !unwantedSubscriptions.includes(item.subscription_name)
    );
    let sortedfreeDetail =filteredfreeDetail&& filteredfreeDetail?.sort((a, b) => a.is_free - b.is_free);

    setFreeCourses(sortedfreeDetail);




    const filteredrecomendedDetail = allcourseDetails?.RecommendedDetails && allcourseDetails?.RecommendedDetails?.length > 0 && allcourseDetails?.RecommendedDetails?.filter(item =>
      !unwantedSubscriptions.includes(item.subscription_name)
    );
    let sortedRecomendedDetail =filteredrecomendedDetail&& filteredrecomendedDetail?.sort((a, b) => a.is_free - b.is_free);

    setCourseRecomended(filteredrecomendedDetail);

    const filteredErgDetail = allcourseDetails?.ErgDetails && allcourseDetails?.ErgDetails?.length > 0 && allcourseDetails?.ErgDetails?.filter(item =>
      !unwantedSubscriptions.includes(item.subscription_name)
    );
    let sortedErgDetail =filteredErgDetail&& filteredErgDetail?.sort((a, b) => a.is_free - b.is_free);
    setCourseErg(sortedErgDetail);





    const filteredrewordnewDetail = allcourseDetails?.WordCourse && allcourseDetails?.WordCourse?.length > 0 && allcourseDetails?.WordCourse?.filter(item =>
      !unwantedSubscriptions.includes(item.subscription_name)
    );
    let sortedWordnewDetail =filteredrewordnewDetail&& filteredrewordnewDetail?.sort((a, b) => a.is_free - b.is_free);
    setWordCourse(sortedWordnewDetail);

    if (searchQuery) {
      // const filteredsearchDetail = allcourseDetails?.SearchCourse && allcourseDetails?.SearchCourse?.length > 0 && allcourseDetails?.SearchCourse?.filter(item =>
      //   !unwantedSubscriptions.includes(item.subscription_name)
      // );

      const filteredsearchDetail = allcourseDetails?.SearchCourse?.length > 0
        ? allcourseDetails.SearchCourse.filter(item =>
          !unwantedSubscriptions.includes(item.subscription_name)
        )
        : [];
      setSearchdCourse(filteredsearchDetail);
    }

    setBundleCourses(allcourseDetails?.BundleDetails)

    handlePrice(allcourseDetails?.BundleDetails)

    setofferDetails(allcourseDetails?.GetOfferDetails && allcourseDetails?.GetOfferDetails[0])
    setLernerViewingsection(allcourseDetails?.GetLearnerDetails?.courses)
    setAssessmentDetails(allcourseDetails?.GetAssessmentDetails)


    // setlearnerdViewing(allcourseDetails?.GetOfferDetails[0])

  }, [allcourseDetails, searchQuery])


  const getPriceValue = (value) => {
    const filteredList = value?.stripe_pricing_list?.filter(data =>
      allcourseDetails?.currency === 'USD'
        ? data?.related_currency_type_lk === 2
        : data?.related_currency_type_lk === 1
    );
    let displayPrice;
    if (filteredList && filteredList?.length > 0 && allcourseDetails?.currency === 'USD') {
      displayPrice = `$${filteredList[0].value}`;
    } else if (filteredList && filteredList?.length > 0 && allcourseDetails?.currency === 'INR') {
      displayPrice = `₹${filteredList[0].value}`;
    } else if (filteredList && filteredList?.length === 0) {
      displayPrice = 'NAN'
    }
    return displayPrice
  }


  React.useEffect(() => {
    clearSearch();
    dispatch(getExcelDetails(cardViewSubMenu))
    dispatch(getOfficeDetails(cardViewSubMenu))
    dispatch(getAccFinDetails(cardViewSubMenu))
    dispatch(getCodingDetails(cardViewSubMenu))
    dispatch(getPowerPointDetails(cardViewSubMenu))
    dispatch(getRecomdedCourseList(userid))
    dispatch(getCertificateList())
    dispatch(getAllFreeCourses())
    dispatch(getAllcourseDetails())
    dispatch(getCategoryDetails())
    dispatch(getWordCourse(cardViewSubMenu))
    dispatch(getBundleCourses1(intfrequency))
    dispatch(getOfferbaner())
    dispatch(getLernerView())
    dispatch(getAssessmentList(userid))

  }, [reference, cardViewSubMenu,intfrequency])

  const handleCardClickItem = (data) => {


    if (userRole === 'USER_DTC') {
      navigate('/auth/course-details', { state: data });
    } else {
      navigate('/app/course-details', { state: data });
    }
  };
  const handleFrequencyChange = (event, value) => {
    if (value) {
      let intfreq
      if (value === 'monthly') {
        setintfrequency(2);
        intfreq = 2
      } else if (value === 'quarterly') {
        setintfrequency(5);
        intfreq = 5
      } else if (value === 'halfyearly') {
        setintfrequency(3);
        intfreq = 3
      } else {
        setintfrequency(1);
        intfreq = 1
      }
      setFrequency(value);
      getIndividualCourses(intfreq);
      getBundleCourses(intfreq);
    }
  }

  const handleChange = (event, value) => {
    if (value) {
      setCurrency(value);
    }
  };

  const getCourserList = async () => {
    setCourseGroup(allcourseDetails?.AllCourseDetails)
    dispatch(setSubscribedCourses(allcourseDetails?.AllCourseDetails));
  }

  const getIndividualCourses = async (freq) => {
    setBundleCoursesLoading(true);
    try {
      const res = await courseApi.getWebsiteIndividualCourses(freq);
      if (res.ok) {        
        setIndividualCourses(res.data);
        // handlePrice(res.data, 'individual')
        setBundleCoursesLoading(false);
      } else {
        setBundleCoursesLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getBundleCourses = async (freq) => {
    setIndividualCoursesLoading(true);
    try {
      const res = await courseApi.getWebsiteBundleCourses(freq);
      if (res.ok) {
        setBundleCourses(res.data);
        // handlePrice(res.data, 'bundle')
        setIndividualCoursesLoading(false);
      } else {
        setIndividualCoursesLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  function stringAvatar() {
    const fullName = userInfo;
    return {
      sx: {

        fontSize: '1.4rem',
        fontWeight: '600',
      },

      children: `${fullName.firstName.charAt(0).toUpperCase()}${fullName.lastName.charAt(0).toUpperCase()}`,
    };
  }


  useEffect(() => {
    getLoginUserDetailsFirstTime();
    getIndividualCourses(intfrequency);
    // getBundleCourses(intfrequency);

    setIsModalOpen(isFirstTimeLogin);
  }, [isFirstTimeLogin]);

  const handleClickTrialButton = async (planId, trialLoadingId, enrollmentType, courseId) => {
    setIsTrialing(trialLoadingId);

    try {
      const res = await trialAndStripeSubscriptionService.postTrialCourseEnrollmentDetails(
        JSON.stringify({ planId: planId, enrollmentType: enrollmentType, courseId: courseId })
      );
      if (res.ok) {
        getCourserList();
        dispatch(openSnackbar('Trial Course Subscribed Successfully.'));
        // navigate('/auth/my-courses');
        // setCardViewSubMenu("MyLearning")
        setCardView("MyLearning")
        setOpenNewCourses(false)
        navigate('/auth/subscribe');

      } else {
        if (res?.data?.message) {
          dispatch(openSnackbar(res.data.message));
        } else {
          dispatch(openSnackbar('Failed to Subscribe Trial Course, Please try again.'));
        }
      }
    } catch (error) {
      console.log(error);
    }
    setIsTrialing(null);
  };

  function LinearProgressWithLabel({ value }) {
    return (
      <Box>
        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '16px !important' }} mb={0.5}>
          <Box sx={{ width: '100%', mr: 1 }}>
            <LinearProgress variant="determinate" value={value} />
          </Box>
          <Box sx={{ width: 'max-content' }}>
            <Typography variant="body2" color="gray" sx={{ fontSize: '0.7rem' }}>
              {value}%
            </Typography>
          </Box>
        </Box>
        <Typography variant="body2" color={'primary'} sx={{ fontSize: '0.7rem', marginTop: '-8px' }}>
          Completed
        </Typography>
      </Box>
    );
  }

  function LinearProgressWithLabelMyCourse({ value }) {
    return (
      <Box>
        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '16px !important' }} mb={0.5}>
          <Box sx={{ width: '100%', mr: 1 }}>
            <LinearProgress variant="determinate" value={value} />
          </Box>
          <Box sx={{ width: 'max-content' }}>
            <Typography variant="body2" color="gray" sx={{ fontSize: '0.7rem' }}>
              {value}%
            </Typography>
          </Box>
        </Box>
        <Typography variant="body2" color={'primary'} sx={{ fontSize: '0.7rem', marginTop: '-8px' }}>
          {value === '100' ? 'Completed' : 'In-Progress'}
        </Typography>
      </Box>
    );
  }

  // const postSubscribeDetails = async (item, planId) => {
  //   const courseId = item.id;
  //   if (item.isPaid) {
  //     // dispatch(setOpenSubscriptionModalDetails(item));
  //     // dispatch(openSubscriptionModal(planId));
  //     let impactClickId = '';
  //     try {
  //       impactClickId = await helper.getClickId();
  //     } catch (error) {
  //       console.error(error);
  //       impactClickId = '';
  //     }

  //     const payload = {
  //       courseId,
  //       planId,
  //       currencyType: currency,
  //       frequency: parseInt(intfrequency),
  //       impactClickId,
  //     };
  //     try {
  //       const res = await trialAndStripeSubscriptionService.createStripeSubscription(JSON.stringify(payload));
  //       if (res.status === 303) {
  //         // dispatch(closeSubscriptionModal());
  //         // setSubscriptionModalLoading(false);
  //         window.location = res.data.paymentUrl;
  //       } else {
  //         if (res?.data?.message) {
  //           dispatch(openSnackbar(res.data.message));
  //         } else {
  //           dispatch(openSnackbar('Failed to Subscribe, Please try again.'));
  //         }
  //         // setSubscriptionModalLoading(false);
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   } else {
  //     setIsSubscribing(planId);
  //     try {
  //       const res = await trialAndStripeSubscriptionService.createStripeSubscription(JSON.stringify({ planId }));
  //       if (res.ok) {
  //         navigate('/auth/subscribe');
  //         setCardView("MyLearning")
  //         setOpenNewCourses(false)
  //         // setCardViewSubMenu("MyLearning")
  //         dispatch(openSnackbar('Course subscribed successfully'));
  //       } else if (res.status === 400) {
  //         dispatch(openSnackbar(res.data.message));
  //       } else {
  //         dispatch(openSnackbar('Failed to subscribed pls try again'));
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //     setIsSubscribing(null);
  //   }
  // };

  const postSubscribeDetails = async (item, planId) => {
    const courseId = item.id;
    if (item.isPaid) {
      // dispatch(setOpenSubscriptionModalDetails(item));
      // dispatch(openSubscriptionModal(planId));
      let impactClickId = '';
      try {
        impactClickId = await helper.getClickId();
      } catch (error) {
        console.error(error);
        impactClickId = '';
      }

      const payload = {
        userId: userid,
        courseId,
        planId,
        currencyType: currency,
        frequency: parseInt(intfrequency),
        impactClickId,
      };
      try {
        const res = await trialAndStripeSubscriptionService.createStripeSubscription(JSON.stringify(payload));
        if (res.status === 303) {
          // dispatch(closeSubscriptionModal());
          // setSubscriptionModalLoading(false);
          window.location = res.data.paymentUrl;
        } else {
          if (res?.data?.message) {
            dispatch(openSnackbar(res.data.message));
          } else {
            dispatch(openSnackbar('Failed to Subscribe, Please try again.'));
          }
          // setSubscriptionModalLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setIsSubscribing(planId);
      try {
        const res = await trialAndStripeSubscriptionService.createStripeSubscription(JSON.stringify({ planId }));
        if (res.ok) {
          navigate('/auth/subscribe');
          setCardView("MyLearning")
          setOpenNewCourses(false)
          // setCardViewSubMenu("MyLearning")
          dispatch(openSnackbar('Course subscribed successfully'));
        } else if (res.status === 400) {
          dispatch(openSnackbar(res.data.message));
        } else {
          dispatch(openSnackbar('Failed to subscribe, please try again'));
        }
      } catch (error) {
        console.log(error);
      }
      setIsSubscribing(null);
    }
  };

  const postAssessmentSubscribtionDetails = async (item, planId) => {
    const courseId = item.id;
      let impactClickId = '';
      try {
        impactClickId = await helper.getClickId();
      } catch (error) {
        console.error(error);
        impactClickId = '';
      }

      const payload = {
        userId: userid,
        courseId,
        planId: item?.subscriptionplanid,
        currencyType: currency,
        frequency: parseInt(intfrequency),
        impactClickId,
        type: 'assessment'
      };

      try {
        const res = await trialAndStripeSubscriptionService.createAssessmentSubscription(JSON.stringify(payload));
        if (res.status === 303) {
          // dispatch(closeSubscriptionModal());
          // setSubscriptionModalLoading(false);
          window.location = res.data.paymentUrl;
        } else {
          if (res?.data?.message) {
            dispatch(openSnackbar(res.data.message));
          } else {
            dispatch(openSnackbar('Failed to Subscribe, Please try again.'));
          }
          // setSubscriptionModalLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
   
  };

  const [formvalue, setFormvalue] = React.useState({
    firstName: '',
    lastName: '',
    phone: '',
    imgUrl: null,
    profileImg: null,
    thumbPreview: null,
  });

  const getLoginUserDetails = async () => {
    const response3 = await loginServices.getUserInfo();
    if (response3.ok) {
      dispatch(loginSuccess(response3.data));
    }
  };

  const getBadgeColor = (level) => {
    switch (level.toLowerCase()) {
      case 'easy':
        return 'success';
      case 'medium':
        return 'warning';
      case 'advanced':
        return 'error';
      default:
        return 'default';
    }
  };
  useEffect(() => {
    setIsClickedButton(false);
  }, [editField]);

  const handleSubmitProfileValues = async (values) => {
    const formData = new FormData();
    formData.append('firstName', values.firstName);
    formData.append('lastName', values.lastName);
    formData.append('phone', values.phone);
    formData.append('profileImg', values.profileImg);

    try {
      const response = await adminServices.profileUpdate(formData);
      if (response.ok) {
        if (response.data && response.data.preSignedUrl) {
          await preSignedServices.preSignedUrlUpload(response.data.preSignedUrl, values.profileImg)
        }
        await getLoginUserDetails();
        setOpenSnackbar(true);
        setSnackbarTitle(response.data.message);
        setEditField(false);
        setLoading(false);
        setTimeout(() => {
          setSnackbarTitle(response.data.message);
          setIsModalOpen(false);
        }, 400);
      } else if (response.status === 400) {
        setOpenSnackbar(true);
        setSnackbarTitle(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleSubmitForm = (values) => {
    // values.profileImg = URL.createObjectURL(values.profileImg);
    handleSubmitProfileValues(values);
    setLoading(true);
  };

  const getLoginUserDetailsFirstTime = async () => {
    try {
      const hasFunctionRun = localStorage.getItem('hasFunctionRun');
      const response3 = await loginServices.getUserInfo();
      if (hasFunctionRun === null) {
        if (response3.ok) {
          dispatch(loginSuccess(response3.data));
          setLastLoginDatetime(response3.data.login_first_time);
          if (response3.data.login_first_time === true) {
            setIsModalOpen(true);
            setIsFirstTimeLogin(true);
            localStorage.setItem('hasFunctionRun', true);
          } else {
            setIsFirstTimeLogin(false);
          }
        } else {
          console.log('Response not OK');
        }
      }
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  const callbackFunction = (data) => {
    setCardView(data)
  }

  const callbackFunctionSubMenu = (data) => {
    setCardViewSubMenu(data)
  }



  const loadMoreCourses = () => {
    setVisibleCount(prevCount => prevCount + 15);
  };

  const displayedCourses = searchedCourse && searchedCourse != null && searchedCourse?.slice(0, visibleCount);

  const handleNavigateBundle = (item) => {
    navigate('/auth/bundledetails', { state: { item } })
  }




  function ValueCurrency(value) {
    const filteredList = value?.stripe_pricing_list?.filter(data =>
      allcourseDetails?.currency === 'USD'
        ? data?.related_currency_type_lk === 2
        : data?.related_currency_type_lk === 1
    );
    let displayPrice;

    if (filteredList && filteredList?.length > 0 && allcourseDetails?.currency === 'USD') {
      displayPrice = `$${filteredList[0].value}`;
    } else if (filteredList && filteredList?.length > 0 && allcourseDetails?.currency === 'INR') {
      displayPrice = `₹${filteredList[0].value}`;
    } else if (filteredList && filteredList?.length === 0) {
      displayPrice = 'NAN'
    }
    return displayPrice

  }


  return (
    <div >
      <Grid container spacing={2}>
        <Grid item xs={2} sm={3} md={2} sx={{
          display: 'flex',
          // position: 'fixed',
          position: isSmallScreen ? 'relative' : 'fixed',
          height: '100vh',
          zIndex: 1,
        }}>
          <Sidebar open={openmodel} close={handleCloseModel} onMenuChange={callbackFunction} onSubMenuChange={callbackFunctionSubMenu} />
        </Grid>
        <Grid item xs={10} sm={9} md={10} style={{ width: 'Calc(100vh)' }} sx={{
          marginLeft: { sm: '240px' },
          padding: 2,
        }}>
          <WebPage sx={{ backgroundColor: '#fff' }} title="Course Lists">
            <Box id="sectionCardCourse" style={{ paddingTop: cardview === 'MyLearning' ? '3rem' : '10rem', }} className={classes.main}>
              <Container sx={{ maxWidth: '1440px !important' }}>
                {cardview === "MyLearning" && !searchedCourse ?
                  <>
                    <Card sx={{ minWidth: 275, marginTop: 2, boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)' }} className={classes.fixedHead} style={{ width: `${widthSection}px` }}>
                      <CardContent sx={{ position: 'relative' }}>
                        <Box
                          sx={{
                            position: 'absolute',
                            width: 40,
                            height: 40,
                            borderRadius: '50%',
                            // backgroundColor: 'primary.main',
                            top: '50%',
                            left: 20,
                            transform: 'translateY(-50%)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}>
                          {/* <ContactEmergencyIcon sx={{ color: 'gray' }} /> */}
                          <Avatar {...stringAvatar()} />
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 5, width: '100%' }}>
                          <Typography className='fontdetails' variant="h5" gutterBottom sx={{ color: 'black', marginLeft: '5px', marginBottom: '-2px' }}>
                            Good {day},
                          </Typography>
                          <Typography className='fontdetails' variant="h5" gutterBottom sx={{ color: 'black', marginLeft: '5px', marginBottom: '-2px', textTransform: 'capitalize' }}>
                            {userInfo && userInfo?.name}
                          </Typography>
                          <Box sx={{ flexGrow: 1 }} />

                        </Box>
                      </CardContent>
                    </Card>
                    <Box sx={{ marginTop: 16, marginBottom: '15px', borderRadius: '12px', overflow: 'hidden' }} >
                      <Box sx={{ backgroundColor: '#F8F9FA', padding: '10px 20px', textAlign: 'center', borderBottom: '1px solid #ccc', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', }}></Box>


                      <Card sx={{ height: '300px', backgroundImage: offerDetails?.data?.[0]?.coverImageUrl ? `url(${offerDetails.data[0].coverImageUrl})` : 'none', backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', }}>
                        <Grid container spacing={2}>
                          <Grid style={{ marginTop: -4, marginBottom: -4 }} item xs={8}>
                            <CardContent>
                            </CardContent>
                          </Grid>
                        </Grid>
                      </Card>

                      <Box sx={{ backgroundColor: '#333', padding: '15px', textAlign: 'center', color: '#fff', borderTop: '1px solid #ccc', }}></Box>


                    </Box>


                    {searchedCourse ?
                      <>
                        {searchedCourse?.length > 0 && <Typography variant="h4" mt={4}>Result for "{searchQuery}"</Typography>}
                        <Grid container spacing={4} mt={1} justifyContent="center">
                          {searchLoading ? (
                            [1, 2].map((_, index) => (
                              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                                <CardSkeleton />
                              </Grid>
                            ))
                          ) : displayedCourses?.length > 0 ? (
                            displayedCourses.map((item, index) => (

                              <Grid item xs={12} sm={6} md={4} lg={3} key={item.subscriptionPlanId}>
                                <UserCard
                                  type={item.type}
                                  item={item}
                                  image={item.thumbImage || item.cover_image_url}
                                  title={item.title}
                                  trial={item.is_trial }
                                  description={item.description}
                                  category={item.category}
                                  logo={item.category?.categoryImgUrl || item.category_thumb_img_url}
                                  isTrial={item.is_trial}
                                  isSubscribed={item.isSubscribed}
                                  subscriptionPlanId={item.subscriptionPlanId}
                                  handleTrial={handleClickTrialButton}
                                  handleSubcribe={postSubscribeDetails}
                                  price={new Intl.NumberFormat('en-US', { style: 'currency', currency }).format(actualCosts[index]?.[currency])}
                                  isTrialLoading={isTrialing}
                                  isSubscribeLoading={isSubscribing}
                                  isPaid={item.isPaid}
                                  paid={item.isPaid}
                                />
                              </Grid>
                            ))
                          ) : (
                            <Grid item xs={12}>
                              <Box mt={2}>
                                <Typography variant="h6" textAlign="center">Sorry, we couldn't find any results for "{searchQuery}"</Typography>
                                {<Typography variant="h6" textAlign="start" sx={{ marginTop: '20px', marginBottom: '20px' }}>User also search for </Typography>}
                                <NotFound courseGroups={freeCourses} />
                              </Box>
                            </Grid>
                          )}

                          {displayedCourses?.length < searchedCourse?.length && (
                            <Grid container justifyContent="center" item xs={12}>
                              <Button variant="contained" onClick={loadMoreCourses}>
                                Load More...
                              </Button>
                            </Grid>

                          )}


                        </Grid>
                      </>

                      :
                      <>

                        {coursegroup && coursegroup?.length > 0 && <Grid style={{ backgroundColor: '#F8F9FA', maxHeight: '450px', minHeight: '450px', padding: '16px 0 0 0' }}>
                          <Typography
                            style={{ marginLeft: 35 }}
                            variant="h4"
                            gutterBottom
                          >
                            Start from where you left
                          </Typography>
                          <Swiper
                            spaceBetween={10}  
                            slidesPerView={4}
                            breakpoints={{
                              1000: {
                                slidesPerView: 4,
                              },
                              600: {
                                slidesPerView: 2,
                              },
                              0: {
                                slidesPerView: 1,
                              },
                            }}
                            navigation={coursegroup?.length > 4}
                            pagination={
                              coursegroup?.length > 4
                                ? {
                                  el: `.${classes.swiperPagination}`,
                                  clickable: true,
                                  bulletClass: 'custom-bullet',
                                  bulletActiveClass: 'custom-bullet-active',
                                }
                                : false
                            }
                            keyboards
                            className="mySwiper"
                          >
                            {coursegroup && coursegroup?.length > 0 && coursegroup.map((item, index) => (
                              <SwiperSlide key={index}
                              >
                                <UserCard
                                  type={item.type}
                                  handleCardClick={() => handleCardClickItem(item)}
                                  image={item.thumbImage}
                                  planStatus={item.planStatus}
                                  title={item.title}
                                  is_free={item.is_free}
                                  trial={item.is_trial ? item.is_trial : item.isTrial}
                                  subscriptionPlanId={item?.subscriptionPlanId || item?.subscriptionplanid}
                                  handleTrial={handleClickTrialButton}
                                  handleSubcribe={postSubscribeDetails}
                                  subscribed={item.isSubscribed}
                                  category={item?.category_description ? item?.category_description : item?.category?.description}
                                  logo={item?.category_image ? item?.category_image : item?.category?.categoryImgUrl}
                                  // logo={item.category.categoryImgUrl}
                                  enrollDate={item.validFrom}
                                  progress={<LinearProgressWithLabelMyCourse value={item.completed ? item.completed : 0} />}
                                  // progress={<LinearProgressWithLabel value={item.completed} />}
                                  data={item}
                                  comingfrom={'MyLearning'}
                                  paid={item?.isPaid}

                                />
                              </SwiperSlide>
                            ))}
                          </Swiper>
                        </Grid>}
                        
                        {/* General Assessment Section in My Learning */}
                        {/* {subscribedAssessment && subscribedAssessment?.length > 0 &&
                          <Grid style={{ backgroundColor: '#F8F9FA', marginTop: "20px", maxHeight: '480px', minHeight: '480px', padding: '16px 0 0 0' }}>
                            <Typography style={{ marginLeft: 35 }} variant="h4" gutterBottom>
                              General Assessment
                            </Typography>
                            <Swiper
                              spaceBetween={10}
                              slidesPerView={4}
                              breakpoints={{
                                1000: {
                                  slidesPerView: 4,
                                },
                                600: {
                                  slidesPerView: 2,
                                },
                                0: {
                                  slidesPerView: 1,
                                },
                              }}
                              navigation={subscribedAssessment?.length > 4}
                              pagination={
                                subscribedAssessment?.length > 4
                                  ? {
                                    el: `.${classes.swiperPagination}`,
                                    clickable: true,
                                    bulletClass: 'custom-bullet',
                                    bulletActiveClass: 'custom-bullet-active',
                                  }
                                  : false
                              }
                              className="mySwiper"
                            >
                              {subscribedAssessment && subscribedAssessment?.length > 0 &&
                                subscribedAssessment.map((item, index) => (
                                  <SwiperSlide key={index}>
                                    <Box
                                      sx={{
                                        margin: '10px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        background: '#fff',
                                        height: '380px',
                                        borderRadius: '10px',
                                        overflow: 'hidden',
                                        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                                      }}
                                    >
                                      <Box
                                        onClick={() => handleNavigateAssessment(item?.assessmentDetails[0])}
                                        sx={{
                                          width: '100%',
                                          height: '60%',
                                          cursor: 'pointer',
                                          borderRadius: '10px',
                                          padding: '10px',
                                        }}
                                      >
                                        <img
                                          src={item.assessmentDetails[0].image_name}
                                          alt="item"
                                          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                        />
                                      </Box>

                                      <CardContent
                                        sx={{
                                          width: '100%',
                                          height: '40%',
                                          display: 'flex',
                                          flexDirection: 'column',
                                          justifyContent: 'flex-start',
                                          gap: 2,
                                          padding: '16px',
                                        }}
                                      >
                                        <Box>
                                          <Typography style={{fontSize:'16px',lineHeight:'1.15',wordWrap:'inherit'}} variant="h6" component="div" marginBottom={1}>
                                            {item.assessmentDetails[0].title}
                                          </Typography>
                                         
                                        </Box>
                                      
                                          <Button
                                            style={{ backgroundColor: '#437BFC', color: 'white' }}
                                            onClick={() => handleNavigateAssessment(item?.assessmentDetails[0])}
                                          >
                                            Start Now
                                          </Button>
                                       
                                      </CardContent>
                                    </Box>
                                  </SwiperSlide>
                                ))
                              }
                            </Swiper>
                          </Grid>
                        } */}


                        <FreeCourses courseGroups={freeCourses} />
                        <RecomendedCourses courseGroups={courseRecomended} />
                      </>
                    }
                  </>
                  :
                  <>
                    {!searchedCourse && <Card sx={{ position: 'fixed', zIndex: '9999', top: '68px', minWidth: 275, marginTop: 2, boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)', }} className={classes.fixedHead} style={{ width: `${widthSection}px` }}>
                      <CardContent sx={{ position: 'relative' }}>
                        <Box
                          sx={{
                            position: 'absolute',
                            width: 40,
                            height: 40,
                            borderRadius: '50%',
                            // backgroundColor: 'primary.main',
                            top: '50%',
                            left: 20,
                            transform: 'translateY(-50%)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}>
                          {/* <ContactEmergencyIcon sx={{ color: 'gray' }} /> */}
                          <Avatar {...stringAvatar()} />
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 5, width: '100%' }}>
                          <Typography className='fontdetails' variant="h5" gutterBottom sx={{ color: 'black', marginLeft: '5px', marginBottom: '-2px' }}>
                            Good {day},
                          </Typography>
                          <Typography className='fontdetails' variant="h5" gutterBottom sx={{ color: 'black', marginLeft: '5px', marginBottom: '-2px', textTransform: 'capitalize' }}>
                            {userInfo && userInfo?.name}
                          </Typography>
                          <Box sx={{ flexGrow: 1 }} />

                        </Box>
                      </CardContent>
                    </Card>}

                    {cardview === 'General Assessment' &&
                      <>
                        <Grid item xs={12} sm={12} mt={2}>
                          <Box sx={{ backgroundColor: '#F8F9FA', padding: '16px' }}>
                            <Typography
                              style={{ marginLeft: 35 }}
                              variant="h4"
                              gutterBottom
                            >
                              General Assessment
                            </Typography>
                            {/* <Swiper
                         modules={[navigate,Autoplay]}
                         spaceBetween={16}
                         slidesPerView={1}
                         autoplay={{
                           delay: 3000,
                           disableOnInteraction: false, 
                         }}
                         loop={true} 
                         navigation
                       > */}
                            {assessmentDetails && assessmentDetails?.length > 0 && assessmentDetails.map((item) => (
                              //  <SwiperSlide key={index}>
                              <Box
                                sx={{
                                  margin: '10px',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  background: '#fff',
                                  height: '250px',
                                  borderRadius: '10px',
                                  overflow: 'hidden',
                                  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                                }}
                              >
                                <Box 
                                // onClick={() => handleNavigateAssessment(item)}
                                 sx={{ width: '40%', height: '100%',  borderRadius: '10px', padding: '10px' }}>
                                  <img
                                    src={item.image_name}
                                    alt="item"
                                    style={{ width: '90%', height: '90%', objectFit: 'cover' }}
                                  />
                                </Box>
                                <CardContent
                                  sx={{
                                    width: '60%',
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    gap: 2,
                                    padding: '16px',
                                  }}
                                >
                                  <Box>
                                    <Typography variant="h6" component="div" marginBottom={1}>
                                      {item.title}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                      component="p"
                                      sx={{
                                        textOverflow: 'ellipsis',
                                        display: '-webkit-box',
                                        WebkitBoxOrient: 'vertical',
                                        WebkitLineClamp: 5,
                                        height: '115px',
                                        overflow: 'hidden',
                                      }}
                                      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.short_description) }}
                                    />

                                  </Box>


                                  <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" marginBottom={'2px'}>
                                    <Typography variant="h6" color="black" width="max-content" sx={{ display: 'flex' }}>
                                      {getPriceValue(item)}
                                    </Typography>

                                    {item?.is_subscribed && item?.subscription_status!=='CANCELED'? <Button style={{ backgroundColor: '#437BFC', color: 'white' }} onClick={() => handleNavigateAssessment(item)}
                                    >
                                      Start Now
                                    </Button>
                                      :
                                      <Button style={{ backgroundColor: '#437BFC', color: 'white' }} onClick={() => { postAssessmentSubscribtionDetails(item, item?.subscriptionplanid) }}>
                                        Buy Now
                                      </Button>}
                                  </Box>
                                </CardContent>
                              </Box>
                              //  </SwiperSlide>
                            ))}
                            {/* </Swiper> */}
                          </Box>
                        </Grid>
                        <RecomendedCourses courseGroups={courseRecomended} />
                      </>
                    }
                    {searchedCourse &&
                      <>
                        {searchedCourse?.length > 0 && <Typography variant="h4" mt={4}>Result for "{searchQuery}"</Typography>}
                        <Grid container spacing={4} mt={1} justifyContent="center">
                          {searchLoading ? (
                            [1, 2].map((_, index) => (
                              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                                <CardSkeleton />
                              </Grid>
                            ))
                          ) : displayedCourses?.length > 0 ? (
                            displayedCourses.map((item, index) => (
                              <Grid item xs={12} sm={6} md={4} lg={3} key={item.subscriptionPlanId}>
                               <UserCard
                                      type={item.type}
                                      handleCardClick={() => handleCardClickItem(item)}
                                      image={item.cover_image_url}
                                      planStatus={item.planStatus}
                                      title={item.title}
                                      is_free={item.is_free}
                                      trial={item.is_trial ? item.is_trial : item.isTrial}
                                      subscriptionPlanId={item?.subscriptionPlanId || item?.subscriptionplanid}
                                      handleTrial={handleClickTrialButton}
                                      handleSubcribe={postSubscribeDetails}
                                      subscribed={item.isSubscribed}
                                      category={item?.category_description ? item?.category_description : item?.category?.description}
                                      logo={item?.category_image ? item?.category_image : item?.category?.categoryImgUrl}
                                      // logo={item.category.categoryImgUrl}
                                      enrollDate={item.validFrom}
                                      progress={<LinearProgressWithLabel value={item.completed ? item.completed : 0} />}
                                      // progress={<LinearProgressWithLabel value={item.completed} />}
                                      data={item}
                                      comingfrom={cardview}
                                      paid={item?.isPaid}

                                    />
                              </Grid>
                            ))
                          ) : (
                            <Grid item xs={12}>
                              <Box mt={2}>
                                {<Typography variant="h6" textAlign="center">Sorry, we couldn't find any results for "{searchQuery}"</Typography>}
                                {<Typography variant="h6" textAlign="start" sx={{ marginTop: '20px', marginBottom: '20px' }}>User also search for </Typography>}
                                <NotFound courseGroups={freeCourses} />
                              </Box>
                            </Grid>
                          )}

                          {displayedCourses?.length < searchedCourse?.length && (
                            <Grid container justifyContent="center" item xs={12}>
                              <Button variant="contained" onClick={loadMoreCourses}>
                                Load More...
                              </Button>
                            </Grid>

                          )}


                        </Grid>
                      </>

                    }
                    {cardViewSubMenu === 'all' &&
                      <>
                        <Typography variant="h4" mt={4}>Subscription Plans</Typography>
                        <Grid container spacing={4} mt={1} justifyContent="center">
                          {individualCoursesLoading ? (

                            [1, 2].map((_, index) => (

                              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>

                                <CardSkeleton />

                              </Grid>

                            ))

                          ) : (

                            individualCourses?.length > 0 ? (
                              individualCourses.map((item, index) => (
                                <Grid item xs={12} sm={6} md={4} lg={3} key={item.subscriptionPlanId}>
                                  <IndividualCard
                                    item={item}
                                    image={item.thumbImage}
                                    title={item.title}
                                    description={item.description}
                                    category={item.category}
                                    logo={item.category.categoryImgUrl}
                                    isTrial={item.isTrial}
                                    isSubscribed={item.isSubscribed}
                                    subscriptionPlanId={item.subscriptionPlanId}
                                    handleTrial={handleClickTrialButton}
                                    handleSubcribe={postSubscribeDetails}
                                    price={new Intl.NumberFormat('en-US', { style: 'currency', currency }).format(actualCosts[index]?.[currency])}
                                    isTrialLoading={isTrialing}
                                    isSubscribeLoading={isSubscribing}
                                    isPaid={item.isPaid}
                                    paid={item.isPaid}
                                  />
                                </Grid>
                              ))
                            ) : (
                              <Grid item xs={12}>
                                <Box mt={4}>
                                  <Typography variant="h6" textAlign="center">No Data</Typography>
                                </Box>
                              </Grid>
                            )
                          )}
                          {bundleCoursesLoading ? (
                            <Grid item xs={12} sm={4} md={4} lg={3}>
                              <CardSkeleton />
                            </Grid>
                          ) : (
                            bundleCourses?.map((item, index) => (
                              <Grid item xs={12} sm={6} md={4} lg={3} key={item.subscriptionPlanId}>
                                <BundleCard
                                  handleCardClick={() => console.log('clk')}
                                  item={item}
                                  image={item.courseobj[0].thumbImage}
                                  subscriptionPlanId={item.subscriptionPlanId}
                                  courseobj={item.courseobj}
                                  handleTrial={handleClickTrialButton}
                                  handleSubcribe={postSubscribeDetails}
                                  price={new Intl.NumberFormat('en-US', { style: 'currency', currency }).format(bundleCosts[index]?.[currency])}
                                  isTrialLoading={isTrialing}
                                  isSubscribeLoading={isSubscribing}
                                  paid={item?.isPaid}

                                />

                              </Grid>

                            ))

                          )}


                        </Grid>
                      </>
                    }

                    {!searchedCourse && cardview !== "General Assessment" &&
                      <Grid item xs={12} sm={12} mt={2} mb={excelDetails?.length > 0 ? 4 : 0} >
                        <>
                          {excelCoursesLoading ? (
                            <Grid item xs={12} sm={4} md={4} lg={3}>
                              <CardSkeleton />
                            </Grid>
                          ) : (
                            excelDetails?.length > 0 &&
                            <Grid style={{ backgroundColor: '#F8F9FA', maxHeight: '450px', minHeight: '450px', padding: '16px 0 0 0' }}>
                              <Typography
                                style={{ marginLeft: 35 }}
                                variant="h4"
                                gutterBottom
                              >
                                Microsoft Excel
                              </Typography>

                              <Swiper
                                spaceBetween={10}
                                slidesPerView={4}
                                breakpoints={{
                                  1000: {
                                    slidesPerView: 4,
                                  },
                                  600: {
                                    slidesPerView: 2,
                                  },
                                  0: {
                                    slidesPerView: 1,
                                  },
                                }}
                                navigation={excelDetails?.length > 4}
                                pagination={
                                  excelDetails?.length > 4
                                    ? {
                                      el: `.${classes.swiperPagination}`,
                                      clickable: true,
                                      bulletClass: 'custom-bullet',
                                      bulletActiveClass: 'custom-bullet-active',
                                    }
                                    : false
                                }
                                keyboards
                                className="mySwiper"
                              >
                                {excelDetails && excelDetails?.length > 0 && excelDetails.map((item, index) => (
                                  <SwiperSlide key={index}
                                  >
                                    <UserCard
                                      type={item.type}
                                      handleCardClick={() => handleCardClickItem(item)}
                                      image={item.cover_image_url}
                                      planStatus={item.planStatus}
                                      title={item.title}
                                      is_free={item.is_free}
                                      trial={item.is_trial ? item.is_trial : item.isTrial}
                                      subscriptionPlanId={item?.subscriptionPlanId || item?.subscriptionplanid}
                                      handleTrial={handleClickTrialButton}
                                      handleSubcribe={postSubscribeDetails}
                                      subscribed={item.isSubscribed}
                                      category={item?.category_description ? item?.category_description : item?.category?.description}
                                      logo={item?.category_image ? item?.category_image : item?.category?.categoryImgUrl}
                                      // logo={item.category.categoryImgUrl}
                                      enrollDate={item.validFrom}
                                      progress={<LinearProgressWithLabel value={item.completed ? item.completed : 0} />}
                                      // progress={<LinearProgressWithLabel value={item.completed} />}
                                      data={item}
                                      comingfrom={cardViewSubMenu}
                                      paid={item?.isPaid}

                                    />
                                  </SwiperSlide>
                                ))}
                              </Swiper>
                              {/* <div className={classes.swiperPagination} /> */}
                            </Grid>)}



                          <Grid item xs={12} sm={12} mt={2} mb={officeDetails?.length > 0 ? 4 : 0} >
                            <>
                              {officeCoursesLoading ? (
                                <Grid item xs={12} sm={4} md={4} lg={3}>
                                  <CardSkeleton />
                                </Grid>
                              ) : (
                                officeDetails?.length > 0 && <Grid style={{ backgroundColor: '#F8F9FA', maxHeight: '450px', minHeight: '450px', padding: '16px 0 0 0' }}>
                                  <Typography
                                    style={{ marginLeft: 35 }}
                                    variant="h4"
                                    gutterBottom
                                  >
                                    Microsoft Office
                                  </Typography>
                                  <Swiper
                                    spaceBetween={10}
                                    slidesPerView={4}
                                    breakpoints={{
                                      1000: {
                                        slidesPerView: 4,
                                      },
                                      600: {
                                        slidesPerView: 2,
                                      },
                                      0: {
                                        slidesPerView: 1,
                                      },
                                    }}
                                    navigation={officeDetails?.length > 4}
                                    pagination={{
                                      el: officeDetails?.length > 4 ? `.${classes.swiperPagination1}` : "",
                                      clickable: officeDetails?.length > 4,
                                      bulletClass: 'custom-bullet',
                                      bulletActiveClass: 'custom-bullet-active'
                                    }}
                                    keyboard
                                    className="mySwiper"
                                  >
                                    {officeDetails && officeDetails?.length > 0 && officeDetails.map((item, index) => (
                                      <SwiperSlide key={index} >
                                        <UserCard
                                          type={item.type}
                                          handleCardClick={() => handleCardClickItem(item)}
                                          image={item.cover_image_url}
                                          planStatus={item.planStatus}
                                          title={item.title}
                                          is_free={item.is_free}
                                          trial={item.is_trial ? item.is_trial : item.isTrial}
                                          subscriptionPlanId={item?.subscriptionPlanId || item?.subscriptionplanid}
                                          handleTrial={handleClickTrialButton}
                                          handleSubcribe={postSubscribeDetails}
                                          subscribed={item.isSubscribed}
                                          category={item?.category_description ? item?.category_description : item?.category.description}
                                          logo={item?.category_image ? item?.category_image : item?.category?.categoryImgUrl}
                                          // logo={item.category.categoryImgUrl}
                                          enrollDate={item.validFrom}
                                          progress={<LinearProgressWithLabel value={item.completed ? item.completed : 0} />}
                                          // progress={<LinearProgressWithLabel value={item.completed} />}
                                          data={item}
                                          comingfrom={cardViewSubMenu}
                                          paid={item?.isPaid}
                                        />
                                      </SwiperSlide>
                                    ))}
                                  </Swiper>
                                  {/* <div className={classes.swiperPagination1} /> */}
                                </Grid>)}
                            </>
                          </Grid>



                          {lernerViewingsection?.length > 0 && <Grid item xs={12} sm={12} mt={2}>
                            <Box sx={{ backgroundColor: '#F8F9FA', maxHeight: '400px', minHeight: '400px', padding: '16px' }}>

                              <Typography
                                style={{ marginLeft: 35 }}
                                variant="h4"
                                gutterBottom
                              >
                                Learners are viewing
                              </Typography>
                              <Swiper
                                modules={[navigate, Autoplay]}
                                spaceBetween={16}
                                slidesPerView={1}
                                autoplay={{
                                  delay: 3000,
                                  disableOnInteraction: false,
                                }}
                                loop={true}
                                navigation
                              >
                                {lernerViewingsection?.length > 0 && lernerViewingsection.map((course, index) => (
                                  <SwiperSlide key={index}>
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        background: '#fff',
                                        height: '300px',
                                        borderRadius: '10px',
                                        overflow: 'hidden',
                                        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                                      }}
                                    >
                                      <Box onClick={() => handleCardClickItem(course)} sx={{ position: 'relative', width: '40%', height: '100%', cursor: 'pointer' }}>
                                        <img
                                          src={course.thumb_img_url}
                                          alt="Course"
                                          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                        />
                                      </Box>
                                      <CardContent
                                        sx={{
                                          width: '60%',
                                          height: '100%',
                                          display: 'flex',
                                          flexDirection: 'column',
                                          justifyContent: 'space-between',
                                          gap: 2,
                                          padding: '16px',
                                        }}
                                      >
                                        <Box sx={{ textAlign: 'left' }}>
                                          <Typography variant="h6" component="div" marginBottom={1}>
                                            {course.title}
                                          </Typography>
                                          <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            component="p"
                                            sx={{
                                              textOverflow: 'ellipsis',
                                              display: '-webkit-box',
                                              WebkitBoxOrient: 'vertical',
                                              WebkitLineClamp: 5,
                                              height: '115px',
                                              overflow: 'hidden',
                                            }}
                                          >
                                            {course.description.replace(/<[^>]*>/g, '')}
                                          </Typography>

                                        </Box>
                                        <Box display="flex" alignItems="center">
                                          <Rating
                                            name="simple-controlled"
                                            value={course.rating ? course.rating : 4.5}
                                            precision={0.5}
                                            readOnly
                                          />
                                          <Typography variant="body2" sx={{ ml: 0.5 }}>
                                            {course.rating ? course.rating : 4.5}
                                          </Typography>
                                          <Typography variant="body2" sx={{ ml: 1 }}>
                                            ({course.users ? course.users : 26378})
                                          </Typography>

                                          <Chip label="Bestseller" size="small" color="warning" sx={{ ml: 1 }} />
                                        </Box>
                                        <Box display="flex" alignItems="center">
                                          <Typography sx={{ fontSize: '26px', fontWeight: 'bold', color: '#20b2aa' }}>
                                            {/* {course.prize?course.prize:'$48'} */}
                                            {ValueCurrency(course)}
                                          </Typography>
                                        </Box>
                                      </CardContent>
                                    </Box>
                                  </SwiperSlide>
                                ))}
                              </Swiper>
                            </Box>
                          </Grid>}

                          <Grid item xs={12} sm={12} mt={2} mb={wordCourse?.length > 0 ? 4 : 0} >
                            <>
                              {officeCoursesLoading ? (
                                <Grid item xs={12} sm={4} md={4} lg={3}>
                                  <CardSkeleton />
                                </Grid>
                              ) : (
                                wordCourse?.length > 0 && <Grid style={{ backgroundColor: '#F8F9FA', maxHeight: '450px', minHeight: '450px', padding: '16px 0 0 0' }}>
                                  <Typography
                                    style={{ marginLeft: 35 }}
                                    variant="h4"
                                    gutterBottom
                                  >
                                    Microsoft Word
                                  </Typography>
                                  <Swiper
                                    spaceBetween={10}
                                    slidesPerView={4}
                                    breakpoints={{
                                      1000: {
                                        slidesPerView: 4,
                                      },
                                      600: {
                                        slidesPerView: 2,
                                      },
                                      0: {
                                        slidesPerView: 1,
                                      },
                                    }}
                                    navigation={wordCourse?.length > 4}
                                    pagination={
                                      wordCourse?.length > 4
                                        ? {
                                          el: `.${classes.swiperPagination}`,
                                          clickable: true,
                                          bulletClass: 'custom-bullet',
                                          bulletActiveClass: 'custom-bullet-active',
                                        }
                                        : false
                                    }

                                    keyboard
                                    className="mySwiper"
                                  >
                                    {wordCourse && wordCourse?.length > 0 && wordCourse.map((item, index) => (
                                      <SwiperSlide key={index} >
                                        <UserCard
                                          type={item.type}
                                          handleCardClick={() => handleCardClickItem(item)}
                                          image={item.cover_image_url}
                                          planStatus={item.planStatus}
                                          title={item.title}
                                          is_free={item.is_free}
                                          trial={item.is_trial ? item.is_trial : item.isTrial}
                                          subscriptionPlanId={item?.subscriptionPlanId || item?.subscriptionplanid}
                                          handleTrial={handleClickTrialButton}
                                          handleSubcribe={postSubscribeDetails}
                                          subscribed={item.isSubscribed}
                                          category={item?.category_description ? item?.category_description : item?.category.description}
                                          logo={item?.category_image ? item?.category_image : item?.category?.categoryImgUrl}
                                          enrollDate={item.validFrom}
                                          progress={<LinearProgressWithLabel value={item.completed ? item.completed : 0} />}
                                          data={item}
                                          paid={item?.isPaid}
                                          comingfrom={cardViewSubMenu}
                                        />
                                      </SwiperSlide>
                                    ))}
                                  </Swiper>
                                </Grid>)}
                            </>
                          </Grid>
                          <Grid item xs={12} sm={12} mt={2} mb={financeDetails?.length > 0 ? 4 : 0} >
                            <>
                              {accCoursesLoading ? (
                                <Grid item xs={12} sm={4} md={4} lg={3}>
                                  <CardSkeleton />
                                </Grid>
                              ) : (
                                financeDetails?.length > 0 && <Grid style={{ backgroundColor: '#F8F9FA', maxHeight: '450px', minHeight: '450px', padding: '16px 0 0 0' }}>
                                  <Typography
                                    style={{ marginLeft: 35 }}
                                    variant="h4"
                                    gutterBottom
                                  >
                                    Accounting and Finance
                                  </Typography>
                                  <Swiper
                                    spaceBetween={10}
                                    slidesPerView={4}
                                    breakpoints={{
                                      1000: {
                                        slidesPerView: 4,
                                      },
                                      600: {
                                        slidesPerView: 2,
                                      },
                                      0: {
                                        slidesPerView: 1,
                                      },
                                    }}
                                    navigation={financeDetails?.length > 4}
                                    pagination={{
                                      el: financeDetails?.length > 4 ? `.${classes.swiperPagination2}` : "",
                                      clickable: financeDetails?.length > 4,
                                      bulletClass: 'custom-bullet',
                                      bulletActiveClass: 'custom-bullet-active'
                                    }}
                                    keyboard
                                    className="mySwiper"
                                  >
                                    {financeDetails && financeDetails?.length > 0 && financeDetails.map((item, index) => (
                                      <SwiperSlide key={index} >
                                        <UserCard
                                          type={item.type}
                                          handleCardClick={() => handleCardClickItem(item)}
                                          image={item.cover_image_url}
                                          planStatus={item.planStatus}
                                          title={item.title}
                                          is_free={item.is_free}
                                          trial={item.is_trial ? item.is_trial : item.isTrial}
                                          subscriptionPlanId={item?.subscriptionPlanId || item?.subscriptionplanid}
                                          handleTrial={handleClickTrialButton}
                                          handleSubcribe={postSubscribeDetails}
                                          subscribed={item.isSubscribed}
                                          category={item?.category_description ? item?.category_description : item?.category.description}
                                          logo={item?.category_image ? item?.category_image : item?.category?.categoryImgUrl}
                                          // logo={item.category.categoryImgUrl}
                                          enrollDate={item.validFrom}
                                          // progress={<LinearProgressWithLabel value={item.completed ? item.completed : 0} />}
                                          // progress={<LinearProgressWithLabel value={item.completed} />}
                                          data={item}
                                          comingfrom={cardViewSubMenu}
                                          paid={item?.isPaid}
                                        />
                                      </SwiperSlide>
                                    ))}
                                  </Swiper>
                                  {/* <div className={classes.swiperPagination2} /> */}
                                </Grid>)}
                            </>
                          </Grid>
                          <Grid item xs={12} sm={12} mt={2} mb={codingDetails?.length > 0 ? 4 : 0} >
                            <>
                              {codingCoursesLoading ? (
                                <Grid item xs={12} sm={4} md={4} lg={3}>
                                  <CardSkeleton />
                                </Grid>
                              ) : (
                                codingDetails?.length > 0 && <Grid style={{ backgroundColor: '#F8F9FA', maxHeight: '450px', minHeight: '450px', padding: '16px 0 0 0' }}>
                                  <Typography
                                    style={{ marginLeft: 35 }}
                                    variant="h4"
                                    gutterBottom
                                  >
                                    Programming
                                  </Typography>
                                  <Swiper
                                    spaceBetween={10}
                                    slidesPerView={4}
                                    breakpoints={{
                                      1000: {
                                        slidesPerView: 4,
                                      },
                                      600: {
                                        slidesPerView: 2,
                                      },
                                      0: {
                                        slidesPerView: 1,
                                      },
                                    }}
                                    navigation={codingDetails?.length > 4}
                                    pagination={{
                                      el: codingDetails?.length > 4 ? `.${classes.swiperPagination3}` : "",
                                      clickable: codingDetails?.length > 4,
                                      bulletClass: 'custom-bullet',
                                      bulletActiveClass: 'custom-bullet-active'
                                    }}
                                    keyboard
                                    className="mySwiper"
                                  >
                                    {codingDetails && codingDetails?.length > 0 && codingDetails?.map((item, index) => (
                                      <SwiperSlide key={index} >
                                        <UserCard
                                          type={item.type}
                                          handleCardClick={() => handleCardClickItem(item)}
                                          image={item.cover_image_url}
                                          planStatus={item.planStatus}
                                          title={item.title}
                                          is_free={item.is_free}
                                          trial={item.is_trial ? item.is_trial : item.isTrial}
                                          subscriptionPlanId={item?.subscriptionPlanId || item?.subscriptionplanid}
                                          handleTrial={handleClickTrialButton}
                                          handleSubcribe={postSubscribeDetails}
                                          subscribed={item.isSubscribed}
                                          category={item?.category_description ? item?.category_description : item?.category?.description}
                                          logo={item?.category_image ? item?.category_image : item?.category?.categoryImgUrl}
                                          // logo={item.category.categoryImgUrl}
                                          enrollDate={item.validFrom}
                                          progress={<LinearProgressWithLabel value={item.completed ? item.completed : 0} />}
                                          // progress={<LinearProgressWithLabel value={item.completed} />}
                                          data={item}
                                          comingfrom={cardViewSubMenu}
                                          paid={item?.isPaid}
                                        />
                                      </SwiperSlide>
                                    ))}
                                  </Swiper>
                                  {/* <div className={classes.swiperPagination3} /> */}
                                </Grid>)}
                            </>
                          </Grid>

                          <Grid item xs={12} sm={12} mt={2} mb={powerpointDetails?.length > 0 ? 4 : 0} >
                            <>
                              {codingCoursesLoading ? (
                                <Grid item xs={12} sm={4} md={4} lg={3}>
                                  <CardSkeleton />
                                </Grid>
                              ) : (
                                powerpointDetails?.length > 0 && <Grid style={{ backgroundColor: '#F8F9FA', maxHeight: '450px', minHeight: '450px', padding: '16px 0 0 0' }}>
                                  <Typography
                                    style={{ marginLeft: 35 }}
                                    variant="h4"
                                    gutterBottom
                                  >
                                    Microsoft PowerPoint
                                  </Typography>
                                  <Swiper
                                    spaceBetween={10}
                                    slidesPerView={4}
                                    breakpoints={{
                                      1000: {
                                        slidesPerView: 4,
                                      },
                                      600: {
                                        slidesPerView: 2,
                                      },
                                      0: {
                                        slidesPerView: 1,
                                      },
                                    }}
                                    navigation={powerpointDetails?.length > 4}
                                    pagination={
                                      powerpointDetails?.length > 4
                                        ? {
                                          el: `.${classes.swiperPagination}`,
                                          clickable: true,
                                          bulletClass: 'custom-bullet',
                                          bulletActiveClass: 'custom-bullet-active',
                                        }
                                        : false
                                    }
                                    keyboard
                                    className="mySwiper"
                                  >
                                    {powerpointDetails && powerpointDetails?.length > 0 && powerpointDetails?.map((item, index) => (
                                      <SwiperSlide key={index} >
                                        <UserCard
                                          type={item.type}
                                          handleCardClick={() => handleCardClickItem(item)}
                                          image={item.cover_image_url}
                                          planStatus={item.planStatus}
                                          title={item.title}
                                          is_free={item.is_free}
                                          trial={item.is_trial ? item.is_trial : item.isTrial}
                                          subscriptionPlanId={item?.subscriptionPlanId || item?.subscriptionplanid}
                                          handleTrial={handleClickTrialButton}
                                          handleSubcribe={postSubscribeDetails}
                                          subscribed={item.isSubscribed}
                                          category={item?.category_description ? item?.category_description : item?.category.description}
                                          logo={item?.category_image ? item?.category_image : item?.category?.categoryImgUrl}
                                          // logo={item?.category_image ? item?.category_image : item?.category?.categoryImgUrl}
                                          // logo={item.category.categoryImgUrl}
                                          enrollDate={item.validFrom}
                                          progress={<LinearProgressWithLabel value={item.completed ? item.completed : 0} />}
                                          // progress={<LinearProgressWithLabel value={item.completed} />}
                                          data={item}
                                          comingfrom={cardViewSubMenu}
                                          paid={item?.isPaid}
                                        />
                                      </SwiperSlide>
                                    ))}
                                  </Swiper>
                                  {/* <div className={classes.swiperPagination3} /> */}
                                </Grid>)}
                            </>
                          </Grid>

                          {cardViewSubMenu !== 'FreeCourses' && <Grid item xs={12} sm={12} mt={2} mb={courseErg?.length > 0 ? 4 : 0} >
                            <>
                              {codingCoursesLoading ? (
                                <Grid item xs={12} sm={4} md={4} lg={3}>
                                  <CardSkeleton />
                                </Grid>
                              ) : (
                                courseErg?.length > 0 && <Grid style={{ backgroundColor: '#F8F9FA', maxHeight: '450px', minHeight: '450px', padding: '16px 0 0 0' }}>
                                  <Typography
                                    style={{ marginLeft: 35 }}
                                    variant="h4"
                                    gutterBottom
                                  >
                                    Low-code and No-code
                                  </Typography>
                                  <Swiper
                                    spaceBetween={10}
                                    slidesPerView={4}
                                    breakpoints={{
                                      1000: {
                                        slidesPerView: 4,
                                      },
                                      600: {
                                        slidesPerView: 2,
                                      },
                                      0: {
                                        slidesPerView: 1,
                                      },
                                    }}
                                    navigation={courseErg?.length > 4}
                                    pagination={
                                      courseErg?.length > 4
                                        ? {
                                          el: `.${classes.swiperPagination}`,
                                          clickable: true,
                                          bulletClass: 'custom-bullet',
                                          bulletActiveClass: 'custom-bullet-active',
                                        }
                                        : false
                                    }
                                    keyboard
                                    className="mySwiper"
                                  >
                                    {courseErg && courseErg?.length > 0 && courseErg?.map((item, index) => (
                                      <SwiperSlide key={index} >
                                        <UserCard
                                          type={item.type}
                                          handleCardClick={() => handleCardClickItem(item)}
                                          image={item.cover_image_url}
                                          planStatus={item.planStatus}
                                          title={item.title}
                                          is_free={item.is_free}
                                          trial={item.is_trial ? item.is_trial : item.isTrial}
                                          subscriptionPlanId={item?.subscriptionPlanId || item?.subscriptionplanid}
                                          handleTrial={handleClickTrialButton}
                                          handleSubcribe={postSubscribeDetails}
                                          subscribed={item.isSubscribed}
                                          category={item?.category_description ? item?.category_description : item?.category.description}
                                          logo={item?.category_image ? item?.category_image : item?.category?.categoryImgUrl}
                                          // logo={item?.category_image ? item?.category_image : item?.category?.categoryImgUrl}
                                          // logo={item.category.categoryImgUrl}
                                          enrollDate={item.validFrom}
                                          progress={<LinearProgressWithLabel value={item.completed ? item.completed : 0} />}
                                          // progress={<LinearProgressWithLabel value={item.completed} />}
                                          data={item}
                                          comingfrom={cardViewSubMenu}
                                          paid={item?.isPaid}
                                        />
                                      </SwiperSlide>
                                    ))}
                                  </Swiper>
                                  {/* <div className={classes.swiperPagination3} /> */}
                                </Grid>)}
                            </>
                          </Grid>}

                          {cardViewSubMenu !== 'FreeCourses' && <Grid item xs={12} sm={12} mt={2} mb={bundleCourses?.length > 0 ? 4 : 0} >
                            <>
                              {/* {bundleCoursesLoading ? (
                              <Grid item xs={12} sm={4} md={4} lg={3}>
                                <CardSkeleton />
                              </Grid>
                            ) : ( */}
                              {bundleCourses?.length > 0 && <Grid style={{ backgroundColor: '#F8F9FA', maxHeight: '420px', minHeight: '420px', padding: '16px 0 0 0' }}>
                                <Typography
                                  style={{ marginLeft: 35 }}
                                  variant="h4"
                                  gutterBottom
                                >
                                  Bundle Courses
                                </Typography>
                                <Swiper
                                  spaceBetween={10}
                                  slidesPerView={4}
                                  breakpoints={{
                                    1000: {
                                      slidesPerView: 4,
                                    },
                                    600: {
                                      slidesPerView: 2,
                                    },
                                    0: {
                                      slidesPerView: 1,
                                    },
                                  }}
                                  navigation={bundleCourses?.length > 4}
                                  pagination={
                                    bundleCourses?.length > 4
                                      ? {
                                        el: `.${classes.swiperPagination}`,
                                        clickable: true,
                                        bulletClass: 'custom-bullet',
                                        bulletActiveClass: 'custom-bullet-active',
                                      }
                                      : false
                                  }
                                  keyboard
                                  className="mySwiper"
                                >
                                  {bundleCourses && bundleCourses?.length > 0 && bundleCourses?.map((item, index) => (
                                    <SwiperSlide key={index} >
                                      <Grid item xs={12} sm={12} md={12} lg={12} key={item.subscriptionPlanId}>
                                        <BundleCard
                                          handleCardClick={() => handleNavigateBundle(item)}
                                          item={item}
                                          image={item.courseobj && item.courseobj?.length > 0 && item.courseobj[0].thumbImage}
                                          subscriptionPlanId={item.subscriptionPlanId}
                                          courseobj={item.courseobj}
                                          handleTrial={handleClickTrialButton}
                                          handleSubcribe={postSubscribeDetails}
                                          // price={'200'}

                                          price={new Intl.NumberFormat('en-US', { style: 'currency', currency }).format(bundleCosts[index]?.[currency])}
                                          isTrialLoading={isTrialing}
                                          isSubscribeLoading={isSubscribing}
                                          paid={item?.isPaid}

                                        />

                                      </Grid>

                                    </SwiperSlide>
                                  ))}
                                </Swiper>
                              </Grid>
                              }
                            </>
                          </Grid>}
                        </>
                      </Grid>}
                  </>
                }
              </Container>
            </Box>
          </WebPage>
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  main: {
    padding: '5rem 0rem',
    // paddingTop: '10rem',
    '@media (max-width: 540px)': {
      padding: '6rem 1rem 1rem 1rem',
    },
  },
  fixedHead: {
    position: 'fixed',
    zIndex: '1000',
    top: '68px',
    '@media (max-width: 1200px)': {
      top: '50px !important'
    },
    '@media (max-width: 1100px) and (min-width: 1000px)': {
      top: '50px !important'
    },

  },

  topcontent: {
    marginLeft: '2rem',
    marginRight: '0rem',
    marginTop: '2rem',
    '@media (max-width: 640px)': {
      marginLeft: '0rem',
      marginRight: '0rem',
      marginTop: '2rem',
    },
  },
  lineHeight1: {
    margin: '0.5rem 0rem 1rem 0rem',
    color: '#0B5D5F',
  },
  aboutCourse: {
    fontSize: '1.2rem',
    fontWeight: '400',
    marginBottom: '2rem',
    color: ' #667085',
  },
  courseBtn: {
    backgroundColor: '#FFD141',
    color: '#000',
    borderRadius: '16px',
    padding: '2px 16px',
    fontWeight: '500',
    fontSize: '14px',
    '&:hover': {
      backgroundColor: '#FFD141',
    },
  },
  searchfield: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'no-wrap',
    marginBottom: '2rem',
    '@media (max-width:640px)': {
      flexWrap: 'wrap',
    },
  },
  text: {
    backgroundColor: '#fff',
    borderRadius: '8px',
    border: '1px solid #D0D5DD',
  },
  getstart: {
    backgroundColor: '#EC7930',
    padding: '7px 26px',
    fontSize: '0.95rem',
    fontWeight: '450',
    color: '#fff',
    borderRadius: '20px',
    margin: '6px 0px 0px 16px',
    '&:hover': {
      backgroundColor: '#EC7930',
      boxShadow: '0 0 1px 6px rgba(236, 121, 48, 40%),0 0 1px 12px rgba(236,121,48, 20%)',
    },
  },
  home: {
    color: '#1D817F',
    fontSize: '0.95rem',
    fontWeight: '550',
  },
  solution: {
    fontSize: '0.95rem',
    color: '#667085',
    marginBottom: '1rem',
  },
  swiperPagination: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },
  '@global': {
    '.custom-bullet': {
      width: '8px',
      height: '8px',
      background: '#000',
      margin: '0 5px',
      borderRadius: '50%',
      opacity: 0.5,
      cursor: 'pointer',
    },
    '.custom-bullet-active': {
      opacity: 1,
      background: '#789df5',
    },
    '.swiper-button-next, .swiper-button-prev': {
      width: '25px',
      height: '25px',
      // color:'#054df7',
      // backgroundColor: '#789df5',
      backgroundColor: '#000000',

      borderRadius: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      // margin: '0px',
      marginTop: '-8px',
      '&::after': {
        fontSize: '14px',
        color: '#fff',
      },
    },
    '.makeStyles-swiperPagination-68': {
      marginTop: '22px'
    },
    '.makeStyles-swiperPagination-140': {
      marginTop: '22px !important'
    },
    '.MuiGrid-root.css-vj1n65-MuiGrid-root': {
      position: 'relative'
    },
    '.swiper-button-next': {
      right: '0px',
    },
    '.swiper-button-prev': {
      left: '0px',
    },

    '.swiper-container-pointer-events': {
      zIndex: '999',
      overflow: 'hidden',
      maxWidth: '95%',
      margin: 'auto',
      height: '390px',
      paddingTop: '10px',
      padding: '10px 15px'
    },


  },
  swiperPagination: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },
  swiperPagination1: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },
  swiperPagination2: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },
  swiperPagination3: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },
  swiperPagination4: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },
  swiperPagination5: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },
  swiperPagination6: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },
  swiperPagination7: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },
  swiperPagination8: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },
  swiperPagination9: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },
  swiperPagination10: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },
  swiperPagination11: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },
  '@global': {
    '.custom-bullet': {
      width: '8px',
      height: '8px',
      background: '#000',
      margin: '0 5px',
      borderRadius: '50%',
      opacity: 0.5,
      cursor: 'pointer',
    },
    '.custom-bullet-active': {
      opacity: 1,
      background: '#789df5',
    },
    '.swiper-button-next, .swiper-button-prev': {
      width: '25px',
      height: '25px',
      // color:'#054df7',
      // backgroundColor: '#789df5',
      backgroundColor: '#000000',

      borderRadius: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      // margin: '0px',
      marginTop: '-8px',
      '&::after': {
        fontSize: '14px',
        color: '#fff',
      },
    },
    '.makeStyles-swiperPagination-68': {
      marginTop: '22px'
    },
    '.makeStyles-swiperPagination-140': {
      marginTop: '22px !important'
    },
    '.MuiGrid-root.css-vj1n65-MuiGrid-root': {
      position: 'relative'
    },
    '.swiper-button-next': {
      right: '0px',
    },
    '.swiper-button-prev': {
      left: '0px',
    },

    '.swiper-container-pointer-events': {
      zIndex: '999',
      overflow: 'hidden',
      maxWidth: '95%',
      margin: 'auto',
      height: '390px',
      paddingTop: '10px',
      padding: '10px 15px'

    }
  },

  iconsec: {

    display: 'flex',

    justifyContent: 'flex-start',

    alignItems: 'center',

    marginBottom: '4px',
    textDecoration: 'none',
  },
}));

export default KeySkillsetCourses;