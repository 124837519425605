import React, { useState, useEffect, useMemo } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Grid, Button, TextField, Avatar, Typography, FormHelperText, MenuItem, InputLabel, Select, CircularProgress, FormControl, Box, LinearProgress,Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { SelectAllRounded } from '@material-ui/icons';

import adminServices from '../../services/adminServices';
import PhoneNumber from '../../components/PhoneNumber/Index'
import SnackBar from '../../components/snackbar/snackbar'
import loginServices from '../../services/loginServices';


// import SnackBar from '../../components/snackbar/snackbar';
// import PhoneNumber from 'src/components/PhoneNumber/Index'
// import adminServices from 'src/services/adminServices';

const Registration = (details, userDetails) => {
    // console.log(details, "details");


    const steps = [
        {
            label: 'About yourself',
        },
        {
            label: 'Profession',
        },
        {
            label: 'Topic of interest',
        },
    ];

    const { t } = useTranslation(); 
    const navigate = useNavigate()
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [alldata, setAlldata] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [gender, setGender] = React.useState('');
    const [genderDetails, setGenderDetails] = React.useState([]);
    const [roleDetails, setRoleDetails] = React.useState([]);
    const [workDetails, setWorkDetails] = React.useState([]);
    const [educationDetails, setEducationDetails] = React.useState([]);
    const [aboutDetails, setAboutDetails] = React.useState([]);
    const [jobDetails, setJobDetails] = React.useState([]);
    const [categoryDetails, setCategoryDetails] = React.useState([]);
    const [snackbar, setSnackbar] = useState(false);
    const [snackbarTitle, setSnackbarTitle] = useState('');
    const [carrierDetails, setCarrierDetails] = React.useState([]);
    const [avatarView, setAvatarView] = React.useState('');
    const [showOthersInput, setShowOthersInput] = useState(false);
    const [newTag, setNewTag] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(25);
    const [sendEmail, setSendEmail] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    
    const handleDateChange = (newValue) => {
        setSelectedDate(newValue);
    };
    const handleChangePhone = (e) => {
        setPhone(e)
    }
    
    const handleChangeGender = (event) => {
        setGender(event.target.value);
    };

    useEffect(() => {
        getDetails()
    }, [])

    const getDetails = async () => {
        const res = await adminServices.getBasicDetails();
        if (res.ok) {
            const courseHashTags = res.data.courseHashTags; 
            const uniqueHashTags = Array.from(new Set(courseHashTags.map(item => item.description)))
              .map(description => {
                return courseHashTags.find(item => item.description === description);
              });
            setCategoryDetails(uniqueHashTags);
            res.data?.settings?.forEach(item => {
                switch (item.config_name) {
                    case 'gender':
                        setGenderDetails(item.data);
                        break;
                    case 'current_role':
                        setRoleDetails(item.data);
                        break;
                    case 'work_experience':
                        setWorkDetails(item.data);
                        break;
                    case 'education':
                        setEducationDetails(item.data);
                        break;
                    case 'job':
                        setJobDetails(item.data);
                        break;
                    case 'hear_about_us':
                        setAboutDetails(item.data);
                        break;
                    case 'career_goal':
                        setCarrierDetails(item.data);
                        break;
                    default:
                        break;
                }
            });
        }
    };

    // setCarrierDetails
    const [initial, setInitial] = useState({
        firstName: '',
        lastName: '',
        email: '',
        // dateOfBirth: '',
        gender: '',
        education: "",
        careerGoal: "",
        currentRole: "",
        job: "",
        referral: "",
        workExperience: "",
        Intrest: ""
    })
    
    // console.log(initial, "initial");

    useMemo(() => {
        if (details.details) {
            setInitial({
                firstName: details.details?.firstName,
                lastName: details.details?.lastName,
                email: details.details?.email,
            })

            // const firstNameInitial = details.details?.firstName.charAt(0).toUpperCase();
            // const lastNameInitial = details.details?.lastName.charAt(0).toUpperCase();
            // const initialsname = `${firstNameInitial}${lastNameInitial}`;
            // const initialsname =  `${details.details?.firstName.charAt(0).toUpperCase()}${details.details?.lastName.charAt(0).toUpperCase()}`
            const initials = `${details.details?.firstName.charAt(0)}${details.details?.lastName.charAt(0)}`.toUpperCase();

            
            setAvatarView(initials)
        }
    }, [details])


    const backToLogin = () =>{
                navigate("/login");

    }
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };


    const [selectedTags, setSelectedTags] = useState([]);
   
    const handleTagClick = (tagCode) => {
        if (tagCode === 'Others') {
            setShowOthersInput(true);  
        } else {
            if (selectedTags.length >= 5 && !selectedTags.includes(tagCode)) {
                setSnackbarTitle('You can only select up to 5 items.');
                setSnackbar(true);
                return;
            }
    
            const updatedTags = selectedTags.includes(tagCode)
                ? selectedTags.filter(tag => tag !== tagCode)  
                : [...selectedTags, tagCode];  
    
            setSelectedTags(updatedTags);
        }
    };
    



    const handleSkipNow = (values) => {
        if (activeStep === 0) {
            setGender("")
            setPhone("")
            setSelectedDate("")
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
        else if (activeStep === 1) {
            setInitial(prevState => ({
                ...prevState,
                education: "",
                careerGoal: "",
                currentRole: "",
                job: "",
                referral: "",
                workExperience: "",
                Intrest: ""
            }));

            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
        else {
            setSnackbarTitle("Account Created!");
            setSnackbar(true);
            SendEmailVerification()
            setTimeout(() => {
                setIsModalOpen(true);              
                  // navigate("/login", { state: { email: values.email } });
              }, 3000);
        }
    }
    const handleSave = (data, phone, selectedDate,gender) => {
        setAlldata(data);
        const requiredFields = [data.firstName, data.lastName, data.email, phone, selectedDate, gender, data.education, data.job, data.currentRole, data.careerGoal, data.referral, data.workExperience];
        const filledFieldsCount = requiredFields.filter(Boolean).length;
        const progressIncrement = filledFieldsCount * 4.16;
        setProgress(Math.min(25 + progressIncrement));
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleUpdateDetails = async (values, phone, selectedTags, details, selectedDate,gendervalue) => {
        // console.log("valuesvalues", selectedTags);
        // console.log(phone, "phonephone");
        // console.log(selectedTags, "selectedTagsselectedTags");
        // console.log(alldata, "alldataalldata");
        // const requiredFields = [data.firstName, data.lastName,data.email, phone, selectedDate, data.gender, data.education, data.job,data.currentRole, data.careerGoal, data.referral, data.workExperience ];
        // console.log(requiredFields,"requiredFields");



        if (selectedTags?.length > 0) {
            setProgress((prev) => prev + 25);
        }
        const dateOfBirth = selectedDate ? new Date(selectedDate).toISOString().split('T')[0] : null;

        setLoading(true);
        const data = {
            careerGoal: values.careerGoal,
            currentRole: values.currentRole,
            dateOfBirth,
            education: values.education,
            email: values.email.toLowerCase(),
            firstName: values.firstName,
            gender: gendervalue,
            job: values.job,
            lastName: values.lastName,
            phoneNumber: phone,
            referral: values.referral,
            workExperience: values.workExperience,
            Intrest: selectedTags,
            progressbar: selectedTags?.length > 0 ? progress + 25 : progress
        }

        const result = await adminServices.Updateusercreation(details.userDetails, data);

        if (result.ok) {
            setSnackbar(true);
            setSnackbarTitle("Account Created!");
            SendEmailVerification()
            setTimeout(() => {
                setIsModalOpen(true);
             //   navigate("/login")
            }, 1000);
            setLoading(false);
        }
    }

    const handlePushDetails = () => {
        if (newTag && !selectedTags.includes(newTag)) {
            const isTagAlreadyInCategoryDetails = categoryDetails.some(item => item.description === newTag);
    
            if (isTagAlreadyInCategoryDetails) {
                setSnackbarTitle(`The tag "${newTag}" is already a default name and cannot be added again.`);
                setSnackbar(true);
                return; 
            }
    
            if (selectedTags?.length === 5) {
                setSnackbarTitle('Only 5 items can be selected');
                setSnackbar(true);
                return; 
            }
    
            setSelectedTags([...selectedTags, newTag]);
            setCategoryDetails([...categoryDetails, { description: newTag }]);
    
            setNewTag('');
            setShowOthersInput(false);
        }
    };
    
    
    const handleLimitExist = () => {
        setSnackbarTitle("Topic of intres  canot be more then 5");
        setSnackbar(true);
    }

    const handleCancel = () => {
        setNewTag('');
        setShowOthersInput(false);
      };

      const SendEmailVerification = async () =>{
        const data = {
            email:details.details?.email,
            firstName:details.details?.firstName,
        }
        try {
           
          const res = await loginServices.sendEmail(data);
          if (res.ok) {
        //  setOpenSnackbar(true)
        //  setSnackbarTitle(res.data.message)
          } 
        } catch (error) {
          console.log(error);
        }
      }
      const closeModal = () => {
        setIsModalOpen(false);
        navigate('/login')
      };
    
    return (
        <>
         <Box sx={{ marginTop: 10, marginLeft: '8.5%', marginRight: '8.5%', border: '1px solid #E0E0E0', }}>
                <Box
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        backgroundColor: '#f5f5f5',
                        padding: '16px',
                        borderRadius: '8px'
                    }}
                >

                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                        {/* <Avatar alt={avatarView} src="/path/to/avatar.jpg" style={{ marginRight: '16px' }} /> */}
                        <Avatar
                alt="User Avatar"
                style={{ marginRight: '10px', backgroundColor: 'black', width: '50px', height: '50px', fontSize: '1.5rem' }}
              >
                {avatarView || '?'}
              </Avatar>
                        <Box>
                            <Typography variant="h5">Welcome to Keyskillset</Typography>
                            <Typography variant="body3">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</Typography>
                        </Box>
                    </Box>
                    {/* Right section: Progress bar and completion text */}
                    <Box style={{ textAlign: 'right', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginRight: '26px', maxWidth: '300px' }}>
                        {/* Complete profile text */}
                        <Typography variant="body2" style={{ color: '#00B818', fontWeight: 'bold', marginBottom: '4px', }}>{progress< 98?'Complete your profile':'Profile Completed'}</Typography>

                        {/* Progress bar */}
                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                            <LinearProgress
                                variant="determinate"
                                value={progress}
                                style={{ width: '180px', height: '8px', borderRadius: '5px', marginRight: '8px' }}
                                sx={{
                                    '& .MuiLinearProgress-bar': {
                                        backgroundColor: '#00B818',
                                    },
                                }}
                            />
                            <Typography variant="body2" style={{ color: '#00B818', fontWeight: 'bold' }}>{Math.round(progress)}%</Typography>
                        </Box>
                    </Box>
                </Box>
                <SnackBar autoHideDuration={5000} open={snackbar} snackbarTitle={snackbarTitle} close={() => setSnackbar(false)} />

                <Formik
                    initialValues={initial}
                    enableReinitialize
                // onSubmit={(values) => {
                //     console.log(values);
                // }}
                >
                    {({ handleChange, values, errors, touched }) => (
                        <Form className={classes.formContainer}>
                            <Typography variant="h5" style={{ marginLeft: '15px', marginBottom: '30px' }} className={classes.formTitle} gutterBottom>
                                Let us know more about you before you Login!
                            </Typography>
                            <Grid container spacing={4}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ maxWidth: '100%', minHeight: '100%' }}>
                                        <Stepper activeStep={activeStep} orientation="vertical">
                                            {steps.map((step, index) => (
                                                <Step key={step.label} sx={{ height: '40px' }}>
                                                    <StepLabel>
                                                        {step.label}
                                                    </StepLabel>

                                                </Step>
                                            ))}
                                        </Stepper>
                                    </Box>
                                </Grid>

                                {activeStep === 0 &&
                                    <Grid item xs={12} md={9}>
                                       <Box sx={{ marginLeft: '3%' }}>

                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                                                        First Name
                                                    </Typography>
                                                    <TextField
                                                        fullWidth
                                                        // label="First Name"
                                                        name="firstName"
                                                        placeholder='First Name'
                                                        value={values.firstName}
                                                        onChange={handleChange}
                                                        error={touched.firstName && Boolean(errors.firstName)}
                                                        helperText={touched.firstName && errors.firstName}
                                                        disabled 
                                                        InputLabelProps={{
                                                            sx: {
                                                                marginBottom: '8px',
                                                            },
                                                        }}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                                                        Last Name
                                                    </Typography>
                                                    <TextField
                                                        fullWidth
                                                        // label="Last Name"
                                                        placeholder='Last Name'
                                                        name="lastName"
                                                        value={values.lastName}
                                                        onChange={handleChange}
                                                        error={touched.lastName && Boolean(errors.lastName)}
                                                        helperText={touched.lastName && errors.lastName}
                                                        disabled 
                                                        InputLabelProps={{
                                                            sx: {
                                                                marginBottom: '8px',
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                                                        Email
                                                    </Typography>
                                                    <TextField
                                                        fullWidth
                                                        // label="Email"
                                                        placeholder='Email'
                                                        name="email"
                                                        value={values.email}
                                                        onChange={handleChange}
                                                        error={touched.email && Boolean(errors.email)}
                                                        helperText={touched.email && errors.email}
                                                        disabled 
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                                                        Phone Number
                                                    </Typography>
                                                    <Box sx={{ width: '100%' }}>
                                                        <PhoneNumber
                                                            fullWidth
                                                            country={'us'}
                                                            name="phoneNumber"
                                                            specialLabel={''}
                                                            value={phone}
                                                            onChange={handleChangePhone}
                                                        />
                                                    </Box>
                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                                                        Date of birth
                                                    </Typography>

                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <DatePicker fullWidth
                                                            // label="Select Date"
                                                            value={selectedDate}
                                                            maxDate={new Date(new Date().setDate(new Date().getDate() - 1))}
                                                            onChange={handleDateChange}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    placeholder="MM/DD/YYYY"
                                                                    inputProps={{
                                                                        ...params.inputProps,
                                                                        readOnly: true,
                                                                    }}
                                                                    fullWidth
                                                                    sx={{
                                                                        'input::placeholder': {
                                                                            textTransform: 'uppercase',
                                                                        },
                                                                        '& .MuiOutlinedInput-root': {
                                                                            '& fieldset': {
                                                                                borderColor: 'black',
                                                                            },
                                                                            '&:hover fieldset': {
                                                                                borderColor: 'black',
                                                                            },
                                                                            '&.Mui-focused fieldset': {
                                                                                borderColor: 'black',
                                                                            },
                                                                        },
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </LocalizationProvider>


                                                  
                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <FormControl fullWidth>
                                                        <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                                                            Gender
                                                        </Typography>
                                                        <Select
                                                            name="gender"
                                                            displayEmpty
                                                            placeholder='Gender'
                                                            value={gender}
                                                            onChange={handleChangeGender}
                                                            error={touched.gender && Boolean(errors.gender)}
                                                        >
                                                            {genderDetails && genderDetails?.length > 0 && genderDetails.map((data) => {
                                                                return <MenuItem value={data.value}>{data.value}</MenuItem>
                                                            })}

                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>

                                            <Grid container justifyContent="flex-end" spacing={2} style={{ marginTop: '20px' }}>

                                                <Grid item>

                                                    <Button onClick={() => handleSave(values, phone, selectedDate,gender)} variant="contained" color="primary" type="submit">
                                                        Save and Continue
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button onClick={handleSkipNow} variant="outlined" className={classes.skipButton}>
                                                        Skip For Now
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>}

                                {activeStep === 1 && (
                                    <Grid item xs={12} md={9}>
                                        <Box sx={{ marginLeft: '3%' }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={6}>
                                                    <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                                                        Education  </Typography>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            name="education"
                                                            value={values.education || ""}
                                                            onChange={handleChange}
                                                            error={touched.education && Boolean(errors.education)}
                                                            displayEmpty
                                                        >
                                                            <MenuItem value="" disabled style={{ fontStyle: "normal" }}>
                                                                Education
                                                            </MenuItem>
                                                            {educationDetails && educationDetails?.length > 0 && educationDetails.map((data) => (
                                                                <MenuItem key={data.value} value={data.value}>{data.value}</MenuItem>

                                                            ))}

                                                        </Select>
                                                        <FormHelperText>{touched.education && errors.education}</FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                                                        Job  </Typography>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            name="job"
                                                            value={values.job || ""}
                                                            onChange={handleChange}
                                                            error={touched.job && Boolean(errors.job)}
                                                            displayEmpty
                                                        >
                                                            <MenuItem value="" disabled style={{ fontStyle: "normal" }}>
                                                                Job
                                                            </MenuItem>
                                                            {jobDetails && jobDetails?.length > 0 && jobDetails.map((data) => (
                                                                <MenuItem key={data.value} value={data.value}>{data.value}</MenuItem>
                                                            ))}
                                                        </Select>
                                                        <FormHelperText>{touched.job && errors.job}</FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                                                        Current Role  </Typography>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            name="currentRole"
                                                            value={values.currentRole || ""}
                                                            onChange={handleChange}
                                                            error={touched.currentRole && Boolean(errors.currentRole)}
                                                            displayEmpty
                                                        >
                                                            <MenuItem value="" disabled style={{ fontStyle: "normal" }}>
                                                                Current Role
                                                            </MenuItem>
                                                            {roleDetails && roleDetails?.length > 0 && roleDetails.map((data) => (
                                                                <MenuItem key={data.value} value={data.value}>{data.value}</MenuItem>
                                                            ))}

                                                        </Select>
                                                        <FormHelperText>{touched.currentRole && errors.currentRole}</FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                                                        Career Goal  </Typography>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            name="careerGoal"
                                                            value={values.careerGoal || ""}
                                                            onChange={handleChange}
                                                            error={touched.careerGoal && Boolean(errors.careerGoal)}
                                                            displayEmpty
                                                        >
                                                            <MenuItem value="" disabled style={{ fontStyle: "normal" }}>
                                                                Career Goal
                                                            </MenuItem>
                                                            {carrierDetails && carrierDetails?.length > 0 && carrierDetails.map((data) => {
                                                                return <MenuItem key={data.value} value={data.value}>{data.value}</MenuItem>

                                                            })}

                                                        </Select>
                                                        <FormHelperText>{touched.careerGoal && errors.careerGoal}</FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                                                        How Did You Hear About Us? </Typography>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            name="referral"
                                                            value={values.referral || ""}
                                                            onChange={handleChange}
                                                            error={touched.referral && Boolean(errors.referral)}
                                                            displayEmpty
                                                        >
                                                            <MenuItem value="" disabled style={{ fontStyle: "normal" }}>
                                                                Referral Source
                                                            </MenuItem>
                                                            {aboutDetails && aboutDetails?.length > 0 && aboutDetails.map((data) => (
                                                                <MenuItem key={data.value} value={data.value}>{data.value}</MenuItem>
                                                            ))}
                                                        </Select>
                                                        <FormHelperText>{touched.referral && errors.referral}</FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                                                        Work Experience</Typography>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            name="workExperience"
                                                            value={values.workExperience || ""}
                                                            onChange={handleChange}
                                                            error={touched.workExperience && Boolean(errors.workExperience)}
                                                            displayEmpty
                                                        >
                                                            <MenuItem value="" disabled style={{ fontStyle: "normal" }}>
                                                            Work Experience
                                                            </MenuItem>
                                                            {workDetails && workDetails?.length > 0 && workDetails.map((data) => {
                                                                return <MenuItem key={data.value} value={data.value}>{data.value}</MenuItem>

                                                            })}

                                                        </Select>
                                                        <FormHelperText>{touched.workExperience && errors.workExperience}</FormHelperText>
                                                    </FormControl>
                                                </Grid>

                                            </Grid>
                                            <Grid container spacing={2} style={{ marginTop: '20px' }} alignItems="center">
                                                <Grid item xs>
                                                    <Button onClick={() => handleBack()} variant="outlined" className={classes.backbutton}>
                                                        Back
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button onClick={() => handleSave(values, phone, selectedDate,gender)} variant="contained" color="primary" type="submit" style={{ marginLeft: '20px' }}>
                                                        Save and Continue
                                                    </Button>

                                                </Grid>
                                                <Grid item>
                                                    <Button onClick={() => handleSkipNow()} variant="outlined" className={classes.skipButton}>
                                                        Skip For Now
                                                    </Button>

                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                )}
                                {activeStep === 2 &&
                                    <Grid item xs={12} md={9}>
                                        <Box sx={{ marginLeft: '2%',marginTop:'30px' }}>

                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexWrap: 'wrap',
                                                    gap: 1,
                                                }}
                                            >
                                                {categoryDetails.map((tag, index) => {
                                                    const tagCode = typeof tag === 'object' ? tag.description : tag;

                                                    return (
                                                        <Box
                                                            key={index}
                                                            className={`${classes.card} ${selectedTags.includes(tagCode) ? classes.selected : ''}`}
                                                            onClick={() => handleTagClick(tagCode)}
                                                        >

                                                            <Typography className={classes.tagText}>
                                                                {typeof tag === 'string' ? (
                                                                    <>
                                                                        {tag.length > 12 ? (
                                                                            <Tooltip title={tag}>
                                                                                <span>{tag.slice(0, 12)}...</span>
                                                                            </Tooltip>
                                                                        ) : (
                                                                            tag
                                                                        )}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {tag.description && tag.description.length > 12 ? (
                                                                            <Tooltip title={tag.description}>
                                                                                <span>{tag.description.slice(0, 12)}...</span>
                                                                            </Tooltip>
                                                                        ) : (
                                                                            tag.description
                                                                        )}
                                                                    </>
                                                                )}
                                                            </Typography>

                                                        </Box>
                                                    );
                                                })}

                                                <Box
                                                    key="others"
                                                    className={classes.card}
                                                    onClick={() => handleTagClick('Others')}
                                                >
                                                    <Typography className={classes.tagText} >
                                                        Others +
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            {showOthersInput && (
                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, margin: 2 }}>
                                                    <TextField
                                                        variant="outlined"
                                                        value={newTag}
                                                        onChange={(e) => setNewTag(e.target.value)}
                                                        placeholder="Enter your interest"
                                                    />{selectedTags.length >= 5 ?
                                                        (
                                                        <Button variant="contained" onClick={handleLimitExist}>
                                                            Submit
                                                        </Button>
                                                        ) : (
                                                            <>
                                                         <IconButton onClick={handleCancel} color="orange">
                                                         <CloseIcon />
                                                       </IconButton>
                                                         <Button onClick={handlePushDetails} variant="contained">
                                                            Submit
                                                        </Button>
                                                        </>
                                                        )}

                                                </Box>
                                            )}
                                            <Grid container spacing={2} style={{ marginTop: '20px' }} alignItems="center">
                                                <Grid item xs>
                                                    <Button onClick={() => handleBack()} variant="outlined" className={classes.backbutton}>
                                                        Back
                                                    </Button>
                                                </Grid>

                                                <Grid item>
                                                    {loading ? (
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            type="submit"
                                                            style={{ marginLeft: '20px', paddingLeft: '30px', paddingRight: '30px' }}
                                                            disabled
                                                        >
                                                            <CircularProgress size={24} style={{ marginRight: '40px', marginLeft: '30px' }} />
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            onClick={() => handleUpdateDetails(values, phone, selectedTags, details, selectedDate,gender)}
                                                            variant="contained"
                                                            color="primary"
                                                            type="submit"
                                                            style={{ marginLeft: '20px' }}
                                                        >
                                                            Save and Continue
                                                        </Button>
                                                    )}

                                                </Grid>
                                               
                                                <Grid item>
                                                    <Button onClick={() => handleSkipNow(values)} variant="outlined" className={classes.skipButton}>
                                                        Skip For Now
                                                    </Button>

                                                </Grid>

                                            </Grid>
                                        </Box>
                                    </Grid>
                                }
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Box>

            <Dialog open={isModalOpen} onClose={closeModal}>
          <DialogTitle>Verify your email.</DialogTitle>
          <DialogContent>
            <p>We have sent an email to {''}<strong>{details.details?.email}</strong>{''}.</p>
            <p>Please verify your email and login!</p>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              size="medium"
              type="submit"
              variant="contained"
              sx={{ backgroundColor: '#D52F37', borderRadius: '5px' }}
              onClick={closeModal}
            >
              Go back To Login
            </LoadingButton>

          </DialogActions>
        </Dialog>

             
            
        </>
    );
};

const useStyles = makeStyles((theme) => ({
    formContainer: {
        padding: '20px',
        // border: '1px solid #E0E0E0',
        borderRadius: '8px',
        backgroundColor: '#fff',
    },
    stepIndicator: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '10px 0',
    },
    step: {
        border: '2px solid #ccc',
        borderRadius: '50%',
        width: '40px',
        height: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '10px',
        fontSize: '18px',
        color: '#333',
    },
    activeStep: {
        borderColor: '#1976D2',
        backgroundColor: '#1976D2',
        color: '#fff',
    },
    stepLabel: {
        textAlign: 'center',
        margin: 0,
        fontWeight: 600,
        fontSize: '14px',
        color: '#333',
    },
    formTitle: {
        fontSize: '22px',
        fontWeight: 'bold',
        marginBottom: '20px',
    },
    skipButton: {
        borderColor: '#1976D2',
        color: '#1976D2',
    },
    card: {
        borderRadius: '10px',
        height: '30px',
        cursor: 'pointer',
        // boxShadow: '0 4px 10px rgba(0, 0, 0, 0.6)',
        boxShadow: '0 3px 7px rgba(0, 0, 0, 0.4)',

        display: 'flex',
        alignItems: 'flex-start',
        paddingLeft: '20px',
        paddingRight: '20px',
        padding: '8px',
        textAlign: 'left',
        margin: '10px',
        transition: 'background-color 0.3s, box-shadow 0.3s',
        width: '150px',
        // height: '35px'
    },
    tagText: {

        // color: '#0099ff',
        color: 'black',
        fontSize: '12px',
        fontWeight: 'bolder',

    },
    selected: {
        backgroundColor: '#ffb36b',
        color: 'white',
    },
    createButton: {
        borderRadius: '5px',
        marginTop: '20px',
        background: "#D52F37",
        fontSize: "12px",
        width: "200px"
    },
}));

export default Registration;