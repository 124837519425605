import React, { useState, useEffect, useRef } from 'react';
import {
  Breadcrumbs,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Popover,
  IconButton,
  Typography,
  Tooltip,
} from '@mui/material';
import axios from 'axios';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { styled, alpha } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Cookies from 'js-cookie';
import lottie from 'lottie-web';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import swal from 'sweetalert';

// custom components
import lottieData from '../../../assets/lottie/lottie-loading.json';
import DeleteAlert from '../../../components/modal/DeleteModal';
import Page from '../../../components/Page';
import PageHeader from '../../../components/PageHeader';
import AddTopicForm from '../../../components/Forms/CreateModule';
import adminServices from '../../../services/adminServices';
import AddScreenForm from './AddScreenModel';
import SnackBar from '../../../components/snackbar/snackbar';
import VideoScreen from './VideoScreen';
import preSignedServices from '../../../services/preSignedServices';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiBackdrop-root': {
    background: 'transparent !important',
  },
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 142,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    left: '540px',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px, rgba(0, 0, 0, 0.03) 0px 0px 6px 1px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

const preSignedUrlUpload = async (url, file) => {
  const response = await axios.put(url, file, {
    headers: {
      'Content-Type': file?.type,
      // 'x-amz-acl': 'public-read',
    },
  });

  
  return response;
};


const SubModuleDetails = () => {
  const loading = useRef();
  const classes = useStyles();
  const location = useLocation();
  const data = JSON.parse(Cookies.get('subModule'));
  const isGoBack = true;
  const [openCreateTopicModule, setOpenCreateTopicModule] = useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [topicList, setTopicList] = useState([]);
  const [topicId, setTopicId] = useState(null);
  const [screenId, setScreenId] = useState(null);
  const [formType, setFormType] = useState('newTopic');
  const [btnLoading, setBtnLoading] = useState(false);
  const [lottieLoading, setLottieLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [openAddScreenForm, setOpenAddScreenForm] = useState(false);
  const [screenName, setScreenName] = useState('');
  const [buttonText, setButtonText] = useState('Create');
  const [deleteType, setDeleteType] = useState('');
  const [formValue, setFormValue] = useState(null);
  const [screenType, setScreenType] = useState('CREATE');
  const [screenData, setScreenData] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarTitle, setSnackbarTitle] = useState('');
  const [audioPath, setAudioPath] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [creatingScreen, setCreatingScreen] = useState('');
  const [modalBtnTxt, setModalBtnTxt] = useState('Create');
  // const [openMenu, setOpenMenu] = useState(true);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  
  const openMenu = Boolean(menuAnchorEl);

  const isPlayingAudio = Boolean(anchorEl);
  const [type, setType] = useState('');
  const courseId = location.state.id;
  const codeScreenType = location.state?.subCategory;
  const authorList = location.state?.authorList;
  const user = useSelector((state) => state.userInfo);
  const [openVideoScreen, setOpenVideoScreen] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    lottie.loadAnimation({
      container: loading.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: lottieData,
    });
  }, []);

  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: expanded === expand ? 'rotate(180deg)' : 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  useEffect(() => {
    getSubmoduleDetails();
  }, []);

  const getSubmoduleDetails = async () => {
    const response = await adminServices.getCourseModuleTopics(data.id);
    if (response.ok) {
      const data = response.data.sort((a, b) => a.positionIndex - b.positionIndex);
      setTopicList(data);
    }
    setLottieLoading(false);
  };

  const handleDeleteVideo = async () => {
    // let response = false;
    const alertRes = await swal({
      text: 'Are you sure you want to delete this video?',
      buttons: {
        confirm: {
          text: 'Okay',
          value: true,
          visible: true,
          className: '',
          closeModal: true,
        },
        cancel: {
          text: 'Cancel',
          value: null,
          visible: true,
          className: '',
          closeModal: true,
        },
      },
      closeOnClickOutside: false,
    });

    if (alertRes) {      
        const deleteResponse = await adminServices.handleDeleteSubmoduleVideo();
        if (deleteResponse.ok) {         
          setOpenSnackbar(true);
          setSnackbarTitle('Video deleted successfully');
          // return true;
        }
        if (!deleteResponse.ok) {
          setOpenSnackbar(true);
          setSnackbarTitle('Something went wrong please try again later');
        }
      
    }
    return true;
  };

async function updateActionScreen(topicId, screenId, formData, courseId,values,keyOBJ){
  console.log([...formData],"formData");
  console.log(values,"values");
  console.log(keyOBJ,"keyOBJ");

    try{
        const actionEditRes = await adminServices.updateActionScreenDetails(topicId, screenId, formData,courseId);
            console.log(actionEditRes,"actionEditRes");
            
        if (actionEditRes.ok) {
                  if (values.keyboardType !== 'CODE' && values.keyboardType !== 'CLICK') {
                    const { windowsKeyObject, macKeyObject } =  actionEditRes.data?.preSignedUrl ? actionEditRes.data?.preSignedUrl:actionEditRes.data ;
                          // Define a function to upload files for each platform
                    const uploadFiles = (keyObject, platform) => {
                            keyObject.forEach((key) => {
                              const { url, positionIndex } = key;
                              const matchingScreenData = keyOBJ.find((screenDataObj) => screenDataObj.positionIndex === positionIndex);
                              if (matchingScreenData && matchingScreenData[platform]?.file) {
                                preSignedServices.preSignedUrlUpload(url, matchingScreenData[platform].file);
                              }
                            });
                          };
                          // Upload files for Windows and Mac
                          if (windowsKeyObject?.length > 0 ) uploadFiles(windowsKeyObject, 'windows');
                          if (macKeyObject?.length > 0 ) uploadFiles(macKeyObject, 'mac');
                      }
                  if (values.codeResultType === 'IMAGE' && values.keyboardType === 'CODE' && values.codeResultImage) {
                    await preSignedUrlUpload(actionEditRes.data.imagePreSignedUrl, values.codeResultImage);
                  }
                  if (values.audioFile && values.audioPreView && actionEditRes.data?.preSignedUrl?.audioPreSignedUrl)  {
                    
                    await preSignedUrlUpload(actionEditRes.data?.preSignedUrl?.audioPreSignedUrl, values.audioFile);
                  }
                  else if (values.audioFile && values.audioPreView && actionEditRes.data?.audioPreSignedUrl) {
                    
                    await preSignedUrlUpload(actionEditRes.data?.audioPreSignedUrl, values.audioFile);
                  }
                  if (values.keyboardType === 'CLICK') {
                    const keyObj=JSON.stringify(keyOBJ)
                    console.log(keyObj,"keyObj");
                    
                    // if (Array.isArray(keyObj)) {
                    //   keyObj.map(async (res, idx) => {
                    //       await preSignedUrlUpload(actionEditRes.data?.windowsPreSignedUrl[idx], res.range.file);
                    //   });
                    // }
                    if(keyOBJ[0]?.range.file){
                      await preSignedUrlUpload(actionEditRes.data?.windowsPreSignedUrl, keyOBJ[0]?.range.file);

                    }

                    else {
                        console.error('keyObj is not an array, its value is:', keyObj);
                    }
                  
                  }
                  getSubmoduleDetails();
                  setOpenAddScreenForm(false);
                  setOpenSnackbar(true);
                  setSnackbarTitle('Screen updated successfully');
            } else if (actionEditRes.status === 400) {
                setOpenSnackbar(true);
                setSnackbarTitle(actionEditRes.data.message);
            }
    }
    catch(error){
    console.log(error)
    }
  }

  async function updateVideoScreen(topicId, screenId, formData, courseId, values){
    try{
        const res = await adminServices.updateVideoScreenDetails(topicId, screenId, formData, courseId);
            if (res.ok) {
              if (values.videoFile) {
                await axios
                  .put(res.data?.videoPreSignedUrl, values.videoFile, {
                    headers: {
                      'Content-Type': values.videoFile?.type,
                      // 'x-amz-acl': 'public-read',
                    },
                    onUploadProgress: (progressEvent) => {
                      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                      console.log(`Upload Progress: ${percentCompleted}%`);
                      setProgress(percentCompleted);
                    },
                  })
                  .then((response) => {
                    if (response.status === 200) {
                      setBtnLoading(false);
                      getSubmoduleDetails();
                      setOpenVideoScreen(false);
                      setOpenSnackbar(true);
                      setSnackbarTitle('Screen created successfully');
                    }
                    setBtnLoading(false);
                  });
                return;
              }
                getSubmoduleDetails();
                setOpenVideoScreen(false);
                setOpenSnackbar(true);
                setSnackbarTitle('Screen updated successfully');
            } else if (res.status === 400) {
                console.log("actionRes",res);
                setOpenSnackbar(true);
                setSnackbarTitle(res.data.message);
            }
    }
    catch(error){
    console.log(error)
    }
  }

  const handleSubmitVideoScreen = async(values) => {
    setBtnLoading(true);
    const formData = new FormData();    
   
    if (values.screenType === 'VIDEO') {

      formData.append('title',values.title)     
      formData.append('isCourseAssessment', values.isCourseAssessment);
      formData.append('isSubModuleAssessment', values.isSubModuleAssessment);
      formData.append('isMutipleChoice', values.isMutipleChoice);
      if (!values.isMutipleChoice) {
        // Set mcqData to null when isMutipleChoice is false
        formData.append('mcqData', JSON.stringify(null));
      } else if (screenType !== 'CREATE') {
      
        // Check if mcqOptions is defined before using map
        if (values && values.mcqOptions && Array.isArray(values.mcqOptions)) {
          const mcqdata = {
            mcqQuestion: values.mcqQuestion,
            mcqOptions: [...values.mcqOptions.map(option => option.option)],
            correctAnswer: values.correctAnswer,
          };
          formData.append('mcqData', JSON.stringify(mcqdata));
        } else {
          // Handle the case when mcqOptions is not defined or not an array
          console.error("mcqOptions is not defined or not an array");
        }
      }

      formData.append('isCaseStudiesAssessment', values.isCaseStudiesAssessment);
      formData.append('caseStudiesQuestion', values.caseStudiesQuestion);
              
      if (values.videoPreView!==null || values.videoPreView!==undefined) {
        const fileObj = {
          fileName: values.videoFile?.name,
          contentType: values.videoFile?.type,
        };
        formData.append('videoPreSignedUrl', JSON.stringify(fileObj));
      }
          
      if(screenType === 'CREATE') {

        const mcqdata = {
          mcqQuestion: values.mcqQuestion,
          mcqOptions: values.mcqOptions.map(option => option.option), 
          correctAnswer: values.correctAnswer,
        };
        
        formData.append('mcqData',stringify(mcqdata));      
       
        const response = await adminServices.createVideoScreenDetails(topicId, formData, courseId);
        console.log(response);
       
        if (response.ok) {     
          
          if (values.videoFile) {
            await axios
              .put(response.data?.videoPreSignedUrl, values.videoFile, {
                headers: {
                  'Content-Type': values.videoFile?.type,
                  // 'x-amz-acl': 'public-read',
                },
                onUploadProgress: (progressEvent) => {
                  const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                  console.log(`Upload Progress: ${percentCompleted}%`);
                  setProgress(percentCompleted);
                },
              })
              .then((response) => {
                if (response.status === 200) {
                  setBtnLoading(false);
                  getSubmoduleDetails();
                  setOpenVideoScreen(false);
                  setOpenSnackbar(true);
                  setSnackbarTitle('Screen created successfully');
                }
                setBtnLoading(false);
              });
            return;
          }

         
        } else if (response.status === 400) {
          setOpenSnackbar(true);
          setSnackbarTitle(response.data.message);
        }
      } else {
        
        await updateVideoScreen(topicId, screenId, formData, courseId, values);
      }
    }
    setBtnLoading(false);
  
  }

  const submitModuleForm = async (values) => {
    setBtnLoading(true);
    if (formType === 'newTopic') {
      const result = await adminServices.createCourseTopic(data.id, { courseSubmoduleTopics: values.courseTitle });
      if (result.ok) {
        getSubmoduleDetails();
        setOpenCreateTopicModule(false);
      }
    } else {
      const result2 = await adminServices.editSubModuleTopicName(data.id, topicId, {
        courseSubmoduleTopics: values.courseTitle,
      });
      if (result2.ok) {
        getSubmoduleDetails();
        setOpenCreateTopicModule(false);
      }
    }
    setBtnLoading(false);
  };

  const handleDeleteTopic = async () => {
    if (deleteType === 'TOPIC') {
      const result3 = await adminServices.deleteSubModuleTopic(data.id, topicId);
      if (result3.ok) {
        setOpenDeleteAlert(false);
        getSubmoduleDetails();
      }
    } else {
      const result6 = await adminServices.deleteScreenById(topicId, screenId);
      if (result6.ok) {
        setOpenDeleteAlert(false);
        getSubmoduleDetails();
      }
    }
  };

  const handleExpandClick = (i) => {
    setExpanded(expanded === i ? -1 : i);
  };

  
  function stringify(obj) 
  {
       let cache = []; 
       /* eslint prefer-arrow-callback: 0 */ 
        const str = JSON.stringify(obj,function (key, value) 
        {    
          if (typeof value === "object" && value !== null) 
          {     
              if (cache.indexOf(value) !== -1) 
              {         // Circular reference found, discard key       
                return; 
              }       // Store value in our collection     
              cache.push(value);   
          }
          return value;   
        }
        );
        
        cache = null; // reset the cache  
        return str; 
  
    }

    const handleSubmitScreenForm = async (values, keyObj,object) => {
      setBtnLoading(true);  
      const formData = new FormData();  
      formData.append('title', values.screenTitle);  
      formData.append('description', values.description);  
      formData.append('horizontalAlign', values.horizontalAlign);  
      formData.append('verticalAlign', values.verticalAlign);
      if(values.dropDownImage){
        formData.append('templateId', values.dropDownImage);
        }
      
      if (values.audioFile && values.audioPreView) {  
        formData.append('fileName', values.audioFile?.name);  
        formData.append('contentType', values.audioFile?.type);  
      } 
  
      if (values.screenType === 'INTRO') {  
        if (values.backgroundImg) {  
          formData.append('backgroundImg', values.backgroundImg[0]);  
        }  
        formData.append('buttonLabel', values.buttonLabel);
          
        if (screenType === 'CREATE') {  
          const response = await adminServices.createIntroScreenDetails(topicId, formData);  
          if (response.ok) {  
            // pre signed url integrate  
            if (values.audioFile && values.audioPreView) {  
              preSignedUrlUpload(response.data?.preSignedUrl, values.audioFile).then((res) => {
                if (res.status === 200) {  
                  setSnackbarTitle('Screen created successfully');  
                  getSubmoduleDetails();  
                  setOpenAddScreenForm(false);  
                  setOpenSnackbar(true);  
                  setBtnLoading(false);  
                }  
              });  
              return;
            } 
            setSnackbarTitle('Screen created successfully'); 
            getSubmoduleDetails();
            setOpenAddScreenForm(false);
            setOpenSnackbar(true);
          } else if (!response.ok) {
            setOpenSnackbar(true);
            setSnackbarTitle(response.data.message);
          }
        } else {
          const response = await adminServices.updateIntroScreenDetails(topicId, screenId, formData); 
          if (response.ok) {
            // pre signed url integrate
            if (values.audioFile && values.audioPreView) {
              await preSignedUrlUpload(response.data?.preSignedUrl, values.audioFile).then((res) => {
                if (res.status === 200) {
                  getSubmoduleDetails();
                  setOpenAddScreenForm(false);
                }
              });
              setBtnLoading(false);
              return;
            }
            getSubmoduleDetails();
            setOpenAddScreenForm(false);
          } else if (!response.ok) {
            setOpenSnackbar(true);
            setSnackbarTitle(response.data.message);
          }
        }
      } else if (values.screenType === 'ACTION') {
        // console.log('message for values',values);
        // formData.append('backgroundBlob',keyObj[0]?.range?.backgroundImg);s
        formData.append('backgroundBlobwindows',keyObj[0]?.windows?.backgroundImg);
        formData.append('backgroundBlobmac',keyObj[0]?.mac?.backgroundImg);
        formData.append('actionType', values.keyboardType);
        formData.append('iqQuestion', values.iqQuestion);
        formData.append('isCourseAssessment', values.isCourseAssessment);
        formData.append('isSubModuleAssessment', values.isSubModuleAssessment);
        formData.append('isMutipleChoice', values.isMutipleChoice);
        if (!values.isMutipleChoice) {
          // Set mcqData to null when isMutipleChoice is false
          formData.append('mcqData', JSON.stringify(null));
        } else if (screenType !== 'CREATE') {
         
          console.log('not create', screenType);
          // Check if mcqOptions is defined before using map
          if (values && values.mcqOptions && Array.isArray(values.mcqOptions)) {
            const mcqdata = {
              mcqQuestion: values.mcqQuestion,
              mcqOptions: [...values.mcqOptions.map(option => option.option)],
              correctAnswer: values.correctAnswer,
            };
            formData.append('mcqData', JSON.stringify(mcqdata));
          } else {
            // Handle the case when mcqOptions is not defined or not an array
            console.error("mcqOptions is not defined or not an array");
          }
        }
        formData.append('isCaseStudiesAssessment', values.isCaseStudiesAssessment);
        formData.append('caseStudiesQuestion', values.caseStudiesQuestion);   
        if (values.keyboardType === 'CODE') {
          formData.append('prependCode', values.prependScript);
          formData.append('appendCode', values.appendScript);
          formData.append('inputCode', values.inputCode);
          formData.append('resultType', values.codeResultType);
        }
        if (values.keyboardType !== 'CODE') {
          if (values.keyboardType === 'CLICK') {
            console.log("on the frontend your rangeobj is ",JSON.stringify(keyObj));
            formData.append('rangeObj', JSON.stringify(keyObj));
          } else {
            formData.append('keyObj', JSON.stringify(keyObj));
          }
        }
        if (values.codeResultType === 'IMAGE' && values.keyboardType === 'CODE' && values.codeResultImage) {
          const fileObj = {
            fileName: values.codeResultImage?.name,
            contentType: values.codeResultImage?.type,
          };
          formData.append('imagePreSignedUrl', JSON.stringify(fileObj));
        } else {
          formData.append('text', values.codeOutputValue ? values.codeOutputValue : '');
        }
        if (values.audioFile && values.audioPreView) {
          const fileObj = {
            fileName: values.audioFile?.name,
            contentType: values.audioFile?.type,
          };

          // formData.append('rangeObj', JSON.stringify(keyObj));
          // formData.append('keyObj', JSON.stringify(keyObj));


          formData.append('audioPreSignedUrl', JSON.stringify(fileObj));
        }
        if(screenType === 'CREATE') {
          const mcqdata = {
            mcqQuestion: values.mcqQuestion,
            mcqOptions: values.mcqOptions.map(option => option.option), 
            correctAnswer: values.correctAnswer,
          };
          formData.append('mcqData',stringify(mcqdata)); 
               
          const response = await adminServices.createActionScreenDetails(topicId, formData, courseId);
          if (response.ok) {
            if (values.keyboardType !== 'CODE') {
              keyObj.map(async (res, idx) => {
                if (values.keyboardType !== 'CLICK') {
                    if (values.keyboardType === 'INDIVIDUAL_HOT_KEYS')
                    {
                      Object.keys(response.data).forEach((key) => {
                        if (key === 'macPreSignedUrl' || key === 'windowsPreSignedUrl') {
                          response.data[key].forEach((item, index) => {
                            if (item.positionIndex === res.positionIndex) {
                              if (res.mac.file && key === 'macPreSignedUrl') {
                                axios.put(item.url, res.mac.file);
                              } 
                              if (res.windows.file && key === 'windowsPreSignedUrl') {
                                axios.put(item.url, res.windows.file);
                              }
                            }
                          });
                        }
                      });  

                    }
                    else
                    {
                    await preSignedUrlUpload(response.data?.macPreSignedUrl[idx], res.mac.file);
                    await preSignedUrlUpload(response.data?.windowsPreSignedUrl[idx], res.windows.file);
                    }
                } else {
                  await preSignedUrlUpload(response.data?.windowsPreSignedUrl[idx], res.range.file);
                }
              });
            }
            if (values.codeResultType === 'IMAGE' && values.keyboardType === 'CODE') {
              await preSignedUrlUpload(response.data.imagePreSignedUrl, values.codeResultImage);
            }
            if (values.audioFile && values.audioPreView) {
              await preSignedUrlUpload(response.data?.audioPreSignedUrl, values.audioFile);
            }
            setBtnLoading(false);
            getSubmoduleDetails();
            setOpenAddScreenForm(false);
            setOpenSnackbar(true);
            setSnackbarTitle('Screen created successfully');
          } else if (response.status === 400) {
            setOpenSnackbar(true);
            setSnackbarTitle(response.data.message);
          }
        } else {
          await updateActionScreen(topicId, screenId, formData, courseId,values,keyObj);
        }
      } else {
        if (values.backgroundImg) {
          formData.append('backgroundImg', values.backgroundImg[0]);
        }
        formData.append('buttonLabel', values.buttonLabel);
        formData.append('soundRequired', values.playSound);
        if (screenType === 'CREATE') {
          const response = await adminServices.createSuccessScreenDetails(topicId, formData);
          if (response.ok) {
            // pre signed url integrate
            if (values.audioFile && values.audioPreView) {
              await preSignedUrlUpload(response.data?.preSignedUrl, values.audioFile).then((res) => {
                if (res.status === 200) {
                  setSnackbarTitle('Screen created successfully');
                  getSubmoduleDetails();
                  setOpenAddScreenForm(false);
                  setOpenSnackbar(true);
                }
                setBtnLoading(false);
              });
              return;
            }
            setSnackbarTitle('Screen created successfully');
            getSubmoduleDetails();
            setOpenAddScreenForm(false);
            setOpenSnackbar(true);
          } else if (!response.ok) {
            setOpenSnackbar(true);
            setSnackbarTitle(response.data.message);
          }
        } else {
          const response = await adminServices.updateSuccessScreenDetails(topicId, screenId, formData);
          if (response.ok) {
            // pre signed url integrate
            if (values.audioFile && values.audioPreView) {
              await preSignedUrlUpload(response.data?.preSignedUrl, values.audioFile).then((res) => {
                if (res.status === 200) {
                  setOpenSnackbar(true);
                  setSnackbarTitle('Screen updated successfully');
                  getSubmoduleDetails();
                  setOpenAddScreenForm(false);
                }
              });
              setBtnLoading(false);
              return;
            }
            getSubmoduleDetails();
            setOpenAddScreenForm(false);
          } else if (!response.ok) {
            setOpenSnackbar(true);
            setSnackbarTitle(response.data.message);
          }
        }
      }
      setBtnLoading(false);
    // } catch (error) {
    //   console.error('Error in handleSubmitScreenForm:', error);
    // }
  };


  const getScreenDetailsById = async (topicId, screenId, type, actionType) => {

  if(type === 'VIDEO')
    setOpenVideoScreen(true)
  else
    setOpenAddScreenForm(true);

  if (type === 'INTRO') {
    const result5 = await adminServices.getIntroScreenDetailsById(topicId, screenId);
    if (result5.ok) {
      setScreenData(result5.data);
    }
  }
  else if (type === 'VIDEO'){

    const actionRes = await adminServices.getVideoScreenDetailsById(topicId, screenId);
    console.log("actionRes",actionRes);
    if (actionRes.ok) {
      console.log("actionRes.ok",actionRes);
      const { mcqData } = actionRes.data;

      if (mcqData !== null && mcqData.mcqOptions !== undefined) {
        console.log("inside mcq data",)
        const formattedScreenData = {
          ...actionRes.data,
          mcqQuestion: mcqData.mcqQuestion,
          mcqOptions: mcqData.mcqOptions.map((option, index) => ({
            option,
            id: index,
            isChecked: option === mcqData.correctAnswer
          })),
          correctAnswer: mcqData.correctAnswer || '',
        };
        console.log("formattedScreenData...", formattedScreenData);
        setScreenData(formattedScreenData);
      } else {
        // If mcqData or mcqOptions is null or undefined, set the entire response data
        console.log("mcq null or false condition",actionRes.data);
        // setScreenData ((prevData) =>({...prevData,...actionRes.data,}));
        const formattedScreenData = {
          ...actionRes.data,
          mcqQuestion: '',
          mcqOptions: [],
          correctAnswer:'',
          isMutipleChoice: false
        };
        console.log("formattedScreenData...", formattedScreenData);
        setScreenData(formattedScreenData);
        
      }
    }

  } 
  else if (type === 'ACTION') {
    let keyType = '';
    if (actionType === 'COMBINED_HOT_KEYS' || actionType === 'INDIVIDUAL_HOT_KEYS') {
      keyType = 'HOTKEYS';
    } else if (actionType === 'CLICK') {
      keyType = 'CLICK';
    } else {
      keyType = 'CODE';
    }

    const actionRes = await adminServices.getActionScreenDetailsById(topicId, screenId, keyType);
    console.log("actionRes",actionRes);
    if (actionRes.ok) {
      console.log("actionRes.ok",actionRes);
      const { mcqData } = actionRes.data;

      if (mcqData !== null && mcqData.mcqOptions !== undefined) {
        console.log("inside mcq data",)
        const formattedScreenData = {
          ...actionRes.data,
          mcqQuestion: mcqData.mcqQuestion,
          mcqOptions: mcqData.mcqOptions.map((option, index) => ({
            option,
            id: index,
            isChecked: option === mcqData.correctAnswer
          })),
          correctAnswer: mcqData.correctAnswer || '',
        };
        console.log("formattedScreenData...", formattedScreenData);
        setScreenData(formattedScreenData);
      } else {
        // If mcqData or mcqOptions is null or undefined, set the entire response data
        console.log("mcq null or false condition",actionRes.data);
        // setScreenData ((prevData) =>({...prevData,...actionRes.data,}));
        const formattedScreenData = {
          ...actionRes.data,
          mcqQuestion: '',
          mcqOptions: [],
          correctAnswer:'',
          isMutipleChoice: false
        };
        console.log("formattedScreenData...", formattedScreenData);
        setScreenData(formattedScreenData);
        
      }
    }
  } else {
    console.log("Before calling getsuccess....")
    const result7 = await adminServices.getSuccessScreenDetailsById(topicId, screenId);
    if (result7.ok) {
         setScreenData((prevData) =>({...prevData,...result7.data,}));   

    }
  }
};

  // drag and drop the topics and screens reordering
  const handleDrop = async (droppedItem) => {
    const topicId = parseInt(droppedItem.destination.droppableId, 10); // topic id assinging
    if (!droppedItem.destination) return;
    // topics reordering
    if (droppedItem.type === 'TOPIC') {
      const updatedList = [...topicList];
      // Remove dragged item
      const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);

      // Add dropped item
      updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
      // Update State
      setTopicList(updatedList);
      const positionArray = [];
      updatedList.map((res, index) => {
        const item = {
          id: res.id,
          positionIndex: index + 1,
        };
        positionArray.push(item);
        return item;
      });
      const positionRes = await adminServices.updateTopicPositions(data.id, positionArray);
      if (positionRes.ok) {
        console.log(positionRes);
      }
    } else {
      // screens reordering

      let resultArray = [];
      const updatedScreenList = [...topicList];

      updatedScreenList.map((item, i) => {
        if (item.id === topicId) {
          // Remove dragged item
          const [reorderedSubItem] = updatedScreenList[i].subModuleTopicsScreen.splice(droppedItem.source.index, 1);
          // Add dropped item
          updatedScreenList[i].subModuleTopicsScreen.splice(droppedItem.destination.index, 0, reorderedSubItem);
          // Update State
          resultArray = updatedScreenList;
          setTopicList(updatedScreenList);
        }
        return item;
      });
      const positionArray = [];
      resultArray.map((res) => {
        if (res.id === topicId) {
          res.subModuleTopicsScreen.map((screen, indx) => {
            const item = {
              id: screen.id,
              positionIndex: indx + 1,
            };
            positionArray.push(item);
            return item;
          });
        }
        return res;
      });
      try {
        const positionRes = await adminServices.updateScreenPositions(topicId, positionArray);
        console.log("message for updateScreenPositions",topicId, positionArray);
        if (positionRes.ok) {
          console.log(positionRes, 'positionRes');
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const ScreenComponent = ({ topic, screens, screenNumber }) => {
   return (
      <Droppable droppableId={topic.id.toString()} type={`${screenNumber}`}>
        {(provided) => (
          <div ref={provided.innerRef}>
            {screens &&
              screens.map((response, index) => {
                return (
                  <Draggable key={`${screenNumber}${index}`} draggableId={`${screenNumber}${index}`} index={index}>
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.draggableProps}>
                        <Box
                          className={classes.cardBox}
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          {...provided.dragHandleProps}
                        >
                          <Typography variant="subtitle1">{response.title}</Typography>

                          <div>
                            {response.audio && (
                              <Tooltip title="Audio file" arrow>
                                <IconButton
                                  aria-describedby={'simple-popover'}
                                  size="small"
                                  id='audiotrackIconId'
                                  onClick={(e) => {
                                    setAudioPath(response.audio);
                                    setAnchorEl(true);
                                  }}
                                >
                                  <AudiotrackIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            )}

                            <Popover
                              sx={{
                                '.css-1xour3s-MuiBackdrop-root-MuiModal-backdrop': {
                                  background: '#fffefe00',
                                },
                              }}
                              id={'simple-popover'}
                              open={isPlayingAudio}
                              anchorEl={anchorEl}
                              onClose={() => setAnchorEl(null)}
                              anchorOrigin={{
                                vertical: 'center',
                                horizontal: 'right',
                              }}
                              transformOrigin={{
                                vertical: 'center',
                                horizontal: 'right',
                              }}
                            >
                              <audio
                                style={{ height: '36px' }}
                                // autoPlay
                                controls
                                src={audioPath}
                                controlsList="nodownload"
                              >
                                <track kind="captions" src="sampleCaptions.vtt" />
                              </audio>
                            </Popover>

                            <Tooltip title="Edit screen details" arrow>
                              <IconButton
                                size="small"
                                id='modeEditForSubmoduleIconId'
                                onClick={() => {
                                  setCreatingScreen(response.type);
                                  setScreenType('EDIT');
                                  setScreenName(response.title);
                                  setTopicId(topic.id);
                                  setScreenId(response.id);
                                  getScreenDetailsById(topic.id, response.id, response.type, response.actionType);
                                }}
                              >
                                <ModeEditIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            { // eslint-disable-next-line prefer-template
                              (user.role==='SUPER_ADMIN' || (authorList).includes(user.firstName + ' '+ user.lastName)) && <Tooltip title="Delete screen" arrow>
                              <IconButton
                                size="small"
                                id='DeleteIconForSubmoduleId'
                                onClick={() => {
                                  setOpenDeleteAlert(true);
                                  setDeleteType('SCREEN');
                                  setTopicId(topic.id);
                                  setScreenId(response.id);
                                }}
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>}
                          </div>
                        </Box>
                      </div>
                    )}
                  </Draggable>
                );
              })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  };

  const Breadcrumb = () => (
    <Breadcrumbs aria-label="breadcrumb" separator="›">
      <Typography
        sx={{ textDecoration: 'none' }}
        variant="body2"
        color="primary"
        component={RouterLink}
        to="/app/dashboard"
      >
        Dashboard
      </Typography>
      <Typography
        sx={{ textDecoration: 'none' }}
        variant="body2"
        color="primary"
        component={RouterLink}
        to="/app/course"
      >
        Course
      </Typography>
      <Typography variant="body2" color="textPrimary">
        Course details
      </Typography>
    </Breadcrumbs>
  );

  // audio file deleting
  const handleDeleteAudio = async () => {
    const deleteRes = await adminServices.handleDeleteScreenAudio(screenId);
    if (deleteRes.ok) {
      setSnackbarTitle('Audio file deleted successfully');
      setOpenSnackbar(true);
      getSubmoduleDetails();
    } else {
      setSnackbarTitle('Something went wrong try again');
      setOpenSnackbar(true);
    }
  };
  const [templates, setTemplates] = useState([]);
  const handleGetTemplate = async (topicId) => {
    try {
      const response = await adminServices.getIntroTemplate(topicId);
      if (response.ok) {
        setTemplates(response.data);
      } else {
        console.log("Failed to fetch template");
      }
    } catch (error) {
      console.error("Error fetching template:", error);
    }
  };

  return (
    <Page title="Sub-module details">
      <PageHeader
        pageTitle={data.courseSubmoduleName}
        buttonText={// eslint-disable-next-line prefer-template
          (user.role==='SUPER_ADMIN' || (authorList).includes(user.firstName + ' '+ user.lastName)) && (location.state.type === 'simulation' || (location.state.type === 'video' && topicList.length===0))? "Create Topic":""}
        handleButtonClick={() => {
          setOpenCreateTopicModule(true);
          setFormType('newTopic');
          setModalTitle('Create Topic');
          setButtonText('Create');
          setFormValue('');
          setType('createTopic');
        }}
        goBack={isGoBack}
        breadcrumbs={<Breadcrumb />}
        submodule={"submodule"}
      />

      {lottieLoading ? (
        <div style={{ width: 50, margin: 'auto' }} ref={loading} />
      ) : (
        <>
          <div>
            <DragDropContext onDragEnd={handleDrop}>
              <Droppable droppableId="list-container" type="TOPIC">
                {(provided) => (
                  <div className="list-container" {...provided.droppableProps} ref={provided.innerRef}>
                    {topicList &&
                      topicList.map((res, index) => (
                        <Draggable
                          key={res.positionIndex}
                          draggableId={res.positionIndex.toString()}
                          index={index}
                          type="TASK"
                        >
                          {(provided) => (
                            <div ref={provided.innerRef} {...provided.draggableProps}>
                              <Card className={classes.cardItem}>
                                <CardActions disableSpacing>
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      paddingLeft: '16px',
                                      width: '100%',
                                      justifyContent: 'space-between',
                                    }}
                                  >
                                    <Box
                                      {...provided.dragHandleProps}
                                      sx={{ minWidth: '60%' }}
                                      onClick={() => handleExpandClick(index)}
                                    >
                                      <Typography sx={{ fontSize: '0.85rem' }}>Topic {index + 1}</Typography>
                                      <Typography variant="subtitle1" sx={{ fontSize: '1.1rem' }}>
                                        {res.courseSubmoduleTopics}
                                      </Typography>
                                    </Box>

                                    <div>
                                      <Tooltip title="Edit topic name" arrow>
                                        <IconButton
                                          size="small"
                                          id='modeEditIconCourseSubmoduleTopicsId'
                                          sx={{ marginLeft: '8px' }}
                                          onClick={() => {
                                            setFormType('editTopic');
                                            setOpenCreateTopicModule(true);
                                            setModalTitle('Edit Topic Name');
                                            setTopicId(res.id);
                                            setButtonText('Update');
                                            setType('createTopic');
                                            setFormValue({
                                              courseTitle: res.courseSubmoduleTopics,
                                              isTrial: null,
                                            });
                                            // setFormValue(res.courseSubmoduleTopics);
                                          }}
                                        >
                                          <ModeEditIcon fontSize="small" />
                                        </IconButton>
                                      </Tooltip>
                                      { // eslint-disable-next-line prefer-template
                                        (user.role==='SUPER_ADMIN' || (authorList).includes(user.firstName + ' '+ user.lastName)) && <Tooltip title="Delete topic" arrow>
                                        <IconButton
                                          size="small"
                                          id=''
                                          sx={{ margin: '0 8px' }}
                                          onClick={() => {
                                            setOpenDeleteAlert(true);
                                            setTopicId(res.id);
                                            setDeleteType('TOPIC');
                                          }}
                                        >
                                          <DeleteIcon fontSize="small" />
                                        </IconButton>
                                      </Tooltip>}

                                      { // eslint-disable-next-line prefer-template
                                        (user.role==='SUPER_ADMIN' || (authorList).includes(user.firstName + ' '+ user.lastName)) && <Button
                                        variant="contained"
                                        startIcon={<AddIcon />}
                                        sx={{ background: '#6D6969' }}
                                        onClick={(event) => {
                                          setMenuAnchorEl(event.currentTarget);
                                          // setOpenAddScreenForm(true);
                                            setScreenName(res.courseSubmoduleTopics);
                                            setTopicId(res.id);
                                            setScreenType('CREATE');
                                            setScreenData(null);
                                        }}
                                        id="basic-button"
                                        aria-controls={openMenu ? 'demo-positioned-men' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={openMenu ? 'true' : undefined}
                                      >
                                        Add Screen
                                      </Button>}

                                      <StyledMenu
                                        id="demo-customized-menu"
                                        MenuListProps={{
                                          'aria-labelledby': 'demo-customized-button',
                                        }}
                                        anchorEl={menuAnchorEl}
                                        open={openMenu}
                                        onClose={() => setMenuAnchorEl(null)}
                                      >
                                        <MenuItem
                                          onClick={() => {
                                            setCreatingScreen('INTRO');
                                            setOpenAddScreenForm(true);
                                            setMenuAnchorEl(false);
                                          }}
                                        >
                                          Intro
                                        </MenuItem>
                                        {location.state.type === 'simulation' && <MenuItem
                                          onClick={() => {
                                            setCreatingScreen('ACTION');
                                            setOpenAddScreenForm(true);
                                            setMenuAnchorEl(false);
                                          }}
                                        >
                                          Action
                                        </MenuItem>}
                                        {location.state.type === 'video' && <MenuItem
                                          onClick={() => {
                                            setCreatingScreen('VIDEO');
                                            setModalBtnTxt("Update")
                                            setOpenVideoScreen(true)
                                            setMenuAnchorEl(false);
                                          }}
                                        >
                                          Video
                                        </MenuItem>}
                                        <MenuItem
                                          onClick={() => {
                                            setCreatingScreen('SUCCESS');
                                            setOpenAddScreenForm(true);
                                            setMenuAnchorEl(false);
                                          }}
                                        >
                                          Success
                                        </MenuItem>
                                      </StyledMenu>
                                    </div>
                                  </div>
                                  <ExpandMore
                                    expand={index}
                                    onClick={() => handleExpandClick(index)}
                                    aria-expanded={expanded}
                                    aria-label="show more"
                                    sx={{ marginLeft: '16px' }}
                                  >
                                    <ExpandMoreIcon />
                                  </ExpandMore>
                                </CardActions>
                                <Collapse in={expanded === index} timeout="auto" unmountOnExit>
                                  <CardContent>
                                    <ScreenComponent
                                      topic={res}
                                      screenNumber={index}
                                      screens={res.subModuleTopicsScreen}
                                    />
                                  </CardContent>
                                </Collapse>
                              </Card>
                            </div>
                          )}
                        </Draggable>
                      ))}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>

          <Typography variant="h6" align="center" marginTop={6}>
            {topicList.length === 0 && 'No Data'}
          </Typography>
        </>
      )}

      <DeleteAlert
        open={openDeleteAlert}
        title="Are you sure you want delete this topic?"
        subTitle="Once you delete the topic all screens will be deleted"
        confirm={handleDeleteTopic}
        close={() => setOpenDeleteAlert(false)}
      />

      <AddTopicForm
        open={openCreateTopicModule}
        modelTitle={modalTitle}
        closeModel={() => setOpenCreateTopicModule(false)}
        textFieldName={'Topic Name'}
        handleSubmitForm={submitModuleForm}
        modelWidth="xs"
        isAuthor={// eslint-disable-next-line prefer-template    
                  (user.role==='SUPER_ADMIN' || (authorList).includes(user.firstName + ' '+ user.lastName))}
        buttonText={buttonText}
        loading={btnLoading}
        data={formValue}
        type={type}
        formType="TOPIC"
      />

      <AddScreenForm
        typeOfScreen={creatingScreen}
        title={screenName}
        modalOpen={openAddScreenForm}
        handleModalClose={() => setOpenAddScreenForm(false)}
        handleSubmitForm={handleSubmitScreenForm}
        formType={screenType}
        authorList={authorList}
        data={screenData}
        loading={btnLoading}
        topicId={topicId}
        screenId={screenId}
        codeScreenType={codeScreenType}
        handleDeleteAudioFile={() => handleDeleteAudio()}
        handleGetTemplate={handleGetTemplate}
        templates={templates}
      />

      <VideoScreen 
       typeOfScreen="VIDEO"
       modalOpen={openVideoScreen}
       formType={screenType}
       data={screenData}
       handleModalClose={() => setOpenVideoScreen(false)}
       buttonText={modalBtnTxt}
       loading={btnLoading}
       handleSubmitForm={handleSubmitVideoScreen}
       handleVideoDelete={() => handleDeleteVideo()}/>

      <SnackBar open={openSnackbar} snackbarTitle={snackbarTitle} close={() => setOpenSnackbar(false)} />
    </Page>
  );
};

const useStyles = makeStyles((theme) => ({
  cardItem: {
    background: '#FAFAFA',
    marginBottom: '24px',
    border: '1px solid #e1e1e1ab',
  },
  cardBox: {
    background: '#E6E6E6',
    padding: '12px',
    borderRadius: '4px',
    marginBottom: '8px',
  },
}));

export default SubModuleDetails;
