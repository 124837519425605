import i18n from 'i18next';
import client from '../apiClient';

function getCourseList() {
  return client.get('/user/enroll/course', {
    headers: {
      'Accept-Language': i18n.language
    }
  });
}


function getFreeCourseList() {
  return client.get('/user/enroll/course/allfreecourses', {
    headers: {
      'Accept-Language': i18n.language
    }
  });
}




function getRecomdedCourseList(id) {
  return client.get(`/user/enroll/course/recomendedcourses?userId=${id}`, {
    headers: {
      'Accept-Language': i18n.language
    }
  });
}

function getCertificateList() {
  return client.get('/user/enroll/course/certificationcourses', {
    headers: {
      'Accept-Language': i18n.language
    }
  });
}

function getExcelDetails(data) {
  return client.get(`/user/enroll/course/excelcourses?from=${data}`, {
    headers: {
      'Accept-Language': i18n.language
    }
  });
}

function getOfficeDetails(data) {
  return client.get(`/user/enroll/course/officecourses?from=${data}`, {
    headers: {
      'Accept-Language': i18n.language
    }
  });
}

function getAccFinDetails(data) {
  return client.get(`/user/enroll/course/accountfincourses?from=${data}`, {
    headers: {
      'Accept-Language': i18n.language
    }
  });
}

function getCodingDetails(data) {
  return client.get(`/user/enroll/course/codingcourses?from=${data}`, {
    headers: {
      'Accept-Language': i18n.language
    }
  });
}
function gePowerPointDetails(data) {
  return client.get(`/user/enroll/course/powerpointcourses?from=${data}`, {
    headers: {
      'Accept-Language': i18n.language
    }
  });
}


function getErgCourseDetails() {
  return client.get(`/user/enroll/course/ergcourse`, {
    headers: {
      'Accept-Language': i18n.language
    }
  });
}

function getWordCours(data) {
  return client.get(`/user/enroll/course/wordcourse?from=${data}`, {
    headers: {
      'Accept-Language': i18n.language
    }
  });
}



const getSubscriptionList = () => client.get('/individual/user/subscription',{headers: {
  'Accept-Language': i18n.language
}});
const getAssessmentSubscriptionList = () => client.get('/individual/user/subscription/assessment',{headers: {
  'Accept-Language': i18n.language
}});

const getCourseModulesAndSubmodules = (courseId, planId) =>
  client.get(`/user/module/submodule/topics?courseId=${courseId}&&planId=${planId}`,{headers: {
    'Accept-Language': i18n.language
  }});

const getUserLastCompletedDetails = (courseId) => client.get(`/user/lastCompletedScreen?courseId=${courseId}`);

const getWebsiteIndividualCourses = (frequency) => client.get(`/website/course/individual?frequency=${frequency}`);

const getWebsiteBundleCourses = (frequency) => client.get(`/website/course/bundle?frequency=${frequency}`);
const getCourseCompletionStatus = (courseId,userId) => client.get(`/client/getcoursecompletionDetails?courseId=${courseId}&&userId=${userId}`);
const updateCourseCompletionStatus = (courseId,userId) => client.put(`/client/updateCoursecompletionstatus?courseId=${courseId}&&userId=${userId}`);

const getAssessmentList = (id) => client.get(`/individual/user/getallassessment?id=${id}`);
 


export default {
  getCourseList,
  getFreeCourseList,
  getRecomdedCourseList,
  getCertificateList,
  getExcelDetails,
  getOfficeDetails,
  getAccFinDetails,
  getCodingDetails,
  gePowerPointDetails,
  getErgCourseDetails,
  getSubscriptionList,
  getAssessmentSubscriptionList,
  getCourseModulesAndSubmodules,
  getUserLastCompletedDetails,
  getWebsiteIndividualCourses,
  getWebsiteBundleCourses,
  getCourseCompletionStatus,
  updateCourseCompletionStatus,
  getWordCours,
  getAssessmentList
};
