import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Box, Grid, Typography, Radio, RadioGroup, FormControlLabel, FormControl, Button, LinearProgress, Paper, Breadcrumbs, Link, Tooltip } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { BarChart } from '@mui/x-charts/BarChart';
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import DOMPurify from "dompurify";
import Page from '../../../components/Page';
import QuestionList from './SimulationQuestionList';
import ProgressBar from '../../generalAssessment/GeneralAssessmentProgresBar';
import Rocket from '../../../assets/iqImages/rocket.gif';
import IqStart from '../../../assets/iqImages/iqStart.png';
import ImageView from '../../../assets/Images/ProgressFrame.png'
import adminServices from '../../../services/adminServices';
import LottieLoading from '../../../components/LottieLoading';
import Snackbar from '../../../components/snackbar/snackbar';
import '../../generalAssessment/generalAssessmentStyle.css';

const GeneralAssessment = (props) => {  
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = props?.data?.id;
  const name = props?.data?.title;
  const [questions, setQuestions] = useState('');
  const [allDetails, setAllDetails] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [textview, setText] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [correctAnswerIndex, setCorrectAnswerIndex] = useState(null);
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(null);
  const [buttonClick, setButtonClick] = useState(false);
  const [spaceview, setSpaceView] = useState(true);
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [CloseTime, setCloseTime] = useState('');

  const [allQuestions, setAllQuestions] = useState([]);
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [attemptAssessment, setAttemptAssessment] = useState('');
  
  
  const [correctResponse, setCorrectResponse] = useState(0);
  const [isTimeout, setIsTimeout] = useState(false);
  const [isopenSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarTitle, setSnackbarTitle] = useState('');
  const [startingtime, setStartingTime] = useState('');
  const [answerCount, setAnswerCount] = useState(0);
  const [isSummery, SetIsSummery] = useState(false);
  const [summaryData, setSummaryData] = useState([]);
  const [time, setTime] = useState(0); 
  const [isActive, setIsActive] = useState(false);  
  const [isPaused, setIsPaused] = useState(false); 

  

  
  const sanitizedContent = DOMPurify.sanitize(questions?.length > 0 && questions[currentIndex]?.question_text);

   
  const handleRetry = async(attemptAssessment) => {
    handleBack()
    setCorrectResponse(0)
    setAllQuestions([])
    setCorrectAnswerIndex(null)
    setIsAnswerCorrect(null)
    setProgress(0)
    setText(false)
    // getAssessmentDetails();
    setSpaceView(false)
    setIsActive(true);
    setIsPaused(false); 
    setCurrentIndex(0);

    // await adminServices.deleteAssessmentById(userid,attemptAssessment);
  }

  const getSummaryById = async () => {
    setIsLoading(true);
    // const result = await adminServices.getSummaryById(userid, id);
    // if (result.ok) {
    //   setSummaryData(result.data);
    // }
    setIsLoading(false)

  }



  const handleBack = () => {
    props.handleBack()  
  }
  const handleKeyDown = (event) => {
    event.preventDefault();
    if (event.key === " ") {
      PostSpace();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const getCurrentTime = () => {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');

    return `${hours}:${minutes}:${seconds}`;
  };

  useEffect(() => {
    setStartingTime(Date.now())
    getAssessmentDetails()
  }, [])


  useEffect(() => {
    let timer;
    
    if (spaceview === false) {
      if (isActive && !isPaused && time > 0) {
        timer = setInterval(() => {
          setTime((prevTime) => prevTime - 1);  
        }, 1000);
      }
      return () => clearInterval(timer);
    }
  }, [spaceview, isActive, isPaused, time]);
  


  const startTimer = () => {
    setIsActive(true);
    setIsPaused(false); 
  };
 
  const pauseTimer = () => {
    setIsPaused(true);
  };
 
  const resumeTimer = () => {
    setIsPaused(false);
  };
 
  const resetTimer = () => {
    setIsActive(false);
    setIsPaused(false);
    setTime(allDetails?.time_in_mins); 
  };



  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins} ${mins === 1 ? "Min" : "Mins"} ${secs} ${secs === 1 ? "Second" : "Seconds"}`;
  };

  const formatTimeTimer = (seconds) => {
  
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };


  function CloseTimeCal(time) {
    if (!time) return 0;
    const totalSeconds = (time.hours || 0) * 3600 + (time.minutes || 0) * 60 + (time.seconds || 0);
    return totalSeconds;
  }




  const getAssessmentDetails = async () => {
    setIsLoading(true)
    const result = await adminServices.getAssessmentDetails('11', id);
    if (result.ok) {
      setAllDetails(result?.data)
      setTime(result?.data?.time_in_mins)
      setQuestions(
        result?.data?.questionsDetails
          .sort(() => Math.random() - 0.5)
          .slice(0, 25)
      );
    }
    setIsLoading(false)

  }
  const PostSpace = async () => {

    const starttime = getCurrentTime()
    const data = {
      assessmentId: id,
      starttime: Date.now()
    };
    localStorage.setItem('assessmentData', JSON.stringify(data));
    setStartTime(starttime)
    setSpaceView(false)
    setIsActive(true);
    setIsPaused(false); 
  };

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const calculateCorrectPercentage = (array, questions) => {
    const totalQuestions = questions?.length;
    if (totalQuestions === 0) return 0;
    const correctResponses = array.filter(item => item.response_recorded === "correct").length;
    setCorrectResponse(correctResponses)
    const percentage = (correctResponses / totalQuestions) * 100;
    return percentage;
  };


  function normalizeOptions(options) {
    return options?.map(option => {
      if (typeof option === "string" && option.startsWith("<") && option.endsWith(">")) {
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = option;
        return tempDiv.textContent || tempDiv.innerText || "";
      }
      return option;
    });
  }

  const handleSubmit = async () => {
    if (selectedOption) {
      const currentQuestion = questions[currentIndex];
      const correctAnswers = currentQuestion.options.correctAnswer;
      const cleartext = normalizeOptions(currentQuestion.options.mcqOptions);
      const selectedIndex = cleartext.indexOf(selectedOption);
      const isCorrect = correctAnswers[selectedIndex];

      if (questions[currentIndex]) {
        questions[currentIndex] = {
          ...questions[currentIndex],
          answerCorrect: isCorrect
        };
      }

      setIsAnswerCorrect(isCorrect);
      setCorrectAnswerIndex(correctAnswers.indexOf(true));
      setButtonClick(true)

      const objData = {
        qn_seq_no: allQuestions?.length + 1,
        response_recorded: isCorrect ? "correct" : "incorrect",
        related_question_id: currentQuestion.id,
      };
      const correctPercentage = Math.ceil(calculateCorrectPercentage([...allQuestions, objData], questions));
      setProgress(correctPercentage);
      setAllQuestions((prevQuestions) => [...prevQuestions, objData]);
    } else {
      alert("Please select any option");
    }
  };



  const handleNext = async () => {
    setButtonClick(false);
    resetState();
    if (questions?.length > currentIndex + 1) {
      setCurrentIndex((prev) => prev + 1);
    } else {
      setText(true)
    }
  };



  const resetState = () => {
    setSelectedOption("");
    setIsAnswerCorrect(null);
    setCorrectAnswerIndex(null);
  };




  const sortedData = summaryData?.scores?.sort((a, b) => new Date(a.date) - new Date(b.date));
  const lastFourScores = sortedData?.slice(0, 5).map(item => item.score);
  const xAxisLabels = ["GA1", "Reattempt1", "Reattempt2", "Reattempt3", "Reattempt4"];


  return (
    <Page>
    
     {isLoading ?
          <>
            <div
              style={{
                position: 'absolute',
                width: '100%',
                height: `calc(100vh - 70px)`,
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 999,
              }}
            >
              <LottieLoading loading={isLoading} />
            </div>
          </>
          :
          <>
            <Grid
              container
              spacing={2}
              marginTop="10px"
              width="93%"
              placeItems='flex-start'
              className='generalSection'
              sx={{
                backgroundColor: 'white',
                placeItems: 'flex-start',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                borderRadius: '8px',
                padding: '16px',
                margin: 'auto',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'start',
              }}
            >
              <Grid item style={{ padding: '0', borderRadius: '10px', }} xs={2} spacing={2}>
                <div style={{ padding: '15px', backgroundColor: "#0b1124", width: "200px", borderRadius: '8px' }}>
                  <div style={{}} >
                    <CircularProgressbar
                      value={progress}
                      text={`${progress}%`}
                    />
                  </div>
                  <Box textAlign="center" margin={0}>
                    <Typography style={{ fontSize: '12px', color: '#e9e9e9', paddingTop: '15px', margin: '0px' }}>Correct Answers in Percentage</Typography>
                    {spaceview === false && textview === true && <Box >
                      <Typography gutterBottom style={{ fontSize: '12px', color: '#e9e9e9', paddingTop: '15px' }}>
                        {correctResponse} Correct Answers
                      </Typography>
                      <Typography style={{ fontSize: '12px', color: '#e9e9e9' }} >
                        {allQuestions?.length - correctResponse} Incorrect Answers
                      </Typography>
                    </Box>}
                  </Box>
                  <div>
                    <img src={ImageView} alt="Brain Illustration" />
                  </div>

                </div>
              </Grid>

              <Grid item className='pannelcontent' xs={7} style={{ padding: '0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box style={{ flexDirection: 'column', }}>
                  {spaceview &&
                    <Box
                      sx={{
                        //  width: '90%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >

                      <Box>
                        <Grid xs={12}>
                          <Box  >
                            <Typography variant="h6" className='generalHeading' gutterBottom  paddingLeft={25}>{name||'General Assessment'} 
                            </Typography>
                            <Box className='properAlign'
                              style={{
                                marginTop: "20px",
                                //  width: '500px',
                                width: '80%',
                                margin: 'auto',
                                minHeight: '200px',
                                border: '2px solid orange',
                                padding: '20px',
                                boxSizing: 'border-box'
                              }}
                            >
                              <img src={Rocket} alt="loading" width={60} style={{ margin: 'auto', display: 'block' }} />
                              <Typography variant="h6" gutterBottom textAlign={'center'}> {allDetails?.title ? allDetails?.title : 'Condition Functions'}
                              </Typography>


                              <Typography variant="body1" textAlign={'center'}>
                                Know how much you've learned with skillset IQ. Take your own time. You can always retake it to improve your score.
                              </Typography>
                            </Box>


                          </Box>
                          <Box mt={4}>
                            <Typography variant="h5" align="center" color={'primary'} gutterBottom>
                              Let's begin!
                            </Typography>
                            <Typography style={{
                              background: '#FEFEFF 0% 0% no-repeat padding-box',
                              textAlign: 'center',
                              borderRadius: '6px',
                              boxShadow: '1px 1px 3px #a4a4a4',
                              width: 'max-content',
                              margin: '1rem auto',
                              padding: '8px 16px',
                              fontWeight: '600',
                            }}>Space</Typography>
                            <Typography align="center">Click on the Spacebar on your keyboard to Start</Typography>
                          </Box>
                        </Grid>
                      </Box>

                    </Box>}
                  {spaceview === false && textview === false &&
                    <>
                      <Typography variant="h6" className='generalHeading' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} > {name||'General Assessment'} 
                      </Typography>
                      <Box style={{
                        marginTop: "20px",
                        marginBottom: "20px",
                        minHeight: '350px',
                        border: '1px solid orange',
                        boxSizing: 'border-box',
                        padding:'20px',
                        marginLeft:'25px',
                        width:'500px'
                      }}>
                        <Typography
                          variant="h6"
                          dangerouslySetInnerHTML={{ __html: sanitizedContent }}
                        />
                        <FormControl>
                          <RadioGroup value={selectedOption} onChange={buttonClick === false ? handleChange : ""}>
                            {questions &&
                              questions[currentIndex]?.options &&
                              questions[currentIndex]?.options?.mcqOptions?.length > 0 &&
                              questions[currentIndex]?.options?.mcqOptions.map((option, i) => {
                                const sanitizedOption = DOMPurify.sanitize(option);
                                const optionView = new DOMParser()
                                  .parseFromString(sanitizedOption, "text/html")
                                  .body.textContent || "";
                                let optionColor = "";
                                if (isAnswerCorrect !== null) {
                                  if (i === correctAnswerIndex) {
                                    optionColor = "#00B818";
                                  } else if (optionView === selectedOption) {
                                    optionColor = "red";
                                  }
                                }
                                return (
                                  <FormControlLabel
                                    key={i}
                                    value={optionView}
                                    control={<Radio />}
                                    label={optionView}
                                    style={{ color: optionColor, marginLeft: "20px" }}
                                  />
                                );
                              })}
                          </RadioGroup>
                        </FormControl>

                        <LinearProgress variant="determinate" value={currentIndex / allDetails?.questions_list?.length * 100} sx={{ marginY: 2 }} />

                        {buttonClick === false &&
                          <Button variant="contained" onClick={() => handleSubmit()}>
                            Submit
                          </Button>}
                        {buttonClick === true && (
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '100%',
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: '600',
                                color: isAnswerCorrect ? 'green' : 'red',
                                textAlign: 'center',
                              }}
                            >
                              {isAnswerCorrect ? "Correct Answer!" : "Wrong Answer!"}
                            </Typography>
                          </Box>
                        )}

                        {buttonClick === true &&
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-between',

                            }}
                          >
                            <Box sx={{ mt: 'auto', textAlign: 'right' }}>
                              <Button variant="contained" onClick={() => handleNext()}>
                                Next
                              </Button>
                            </Box>
                          </Box>


                        }
                      </Box></>}
                  {spaceview === false && textview === true &&
                    <>
                      <Typography variant="h6" className='generalHeading' gutterBottom > General Assessment
                      </Typography>
                      <Box

                        sx={{
                          margin: 'auto',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginTop: '55px',
                        }}>

                        <Box className="contentrgb rgb-animate">
                          <Typography variant="h4" sx={{ color: 'black', textAlign: 'center', marginTop: '8px' }}> Congratulations ! </Typography>
                          <Typography sx={{
                            fontWeight: 'normal', color: 'black', textAlign: 'center', padding: "10px", lineHeight: 1.1,
                          }}>
                            Your General Assessment Challenge is Completed
                          </Typography>
                        </Box>
                        <Typography
                          variant="h6"
                          sx={{
                            marginTop: "20px"
                          }}
                        >
                          Your Score is {correctResponse}/{questions && questions?.length}
                        </Typography>

                        <Button sx={{
                          marginTop: '10px',
                          backgroundColor: "#fff",
                          color: "black",
                          border: "1px solid black",
                          borderRadius: "5px",
                          cursor: "pointer",
                          fontSize: "14px",
                          padding: '5px 10px',
                        }}
                          onClick={() => handleRetry(attemptAssessment)}>
                          Back
                        </Button>
                      </Box>
                      <LinearProgress variant="determinate" value={parseInt(Math.ceil(allQuestions?.length / questions[currentIndex]), 10)} sx={{ marginY: 2 }} />
                    </>
                  }
                </Box>
              </Grid>

              <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center' }}>
                <QuestionList question={questions} bestScore={progress} firstScore={progress} handleBack={handleBack} currentIndex={currentIndex} spaceview={spaceview} />
              </Grid>
            </Grid>
          </>}
        
      <Snackbar open={isopenSnackbar} snackbarTitle={snackbarTitle} close={() => setOpenSnackbar(false)} />

    </Page>
  );
};

export default GeneralAssessment;
