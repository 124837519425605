import client from './apiClient';

const postTrialCourseEnrollmentDetails = (data) => client.post(`/individual/user/course`, data);

const createStripeSubscription = (data) => client.post(`/payment/stripe/subscription`, data);

const cancelStripeSubscription = (data) => client.put(`/payment/stripe/subscription`, JSON.stringify(data));

// const postTrialCourseEnrollmentDetails = (data) => client.post(`/individual/user/course`, data);

// const createStripeSubscription = (data) => client.post(`/payment/stripe/subscription`, data);
const createAssessmentSubscription = (data) => client.post(`/payment/stripe/subscription/assessment`, data);

export default {
  postTrialCourseEnrollmentDetails,
  createStripeSubscription,
  cancelStripeSubscription,
  createAssessmentSubscription
};
