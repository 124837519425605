/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { I18nextProvider } from 'react-i18next';
import i18n from '../../../src/website/pages/i18n'; 
import { Container, Grid, Typography, Link, Box, TextField, Hidden, AppBar, Toolbar,Dialog, DialogActions, DialogContent, DialogTitle,Button  } from '@mui/material';
import { Link as RouterLink, useNavigate, useLocation, useSearchParams,useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import Cookies from 'js-cookie';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useDispatch } from 'react-redux';
import jwtdecode from 'jwt-decode';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import BackgroundImg from '../../assets/logo/images/bg.jpg';
import BackgroundImgs from '../../assets/logo/images/Register.png';
import BackgroundImges from '../../assets/logo/images/BackgroundImgs.png';
import BackgroundImges1 from '../../assets/logo/images/backgroundImage1.png';
import BackgroundImges2 from '../../assets/logo/images/backgroundImage2.png';
import BackgroundImges3 from '../../assets/logo/images/backgroundImage3.png';
import BackgroundImges4 from '../../assets/logo/images/backgroundImage4.png';
import BackgroundImges5 from '../../assets/logo/images/backgroundImage5.png';
import Page from '../../components/Page';
import LanguageSwitcher from '../../../src/website/pages/LanguageSwitcher';
import loginServices from '../../services/loginServices';
import SnackBar from '../../components/snackbar/snackbar';
import apiClient from '../../services/apiClient';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import ClearIcon from '@mui/icons-material/Clear';
import { styled } from '@mui/material/styles';
import ThemeProvider from '../../theme';
import Logo from '../../assets/logo/logo.png';
import {
  loginSuccess,
  openSubscriptionModal,
  setSubscribedCourses,
  setOpenSubscriptionModalDetails,
  openSnackbar,
} from '../../store/reducer';
import trialAndStripeSubscriptionService from '../../services/trialAndStripeSubscriptionService';
import courseApi from '../../services/users/courseApi';
const settings = require('../../settings');
// import apiUrl from '../../settings';
export function Timer({ initialTime }) {
  const [time, setTime] = useState(initialTime);

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(prevTime => {
        if (prevTime === 0) {
          clearInterval(timer);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [initialTime]);

  return (
    <Grid item xs={12} md={6} style={{marginTop:"5px"}} >
      <Typography style={{ fontSize: "11px" }}>
        <span></span>You can re-send OTP in {time} sec
      </Typography>
    </Grid>
  );
}

export default function Index() {
  const webClientId = process.env.REACT_APP_CLIENT_ID;
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation(); 
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [changePasswordLoading, setChangePasswordLoading] = useState(false);

  const [forgetloading, setForgetLoading] = useState(false);
  const params = useParams();
  const [forgotOTP, setforgotOTP] = useState(true);
  const [visible, setVisible] = React.useState(false);
  const [resetVisible, setResetVisible] = React.useState(false);
  const [confirmVisible, setConfirmVisible] = React.useState(false);
  

  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [otpVisible, setotpVisible] = useState(false);
  const [password, setPassword] = useState(false);
  const [passwordVisible, setPassrordVisible] = useState(false);
  const [snackbarTitle, setSnackbarTitle] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [disableEmail, setDisableEmail] = useState(false);
  const [announcementVisible, setAnnouncementVisible] = useState(true);
  const [resendOTPText, setResendOTPText] = useState(false);
  const [sentOTPText, setSentOTPText] = useState(false);    
  const [showResendButton, setShowResendButton] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [initialTextVisible, setInitialTextVisible] = useState(true);
  const [authMethod, setAuthMethod] = useState("otp");
  const [forgetClick, setForgetClick] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);  
  const [emailFrom , setEmailFrom] = useState('')
  const [passwordValue, setPasswordValue] = useState('');
  const [confirmPasswordValue, setConfirmPasswordValue] = useState('');
  const [forgetEmail, setForgetEmail] = useState('');
  const [passwordError, setPasswordError] = useState({pasErr:'',conPassErr:''});
  const [isModalOpen, setIsModalOpen] = useState(false);


  



  
  const [selectedValue, setSelectedValue] = useState('');

  useEffect(() => {
    let timeoutId;
    if (otpVisible) {
      timeoutId = setTimeout(() => {
        setInitialTextVisible(false);
        setShowResendButton(true);
      }, 30000);

      timeoutId = setTimeout(() => {
        setShowResendButton(true);
      }, 60000);

      setOtpSent(true);
    }
    return () => clearTimeout(timeoutId);
  }, [otpVisible]);

  const images = [BackgroundImges1, BackgroundImges2, BackgroundImges3, BackgroundImges4, BackgroundImges5];
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    }, 4000); // Change image every 4 seconds
    return () => clearInterval(interval);
  }, []);

  const generateOTP = async (values) => {
    setLoading(true);
    const data = {
      emailAddress: values.email,
    };

    const response = await loginServices.generateOTP(data);    
    if (response.ok) {
      setSnackbarTitle(response.data);
      setOpenSnackbar(true);
      setotpVisible(true)
      setDisableEmail(true)
    } else {
      setLoading(true);
      setSnackbarTitle(response.data?.message?response.data.message:response.problem);
      setOpenSnackbar(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const emailFromUrl = queryParams.get('email'); 
    if (emailFromUrl) {
      setEmailFrom(decodeURIComponent(emailFromUrl));  
    } else {
      console.log('Email query parameter is missing');
    }
  }, []);

  const handleSubmitForm = async (values) => {    
    setLoading(true);
    const data = {
      emailAddress: values.email,
      password: values.password?values.password:''
    };

    const response = await loginServices.userLogin(data);
    if (response.ok) {
      apiClient.setHeader('Authorization', `BEARER ${response.data.token}`);
      Cookies.set('token', response.data.token);
      Cookies.set('refreshToken', response.data.refreshToken);
      Cookies.set('isLogIn', true);
      const decoded = await jwtdecode(response.data.token);
      Cookies.set('tokenExpireTime', decoded.exp);
      await getLoginUserDetails();
    } else if (!response.ok) {
      
      setLoading(true);
      if (response?.data?.message) {
        if (typeof response.data.message === 'object') {
          setSnackbarTitle(response.data.message.password);
        } else {
          if (response.data.message.includes('You have not verified your email ID. Please verify before Login!')) {
            setSentOTPText(true);
          }   
          setSnackbarTitle(response.data.message);

          // dispatch(openSnackbar(response.data.message));
        }
      } else {
        setSnackbarTitle('Something went wrong, Please try again.');
      }
      setOpenSnackbar(true);
    }
    setLoading(false);
  };

  const postTrialCourseDetails = async (planId,enrollmentType,courseId) => {
    try {      
      const res = await trialAndStripeSubscriptionService.postTrialCourseEnrollmentDetails(
        JSON.stringify({ planId: planId,enrollmentType:enrollmentType,courseId:courseId})
      );
      if (res.ok) {
        await getCourserList();
        dispatch(openSnackbar('Trial Course Subscribed Successfully.'));
        navigate('/auth/my-courses');
      } else {
        if (res?.data?.message) {
          dispatch(openSnackbar(res.data.message));
        } else {
          dispatch(openSnackbar('Failed to Subscribe Trial Course, Please try again.'));
        }
        navigate('/auth/my-courses');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCourserList = async () => {
    try {
      const response = await courseApi.getCourseList();
      if (response.ok) {
        dispatch(setSubscribedCourses(response.data));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const postSubscribeDetails = async (planId) => {
    dispatch(setOpenSubscriptionModalDetails(location?.state?.openSubscriptionModalDetails));
    dispatch(openSubscriptionModal(planId));
    navigate('/auth/subscribe', { replace: true });
    // window.location.href = `${apiUrl}/auth/my-courses`;
    // window.location.reload();
  };

  const getLoginUserDetails = async () => {
    try {
      const response3 = await loginServices.getUserInfo();
      if (response3.ok) {
        dispatch(loginSuccess(response3.data));
        if (response3.data.role === 'CONTENT_WRITER' || response3.data.role === 'AUTH_USER') {
          navigate('/app/course', { replace: true });
        } else if (response3.data.role === 'USER_DTC') {
          await getCourserList();
          if (location?.state?.from === 'trialButton') {
            await postTrialCourseDetails(location?.state?.planId);
          } else if (location?.state?.from === 'subscribeButton') {
            await postSubscribeDetails(location?.state?.planId);
          } else {
            if (searchParams.get('isSubscribe')) {
              navigate('/auth/subscribe', { replace: true });
            } else {
              navigate('/auth/subscribe', { replace: true });
            }
          }
        } else {
          navigate('/app/dashboard', { replace: true });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickShowPassword = () => {
    setVisible(!visible);
  };
  const handleClickResetPassword = () => {
    setResetVisible(!resetVisible);
  };
  const handleClickConfirmPassword = () => {
    setConfirmVisible(!confirmVisible);
  };

  
  // Function to handle edit icon click
  const handleEditClick = () => {
    setotpVisible(false);
  };


  const forgetPassword = async () => {
    setForgetClick(true)
  }
  const SendEmailVerification = async (email) =>{
    try {
      const res = await loginServices.sendEmail(email);
      if (res.ok) {
     setOpenSnackbar(true)
     setSnackbarTitle(res.data)
      } 
       

      
    } catch (error) {
      setForgetLoading(false)
      console.log(error);
    }
  }
  const backToLogin = () => {
    setSendEmail(false)
    setForgetClick(false)
  }

  const ResetPasswordClick = async (email) => {
    setForgetLoading(true)
    try {
      const res = await loginServices.forgetPassword(email);
      if (res.ok) {
        setForgetEmail(res.data?.emailId)
        setSendEmail(true)
        setForgetLoading(false)
      } else {
        if (res?.data?.message) {
          setOpenSnackbar(true);
          setSnackbarTitle(res.data.message)
          // dispatch(openSnackbar(res.data.message));
        } else {
          setOpenSnackbar(true);
          setSnackbarTitle(res.data.message)
          // dispatch(openSnackbar('Failed to send mail.'));
        }
        setForgetLoading(false)

      }
    } catch (error) {
      setForgetLoading(false)
      console.log(error);
    }


  }


  const validate = () => {
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[A-Z])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;

    if (!passwordValue && !confirmPasswordValue) {
      setPasswordError({
        pasErr: 'New password is required',
        conPassErr: 'Confirm password is required'
      });
    }
    else if (!passwordValue) {
      setPasswordError({
        pasErr: 'New password is required'
      });
    }
    else if (!confirmPasswordValue) {
      setPasswordError({
        conPassErr: 'Confirm password is required'
      });
    }
    else if (!passwordRegex.test(passwordValue)) {
      setPasswordError({
        pasErr: 'Password should contain minimum of 8 characters, ONE uppercase letter, ONE lowercase letter, ONE number, and ONE special character from @#$%^_-'
      });
    }
    else if (passwordValue !== confirmPasswordValue) {
      setPasswordError({
        pasErr: 'Passwords do not match',
        conPassErr: 'Passwords do not match'
      });
    } else {
      return true
    }
  };

  const ChangePassword = async () => {
    let data = {
      email: emailFrom,
      password: passwordValue
    }
    try {
      setChangePasswordLoading(true)
      const res = await loginServices.PasswordChange(data);
      console.log(res, 'res');

      if (res.ok) {
        setChangePasswordLoading(false)
        setIsModalOpen(true)
      } else {
        setChangePasswordLoading(false)
        if (res?.data?.error) {
          setOpenSnackbar(true);
          setSnackbarTitle(res?.data?.error)

        } else {
          dispatch(openSnackbar('Failed to Re-set password.'));
        }
      }
    } catch (error) {
      console.log(error);
    }


  }
  const handleChangePassword = async () => {
    let validation = validate()
    if (validation === true) {
      ChangePassword()
    }
  }
  const closeModal = () => {
    setIsModalOpen(false);
    setForgetClick(false)
    navigate('/login')
    setEmailFrom('')
  };

  return (
    <Box className={classes.mainContainer}>
      {process.env.REACT_APP_ENV === 'dev' && <LanguageSwitcher />}
      <Container maxWidth="lg">
        <Grid container spacing={2} alignItems="stretch">
          <Grid item xs={12} md={7} >
            <Carousel autoPlay
              interval={7000}
              showArrows={false}
              showStatus={false}
              axis='horizontal'
              infinitegop
              showThumbs={false}
              showIndicators={false}
              className={classes.carouselContainer}
            >
              {images.map((image, idx) => (
                <div key={idx}>
                  <img className={classes.slideImg} alt={`Image ${idx + 1}`} src={image} style={{ width: '100%', height: '540px' }} />
                </div>
              ))}
            </Carousel>
          </Grid>

          <>
            {!emailFrom && <Grid item xs={12} md={5}>
              <Box className={classes.formcontainer}>
                <Formik
                  initialValues={{
                    email: '',
                    password: '',
                  }}
                  validationSchema={Yup.object().shape({
                    email: Yup.string().required('Email is required').email('Invalid email format'),
                    ...(forgetClick
                      ? {}
                      : {
                        password: Yup.string().required('Password is required'),
                      }),
                  })}
                  onSubmit={(values) => {
                    console.log('inside submit');
                    if (!forgetClick) {
                      console.log('inside if');
                      handleSubmitForm(values)
                    }
                    else {
                      console.log('inside else');
                      ResetPasswordClick(values)
                    }

                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    touched,
                    values,
                  }) => (
                    <Form>
                      <Grid container spacing={2} sx={{paddingLeft: "17px"}}>                     
                        {!forgetClick ? <Grid container spacing={2}>
                          <Grid item xs="12">
                            <Typography color="#000000" className={classes.login} align="left" variant="h4">
                              {t('login_to_keySkillset')}
                            </Typography>
                          </Grid>

                          <Grid item xs={12}>
                            <TextField
                              // sx={{
                              //   maxWidth:"500px"
                              // }}
                              // className={classes.emailTextfield}
                              fullWidth
                              // label="Register Email ID"
                              placeholder={t('register_email')}
                              name="email"
                              variant="outlined"
                              color="success"
                              // onBlur={handleBlur}
                              onChange={(e) => {
                                setFieldValue('email', e.target.value.trim());
                                // setFieldValue('password', '');
                              }}
                              error={Boolean(touched.email && errors.email)}
                              helperText={touched.email && errors.email}
                              value={values.email}
                              disabled={disableEmail}
                              InputProps={{
                                endAdornment: (
                                  <IconButton onClick={() => setDisableEmail(false)}>
                                    <EditIcon
                                      fontSize="small"
                                      style={{ color: '#BCBCBC' }}
                                      onClick={() => {
                                        handleEditClick();
                                      }}
                                    />
                                  </IconButton>
                                ),
                              }}
                            />

                          </Grid>

                          <Grid item xs="12">
                            {/* <Typography className={classes.email} variant="subtitle1">
                            OTP
                          </Typography> */}
                            <TextField
                              fullWidth
                              sx={{
                                marginTop: "10px"
                              }}
                              name="password"
                              variant="outlined"
                              color="success"
                              // label="OTP"
                              placeholder='Enter Password'
                              type={visible ? 'text' : 'password'}
                              onBlur={handleBlur}
                              onChange={(e) => {
                                setFieldValue('password', e.target.value.trim());
                              }}
                              error={Boolean(touched.password && errors.password)}
                              helperText={touched.password && errors.password}
                              value={values.password}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    {visible ? (
                                      <VisibilityIcon
                                        className={classes.visibleIcon}
                                        onClick={handleClickShowPassword}
                                      />
                                    ) : (
                                      <VisibilityOffIcon
                                        className={classes.visibleIcon}
                                        onClick={handleClickShowPassword}
                                      />
                                    )}
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>

                          <Box sx={{ marginTop: "15px", marginLeft: '16px' }}>
                          </Box>
                          <Grid item xs={12}>
                            <Grid container spacing={2} columns={{ xs: 4, md: 12 }} sx={{ marginTop: '3px' }} >

                              <Grid item xs={6} md={3} container justifyContent="flex-start">
                                <LoadingButton
                                  size="medium"
                                  type="submit"
                                  variant="contained"
                                  loading={loading}
                                  sx={{ backgroundColor: '#D52F37', borderRadius: '5px' }}
                                >
                                  {t('login')}
                                </LoadingButton>
                              </Grid>
                              
                            {!sentOTPText && <Grid item xs={6} md={3} container justifyContent="flex-start">
                                <Typography
                                  className={classes.resendotpbutton}
                                  onClick={() => {
                                    forgetPassword();
                                  }}
                                  onMouseLeave={(e) => {
                                    e.currentTarget.style.backgroundColor = 'transparent';
                                  }}
                                  style={{ whiteSpace: 'nowrap' }}
                                >
                                  Forgot password?
                                </Typography>
                              </Grid>}
                              {sentOTPText &&<Grid item xs={6} md={3} container justifyContent="flex-start">
                                <Typography
                                  className={classes.resendotpbutton}
                                  onClick={() => {
                                    SendEmailVerification(values);
                                  }}
                                  onMouseLeave={(e) => {
                                    e.currentTarget.style.backgroundColor = 'transparent';
                                  }}
                                  style={{ whiteSpace: 'nowrap' }}
                                >
                                  Verify Email
                                </Typography>
                              </Grid>}
                            </Grid>
                          </Grid>
                          <Grid item xs={12} md={12} className={classes.otpalert}  >
                            <Alert
                              severity="info"
                              sx={{
                                fontSize: "10px",
                                color: '#4B8EFD',
                                p: 0, // Padding
                                m: 1, // Margin
                              }}
                            >{t('seamless_login_message')}</Alert>
                          </Grid>
                        </Grid> :
                          <> {!sendEmail && <Grid container spacing={2}>
                            <Grid item xs="12">
                              <Typography color="#000000" className={classes.login} align="left" variant="h4">
                                {t('forget_password_keySkillset')}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                fullWidth
                                placeholder={t('register_email')}
                                name="email"
                                variant="outlined"
                                color="success"
                                onChange={(e) => {
                                  setFieldValue('email', e.target.value.trim());
                                }}
                                error={Boolean(touched.email && errors.email)}
                                helperText={touched.email && errors.email}
                                value={values.email}
                                disabled={disableEmail}
                                InputProps={{
                                  endAdornment: (
                                    <IconButton onClick={() => setDisableEmail(false)}>
                                      <EditIcon
                                        fontSize="small"
                                        style={{ color: '#BCBCBC' }}
                                        onClick={() => {
                                          handleEditClick();
                                        }}
                                      />
                                    </IconButton>
                                  ),
                          }}
                        />

                      </Grid>
                      <Grid item xs={12} md={6} container justifyContent="flex-start" marginTop={3} marginBottom={5}>
                            <LoadingButton
                              size="medium"
                              type="submit"
                              variant="contained"
                              loading={forgetloading}
                              sx={{ backgroundColor: '#D52F37', borderRadius: '5px' }}
                              // onClick={ResetPasswordClick}
                            >
                              {t('reset_password')}
                            </LoadingButton>
                          </Grid>
                      </Grid>} 
                      {sendEmail&&<Grid container spacing={2}>
                      <Grid item xs="12">
                        <Typography color="#000000" className={classes.login} align="center" variant="h4"  marginTop={5}>
                          {t('email_sent')}
                        </Typography>
                      </Grid>
                      <Grid xs={12} className={classes.whiteBorder}>
                        <span>  </span>
                      </Grid>
                     <Grid>
                     <Typography color="#000000"  align="center" marginTop={2} >
                     {t('email_context1')}{''}{forgetEmail}{''} {t('email_context2')}
                        </Typography>
                     </Grid>
                            <Grid>
                              <Typography color="#000000" align="center"  marginTop={3}>
                                {t('email_failed')}{' '}
                                <a href="mailto:contact@keyskillset.com" style={{ fontWeight: 'bold', color: '#000000' }}>
                                  contact@keyskillset.com
                                </a>

                              </Typography>
                            </Grid>
                            <Grid container spacing={2} justifyContent="center" alignItems="center" marginTop={3} marginBottom={5}>
                              <Grid item xs={12} md={6} justifyContent="center" display="flex">
                                <LoadingButton
                                  size="medium"
                                  // type="submit"
                                  variant="contained"
                                  loading={loading}
                                  sx={{ backgroundColor: '#696764', borderRadius: '5px' }}
                                  onClick={() => {
                                    setSendEmail(false)
                                  }}
                                >
                                  {t('try_again')}
                                </LoadingButton>
                              </Grid>
                              <Grid item xs={12} md={6} display="flex">
                                <LoadingButton
                                  size="medium"
                                  type="submit"
                                  variant="contained"
                                  loading={loading}
                                  sx={{ backgroundColor: '#D52F37', borderRadius: '5px' }}
                                  onClick={backToLogin} 
                                >
                                  {t('back_to_login')}
                                </LoadingButton>
                              </Grid>
                            </Grid>
                      </Grid>} 
                      </> }

                   {!sendEmail&& 
                   <>
                   <Grid xs={12} className={classes.whiteBorder}>
                        <span>  </span>
                      </Grid>
                      <Grid item xs="12">
                        <Typography className={classes.email}>
                          {t('dont_have_account')}
                          <RouterLink
                            to={{
                              pathname: '/sign-up',
                              // search: '?isSubscribe=true',
                            }}
                            // state={location.state}
                            className={classes.signup}
                          >
                            {t('reg_now')}
                          </RouterLink>
                        </Typography>
                      </Grid>
                      </>}
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Box>
          </Grid>}
          
          {emailFrom&&
          <Grid item xs={12} md={5}>
            <Box className={classes.formcontainer}>
            <Grid container spacing={3} >
            <Grid item xs="12">
            <Typography color="#000000" className={classes.login} align="left" variant="h4">
                     {t('password_reset')}
                        </Typography>
                     </Grid>
                    <Grid item xs="12">
                      <TextField
                        fullWidth
                        sx={{
                          marginTop: "10px"
                        }}
                        name=" Enter Password"
                        variant="outlined"
                        color="success"
                        placeholder="Enter New Password"
                        type={resetVisible ? 'text' : 'password'}
                        onChange={(e) => {
                          setPasswordValue(e.target.value.trim());
                          setPasswordError({ pasErr: '' });
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickResetPassword}
                              >
                                {resetVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        value={passwordValue}
                        helperText={passwordError.pasErr && passwordError.pasErr}
                        FormHelperTextProps={{
                          sx: {
                            color: 'red', // Sets the color of the helper text to red
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs="12">
                      <TextField
                        fullWidth
                        sx={{
                          marginTop: "10px"
                        }}
                        name="confirmPassword"
                        variant="outlined"
                        color="success"
                        placeholder="Re-Enter New Password"
                        type={confirmVisible ? 'text' : 'password'}
                        onChange={(e) => {
                          setConfirmPasswordValue(e.target.value.trim());
                          setPasswordError({ conPassErr: '' });
                        }}
                        value={confirmPasswordValue}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickConfirmPassword}
                              >
                                {confirmVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        helperText={passwordError.conPassErr && passwordError.conPassErr}
                        FormHelperTextProps={{
                          sx: {
                            color: 'red', // Sets the color of the helper text to red
                          }
                        }}
                      />
                    </Grid>

                      <Grid item xs={12} md={6} container justifyContent="flex-start">
                            <LoadingButton
                              size="medium"
                              type="submit"
                              variant="contained"
                              loading={changePasswordLoading}
                              sx={{ backgroundColor: '#D52F37', borderRadius: '5px' }}
                              onClick={handleChangePassword}
                            >
                              {t('password_change')}
                            </LoadingButton>
                          </Grid>
                          </Grid>
                          <>
                   <Grid xs={12} className={classes.whiteBorder} marginTop={3}>
                        <span>  </span>
                      </Grid>
                      <Grid item xs="12">
                        <Typography className={classes.email} marginTop={3}>
                          {t('dont_have_account')}
                          <RouterLink
                            to={{
                              pathname: '/sign-up',
                            }}
                            className={classes.signup}
                          >
                            {t('reg_now')}
                          </RouterLink>
                        </Typography>
                      </Grid>
                      </>
                      </Box>
                      </Grid>
            }</>
        </Grid>

        <Dialog open={isModalOpen} onClose={closeModal}>
          <DialogTitle>Password Changed!</DialogTitle>
          <DialogContent>
            <p>You have successfully changed your password.</p>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              size="medium"
              type="submit"
              variant="contained"
              loading={changePasswordLoading}
              sx={{ backgroundColor: '#D52F37', borderRadius: '5px' }}
              onClick={closeModal}
            >
              Go back To Login
            </LoadingButton>

          </DialogActions>
        </Dialog>
      </Container>
      {/* <SnackBar open={false} snackbarTitle={''} close={() => {}} /> */}
      <SnackBar open={openSnackbar} snackbarTitle={snackbarTitle} close={() => setOpenSnackbar(false)} />
    </Box>
  );
}
const useStyles = makeStyles((theme) => ({
  Maxheight: {
    height: '100vh',

    // [theme.breakpoints.up('xl')]: {
    //   height: '71.2vh',
    // },

    [theme.breakpoints.down('lg')]: {
      height: '100vh !important',
    },
    [theme.breakpoints.down('md')]: {
      height: '100vh',
    },
  },
  containerMaxWidth: {
    maxWidth: '100%', 
    // width: '100% !important',
    padding: '0px',
    margin: '0 auto',
  },
  // mainContainer:{
  //   display:"flex",
  //   justifyContent:"flex-start",
  //   padding:"30px 50px",
  //   '@media (max-width: 767px)': {
  //     // height: '240px',
  //     // marginTop: '70px',
  //     // display:"flex",
  //     // padding: "20px 30px",
  //     padding: "20px 0",
  //   },
  // },
  mainContainer: {
    padding: "30px 0",
    background: "#F0F0F0",
    height: '90vh',
    marginTop: "4rem",
    '@media (max-width: 767px)': {
      padding: "20px 0",
    },
  },
  containerWrapper: {
    display: "grid",
    gridTemplateColumns: "repeat(2,1fr)",
  },
  // formcontainer:{
  //   background:"#FFFFFF",
  //   //  width:"600px",
  //   //  margin:"10px",
  //   flexGrow:1,
  //   '@media (max-width: 767px)': {
  //     // height: '240px',
  //     // marginTop: '70px',
  //     display:"flex",
  //   },

  // },
  formcontainer: {
    background: "#FFFFFF",
    padding: '36px',
    borderRadius: "20px",
    marginTop: "4px",
    // minWidth:"509px",
    minHeight: "532px",
    [theme.breakpoints.down('sm')]: {
      padding: '20px',
    },
  },
  generateOTP:
  {
    width: '70px',
    height: "35px"
  },
  dotColorS: {
    background: '#00B673',
    height: '6px',
    width: '6px',
    borderRadius: '50%',
    display: 'inline-block',
    margin: '6px 0px 0px 0px',
  },
  dotColor: {
    background: '#00B673',
    height: '6px',
    width: '6px',
    borderRadius: '50%',
    display: 'inline-block',
    margin: '6px 0px 0px 0px',
  },
  listNameWidth: {
    color: '#FFFFFF',
    fontSize: '0.9rem',
    fontWeight: '300',
    padding: '0px 0px 0px 8px',
    maxWidth: '85%',
  },
  listName: {
    color: '#FFFFFF',
    fontSize: '0.9rem',
    fontWeight: '300',
    padding: '0px 0px 0px 8px',
    maxWidth: '82%',

    // marginTop: '1rem',
  },
  mainContents: {
    marginTop: '3rem',
    [theme.breakpoints.down('md')]: {
      marginTop: '1.5rem',
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  titleName: {
    color: '#FFB880',
    fontSize: '1rem',
    fontWeight: '400',
    [theme.breakpoints.up('xl')]: {
      fontSize: '1.5rem',
      fontWeight: '400',
    },
  },
  imgContent: {
    background: '#0000001f',
    padding: '3rem 2rem 0rem 2rem',
    // opacity: 0.5,
    [theme.breakpoints.down('md')]: {
      padding: '2rem 2rem 0rem 2rem',
    },
  },
  boxContainer: {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    minHeight: "532px",
    marginTop: "15px",
    borderRadius: "20px",
    animationName: '$slideInRight',
    animationDuration: '1s',
    animationFillMode: 'forwards',
    // display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'center',
    '@media (max-width: 767px)': {
      height: '240px',
      marginTop: '70px'
    },
  },
  gridContainer: {
    display: ' flex',
    justifyContent: 'end',
    background: '#fff',
    borderRadius: '8px',
    boxShadow: '0px 3px 6px #00000029',
    [theme.breakpoints.down('md')]: {
      marginTop: '13rem',
    },
  },
  orContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(2, 0), // Adjust the margin as needed
  },
  line: {
    flex: 1,
    borderTop: `1px solid ${theme.palette.text.secondary}`,
  },
  orText: {
    margin: theme.spacing(0, 2), // Adjust the margin as needed
    color: theme.palette.text.secondary,
    fontSize: '0.8rem', // Adjust the font size as needed
  },
  grid: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  form: {
    paddingBottom: '2rem',
    paddingTop: '2rem',
  },
  divider: {
    height: '2px',
    width: '145px',
    backgroundColor: 'black',
  },
  // keySkillsetLogo: {
  //   position: 'fixed',
  //   left: '2rem',
  //   top: '2rem',
  // },
  ok: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  email: {
    padding: '2px',
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: "400"
  },
  visibleIcon: {
    color: 'grey',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  signup: {
    color: "#D52F37"
  },
  otpalert: {
    borderRadius: "5px",
    padding: "0px",
    marginTop: '10px',
  },
  resendotpbutton: {
    color: "#D52F37",
    textAlign: 'center',
    fontSize: "14px",
    width: "100px",
    height: "34px",
    marginTop: "2px",
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    borderRadius: "5px",
    padding: "5px",
  },
  login: {
    marginBottom: theme.spacing(2),
    '@media (max-width: 500px)' : {
      marginBottom: '0px',
      marginTop: '20px'
    }
  },
  // form: {
  //   paddingBottom: '2rem',
  //   paddingTop: '2rem',
  // },
  resendotp_text: {
    color: "#02AC19",
    fontSize: "12px"
  },
  container: {
    maxWidth: '1536px',
    '@media (min-width: 601px)': {
      padding: '0px 2rem',
    },

    '@media (min-width: 1201px)': {
      padding: ' 0px 2rem',
    },
    '@media (max-width: 600px)': {
      padding: '0px 1rem',
    },
  },
  carouselContainer: {
    backgroundColor: 'transparent',
    maxWidth: '100%',
    margin: 'auto',
  },
  whiteBorder:{
    background:"whitesmoke",
    marginTop:'20px',
    height:"1px",
    padding:'1px',
    borderRadius:'5px',
    marginLeft:'15px'
  },
  slideImg: {
    height: '540px',
    '@media (max-width: 540px)': {
      maxHeight: '250px',
    },
  }
}));
