import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack, Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
import { Sidebar, Menu, MenuItem, SubMenu, useProSidebar } from 'react-pro-sidebar';
import { useTranslation } from 'react-i18next';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'; // hooks
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import useResponsive from '../../hooks/useResponsive';
// components
// import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
//
import navConfig from './NavConfig';
import Logo from '../../assets/logo/logo.png';
import './style.css';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 270;
const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    // width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[400],
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

const SideNavBar = ({ item }) => {

  const { t } = useTranslation('nav'); 
  const { pathname } = useLocation();
  const { title, path, hrefLink, icon, info, children } = item;

  console.log(children);

  if (children) {
    return (
      <SubMenu
        label={t(title)}
        rootStyles={{
          color: '#fff',
          backgroundColor: '#000e3b',
        }}
        icon={icon}
      >
        {children.map((item) => {
          const { title, path } = item;
          return (
            <MenuItem
              // icon={icon}
              active={path === pathname}
              component={<RouterLink to={path} />}
              rootStyles={{
                color: '#fff',
              }}
            >
              {' '}
              {t(title)}{' '}
            </MenuItem>
          );
        })}
      </SubMenu>
    );
  }

  return (
    <MenuItem
      component={<RouterLink to={path} />}
      active={path === pathname}
      icon={icon}
      rootStyles={{
        color: '#fff',
      }}
    >
      {t(title)}
    </MenuItem>
  );
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const { collapseSidebar, collapsed } = useProSidebar();
  const isDesktop = useResponsive('up', 'lg');
  const [layoutType, setLayoutType] = useState([]);
  const userInfo = useSelector((state) => state.userInfo);

  useEffect(() => {
    if (userInfo && userInfo.role === 'SUPER_ADMIN') {
      setLayoutType(navConfig.adminNavConfig);
    } 
    
    else if(userInfo && userInfo.role === 'CLIENT_ADMIN') {
      setLayoutType(navConfig.contentNavConfig);
    }
      else {
      setLayoutType(navConfig.clientNavConfig);
    }
  }, [userInfo]);

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box p={2} m={'auto'} mt={4} mb={3}>
        <img src={Logo} alt="logo" width="145" style={{ margin: 'auto' }} />
      </Box>

      <NavSection navConfig={layoutType} />
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ padding: '16px !important' }}>
        <Typography color="#fff" variant="body2">
          Powered by
        </Typography>
        <Typography color="#fff" variant="subtitle1">
          Hotkeys Holding LLC
        </Typography>
      </Box>
    </Scrollbar>
  );

  return (
    <>
      {/* {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH, bgcolor: '#000E3B' },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: '#000E3B',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      )} */}

      {isDesktop && (
        <Sidebar
          backgroundColor="#000e3b"
          style={{
            position: 'fixed',
            height: '100vh',
            zIndex: 1100,
          }}
          width="270px"
          collapsedWidth={isDesktop ? '74px' : '0px'}
        >
          <Box p={2} m={'auto'} mt={4} mb={3}>
            <img src={Logo} alt="logo" width={!collapsed ? '145' : '56px'} style={{ margin: 'auto' }} />
          </Box>
          <Menu>
            {layoutType.map((item) => (
              <SideNavBar item={item} />
            ))}
          </Menu>

          <Box sx={{ flexGrow: 1 }} />

          {!collapsed && (
            <Box sx={{ padding: '8px 16px !important' }}>
              <Typography color="#fff" variant="body2">
                Powered by
              </Typography>
              <Typography color="#fff" variant="subtitle1">
                Hotkeys Holding LLC
              </Typography>
            </Box>
          )}

          <Box
            sx={{
              padding: '8px 16px !important',
              display: 'flex',
              justifyContent: collapsed ? 'center' : 'none',
              '&:hover': {
                backgroundColor: '#FE7000',
                cursor: 'pointer',
              },
            }}
            onClick={() => collapseSidebar()}
          >
            <>
              {!collapsed ? (
                <KeyboardDoubleArrowLeftIcon sx={{ paddingRight: '8px', color: '#fff' }} />
              ) : (
                // <Tooltip
                //   title="Toggle sidebar"
                //   style={{
                //     zIndex: '9999',
                //   }}
                // >
                <KeyboardDoubleArrowRightIcon sx={{ paddingRight: '8px', color: '#fff' }} />
                // </Tooltip>
              )}
            </>

            {!collapsed && (
              <Typography variant="body2" color="#fff">
                Collapse sidebar
              </Typography>
            )}
          </Box>
        </Sidebar>
      )}

      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH, bgcolor: '#000E3B' },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </>
  );
}
