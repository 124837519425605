import React, { useState, useEffect } from "react";
import {
     Grid, Typography, Container, Button, IconButton, TextField,
    InputAdornment, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Paper, TablePagination,Switch 
} from '@mui/material';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import moment from "moment";
import lottie from 'lottie-web';
import Page from '../../../components/Page';
import PageHeader from '../../../components/PageHeader';
import adminServices from '../../../services/adminServices';
import SnackBar from '../../../components/snackbar/snackbar';
import GenericAssessment from "./GenericAssessmentadd";
import EditGenericAssessment from './EditgenericAssessment'
import DeleteAlert from '../../../components/modal/DeleteModal';
import lottieData from '../../../assets/lottie/lottie-loading.json';
import AssessmentSimulation from './GeneralAssessmentSimulation';

const Assessment = () => {
    const [openCreateMcq, setOpenCreateMcq] = useState(false);
    const [keyObjects, setKeyObjects] = useState([]);
    const [snackbarTitle, setSnackbarTitle] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    // const loading = useRef();
    const [searchedDetails, setSearchedDetails] = useState('');
    const [openAssessment, setOpenAssessment] = useState(false);
    const [openAssessmentEdit, setOpenAssessmentEdit] = useState(false);
    const [alldata, setData] = useState('');
    const [deleteAlert, setDeleteAlert] = useState(false);
    const [deleteModalTitle, setDeleteModalTitle] = useState('');
    const [deleteId, setDeleteId] = useState('');
    const [simulationData, setSimulationData] = useState('');

    const [lottieLoading, setLottieLoading] = useState(true);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [assessmentDetails, setAssessmentDetails] = useState([]);
    const [simulationStart, setSimulationStart] = useState(false);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

     const handleBack = () =>{
        setSimulationStart(false)
     }
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        getAssementList();
    }, [searchedDetails, page, rowsPerPage]);

    const RefreshDetails = () => {
        getAssementList();
    }

    const getAssementList = async () => {
        const category = "general";
        try {
            const response = await adminServices.getGenericAssessment(searchedDetails, category, page, rowsPerPage);
            if (response.ok) {
                setAssessmentDetails(response.data);
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleAssessmentClick = () => {
        setOpenAssessment(true);
    };

    const handleCloseAssessment = () => {
        setOpenAssessment(false);
    };
    const handleCloseAssessmentEdit = () => {
        setOpenAssessmentEdit(false);
        setData('')
    };


    const handleEdit = (data) => {
        setOpenAssessmentEdit(true)
        setData(data)
    }

    const handleDelete = (data) => {
        setDeleteAlert(true);
        setDeleteId(data.id);
        setDeleteModalTitle('Are you sure you want delete this assessment?');
    }
     const handleSimulation= (data) =>{
        setSimulationStart(true)
        setSimulationData(data)
        console.log(data,'3333333333333333333');
     }

    const handleDeleteGeneralAssessment = async () => {
        try {
            const response = await adminServices.deleteGenericAssessment(deleteId);
            if (response.ok) {
                setSnackbarTitle('Assignment deleted successfully');
                setOpenSnackbar(true);
                getAssementList();
                setDeleteAlert(false)
                setDeleteId('')
                setDeleteModalTitle("");
            }
        } catch (error) {
            console.log(error);
        }
    }

const handleToggleButton  = async(id,value) =>{
     try {
        const resulte = await adminServices.UpdateGeneralAssesmentStatus(id,value);
        if (resulte.ok) {
            setSnackbarTitle('Assignment status updated successfully');
            setOpenSnackbar(true);
            getAssementList();
            setDeleteId('')
            setDeleteModalTitle("");
        }
     } catch (error) {
        console.log(error,'error');
     }

};

    return (

        <>


{!simulationStart? 
  <>
            <Page title="General Assessment">
                <PageHeader pageTitle="General Assessment" submodule="submodule" />
                <Container maxWidth={false} sx={{ padding: '0 !important' }}>
              
                    <Grid container spacing={2}>
                        <Grid align="end" item xs={12}>
                            <TextField
                                variant="outlined"
                                placeholder="Search"
                                id="searchassessment"
                                size="small"
                                sx={{ width: '250px', height: '40px' }}
                                value={searchedDetails}
                                onChange={(e) => setSearchedDetails(e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                    sx: { height: '100%' },
                                }}
                            />
                            <Button
                                style={{ marginLeft: '15px', height: '40px' }}
                                id="createassessmentButtongeneral"
                                variant="contained"
                                color="primary"
                                onClick={handleAssessmentClick}
                            >
                                Create Assessment
                            </Button>
                        </Grid>
                    </Grid>

                </Container>   

                <GenericAssessment
                    open={openAssessment}
                    onClose={handleCloseAssessment}
                    callBack={RefreshDetails}
                />
                <EditGenericAssessment
                    open={openAssessmentEdit}
                    onClose={handleCloseAssessmentEdit}
                    data={alldata}
                    callBack={RefreshDetails}
                />
                <DeleteAlert
                    open={deleteAlert}
                    title={deleteModalTitle}
                    confirm={handleDeleteGeneralAssessment}
                    close={() => setDeleteAlert(false)}
                />
            </Page>
            <SnackBar open={openSnackbar} snackbarTitle={snackbarTitle} close={() => setOpenSnackbar(false)} />

            <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>Name</TableCell>
                            <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>Author</TableCell>
                            <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>Live</TableCell>
                            <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>Questions</TableCell>
                            <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>level</TableCell>
                            <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>Category</TableCell>
                            <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>Date</TableCell>
                            <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
    {loading && (
        <TableRow>
            <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                Loading...
            </TableCell>
        </TableRow>
    )}

    {!loading && assessmentDetails && assessmentDetails.length > 0 ? (
        assessmentDetails.map((row) => (
            <TableRow key={row.id} sx={{ '&:hover': { backgroundColor: '#f1f1f1' } }}>
                <TableCell sx={{ padding: '10px' }}>{row.title}</TableCell>
                <TableCell sx={{ marginLeft: '40px' }}>{row?.userDetails?.first_name}</TableCell>
                <TableCell sx={{ marginLeft: '40px' }}>
                    <Switch
                        checked={row?.is_published === true}
                        sx={{
                            '& .MuiSwitch-switchBase': {
                                color: row?.is_published ? 'green' : 'default',
                            },
                            '& .Mui-checked': {
                                color: 'green',
                            },
                            '& .Mui-Track': {
                                backgroundColor: row?.is_published ? 'green' : 'default',
                            },
                        }}
                        onChange={() => {
                            const newValue = !row?.is_published;
                            handleToggleButton(row.id, newValue); 
                        }}
                    />
                </TableCell> 
                <TableCell sx={{ marginLeft: '40px' }}>{row.questionsDetails?.length}</TableCell>
                <TableCell sx={{ marginLeft: '40px' }}>{row?.complexity_level}</TableCell>
                <TableCell sx={{ marginLeft: '40px' }}>{row?.categoryDetails?.description}</TableCell>
                <TableCell sx={{ padding: '10px' }}>
                    {moment(row.create_datetime).format("MMM Do YY")}
                </TableCell>
                <TableCell sx={{ padding: '10px' }}>
                    <IconButton id="editassessmentgeneral" onClick={() => handleEdit(row)} color="primary">
                        <EditIcon />
                    </IconButton>
                    <IconButton id="deleteassessmentgeneral" onClick={() => handleDelete(row)} color="primary">
                        <DeleteIcon />
                    </IconButton>
                    <IconButton id="deleteassessmentgeneral" onClick={() => handleSimulation(row)} color="primary">
                        <PlayCircleFilledWhiteIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
        ))
    ) : (
        <TableRow>
            <TableCell colSpan={8} style={{ textAlign: 'center' }}>
                {!loading && assessmentDetails && assessmentDetails.length === 0  && "No Data Found"}
            </TableCell>
        </TableRow>
    )}
</TableBody>




                </Table>
            </TableContainer>

            <TablePagination
                component="div"
                count={assessmentDetails?.length || 0}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 15, 20, 25]}
                sx={{ marginTop: 2 }}
            />



        </>
        :
        <AssessmentSimulation  data={simulationData} handleBack={handleBack}/>}
        </>
    );
};

export default Assessment;
