// 1
import React, { useEffect, useMemo, useState } from 'react';
import { Box, Grid, Typography, Container, Rating, Button, IconButton, CardMedia } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import GridOnIcon from '@mui/icons-material/GridOn';
import ViewListIcon from '@mui/icons-material/ViewList';
import { makeStyles } from '@mui/styles';
import CardContent from '@mui/material/CardContent';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import { transform } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import PersonIcon from '@mui/icons-material/Person';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import SwiperCore, { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import helper from '../../../utils/helper';
import Page from '../../../components/Page';
import UserCard from '../../../components/cards/Coursecard';
import courseApi from '../../../services/users/courseApi';
import CardSkeleton from '../../../components/Skeleton/cardSkeleton';
import Background from '../../../assets/logo/myCourseBackground.svg';
import trialAndStripeSubscriptionService from '../../../services/trialAndStripeSubscriptionService';
import {
    openSubscriptionModal,
    setOpenSubscriptionModalDetails,
    openSnackbar,
    setSubscribedCourses,
  } from '../../../store/reducer';
  import {
    getExcelDetails, getOfficeDetails, getAccFinDetails, getCodingDetails, getPowerPointDetails, getRecomdedCourseList, getCertificateList, getAllFreeCourses,
    getAllcourseDetails, getSearchedCourse, getCategoryDetails, getErgDetails, getWordCourse
  } from '../../../Redux/Action'

SwiperCore.use([Navigation, Pagination, Mousewheel, Keyboard]);
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function RecomendedCourse(props) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const navigate = useNavigate();
    const [courseList, setCourseList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [courseGroups, setCourseGroup] = useState([]);
    const [isSubscribing, setIsSubscribing] = useState(null);
    const [isTrialing, setIsTrialing] = useState(null);
    const [intfrequency, setintfrequency] = React.useState(1);
    const { openSubscriptionModalDetails, userInfo } = useSelector((state) => state);
    const userRole = useSelector((state) => state.userInfo && state.userInfo.role);
    const userid = useSelector((state) => state.userInfo && state.userInfo.id);
    const [currency, setCurrency] = React.useState(userInfo?.currencyType ? userInfo?.currencyType.code : 'USD');
    React.useMemo(() => {
        if (props.courseGroups?.length > 0) {
            const newArray = [props.courseGroups];
            setCourseGroup(newArray)
        }
        else {
            const newArray1 = [];
            setCourseGroup(newArray1)
        }
    }, [props])

    const style = {
        marginTop: '14px',
    };

    useEffect(() => {
        const script = document.getElementById('zsiqscript');
        if (script) {
            script.parentNode.removeChild(script);
        }
    }, []);


    useEffect(() => {
        getCourserList();
    }, []);


    const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
        ({ theme }) => ({
            [`& .${tooltipClasses.tooltip}`]: {
                backgroundColor: theme.palette.common.white,
                color: 'rgba(0, 0, 0, 0.87)',
                boxShadow: theme.shadows[2],
                fontSize: 11,
            },
        })
    );


    const getCourserList = async () => {
        const response = await courseApi.getCourseList();
        if (response.ok) {
            setCourseList(response.data);
            setLoading(false);

            const individualCourses = response.data.filter((item) => item.isBundle === false);
            const bundleCourses = response.data.filter((item) => item.isBundle);
            const grouped = Array.from(
                bundleCourses
                    .reduce((m, o) => m.set(o.subscriptionPlanId, (m.get(o.subscriptionPlanId) || []).concat(o)), new Map())
                    .values()
            );
            // setCourseGroup([[...individualCourses], ...grouped]);

            if (userRole === 'USER_DTC' && response.data?.length === 0) {
                navigate('/auth/subscribe');
            }
        }
    };

    const handleClickTrialButton = async (planId, trialLoadingId, enrollmentType, courseId) => {
        setIsTrialing(trialLoadingId);
    
        try {
            const res = await trialAndStripeSubscriptionService.postTrialCourseEnrollmentDetails(
                JSON.stringify({ planId, enrollmentType, courseId })
            );
            if (res.ok) {
                getCourserList();
                dispatch(openSnackbar('Trial Course Subscribed Successfully.'));
                navigate('/auth/subscribe');
                // callbackFunctionRecomended()
                dispatch(getAllcourseDetails())
            } else{
                // if (res?.data?.message) {
                    dispatch(openSnackbar(res?.data?.message ? res?.data?.message: 'Failed to Subscribe Trial Course, Please try again.'));
                // } else {
                //     dispatch(openSnackbar('Failed to Subscribe Trial Course, Please try again.'));
                // }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsTrialing(null); 
        }
    };
    
    // const handleClickTrialButton = async (planId, trialLoadingId, enrollmentType, courseId) => {
    //     setIsTrialing(trialLoadingId);
    
    //     try {
    //       const res = await trialAndStripeSubscriptionService.postTrialCourseEnrollmentDetails(
    //         JSON.stringify({planId, enrollmentType, courseId })
    //       );
    //       if (res.ok) {
    //         getCourserList();
    //         dispatch(openSnackbar('Trial Course Subscribed Successfully.'));
    //         // setCardView("MyLearning")
    //         // setOpenNewCourses(false)
    //         navigate('/auth/subscribe');
    
    //       } else {
    //         if (res?.data?.message) {
    //           dispatch(openSnackbar(res.data.message));
    //         } else {
    //           dispatch(openSnackbar('Failed to Subscribe Trial Course, Please try again.'));
    //         }
    //       }
    //     } catch (error) {
    //       console.log(error);
    //     }
    //     setIsTrialing(null);
    //   };


      const postSubscribeDetails = async (item, planId) => {
        const courseId = item.id;
        if (item.isPaid) {
          let impactClickId = '';
          try {
            impactClickId = await helper.getClickId();
          } catch (error) {
            console.error(error);
            impactClickId = '';
          }
      
          const payload = {
            userId:userid,
            courseId,
            planId,
            currencyType: currency,
            frequency: parseInt(intfrequency, 10), 
            impactClickId,
          };
      
          try {
            const res = await trialAndStripeSubscriptionService.createStripeSubscription(
              JSON.stringify(payload)
            );
            if (res.status === 303) {
              window.location = res.data.paymentUrl;
            } else {
              const message = res?.data?.message || 'Failed to Subscribe, Please try again.';
              dispatch(openSnackbar(message));
            }
          } catch (error) {
            console.log(error);
          }
        } else {
          setIsSubscribing(planId);
          try {
            const res = await trialAndStripeSubscriptionService.createStripeSubscription(
              JSON.stringify({ planId })
            );
            if (res.ok) {
              navigate('/auth/subscribe');
              dispatch(getAllcourseDetails())
            // callbackFunctionRecomended()
              dispatch(openSnackbar('Course subscribed successfully'));
            } else {
              const message = res?.data?.message || 'Failed to subscribe, please try again.';
              dispatch(openSnackbar(message));
            }
          } catch (error) {
            console.log(error);
          } finally {
            setIsSubscribing(null); // Ensure this is reset even if there's an error
          }
        }
      };
      
    const handleCardClickItem = (data) => {
        if (userRole === 'USER_DTC') {
            navigate('/auth/course-details', { state: data });
        } else {
            navigate('/app/course-details', { state: data });
        }
    };


    const splitText = (text, maxWords) => {
        const words = text.split(' ');
        if (words.length > maxWords) {
            const firstLine = words.slice(0, maxWords).join(' ');
            const secondLine = words.slice(maxWords).join(' ');
            return [firstLine, secondLine];
        }
        return [text];
    };

    function LinearProgressWithLabel({ value }) {
        return (
            <Box>
                <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '16px !important' }} mb={0.5}>
                    <Box sx={{ width: '100%', mr: 1 }}>
                        <LinearProgress variant="determinate" value={value} />
                    </Box>
                    <Box sx={{ width: 'max-content' }}>
                        <Typography variant="body2" color="gray" sx={{ fontSize: '0.7rem' }}>
                            {value}%
                        </Typography>
                    </Box>
                </Box>
                <Typography variant="body2" color={'primary'} sx={{ fontSize: '0.7rem', marginTop: '-8px' }}>
                    Completed
                </Typography>
            </Box>
        );
    }

    const ButtonGrp = () => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
            <IconButton>
                <SearchIcon fontSize="medium" style={{ color: '#BCBCBC' }} />
            </IconButton>
            <IconButton>
                <FilterAltIcon fontSize="medium" style={{ color: '#BCBCBC' }} />
            </IconButton>
            <IconButton>
                <GridOnIcon fontSize="medium" style={{ color: '#00B673' }} />
            </IconButton>
            <IconButton>
                <ViewListIcon fontSize="medium" style={{ color: '#BCBCBC' }} />
            </IconButton>
        </div>
    );

    return (

        <Grid>
            <Page title="Course-list" style={style}>
                <Container maxWidth={false} sx={{ padding: '0 !important' }}>


                    <Grid container spacing={2}>

                        {loading ? (
                            <>
                                {[1, 2, 3, 4].map((res) => (
                                    <Grid item xs={12} sm={4} md={4} lg={3} key={res}>
                                        <CardSkeleton />
                                    </Grid>
                                ))}
                            </>
                        ) : (
                            <>
                                {userRole === 'AUTH_USER' && courseList.map((item) => (

                                    <>
                                        {item.type === 'simulation' && (
                                            <Grid item xs={12} sm={4} md={4} lg={3} xl={3} key={item.id}>

                                                <UserCard
                                                    handleCardClick={() => handleCardClickItem(item)}
                                                    image={item.thumbImage}
                                                    planStatus={item.planStatus}
                                                    title={item.title}
                                                    trial={item.isTrial}
                                                    subscribed={item.isSubscribed}
                                                    category={item?.category_description || item?.category?.description || item?.category_code}
                                                    logo={item?.category_image ? item?.category_image : item?.category?.categoryImgUrl}
                                                    enrollDate={item.validFrom}
                                                    progress={<LinearProgressWithLabel value={item.completed} />}
                                                    data={item}
                                                    paid={item?.isPaid}
                                                />
                                            </Grid>
                                        )}
                                    </>
                                ))}

                                {courseGroups && courseGroups?.length > 0 && courseGroups[0]?.length > 0 ? courseGroups.map((res, indx) => {
                                    return (

                                        <>
                                            {res?.length > 0 &&
                                                <Grid item xs={12} sm={12} mb={res?.length > 0 ? 4 : 0} key={indx}>

                                                    <>
                                                        {/* <Grid style={{ backgroundColor: '#F8F9FA', maxHeight: '510px', minHeight: '510px', padding: '16px 0 0 0' }}> */}
                                                        <Grid style={{ backgroundColor: '#F8F9FA', maxHeight: '460px', minHeight: '460px', padding: '16px 0 0 0' }}>

                                                            {/* <Typography
                                                                style={{ marginLeft: 28 }}
                                                                variant="h4"
                                                                gutterBottom
                                                            >
                                                                1                                                            </Typography> */}

                                                            <Typography style={{ marginLeft: 26, fontWeight: 'bold', marginBottom: 10 }} variant="h5" component="div" fontWeight="bold">
                                                                Recommended Courses for You
                                                            </Typography>
                                                            <Swiper
                                                                spaceBetween={10}
                                                                slidesPerView={4}
                                                                breakpoints={{
                                                                    1000: {
                                                                        slidesPerView: 4,
                                                                    },
                                                                    600: {
                                                                        slidesPerView: 2,
                                                                    },
                                                                    0: {
                                                                        slidesPerView: 1,
                                                                    },
                                                                }}
                                                                navigation={res?.length > 4}
                                                                pagination={{
                                                                    el: res.length > 4 ? `.${classes.swiperPagination}` : "",
                                                                    clickable: res.length > 4,
                                                                    bulletClass: 'custom-bullet',
                                                                    bulletActiveClass: 'custom-bullet-active'
                                                                }}
                                                                keyboard
                                                                className="mySwiper"
                                                            >
                                                                {res && res?.length > 0 && res.map((course, index) => (

                                                                    // type={item.type}
                                                                    // handleCardClick={() => handleCardClickItem(item)}
                                                                    // image={item.cover_image_url}
                                                                    // planStatus={item.planStatus}
                                                                    // title={item.title}
                                                                    // trial={item.is_trial ? item.is_trial : item.isTrial}
                                                                    // subscriptionPlanId={item?.subscriptionPlanId || item?.subscriptionplanid}
                                                                    // handleTrial={handleClickTrialButton}
                                                                    // handleSubcribe={postSubscribeDetails}
                                                                    // subscribed={item.isSubscribed}
                                                                    // category={item?.category_description ? item?.category_description : item?.category.description}
                                                                    // logo={item?.category_image ? item?.category_image : item?.category?.categoryImgUrl}
                                                                    // // logo={item.category.categoryImgUrl}
                                                                    // enrollDate={item.validFrom}
                                                                    // progress={<LinearProgressWithLabel value={item.completed ? item.completed : 0} />}
                                                                    // // progress={<LinearProgressWithLabel value={item.completed} />}
                                                                    // data={item}
                                                                    // comingfrom={cardViewSubMenu}
                                                                    // paid ={item?.isPaid}  

                                                                    <SwiperSlide key={index}>                                                                        
                                                                        <UserCard
                                                                            type={course.type}
                                                                            handleCardClick={() => handleCardClickItem(course)}
                                                                            image={course.cover_image_url}
                                                                            planStatus={course.status ? String(course.status) : ''}
                                                                            title={course.title}
                                                                            trial={course.is_trial || false}
                                                                            isTrial={course.is_trial}
                                                                            is_free={course.is_free}
                                                                            subscribed={course.isSubscribed}                                                                            subscriptionPlanId={course?.subscriptionPlanId || course?.subscriptionplanid}
                                                                            handleTrial={handleClickTrialButton}
                                                                            handleSubcribe={postSubscribeDetails}
                                                                            category={course?.category_description || course?.category?.description || course?.category_code}
                                                                            // logo={course?.category_image ? course?.category_image : course?.category?.categoryImgUrl ? course?.category?.categoryImgUrl:course?.category_thumb_img_url}
                                                                            logo={course?.category_image || course?.category?.categoryImgUrl || course?.category_thumb_img_url || course?.thumb_img_url}
                                                                            enrollDate={course.enrollDate}
                                                                            progress={<LinearProgressWithLabel value={course.progress || 0} />}
                                                                            data={course}
                                                                            comingfrom={"RecomendedCourses"}
                                                                            paid ={course?.isPaid}  
                                                                        />
                                                                    </SwiperSlide>
                                                                ))}
                                                            </Swiper>

                                                            {/* <div className={classes.swiperPagination} /> */}
                                                        </Grid>


                                                    </>


                                                </Grid>}
                                        </>
                                    )
                                }) : ""}
                            </>
                        )}
                    </Grid>
                </Container>
            </Page>
        </Grid>

    );

}

const useStyles = makeStyles((theme) => ({
    coursediv: {
        height: '10rem',
        backgroundImage: `url(${Background})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        marginTop: '4rem',
    },
    title: {
        padding: '4rem 0rem 0rem 0rem',
    },
    scrollContainer: {
        overflowX: 'auto',
        whiteSpace: 'nowrap',
        '-webkit-overflow-scrolling': 'touch',
    },
    swiperPagination: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '10px',

    },
    '@global': {
        '.custom-bullet': {
            width: '8px',
            height: '8px',
            background: '#000',
            margin: '0 5px',
            borderRadius: '50%',
            opacity: 0.5,
            cursor: 'pointer',
        },
        '.custom-bullet-active': {
            opacity: 1,
            background: '#789df5',
        },
        '.swiper-button-next, .swiper-button-prev': {
            width: '25px',
            height: '25px',
            // color:'#054df7',
            // backgroundColor: '#789df5',
            backgroundColor: '#000000',

            borderRadius: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            margin: '0px',
            '&::after': {
                fontSize: '14px',
                color: '#fff',
            },
        },

        // '.mySwiper .swiper-button-next':{
        //    display: 'none'
        // },
        '.makeStyles-swiperPagination-68': {
            marginTop: '22px'
        },
        //   '.mySwiper .swiper-button-prev':{
        //     display: 'none'
        //  },
        '.makeStyles-swiperPagination-140': {
            marginTop: '22px !important'
        },
        '.MuiGrid-root.css-vj1n65-MuiGrid-root': {
            position: 'relative'
        },
        '.swiper-button-next': {
            right: '0px',
        },
        '.swiper-button-prev': {
            left: '0px',
        },
        // '.': {
        //   left: '0px',
        // },
        '.swiper-container-pointer-events': {
            zIndex: '999',
            overflow: 'hidden',
            maxWidth: '95%',
            margin: 'auto'
        },
        '.cardhover': {
            transform: 'transform 0.3s ease',
            boxShadow: '0.3s eases',
            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
            zIndex: 1,
        },
        '.cardhover:hover': {
            transform: 'scale(1.05)',
            boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.3)',
            zIndex: 10, // Increase z-index on hover to avoid overlap
            position: 'relative', // Ensure the card stays in the same layer as it scales
            //     transform: scale(1.05); /* Slightly increase the size */
            //     box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3); /* Add shadow effect */
        }



    },

    trial: {
        color: 'white',
        padding: '0px 6px 0px 6px ',
        background: 'grey',
    },
    card: {
        border: '0.5px solid #DFDFDF',
        boxShadow: '0px 3px 6px  #0000001A',
        borderRadius: '6px',
        backgroundImage: `url(${Background})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom right',
        cursor: 'pointer',
        marginTop: '16px',
        marginLeft: '16px',
        marginRight: '16px',
        width: '100%'
    },
    date: {
        marginTop: '15px',
    },
    button: {
        backgroundColor: '#F8F5F4',
        padding: '4px 6px',
        borderRadius: '6px',
        width: 'max-content',
    },
}));